import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { TableField } from 'models';

/** Table field state entity. */
export type TableFieldsState = EntityState<TableField, number>;

/** Table field store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'TableFields', resettable: true })
export class TableFieldsStore extends EntityStore<TableFieldsState> {
  constructor() {
    super();
  }
}
