/**
 * Command registered to the command palette.
 */
export class Command {
  /** Function to perform on execution. */
  action: () => void;
  /** A brief description of the command's function. */
  description: string;
  /** Optional filter prefix such as '@' character before the name.
   *
   * The command will **only** show in the palette if the search query starts with this prefix.
   */
  filterPrefix?: string | undefined;
  /** Classification of the command. */
  group: string;
  /** Display name. */
  name: string;

  constructor(
    command: string,
    description: string,
    action: () => void,
    group: string = 'Global',
    filterPrefix?: string | undefined
  ) {
    // TODO add some validation around name and description etc. to keep strings appropriate.
    this.name = command;
    this.description = description;
    this.action = action;
    this.group = group;
    this.filterPrefix = filterPrefix;
  }
}
