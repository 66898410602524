<!-- A hidden div is created to set the position of appearance of the menu-->
<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="actionMenu"
></div>
<mat-menu #actionMenu="matMenu" (closed)="onMenuClosed()">
  <button mat-menu-item (click)="onClickOpenDocuments()" *ngIf="!hideOpen">
    <span transloco="OPEN_DOCUMENTS"></span>
  </button>
  <button
    *ngIf="!hideOpenFullViewer"
    mat-menu-item
    (click)="onClickOpenDocuments(true)"
  >
    <span transloco="OPEN_IN_FULL_VIEWER"></span>
  </button>
  <mat-divider *ngIf="!hideOpen || !hideOpenFullViewer"></mat-divider>
  <ng-container *ngIf="isGseConnected$ | async">
    <button
      mat-menu-item
      [disabled]="!inbox.permissions.emailDocuments"
      [matMenuTriggerFor]="emailAnnotationsMenu"
    >
      <span transloco="EMAIL"></span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>
  <button mat-menu-item (click)="onClickCopy()">
    <span transloco="COPY"></span>
  </button>
  <button mat-menu-item (click)="onClickExport()">
    <span transloco="DOWNLOAD"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="!hideMultiple"
    [disabled]="mergeDisabled"
    (click)="onClickMerge()"
  >
    <span transloco="MERGE"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="!inbox.permissions.moveDocuments"
    (click)="onClickMove()"
  >
    <span transloco="MOVE"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="!inbox.permissions.moveDocuments"
    (click)="onClickIndex()"
  >
    <span transloco="INDEX"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="!inbox.permissions.deleteDocuments"
    (click)="onClickDelete()"
  >
    <span transloco="DELETE"></span>
  </button>
</mat-menu>
<mat-menu #emailAnnotationsMenu="matMenu">
  <button mat-menu-item (click)="onClickEmail(true)">
    <span transloco="ANNOTATIONS"></span>
  </button>
  <button mat-menu-item (click)="onClickEmail(false)">
    <span transloco="NO_ANNOTATIONS"></span>
  </button>
</mat-menu>
