import { assert, assertExists } from 'common';
import {
  PDFImage,
  PDFPage,
  PDFPageDrawImageOptions,
  StandardFonts,
  degrees,
} from 'pdf-lib';

import { Color, ConvertableAnnotation, IDocumentAnnotationBase } from '.';

/**
 * Stamp annotation.
 *
 * Stamp annotations have their text fill a box with a matching border on a
 * single line.
 *
 * They are rendered to the PDF view as an image due to library limitations
 * with SVG and text formatting. This is required until there is support for
 * https://github.com/Hopding/pdf-lib/issues/1135. At which point it can be
 * rendered as regular text plus a rectangle.
 *
 * @todo Still missing support for styles.
 */
export class StampAnnotation
  extends ConvertableAnnotation
  implements IDocumentAnnotationBase
{
  /**
   * False for this type because the font is not embedded to the PDF on render.
   *
   * @inheritdoc
   */
  hasFont = false;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    /** Width of the text area. */
    public width: number,
    /** Height of the text area. */
    public height: number,
    /** Font size. */
    public fontSize: number,
    /** Text. */
    public text: string,
    /** Color. */
    public color: Color = new Color(0, 0, 0),
    /**
     * Font.
     *
     * @todo We may want to implement our own list of fonts rather than the PDF-Lib ones.
     */
    public font: StandardFonts = StandardFonts.TimesRoman,
    public rotation: number = 0,
    public visible: boolean = true,
    /**
     * Embedded image for annotation.
     */
    private embeddedImage?: PDFImage
  ) {
    super();
  }

  /** @inheritdoc */
  get requiredFonts(): StandardFonts[] {
    return [this.font];
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    // Generate options.
    const options = this.toPdfImageOptions();
    // Update scale.
    if (scalePixelsToPoints) this.convertPixelsToPoints(options);

    this.adjustForRotation(page, options);

    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    // Render on page.
    assertExists(
      this.embeddedImage,
      'Annotation is missing the required embedded image.'
    );
    page.drawImage(this.embeddedImage, options);
  }

  /**
   * Set the image embedded for this annotation.
   *
   * @param image Embedded image.
   */
  embedImage(image: PDFImage) {
    this.embeddedImage = image;
  }

  /**
   * Convert the stamp to a PNG, and get the data URL.
   *
   * @returns Data URL of PNG.
   */
  toPng(): string {
    return this.createStampPng(
      this.text,
      undefined,
      this.width,
      this.height,
      this.color.hexCode,
      this.color.opacity
    );
  }

  /**
   * Draw a stamp as a PNG.
   *
   * This allows us to use canvas based text rendering techniques to "fit", but add it to a PDF as an element.
   *
   * @todo Fix font mapping.
   * @todo Rotation is still a bit odd, I believe its an issue of atalasoft rotating on a corner vs pdf on center or similar.
   *
   * @param text Text to display.
   * @param fontFamily Font family.
   * @param width Width of stamp.
   * @param height Height of stamp.
   * @param color Color of text and border.
   * @param opacity Opacity.
   * @param radius Border edge raidus.
   * @param padding Padding between text and border.
   * @param borderWidth Thickness of the border.
   *
   * @returns PNG as Data URL.
   */
  private createStampPng(
    text: string,
    fontFamily: string = 'sans-serif',
    width: number = 300,
    height: number = 100,
    color: string = 'red',
    opacity: number = 1,
    radius: number = 10,
    padding: number = 10,
    borderWidth: number = 15
  ) {
    // Creates a canvas.
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    // Set the opacity.
    context.globalAlpha = opacity;
    // Draw the text, fitting withing the given height and width.
    context.font = `bold ${height - borderWidth * 2}px ${fontFamily}`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = color;
    context.fillText(
      text,
      width / 2,
      height / 2,
      width - 2 * padding - borderWidth
    );
    // Draw a rectangle with round corners around the text.
    context.strokeStyle = color;
    context.lineWidth = borderWidth;
    (context as any).roundRect(
      borderWidth / 2,
      borderWidth / 2,
      width - borderWidth,
      height - borderWidth,
      radius
    );
    context.stroke();
    // Render the canvas to a PNG.
    return canvas.toDataURL();
  }

  private adjustForRotation(page: PDFPage, options: PDFPageDrawImageOptions) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.width);
    assertExists(options.height);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    options.rotate = degrees(
      ((options.rotate?.angle ?? 0) + pageRotation.angle) % 360
    );
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        // The x value requires adding the image height to correct corner.
        options.x = options.y + options.height;
        // The y value requires subtracting page height, and the object height
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.height;
        break;
      case 180:
        // Subtract x value from page width.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height, then the height x2 to offset corner.
        options.y = page.getHeight() - options.y - options.height * 2;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.x = page.getWidth() - options.y - options.height;
        // Subtract the object height from the original x coordinate to offset.
        options.y = xOrig - options.height;
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Convert the annotation to PDF element drawing options as image.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfImageOptions(): PDFPageDrawImageOptions {
    const options: PDFPageDrawImageOptions = {
      x: this.x,
      y: this.y,
      height: this.height,
      width: this.width,
      rotate: degrees(this.rotation * -1),
    };
    return options;
  }
}
