import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Inbox } from 'models';

export interface InboxesState extends EntityState<Inbox>, ActiveState {}

/** Inboxes Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inboxes', resettable: true })
export class InboxesStore extends EntityStore<InboxesState> {
  constructor() {
    super();
  }
}
