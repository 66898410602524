<div class="thumbnail-container">
  <ng-container
    *ngIf="isGuest; then guestMode; else standardMode"
  ></ng-container>
  <ng-template #guestMode>
    <button
      aria-label="Thumbnail Preview Button"
      *ngIf="isPdf || isPdfConvertable; else download"
      [ngClass]="{ 'pdf-preview-open': isPdfPreviewOpen$ | async }"
      mat-icon-button
      mat-large-icon-button
      (click)="onClickThumbnailIcon($event)"
    >
      <mat-icon>preview</mat-icon>
    </button>
    <ng-template #download>
      <div
        [matTooltip]="'NO_EXPORT_PERMISSION_MSG' | transloco"
        [matTooltipDisabled]="permissions.exportDocuments"
        matTooltipPosition="right"
      >
        <button
          aria-label="Download Document Button"
          [disabled]="!permissions.exportDocuments"
          mat-icon-button
          mat-large-icon-button
          (click)="onClickDownload($event)"
        >
          <mat-icon>{{
            permissions.exportDocuments ? 'file_download' : 'file_download_off'
          }}</mat-icon>
        </button>
      </div>
    </ng-template>
  </ng-template>
  <ng-template #standardMode>
    <button
      aria-label="Thumbnail Preview Button"
      mat-icon-button
      mat-large-icon-button
      (click)="onClickThumbnailIcon($event)"
      [ngClass]="{ 'pdf-preview-open': isPdfPreviewOpen$ | async }"
    >
      <mat-icon *ngIf="!isSvgIcon">{{ icon }}</mat-icon>
      <mat-icon *ngIf="isSvgIcon" [svgIcon]="icon"></mat-icon>
    </button>
  </ng-template>
</div>
