<ng-container *ngIf="archive$ | async as archive">
  <mat-toolbar>
    <app-left-sidebar-toggle-button
      *ngIf="!isGuest && (useCompactLayout$ | async) === false"
    ></app-left-sidebar-toggle-button>
    <app-search-result-archive-selector></app-search-result-archive-selector>
    <button
      mat-icon-button
      [matTooltip]="'REFINE_SEARCH' | transloco"
      matTooltipShowDelay="1000"
      (click)="clickRefineSearch()"
    >
      <mat-icon>find_replace</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'REFRESH' | transloco"
      matTooltipShowDelay="1000"
      (click)="onRefreshSearch()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      *ngIf="!isGuest"
      [disabled]="isEditDisabled"
      mat-icon-button
      [matTooltip]="'TOGGLE_EDIT' | transloco"
      matTooltipShowDelay="1000"
      (click)="clickToggleEdit()"
    >
      <mat-icon [class.edit-enabled]="editModeEnabled">edit</mat-icon>
    </button>
    <button
      *ngIf="!isGuest; else guestControls"
      mat-icon-button
      [disabled]="isNoneSelected"
      [matTooltip]="'ACTIONS' | transloco"
      matTooltipShowDelay="1000"
      (click)="actionMenu.openMenu($event, selectedSearchResults)"
    >
      <mat-icon [matBadge]="selectedCount" [matBadgeHidden]="isNoneSelected"
        >assignment_turned_in</mat-icon
      >
    </button>
    <ng-template #guestControls>
      <button
        (click)="onOpenDocuments()"
        mat-icon-button
        [disabled]="isNoneSelected"
      >
        <mat-icon
          [matTooltip]="'OPEN_DOCUMENTS' | transloco"
          matTooltipShowDelay="1000"
          matTooltipPosition="right"
          [matBadge]="selectedCount"
          [matBadgeHidden]="isNoneSelected"
          >file_open</mat-icon
        >
      </button>
    </ng-template>
    <span class="spacer"></span>
    <ng-container
      *ngIf="(useCompactLayout$ | async) === false; else mobileMoreMenu"
    >
      <button
        *ngIf="!isGuest"
        [ngClass]="{ favorited: (search$ | async)?.isFavorite }"
        mat-icon-button
        [matTooltip]="'FAVORITE' | transloco"
        matTooltipShowDelay="1000"
        (click)="clickFavorite()"
      >
        <mat-icon>star</mat-icon>
      </button>
      @if (shareLinksSupported) {
        <button
          *ngIf="!isGuest"
          mat-icon-button
          (click)="clickShare()"
          [matTooltip]="'SHARE' | transloco"
          matTooltipShowDelay="1000"
          matTooltipPosition="left"
          [disabled]="!isSingleSelection"
        >
          <mat-icon>share</mat-icon>
        </button>
      } @else {
        <div
          [matTooltip]="shareLinksUnsupportedMessage"
          matTooltipShowDelay="1000"
        >
          <button
            *ngIf="!isGuest"
            mat-icon-button
            matTooltipPosition="left"
            [disabled]="true"
          >
            <mat-icon>share</mat-icon>
          </button>
        </div>
      }
    </ng-container>
    <ng-template #mobileMoreMenu>
      <button
        aria-label="More Actions Menu"
        *ngIf="!isGuest"
        mat-icon-button
        [matMenuTriggerFor]="moreMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #moreMenu="matMenu">
        <button
          mat-menu-item
          [ngClass]="{ favorited: (search$ | async)?.isFavorite }"
          (click)="clickFavorite()"
        >
          <mat-icon>star</mat-icon>
          {{ 'FAVORITE' | transloco }}
        </button>
        @if (shareLinksSupported) {
          <button
            mat-menu-item
            (click)="clickShare()"
            [disabled]="!isSingleSelection"
          >
            <mat-icon>share</mat-icon>
            {{ 'SHARE' | transloco }}
          </button>
        } @else {
          <div
            [matTooltip]="shareLinksUnsupportedMessage"
            matTooltipShowDelay="1000"
          >
            <button
              mat-menu-item
              *ngIf="!isGuest"
              matTooltipPosition="left"
              [disabled]="true"
            >
              <mat-icon>share</mat-icon>
              {{ 'SHARE' | transloco }}
            </button>
          </div>
        }
      </mat-menu>
    </ng-template>
    <app-right-sidebar-toggle-button></app-right-sidebar-toggle-button>
  </mat-toolbar>
</ng-container>
