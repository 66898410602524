import { assert } from 'common';

/**
 * A requested document for an archive.
 *
 * The string representation of an `ArchiveRequestedDocument` is a string in the form of
 * `<docId>.<secureId>`.
 *
 * @example
 * const doc1 = new ArchiveRequestedDocument(123, 'secureId');
 * console.log(doc1.toString()); // '123.secureId'
 *
 * const doc2 = new ArchiveRequestedDocument('123.secureId');
 * console.log(doc2.toString()); // '123.secureId'
 */
export class ArchiveRequestedDocument {
  /** Document Id. */
  docId: number;
  /** Secure Id. */
  secureId: string;

  constructor(docIdOrString: number | string, secureId?: string) {
    if (typeof docIdOrString === 'string') {
      const [docId, secureId] = docIdOrString.split('|');
      this.docId = Number(docId);
      this.secureId = secureId;
    } else {
      assert(typeof secureId === 'string' && secureId.length > 0),
        "SecureId can't be empty when docId is a number";
      this.docId = docIdOrString;
      this.secureId = secureId;
    }
  }

  /**
   * Returns a string representation of the ArchiveRequestedDocument object.
   *
   * @return {string} The string representation of the object in the format "<docId>|<secureId>".
   */
  toString(): string {
    return `${this.docId}|${this.secureId}`;
  }

  /**
   * Checks if a string has exactly two parts separated by a pipe.
   *
   * @param {string} str - The string to be checked.
   * @return {boolean} Returns true if the string has exactly two parts separated by a period, otherwise false.
   */
  static looksLike(str: string): boolean {
    // Looks like a `docId|secureId`, e.g. `123|0000-0000-0000-0000`.
    return str.split('|').length === 2;
  }
}
