import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { AuthenticationProviderConfiguration } from 'models';

/**
 * Azure MSAL instance factory.
 *
 * @param azureConfig Azure authentication provider config or undefined if it is not configured.
 * @returns An Azure client application.
 */
export const msalInstanceFactory = (
  azureConfig: AuthenticationProviderConfiguration | undefined
): IPublicClientApplication => {
  if (!azureConfig) {
    return new PublicClientApplication({
      auth: {
        clientId: '',
      },
    });
  }

  const redirectUrl = [
    location.protocol,
    '//',
    location.host,
    '/login',
    '/redirect',
  ].join('');

  return new PublicClientApplication({
    auth: {
      clientId: azureConfig.clientId,
      authority: azureConfig.orgUrl,
      redirectUri: encodeURI(redirectUrl.toLowerCase()),
    },
  });
};
