<h1 mat-dialog-title transloco="FIND_IN_FILE"></h1>
<div mat-dialog-content>
  <mat-form-field class="search-form-field">
    <mat-label>{{ 'SEARCH_TEXT' | transloco }}</mat-label>
    <input
      matInput
      type="text"
      class="form-control"
      [(ngModel)]="data.searchText"
      (keydown.enter)="onRunSearch()"
      #search="ngModel"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close="" transloco="CANCEL"></button>
  <button mat-button (click)="onRunSearch()" transloco color="primary">
    SEARCH
  </button>
</div>
