import {
  CapStyle,
  Color,
  EllipseAnnotation,
  EndCapStyle,
  ImageAnnotation,
  LineAnnotation,
  LineAppearance,
  LineStyle,
  PathAnnotation,
  RectangleAnnotation,
  StampAnnotation,
  TextAnnotation,
} from 'models';
import { StandardFonts } from 'pdf-lib';

export interface LayerDataCollection {
  LayerData: LayerData;
}

/**
 * The sparse object of Atalasoft annotation objects.
 */
export interface LayerDataItems {
  CustomRectangleData?: CustomRectangleData | CustomRectangleData[];
  CustomSignatureData?: EmbeddedImageData | EmbeddedImageData[];
  CustomStampData?: CustomStampData | CustomStampData[];
  CustomTextData?: CustomTextData | CustomTextData[];
  EllipseData?: EllipseData | EllipseData[];
  EmbeddedImageData?: EmbeddedImageData | EmbeddedImageData[];
  FreehandData?: FreehandData | FreehandData[];
  LineData?: LineData | LineData[];
  LinesData?: LinesData | LinesData[];
  RectangleData?: RectangleData | RectangleData[];
}

/**
 * Base LayerData inteface.
 *
 * These properties exist on all layer data objects.
 */
export interface AnnotationBase {
  CanMirror: boolean;
  CanMove: boolean;
  CanResize: boolean;
  CanRotate: boolean;
  CanSelect: boolean;
  CreationTime: string;
  ExtraProperties: ExtraProperties;
  Location: Point;
  ModifiedTime: string;
  Rotation: number;
  Size: Size;
  Visible: boolean;
}

/**
 * Layer root.
 */
export interface LayerData extends AnnotationBase {
  GroupAnnotation: boolean;
  Items: {
    Items: LayerDataItems;
  };
}

export interface Fill {
  Color: number;
  // Used by Legacy Desktop Stamps.
  HatchForeColor?: number;
  ctor: number;
}

export interface Font {
  Bold: boolean;
  CharSet: number;
  Italic: boolean;
  Name: string;
  Size: number;
  Strikeout: boolean;
  Underline: boolean;
}

export interface ExtraProperties {
  ExtraPropertiesEntry: Array<boolean | string>;
}

export interface Image {
  BitCount: number;
  DpiX: number;
  DpiY: number;
  Height: number;
  ImageData: string;
  Width: number;
}

/**
 * Points.
 *
 * @todo I would prefer this could be deserialized without this extra nesting level.
 */
export interface Points {
  Points: Point | Point[] | number[];
}

export interface Cap {
  Size: Size;
  Style: OutlineEndCapStyle;
}

export interface Size {
  height: number;
  width: number;
}

export interface Outline {
  Alignment: Alignment;
  Brush?: Fill;
  Color: number;
  DashCap: DashCap;
  DashOffset: number;
  DashStyle: DashStyle;
  EndCap: Cap;
  LineJoin: DashCap;
  MiterLimit: number;
  StartCap: Cap;
  Width: number;
  ctor: number;
}

export interface Shadow {
  Color: number;
  ctor: number;
}

export interface Point {
  x: number;
  y: number;
}

/// Specific annotation data types here:

export interface RectangleData extends AnnotationBase {
  Fill: Fill;
  Outline: Outline;
  Shadow: Shadow;
  ShadowOffset: Point;
  Translucent: boolean;
}

/**
 * Convert from Atalasoft `RectangleData` to a `RectangleAnnotation`.
 *
 * @param rectangleData `RectangleData` item.
 * @param pageIndex Page index.
 * @returns A new `RectangleAnnotation`.
 */
export const ConvertRectangleDataToRectangleAnnotation = (
  rectangleData: RectangleData,
  pageIndex: number
): RectangleAnnotation => {
  return new RectangleAnnotation(
    pageIndex,
    rectangleData.Location.x,
    rectangleData.Location.y,
    rectangleData.Size.height,
    rectangleData.Size.width,
    {
      caps: CapStyle.Butt,
      color: Color.convertColor(rectangleData.Outline.Color),
      style: LineStyle.Solid,
      thickness: rectangleData.Outline.Width,
    },
    Color.convertColor(rectangleData.Fill.Color),
    rectangleData.Rotation,
    true
  );
};

export interface CustomRectangleData extends AnnotationBase {
  Fill: Fill;
  Tag: string;
  Translucent: boolean;
}

/**
 * Convert from Atalasoft `CustomRectangleData` to a `RectangleAnnotation`.
 *
 * @param customRectangleData `CustomRectangleData` item.
 * @param pageIndex Page index.
 * @returns A new `RectangleAnnotation`.
 */
export const ConvertCustomRectangleDataToRectangleAnnotation = (
  customRectangleData: CustomRectangleData,
  pageIndex: number
): RectangleAnnotation => {
  // Special handlers:
  if (customRectangleData.Tag === 'Highlight') {
    // If highlight created in Legacy Desktop client, replace the fill color
    // with correct transparent yellow fill.
    customRectangleData.Fill.Color = -2130706688;
  }

  return new RectangleAnnotation(
    pageIndex,
    customRectangleData.Location.x,
    customRectangleData.Location.y,
    customRectangleData.Size.height,
    customRectangleData.Size.width,
    {
      // CustomRectangleData is only a fill rectangle, no border data.
      caps: CapStyle.Butt,
      color: new Color(0, 0, 0),
      style: LineStyle.Solid,
      thickness: 0,
    },
    Color.convertColor(customRectangleData.Fill.Color),
    customRectangleData.Rotation,
    true
  );
};

export interface CustomStampData extends AnnotationBase {
  CornerRadius: number;
  Fill: Fill;
  Font: Font;
  FontBrush: Fill;
  Outline: Outline;
  Padding: number;
  Tag: string;
  Text: string;
}

/**
 * Convert from Atalasoft `CustomStampData` to a `StampAnnotation`.
 *
 * @param stampData `CustomStampData` item.
 * @param pageIndex Page index.
 * @returns A new `StampAnnotation`.
 */
export const ConvertCustomStampDataToStampAnnotation = (
  stampData: CustomStampData,
  pageIndex: number
): StampAnnotation => {
  const stampAnnotation = new StampAnnotation(
    pageIndex,
    stampData.Location.x,
    stampData.Location.y,
    stampData.Size.width,
    stampData.Size.height,
    stampData.Font.Size,
    stampData.Text,
    // Include fallover for missing color property from Legacy Desktop annotations.
    Color.convertColor(
      stampData.FontBrush.Color ?? stampData.FontBrush.HatchForeColor
    ),
    undefined,
    stampData.Rotation,
    stampData.Visible
  );
  // Check for font availability, otherwise leave as the default font.
  if (
    Object.values(StandardFonts).includes(stampData.Font.Name as StandardFonts)
  ) {
    stampAnnotation.font = stampData.Font.Name as StandardFonts;
  }
  return stampAnnotation;
};

export interface CustomTextData extends AnnotationBase {
  Alignment: Alignment;
  AutoSize: boolean;
  Fill: Fill;
  Font: Font;
  FontBrush: Fill;
  FormatFlags: number;
  LineAlignment: Alignment;
  Minimized: boolean;
  OCRType: number;
  Outline: Outline;
  Padding: number;
  RenderingHint: string;
  Shadow: Fill;
  ShadowMode: string;
  ShadowOffset: Point;
  Tag: string;
  Text: string;
  Trimming: Style;
}

/**
 * Convert from Atalasoft `CustomTextData` to a `TextAnnotation`.
 *
 * @param customTextData `CustomTextData` item.
 * @param pageIndex Page index.
 * @returns A new `TextAnnotation`.
 */
export const ConvertTextDataToTextAnnotation = (
  customTextData: CustomTextData,
  pageIndex: number
): TextAnnotation => {
  const textAnnotation = new TextAnnotation(
    pageIndex,
    customTextData.Location.x,
    customTextData.Location.y,
    customTextData.Size.width,
    customTextData.Size.height,
    customTextData.Font.Size,
    customTextData.Text,
    Color.convertColor(customTextData.FontBrush.Color),
    undefined, // Mapped to matching standard font bellow.
    {
      caps: CapStyle.Butt,
      color: Color.convertColor(customTextData.Outline.Color),
      style: LineStyle.Solid,
      thickness: customTextData.Outline.Width,
    },
    Color.convertColor(customTextData.Fill.Color),
    customTextData.Rotation,
    customTextData.Visible
  );
  // Check for font availability, otherwise leave as the default font.
  if (
    Object.values(StandardFonts).includes(
      customTextData.Font.Name as StandardFonts
    )
  ) {
    textAnnotation.font = customTextData.Font.Name as StandardFonts;
  }
  return textAnnotation;
};

export interface EllipseData extends AnnotationBase {
  Fill: Fill;
  Outline: Outline;
  Shadow: Shadow;
  ShadowOffset: Point;
  Translucent: boolean;
}

/**
 * Convert from Atalasoft `EllipseData` to a `EllipseAnnotation`.
 *
 * @param ellipseData `EllipseData` item.
 * @param pageIndex Page index.
 * @returns A new `EllipseAnnotation`.
 */
export const ConvertEllipseDataToEllipseAnnotation = (
  ellipseData: EllipseData,
  pageIndex: number
): EllipseAnnotation => {
  return new EllipseAnnotation(
    pageIndex,
    // Atalasoft uses top-right, pdf uses center, adjust here.
    // Convert both starting location and size.
    ellipseData.Location.x + ellipseData.Size.width / 2,
    ellipseData.Location.y + ellipseData.Size.height / 2,
    ellipseData.Size.width / 2,
    ellipseData.Size.height / 2,
    {
      color: Color.convertColor(ellipseData.Outline.Color),
      caps: CapStyle.Butt,
      thickness: ellipseData.Outline.Width,
      style: LineStyle.Solid,
    },
    Color.convertColor(ellipseData.Fill.Color),
    ellipseData.Rotation,
    ellipseData.Visible
  );
};

export interface EmbeddedImageData extends AnnotationBase {
  Image: Image;
  ShadowOffset: Point;
}

/**
 * Convert from Atalasoft `EmbeddedImageData` to a `ImageAnnotation`.
 *
 * @param embeddedImageData `EmbeddedImageData` item.
 * @param pageIndex Page index.
 * @returns A new `ImageAnnotation`.
 */
export const ConvertEmbeddedImageDataToImageAnnotation = (
  embeddedImageData: EmbeddedImageData,
  pageIndex: number
): ImageAnnotation => {
  // Configure an empty Image annotation.
  const annotation = new ImageAnnotation(
    pageIndex,
    embeddedImageData.Location.x,
    embeddedImageData.Location.y,
    embeddedImageData.Size.height,
    embeddedImageData.Size.width,
    '', // Value will be set below.
    embeddedImageData.Image.Height,
    embeddedImageData.Image.Width,
    embeddedImageData.Rotation,
    undefined,
    embeddedImageData.Visible
  );
  // Convert the AtalaImage and set the image to the PNG result.
  annotation.createPngFromAtalaImage(embeddedImageData.Image.ImageData);
  return annotation;
};

export interface FreehandData extends AnnotationBase {
  ClosedShape: boolean;
  Fill: Fill;
  LineType: LineType;
  // This can denote if it is a "Signature", only seems used then.
  Name?: string;
  Outline: Outline;
  Points: Points;
  Rotation: number;
  Size: Size;
  Translucent: boolean;
  // Non-signature, freehand drawings have this property.
  UserName?: string;
  Visible: boolean;
}

/**
 * Convert from Atalasoft `FreehandData` to a `PathAnnotation`.
 *
 * @param freehandData `FreehandData` item.
 * @param pageIndex Page index.
 * @returns A new `PathAnnotation`.
 */
export const ConverFreehandDataToPathAnnotation = (
  freehandData: FreehandData,
  pageIndex: number
): PathAnnotation => {
  return new PathAnnotation(
    pageIndex,
    freehandData.Location.x,
    freehandData.Location.y,
    Color.convertColor(freehandData.Outline.Color),
    freehandData.Outline.Width,
    freehandData.Rotation,
    freehandData.Visible,
    freehandData.Points.Points as number[],
    undefined,
    freehandData.Size.height,
    freehandData.Size.width
  );
};

export interface LineData extends AnnotationBase {
  EndPoint: Point;
  Outline: Outline;
  StartPoint: Point;
  Translucent: boolean;
}

/**
 * Convert from Atalasoft `LineData` to a `LineAnnotation`.
 *
 * @param lineData `LineData` item.
 * @param pageIndex Page index.
 * @returns A new `LineAnnotation`.
 */
export const ConvertLineDataToLineAnnotation = (
  lineData: LineData,
  pageIndex: number
): LineAnnotation => {
  return new LineAnnotation(
    pageIndex,
    lineData.Location.x + lineData.StartPoint.x,
    lineData.Location.y + lineData.StartPoint.y,
    // Atalasoft stores end points relative to start.
    {
      x: lineData.Location.x + lineData.EndPoint.x,
      y: lineData.Location.y + lineData.EndPoint.y,
    },
    new LineAppearance(
      CapStyle.Butt,
      Color.convertColor(lineData.Outline.Color),
      LineStyle.Solid,
      lineData.Outline.Width,
      // Support arrows by setting the end cap styling.
      lineData.Outline.EndCap.Style === OutlineEndCapStyle.FilledArrow
        ? EndCapStyle.Arrow
        : EndCapStyle.None
    ),
    lineData.Size.width,
    lineData.Visible
  );
};

export interface LinesData extends AnnotationBase {
  Outline: Outline;
  Points: Points;
  Translucent: boolean;
}

/**
 * Convert from Atalasoft `LinesData` to a `PathAnnotation`.
 *
 * @param linesData `LinesData` item.
 * @param pageIndex Page index.
 * @returns A new `PathAnnotation`.
 */
export const ConvertLinesDataToPathAnnotation = (
  linesData: LinesData,
  pageIndex: number
): PathAnnotation => {
  return new PathAnnotation(
    pageIndex,
    linesData.Location.x,
    linesData.Location.y,
    Color.convertColor(linesData.Outline.Color),
    linesData.Outline.Width,
    linesData.Rotation,
    linesData.Visible,
    linesData.Points.Points as number[],
    undefined,
    linesData.Size.height,
    linesData.Size.width
  );
};

/// Layer data object enums:
// TODO: These enums are not complete.

export enum Alignment {
  Center = 'Center',
  Near = 'Near',
}

export enum DashCap {
  Round = 'Round',
}

export enum DashStyle {
  Solid = 'Solid',
}

export enum Style {
  None = 'None',
}

export enum OutlineEndCapStyle {
  FilledArrow = 'FilledArrow',
  None = 'None',
}

export enum LineType {
  Straight = 'Straight',
}
