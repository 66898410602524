import { UserAction, UserActions } from 'models';

export interface QueuedDocument {
  Key: string;
  ProcessID: number;
  ProcessStatus: number;
  Name: string;
  Actions: Action[];
  UsersAndGroups: string[];
  UserIsSecuredToQueue: boolean;
}

export interface Action {
  Key: string;
  Name: string;
}

export const createUserAction = (apiActions: Action): UserAction => ({
  key: apiActions.Key,
  name: apiActions.Name,
});

export const createUserActions = (
  queuedDocument: QueuedDocument
): UserActions => queuedDocument.Actions.map(createUserAction);
