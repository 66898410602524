import { TaskSearch, TaskSearchArchive } from 'models';

import { S9ApiArchive, createArchive } from './archive';

/**
 * Creates a 'TaskSearchArchive from a 'S9ApiTaskSearchArchive'.
 *
 * @param apiTaskArchive Square 9 Api task search archive.
 * @returns A task search archive.
 */
export const createTaskSearchArchive = (
  apiTaskArchive: S9ApiTaskSearchArchive
): TaskSearchArchive => {
  const taskArchive = createArchive(
    apiTaskArchive as S9ApiArchive
  ) as TaskSearchArchive;
  taskArchive.processCount = apiTaskArchive.ProcessCount;
  taskArchive.processIds = apiTaskArchive.ProcessIDs;
  return taskArchive;
};

/**
 * Creates a 'TaskSearch' from a 'S9ApiTaskSearch'.
 *
 * @param apiTaskSearch An API task search object.
 * @returns A TaskSearch object.
 */
export const createTaskSearch = (
  apiTaskSearch: S9ApiTaskSearch
): TaskSearch => ({
  id: apiTaskSearch.Id,
  key: apiTaskSearch.Key,
  title: apiTaskSearch.Title,
  workflowId: apiTaskSearch.WorkflowId,
  archives: apiTaskSearch.Archives.map(createTaskSearchArchive),
  processCount: apiTaskSearch.ProcessCount,
});

export interface S9ApiTaskSearchArchive extends S9ApiArchive {
  ProcessCount: number;
  ProcessIDs: number[];
}

export interface S9ApiTaskSearch {
  Archives: S9ApiTaskSearchArchive[];
  Id: string;
  Key: string;
  ProcessCount: number;
  Title: string;
  WorkflowId: string;
}
