import { UploadFile, UploadInput, UploadStatus } from '@angular-ex/uploader';
import { EventEmitter } from '@angular/core';

import { UploadService } from '../services/upload.service';

/**
 * Upload class for handling file uploads.
 */
export class Uploader {
  /**
   * The list of files currently in the uploader.
   */
  files: UploadFile[];
  /**
   * The input event used by @angular-ex/uploader.
   */
  input: EventEmitter<UploadInput>;
  /**
   * The url where the files will be uploaded.
   */
  url: string;

  constructor(
    private service: UploadService,
    uploadUrl: string
  ) {
    this.files = [];
    this.input = new EventEmitter<UploadInput>();
    this.url = uploadUrl;
  }

  /**
   * Gets if the upload is in progress.
   *
   * @returns True if the upload is in progress.
   */
  get inProgress(): boolean {
    return this.service.uploadInProgress(this);
  }

  /**
   * Gets the current progress of the upload.
   *
   * @returns The current state of the upload between 0 and 100.
   */
  get progress(): number {
    return this.service.uploadProgress(this);
  }

  /**
   * Gets if the upload is done.
   *
   * @returns True if this upload is complete.
   */
  get isDone(): boolean {
    return (
      this.files.length > 0 &&
      this.files.every((f) => f.progress.status === UploadStatus.Done)
    );
  }

  /**
   * Gets a list of files that have uploaded successfully.
   *
   * @returns A list of all completed upload files.
   */
  get uploadedFiles(): UploadFile[] {
    return this.files.filter(
      (f) => f.responseStatus && f.responseStatus === 200
    );
  }

  /**
   * Removes completed files from the list of upload files.
   *
   * @returns A list of filenames which were uploaded to the cache.
   */
  flushCompletedFiles(): string[] {
    const filenames: string[] = this.uploadedFiles.map(
      (f) => f.response.files[0].name
    );
    this.files = this.files.filter((f) => filenames.includes(f.name));
    return filenames;
  }
}
