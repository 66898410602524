<ng-container *ngIf="inbox$ | async as inbox">
  <mat-toolbar>
    <app-left-sidebar-toggle-button
      *ngIf="(useCompactLayout$ | async) === false"
    ></app-left-sidebar-toggle-button>
    <button mat-button [matTooltip]="inbox.name">
      <mat-icon [matBadge]="inbox.files.length" matBadgePosition="above before">
        inbox
      </mat-icon>
      <span
        *ngIf="(useCompactLayout$ | async) === false"
        style="margin-left: 5px"
        >{{ inbox.name }}</span
      >
    </button>
    <button
      mat-icon-button
      [matTooltip]="'REFRESH' | transloco"
      (click)="onRefresh()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      [disabled]="!inbox.permissions.modifyDocuments"
      [matTooltip]="'TOGGLE_EDIT' | transloco"
      (click)="onClickToggleEdit()"
    >
      <mat-icon [class.edit-enabled]="editModeEnabled">edit</mat-icon>
    </button>
    <button
      mat-icon-button
      [disabled]="isNoneSelected"
      [matTooltip]="'ACTIONS' | transloco"
      (click)="actionMenu.openMenu($event, selectedInboxFiles)"
    >
      <mat-icon [matBadge]="selectedCount" [matBadgeHidden]="isNoneSelected"
        >assignment_turned_in</mat-icon
      >
    </button>
    <span class="spacer"></span>
    <app-right-sidebar-toggle-button></app-right-sidebar-toggle-button>
  </mat-toolbar>
</ng-container>
