import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { Field, LiveField } from 'models';

import { FieldsState, FieldsStore } from './fields.store';

/**
 * Field store query.
 */
@Injectable({ providedIn: 'root' })
export class FieldsQuery extends QueryEntity<FieldsState> {
  /** Observable of all available fields. */
  fields$ = this.selectAll();

  /**
   * Currently active field Id number.
   *
   * @returns Field Id number.
   * @throws {TypeError} If current state field Id does not return a number.
   */
  get activeId(): number {
    const id = this.getActiveId();
    if (typeof id !== 'number') {
      throw new TypeError('Field Id was not a number.');
    }
    return id;
  }

  constructor(protected store: FieldsStore) {
    super(store);
  }

  /**
   * Get a field.
   *
   * @param fieldId Field Id.
   * @returns Field.
   * @throws {RangeError} If the field is not found.
   */
  getField(fieldId: number = this.activeId): Field {
    const field = this.getEntity(fieldId);
    if (typeof field === 'undefined') {
      throw new RangeError('Field was not found for the provided Id..');
    }
    return {
      ...field,
      liveField: field.liveField ? new LiveField(field.liveField) : undefined,
    };
  }
}
