import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, interval } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { assertExists } from 'common';
import {
  ArchiveSession,
  InboxIndexToArchiveSession,
  InboxSession,
  SearchPrompt,
  SearchResults,
  Session,
  ViewerSessionProvider,
  createArchiveSessionDocumentFromSearchResult,
  createSessionSearchCriteria,
} from 'models';

import { VIEWER_SESSION_PROVIDER } from '../common/tokens';
import { AppConfigQuery } from '../modules/app-config';
import { ApplicationQuery } from '../state/application/application.query';

import { AuthenticationService } from './authentication.service';

/**
 * Viewer Session Service.
 */
@Injectable({
  providedIn: 'root',
})
export class ViewerService {
  constructor(
    @Inject(VIEWER_SESSION_PROVIDER)
    private viewerProvider: ViewerSessionProvider,
    private appConfigQuery: AppConfigQuery,
    private appQuery: ApplicationQuery,
    private auth: AuthenticationService
  ) {}

  /**
   * Creates a new viewer session for viewing inbox documents.
   *
   * @param session An inbox session.
   * @returns An observable session Id.
   */
  createInboxSession(session: InboxSession): Observable<string> {
    return this.viewerProvider.createInboxSession(session);
  }

  /**
   * Creates a new viewer session for indexing inbox documents to an archive..
   *
   * @param session A Session.
   * @returns A Session with an ID.
   */
  createIndexFromInboxToArchiveSession(
    session: InboxIndexToArchiveSession
  ): Observable<string> {
    return this.viewerProvider.createIndexFromInboxToArchiveSession(session);
  }

  /**
   * Creates a new viewer session.
   *
   * @param session A Session.
   * @returns A Session with an ID.
   */
  createSession(session: Session): Observable<string> {
    return this.viewerProvider.createSession(session);
  }

  /**
   * Opens the provided rows in the viewer.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param searchId Search Id.
   * @param searchPrompts Search prompts.
   * @param searchResults Results to be opened.
   * @param openNewTab Open a session in a new window/tab if `true` otherwise set current window location to the requested viewer session.
   * @returns An observable that emits when the doc viewer session has closed.
   */
  openSelectedArchiveDocuments(
    databaseId: number,
    archiveId: number,
    searchId: number,
    searchPrompts: SearchPrompt[],
    searchResults: SearchResults,
    openNewTab: boolean
  ): Observable<void> {
    const session: ArchiveSession = {
      database: databaseId,
      documents: searchResults.map((searchResult) =>
        createArchiveSessionDocumentFromSearchResult(searchResult, archiveId)
      ),
      searchCriteria: createSessionSearchCriteria(searchPrompts),
      searchId,
    };

    return this.createSession(session).pipe(
      switchMap((sessionId) => this.openViewerSession(sessionId, openNewTab))
    );
  }

  /**
   * Open a viewer session.
   *
   * @param sessionId A session id for an existing session.
   * @param openNewTab Open a session in a new window/tab if `true` otherwise set current window location to the requested viewer session.
   * @returns An observable completed on close.
   */
  openViewerSession(sessionId: string, openNewTab: boolean): Observable<void> {
    const url = `${this.appConfigQuery.appConfig.viewerUrl}/viewer/#/globalSearch?session=${sessionId}&token=${this.auth.user.token}`;
    if (openNewTab) {
      const viewerWindow = window.open(url, '_blank');
      if (this.appQuery.refreshResultsWhenClosingDocumentTabs) {
        assertExists(
          viewerWindow,
          'Viewer window must exist to listen for close event.'
        );
        return interval(1000).pipe(
          map(() => viewerWindow.closed), // map to value being checked
          filter((closed) => !!closed), // filter out falsy values
          map(() => {}), // remap to void when we finally get a closed response
          take(1)
        );
      } else {
        return EMPTY;
      }
    } else {
      window.location.href = url;
      return EMPTY;
    }
  }
}
