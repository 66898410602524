import { AdvancedLink } from 'models';

/**
 * Converts an API AdvancedLink object to an AdvancedLink.
 *
 * @param apiAdvancedLink API List.
 * @returns A list.
 */
export const createAdvancedLinkFromApi = (
  apiAdvancedLink: S9ApiAdvancedLinkDefinition
): AdvancedLink => ({
  id: apiAdvancedLink.Id,
  name: apiAdvancedLink.Name,
  archiveId: apiAdvancedLink.ArchiveId,
  fieldId: apiAdvancedLink.FieldId,
  urlPattern: apiAdvancedLink.UrlPattern,
});

/** S9API List. */
export interface S9ApiAdvancedLinkDefinition {
  /** Archive Id. */
  ArchiveId: number;
  /** Field Id. */
  FieldId: number;
  /** ID. */
  Id: number;
  /** Name. */
  Name: string;
  /** URL Pattern. */
  UrlPattern: string;
}

/**
 * Create a `AdvancedLink` from a `S9ApiAdvancedLink`.
 *
 * @param s9ApiAdvancedLink Square 9 API advancedLink.
 * @returns A new field object.
 */
export const createAdvancedLink = (
  s9ApiAdvancedLink: S9ApiAdvancedLinkDefinition
): AdvancedLink => ({
  id: s9ApiAdvancedLink.Id,
  name: s9ApiAdvancedLink.Name,
  archiveId: s9ApiAdvancedLink.ArchiveId,
  fieldId: s9ApiAdvancedLink.FieldId,
  urlPattern: s9ApiAdvancedLink.UrlPattern,
});
