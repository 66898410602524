<h2 mat-dialog-title>User Settings</h2>
<form [formGroup]="userSettingsGroup">
  <mat-dialog-content>
    <mat-tab-group dynamicHeight color="accent">
      <mat-tab label="General">
        <mat-list>
          <div class="setting-group-title">Results:</div>
          <mat-list-item class="input-height-item">
            <mat-form-field floatLabel="always" hideRequiredMarker="true">
              <mat-label transloco="RESULTS_PER_PAGE"></mat-label>
              <input
                matInput
                type="number"
                formControlName="archiveResultsPerPage"
              />
              <mat-hint>{{ 'BETWEEN_25_AND_200' | transloco }}</mat-hint>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="searchCountEnabled">
              {{ 'SHOW_ARCHIVE_SEARCH_RESULT_COUNTS' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="sortResultsArchivesByResultsCount">
              {{ 'MULTI_ARCHIVE_COUNT_SORT' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="hideArchivesWithNoResults">
              {{ 'MULTI_ARCHIVE_HIDE_EMPTY' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item *ngIf="(allowGuestSquare9Viewer$ | async) || !isGuest">
            <mat-checkbox formControlName="doubleClickOpen">
              {{ 'DOUBLE_CLICK_TO_OPEN_DOCUMENTS' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox
              formControlName="usePreviousSearchCriteriaInRelatedSearch"
              *ngIf="(allowGuestSquare9Viewer$ | async) || !isGuest"
            >
              {{ 'USE_PREVIOUS_SEARCH_RELATED' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox
              formControlName="alwaysOpenNewTab"
              *ngIf="(allowGuestSquare9Viewer$ | async) || !isGuest"
            >
              {{ 'GRID_OPEN_DOCUMENT_IN_NEW_TAB' | transloco }}
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox
              formControlName="refreshResultsWhenClosingDocumentTabs"
              *ngIf="(allowGuestSquare9Viewer$ | async) || !isGuest"
            >
              {{
                'REFRESH_RESULTS_WHEN_CLOSING_DOCUMENTS_OPENED_IN_TAB'
                  | transloco
              }}
            </mat-checkbox>
          </mat-list-item>
          <div class="setting-group-title">Archive History:</div>
          <mat-list-item class="input-height-item">
            <mat-form-field floatLabel="always" hideRequiredMarker="true">
              <mat-label transloco="RESULTS_PER_PAGE"></mat-label>
              <input
                matInput
                type="number"
                formControlName="archiveHistoryResultsPerPage"
              />
              <mat-hint>{{ 'BETWEEN_25_AND_200' | transloco }}</mat-hint>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="showDomainInHistoryResults">{{
              'SHOW_DOMAIN_IN_HISTORY' | transloco
            }}</mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab label="Appearance">
        <mat-list>
          <div class="setting-group-title">{{ 'THEME' | transloco }}:</div>
          <mat-form-field id="theme-picker">
            <mat-label>Color Theme</mat-label>
            <mat-select formControlName="themeMode">
              <mat-option [value]="themeModes.light">
                {{ 'LIGHT' | transloco }}
              </mat-option>
              <mat-option [value]="themeModes.dark">
                {{ 'DARK' | transloco }}
              </mat-option>
              <mat-option [value]="themeModes.system">
                {{ 'FOLLOW_SYSTEM' | transloco }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="!isGuest">
            <div class="setting-group-title">GlobalAction Tasks:</div>
            <mat-list-item>
              <mat-checkbox
                (change)="onShowGlobalActionTasksChange($event)"
                formControlName="showGlobalActionTasks"
              >
                {{ 'ENABLED' | transloco }}
              </mat-checkbox>
            </mat-list-item>
          </ng-container>
        </mat-list>
        <div *ngIf="!isGuest && userSettingsGroup.value.showGlobalActionTasks">
          <mat-accordion>
            <mat-expansion-panel class="darkened-background">
              <mat-expansion-panel-header>
                <mat-panel-title>{{
                  'AVAILABLE_TASKS' | transloco
                }}</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list formControlName="enabledTasksList">
                <mat-list-option
                  *ngFor="let task of taskSearches$ | async"
                  [value]="task.id"
                >
                  {{ task.title }}
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mat-list *ngIf="!isGuest">
          <div class="setting-group-title">Favorite Searches:</div>
          <mat-list-item>
            <mat-checkbox formControlName="showFavoriteSearches">
              <span transloco="ENABLED"></span>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <div class="setting-group-title">{{ 'VIEWER' | transloco }}:</div>
          <mat-list-item>
            <mat-checkbox formControlName="viewerGoToFirstPageOnLoad"
              ><span transloco="VIEWER_ALWAYS_LOAD_FIRST_PAGE"></span>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="viewerUseInternal"
              ><span transloco="USE_INTERNAL_DOCUMENT_VIEWER"></span>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item *ngIf="internalImportsSupported">
            <mat-checkbox formControlName="viewerUseInternalForImport"
              ><span
                transloco="USE_INTERNAL_DOCUMENT_VIEWER_FOR_IMPORTS"
              ></span>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="viewerAutoSave"
              ><span
                transloco="AUTOMATICALLY_SAVE_DOCUMENT_CHANGES_SETTING"
              ></span>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="persistArchiveImportData"
              ><span transloco="PERSIST_ARCHIVE_IMPORT_DATA_SETTING"></span>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <div class="setting-group-title">Data XChange:</div>
          <mat-list-item>
            <mat-checkbox formControlName="dxcSearchSystemFields"
              ><span transloco="DXC_SEARCH_SYSTEM_FIELDS"></span>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="dxcAppendToMultivalue"
              ><span transloco="DXC_APPEND_MULTIVALUES"></span>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <div class="setting-group-title">{{ 'NAVIGATION' | transloco }}:</div>
          <mat-list-item>
            <mat-checkbox formControlName="showVersionsArchive"
              ><span transloco="SHOW_VERSIONS_ARCHIVE"></span>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab [label]="'KEYFREE' | transloco">
        <mat-list>
          <div class="setting-group-title">KEYFREE TYPE:</div>
          <mat-checkbox formControlName="keyfreeUseOcr">
            {{ 'USE_OCR' | transloco }}
          </mat-checkbox>
        </mat-list>
      </mat-tab>
      <mat-tab [label]="'LANGUAGE' | transloco">
        <mat-selection-list
          formControlName="currentLanguage"
          [multiple]="false"
        >
          <mat-list-option
            *ngFor="let language of languages$ | async"
            [value]="language"
          >
            {{ language }}
          </mat-list-option>
        </mat-selection-list>
      </mat-tab>
      <mat-tab [label]="'USER' | transloco" *ngIf="showUserTab" #userTab>
        <mat-list>
          <form [formGroup]="userPasswordFormGroup">
            <div class="setting-group-title">
              {{ 'CHANGE_PASSWORD' | transloco }}:
            </div>
            <mat-list-item class="input-height-item">
              <mat-form-field hideRequiredMarker="true">
                <mat-label transloco="CURRENT_PASSWORD"></mat-label>
                <input
                  matInput
                  type="password"
                  formControlName="currentPassword"
                />
                <mat-error
                  *ngIf="
                    userPasswordFormGroup.controls.currentPassword.hasError
                  "
                  >{{ 'PASSWORD_IS_REQUIRED' | transloco }}</mat-error
                >
              </mat-form-field>
            </mat-list-item>
            <mat-list-item class="input-height-item">
              <mat-form-field>
                <mat-label transloco="NEW_PASSWORD"></mat-label>
                <input
                  matInput
                  #password
                  formControlName="newPassword"
                  type="password"
                />
              </mat-form-field>
            </mat-list-item>
            <mat-list-item class="password-height-item">
              <mat-password-strength
                matLine
                #passwordComponent
                [password]="password.value"
                [enableLengthRule]="!customPasswordRegexProvided"
                [enableLowerCaseLetterRule]="!customPasswordRegexProvided"
                [enableUpperCaseLetterRule]="!customPasswordRegexProvided"
                [enableDigitRule]="!customPasswordRegexProvided"
                [enableSpecialCharRule]="!customPasswordRegexProvided"
                [max]="20"
              >
              </mat-password-strength>
              <mat-password-strength-info
                #passwordStrengthInfo
                matLine
                [passwordComponent]="passwordComponent"
              >
              </mat-password-strength-info>
            </mat-list-item>
            <mat-list-item class="input-height-item">
              <mat-form-field>
                <mat-label transloco="CONFIRM_PASSWORD"></mat-label>
                <input
                  matInput
                  [formControl]="
                    passwordComponent.passwordConfirmationFormControl
                  "
                  type="password"
                />
                <mat-error
                  *ngIf="
                    passwordComponent.passwordConfirmationFormControl.hasError(
                      'notConfirmed'
                    )
                  "
                  >{{
                    'PASSWORD_AND_CONFIRM_DO_NOT_MATCH' | transloco
                  }}</mat-error
                >
              </mat-form-field>
            </mat-list-item>
          </form>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      <span transloco="CANCEL"></span>
    </button>
    <button mat-button (click)="onReset()">
      <span transloco="RESET"></span>
    </button>
    <span class="spacer"></span>
    <button
      mat-button
      (click)="onUpdatePassword()"
      *ngIf="userTabIsActive"
      [disabled]="disableUserProfileUpdate"
    >
      <span transloco="UPDATE"></span>
    </button>
    <button
      *ngIf="!userTabIsActive"
      [disabled]="userSettingsGroup.invalid"
      mat-button
      cdkFocusInitial
      (click)="onSave()"
    >
      <span transloco="SAVE"></span>
    </button>
  </mat-dialog-actions>
</form>
