import { EventEmitter, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

import { TableField } from 'models';

import { ApplicationQuery } from '../state/application/application.query';
import {
  OpenArchiveDocumentSource,
  OpenTaskSearchDocumentSource,
} from '../state/application/open-document-source';
import { ArchivesQuery } from '../state/archives/archives.query';

/** Table field change event. */
export interface ActiveTableField {
  /** Document ID for the document to be displayed. */
  documentId: number;
  /** Secure ID for the document to be displayed. */
  documentSecureId: string;
  /** Table field to be displayed. */
  tableField: TableField;
}

/** Represents that there is currently not active table field. */
export const NO_ACTIVE_TABLE_FIELD = undefined;

/** Table field UI service. */
@Injectable({
  providedIn: 'root',
})
export class TableFieldUIService {
  /** Current active table field. Undefined if there is not an active table field. */
  activeTableField: ActiveTableField | typeof NO_ACTIVE_TABLE_FIELD;
  /** Observable active table field. Undefined if there is not an active table field. */
  activeTableField$: Observable<
    ActiveTableField | typeof NO_ACTIVE_TABLE_FIELD
  >;

  /** Table field load completed event. */
  onTableFieldLoaded = new EventEmitter<ActiveTableField>();

  /** Table field event. */
  private activeTableFieldSubject = new BehaviorSubject<
    ActiveTableField | typeof NO_ACTIVE_TABLE_FIELD
  >(NO_ACTIVE_TABLE_FIELD);

  constructor(
    private logger: NGXLogger,
    private archivesQuery: ArchivesQuery,
    private appQuery: ApplicationQuery
  ) {
    this.activeTableField$ = this.activeTableFieldSubject.asObservable();
    this.listenToActiveTableField();
    this.listenToClearActiveTableField();
    this.listenToOpenDocument();
  }

  /**
   * Whether there is currently an active table field.
   *
   * @returns A boolean indicating if there is an active table field.
   */
  private get hasActiveTableField(): boolean {
    return this.activeTableField !== NO_ACTIVE_TABLE_FIELD;
  }

  /**
   * Clear the active table field.
   */
  clear(): void {
    this.activeTableFieldSubject.next(NO_ACTIVE_TABLE_FIELD);
  }

  /**
   * Changes the active document in the table field.
   *
   * @param documentId Document Id.
   * @param documentSecureId Document Secure Id.
   * @throws Throws an error if there is no active table field.
   */
  switchActiveDocument(documentId: number, documentSecureId: string): void {
    if (this.activeTableField === NO_ACTIVE_TABLE_FIELD) {
      throw new Error(
        'There must be an active table field in order to switch active document.'
      );
    }
    if (this.activeTableField.documentId === documentId) {
      this.logger.debug('Document is already active in table field.');
      return;
    }
    const tableField = this.activeTableField.tableField;
    this.activeTableFieldSubject.next({
      tableField,
      documentId,
      documentSecureId,
    });
  }

  /**
   * Updates the table field.
   *
   * For new indexing documents, use `documentId=0` and `documentSecureId=''`.
   *
   * @param tableField Table field.
   * @param documentId Document ID.
   * @param secureId Document Secure ID.
   */
  update(tableField: TableField, documentId: number, secureId: string): void {
    this.activeTableFieldSubject.next({
      tableField,
      documentId,
      documentSecureId: secureId,
    });
  }

  private listenToActiveTableField(): void {
    this.activeTableField$.subscribe(
      (activeTableField) => (this.activeTableField = activeTableField)
    );
  }

  /**
   * Listens to the archive route parameters and clears the
   * active table field on change.
   */
  private listenToClearActiveTableField(): void {
    this.archivesQuery.archiveRouteParams$.subscribe(() => {
      if (this.activeTableField) {
        this.logger.debug(
          'Active archive route parameters changed. Clearing active table field...'
        );
        this.clear();
      }
    });
  }

  private listenToOpenDocument(): void {
    this.appQuery.openDocument$.subscribe((openDocument) => {
      if (!this.hasActiveTableField) {
        // If there wasn't a table field active before we shouldn't try to activate one now.
        return;
      }

      if (!openDocument) {
        this.logger.debug(
          'Open document has cleared. Clearing the active table field...'
        );
        this.clear();
      } else
        switch (openDocument.type) {
          case 'inbox': {
            this.logger.debug(
              'Open document has switched to an inbox document. Clearing the active table field...'
            );
            this.clear();

            break;
          }
          case 'archive': {
            const openArchiveDocument =
              openDocument.source as OpenArchiveDocumentSource;
            if (!openArchiveDocument.documentSecureId) {
              this.logger.debug('Open document secure id is not set.');
              return;
            }

            this.switchActiveDocument(
              openArchiveDocument.documentId,
              openArchiveDocument.documentSecureId
            );

            break;
          }
          case 'taskSearch': {
            const openTaskSearchDocument =
              openDocument.source as OpenTaskSearchDocumentSource;
            if (!openTaskSearchDocument.documentSecureId) {
              this.logger.debug('Open documetn secure id is not set.');
              return;
            }

            this.switchActiveDocument(
              openTaskSearchDocument.documentId,
              openTaskSearchDocument.documentSecureId
            );

            break;
          }
          default:
            this.logger.error('Provided document type is not handled.');
            break;
        }
    });
  }
}
