<h2 mat-dialog-title>{{ 'CONFIGURE_GSE' | transloco }}</h2>
<mat-dialog-content>
  <form [formGroup]="settings" class="settings-form">
    <mat-form-field>
      <mat-label transloco="PORT"></mat-label>
      <input
        matInput
        type="number"
        formControlName="port"
        class="form-control"
        min="1"
        max="65535"
      />
    </mat-form-field>
  </form>
  <p *ngIf="showTestMessage" transloco="GSE_SETTINGS_CHANGED"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'CANCEL' | transloco }}
  </button>
  <span class="spacer"></span>
  <button mat-button (click)="onTestConnection()">
    {{ 'TEST_CONNECTION' | transloco }}
  </button>
  <button mat-button [disabled]="disableSave" (click)="onSave()">
    {{ 'SAVE' | transloco }}
  </button>
</mat-dialog-actions>
