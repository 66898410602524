<mat-form-field>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onSelectionChanged($event)"
    [value]="cellParameters.value"
  >
    <mat-option
      *ngFor="let option of cellParameters.selectionOptions"
      [value]="option.value"
      >{{ option.display }}</mat-option
    >
  </mat-select>
</mat-form-field>
