<ng-container *ngIf="isEditMode; else staticText">
  <mat-form-field appearance="fill" class="edit-form">
    <mat-label>Instance</mat-label>
    <input
      type="text"
      aria-label="Instance"
      matInput
      [formControl]="urlControl"
      [matAutocomplete]="auto"
      (blur)="onAutoCompleteBlur(true, auto)"
      (keyup.enter)="onAutoCompleteBlur()"
    />
    <button
      matSuffix
      mat-icon-button
      *ngIf="shouldShowClearAutoComplete"
      (click)="onClearAutoComplete()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-spinner
      *ngIf="loadingConfig$ | async"
      matSuffix
      [diameter]="18"
      style="float: right; margin-left: 8px; margin-top: 6px"
    ></mat-spinner>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onAutoCompleteSelected($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="urlControl.invalid">{{ inputError }}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #staticText>
  <div class="static-text-area">
    <ng-container *ngIf="parseHost(url) as parsedHost; else unknownHost">
      <mat-icon
        class="dim text-icon"
        matTooltip="{{ url }}"
        matTooltipShowDelay="1000"
        matTooltipPosition="right"
        >{{ parsedHost.isCloud ? 'cloud' : 'dns' }}</mat-icon
      >
      <div class="dim static-url-text">
        {{ parsedHost.host }}
      </div>
    </ng-container>
    <ng-template #unknownHost>
      <mat-icon
        class="dim mat-error text-icon"
        matTooltip="{{ url }}"
        matTooltipShowDelay="1000"
        matTooltipPosition="right"
        >cloud_off</mat-icon
      >
      <div class="mat-error dim static-url-text">
        {{ 'UNKNOWN_HOST' | transloco }}
      </div>
    </ng-template>

    <span class="spacer"></span>
    <button
      mat-icon-button
      (click)="isEditMode = true"
      matTooltip="{{ 'EDIT_INSTANCE' | transloco }}"
      matTooltipShowDelay="1000"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-template>

<button
  mat-raised-button
  id="continue-button"
  color="primary"
  *ngIf="!isValid && isEditMode && (loadingConfig$ | async) !== true"
  [disabled]="urlControl.invalid"
  (click)="onContinueClick()"
>
  {{ 'CONTINUE' | transloco }}
</button>
