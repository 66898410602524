import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';

import { Field, FieldProvider } from 'models';

import { FIELD_PROVIDER } from '../../common/tokens';

import { FieldsStore } from './fields.store';

/**
 * Field service.
 */
@Injectable({ providedIn: 'root' })
export class FieldsService {
  constructor(
    private fieldsStore: FieldsStore,
    @Inject(FIELD_PROVIDER) private fieldProvider: FieldProvider
  ) {}

  /**
   * Add a new field to the store.
   *
   * @param field Field.
   */
  add(field: Field) {
    this.fieldsStore.add(field);
  }

  /**
   * Refresh the list of available fields.
   *
   * @param databaseId Database ID.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }

    this.fieldProvider
      .getAll(databaseId)
      .pipe(setLoading(this.fieldsStore))
      .subscribe((fields) => {
        this.fieldsStore.set(fields);
      });
  }

  /**
   * Remove a field from the store.
   *
   * @param id Field ID.
   */
  remove(id: number) {
    this.fieldsStore.remove(id);
  }

  /**
   * Update a field in the store.
   *
   * @param id Field ID.
   * @param field Updated field object.
   */
  update(id: number, field: Partial<Field>) {
    this.fieldsStore.update(id, field);
  }
}
