<h2 mat-dialog-title transloco="ADD_FAVORITE_SEARCH"></h2>
<mat-dialog-content>
  <p><span transloco="ADD"></span> "{{ searchName }}" as a favorited search.</p>
  <form [formGroup]="favoriteForm" id="favoritesForm" (ngSubmit)="onSubmit()">
    <p>
      <mat-form-field class="full-width">
        <mat-label transloco="CUSTOM_SEARCH_LABEL"></mat-label>
        <input
          matInput
          type="text"
          formControlName="searchLabel"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.searchLabel.errors }"
        />
      </mat-form-field>
      <mat-list>
        <mat-list-item>
          <mat-checkbox formControlName="showPrompt">
            <span transloco="SHOW_PROMPT"></span
          ></mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox formControlName="useCurrentPromptData">
            <span transloco="USE_CURRENT_PROMPT_DATA"></span
          ></mat-checkbox>
        </mat-list-item>
      </mat-list>
    </p>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close transloco="CANCEL"></button>
  <button
    mat-button
    type="submit"
    form="favoritesForm"
    transloco="ADD"
  ></button>
</mat-dialog-actions>
