import { ArchiveSecurityProperties } from './properties.enum';

/** Describes what archive or document permissions are for the user. */
export interface Permissions {
  /** Add new document permission. */
  addNewDocuments: boolean;
  /**
   * Delete batches.
   *
   * @deprecated Legacy batch manager no longer exists.
   */
  deleteBatches: boolean;
  /** Delete document permission. */
  deleteDocuments: boolean;
  /** Email document permission. */
  emailDocuments: boolean;
  /** Export data permission. */
  exportData: boolean;
  /** Export document permission. */
  exportDocuments: boolean;
  /** Full API permission. */
  fullApiAccess: boolean;
  /** Launch document permission. */
  launchDocument: boolean;
  /** Launch new version permission. */
  launchNewVersion: boolean;
  /** Modify document annotation permission. */
  modifyAnnotations: boolean;
  /** Modify document data ermission. */
  modifyData: boolean;
  /** Modify document pages permission. */
  modifyDocumentPages: boolean;
  /** Modify document permission. */
  modifyDocuments: boolean;
  /** Move document permission. */
  moveDocuments: boolean;
  /** Print document permission. */
  printDocuments: boolean;
  /** Publish document revision permission. */
  publishDocumentRevisions: boolean;
  /** View document history permission. */
  viewDocumentHistory: boolean;
  /** View document revisions permission. */
  viewDocumentRevisions: boolean;
  /** View document permission. */
  viewDocuments: boolean;
  /** View in Acrobat permission. */
  viewInAcrobat: boolean;
}

/**
 * Create a permission object from archive permissions bitwise.
 *
 * @param permissions Permissions.
 * @returns An object containing bools for each permission.
 */
export const createPermissions = (permissions: number): Permissions => ({
  addNewDocuments: hasPermission(permissions, ArchiveSecurityProperties.add),
  fullApiAccess: hasPermission(
    permissions,
    ArchiveSecurityProperties.fullApiAccess
  ),
  deleteBatches: hasPermission(
    permissions,
    // Check for the deprecated delete batches permission.
    ArchiveSecurityProperties.deleteBatches
  ),
  deleteDocuments: hasPermission(permissions, ArchiveSecurityProperties.delete),
  emailDocuments: hasPermission(permissions, ArchiveSecurityProperties.email),
  exportDocuments: hasPermission(
    permissions,
    ArchiveSecurityProperties.exportDocuments
  ),
  launchDocument: hasPermission(
    permissions,
    ArchiveSecurityProperties.launchDocument
  ),
  launchNewVersion: hasPermission(
    permissions,
    ArchiveSecurityProperties.launchNewVersion
  ),
  modifyAnnotations: hasPermission(
    permissions,
    ArchiveSecurityProperties.modifyAnnotations
  ),
  modifyData: hasPermission(permissions, ArchiveSecurityProperties.modifyData),
  modifyDocumentPages: hasPermission(
    permissions,
    ArchiveSecurityProperties.modifyPages
  ),
  moveDocuments: hasPermission(
    permissions,
    ArchiveSecurityProperties.moveDocuments
  ),
  printDocuments: hasPermission(permissions, ArchiveSecurityProperties.print),
  publishDocumentRevisions: hasPermission(
    permissions,
    ArchiveSecurityProperties.publishDocumentRevisions
  ),
  viewDocumentHistory: hasPermission(
    permissions,
    ArchiveSecurityProperties.viewDocumentHistory
  ),
  viewDocumentRevisions: hasPermission(
    permissions,
    ArchiveSecurityProperties.viewDocumentRevisions
  ),
  viewDocuments: hasPermission(permissions, ArchiveSecurityProperties.view),
  viewInAcrobat: hasPermission(
    permissions,
    ArchiveSecurityProperties.viewInAcrobat
  ),
  exportData: hasPermission(permissions, ArchiveSecurityProperties.exportData),
  modifyDocuments: hasPermission(
    permissions,
    ArchiveSecurityProperties.modifyDocuments
  ),
});

/**
 * Checks if the permissions contain a specific permission.
 *
 * @param permissionSet Complete archive permissions.
 * @param testPermission Permission to check.
 * @returns A boolean representing if the user has the permission.
 */
const hasPermission = (
  permissionSet: number,
  testPermission: number
): boolean => {
  return (permissionSet & testPermission) === testPermission;
};

/**
 * Filter the provided permissions to remove any that are not allowed by a readonly user.
 *
 * @param permissions Original permissions.
 * @returns A permissions object stripped of any permissions that are not allowed for a readonly user.
 */
export const filterReadOnlyPermissions = (
  permissions: Permissions
): Permissions => ({
  ...permissions,
  addNewDocuments: false,
  deleteDocuments: false,
  launchDocument: false,
  modifyAnnotations: false,
  modifyData: false,
  modifyDocuments: false,
  modifyDocumentPages: false,
  moveDocuments: false,
});

/**
 * Filter the provided permissions to remove any that are not allowed by a check-in/check-out archives.
 *
 * @param permissions Original permissions.
 * @returns A permissions object stripped of any permissions that are not allowed for a check-in/check-out archives.
 */
export const filterCheckInCheckOutPermissions = (
  permissions: Permissions
): Permissions => ({
  ...permissions,
  deleteDocuments: false,
  launchDocument: false,
  modifyAnnotations: false,
  modifyData: false,
  modifyDocuments: false,
  modifyDocumentPages: false,
});
