import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GSEConfigurationDialogComponent } from '../components/gseconfiguration-dialog/gseconfiguration-dialog.component';
import { GSEInstallDialogComponent } from '../components/gseinstall-dialog/gseinstall-dialog.component';

const INSTALL_DIALOG_MIN_WIDTH = 400;
const SCAN_DIALOG_MIN_WIDTH = 250;

/** GSE Dialog Service. */
@Injectable({
  providedIn: 'root',
})
export class GSEDialogService {
  constructor(private dialog: MatDialog) {}

  /** Opens the configuration dialog. */
  openConfiguration(): void {
    this.dialog.open(GSEConfigurationDialogComponent);
  }

  /** Opens the install dialog. */
  openInstall(): void {
    this.dialog.open(GSEInstallDialogComponent, {
      minWidth: INSTALL_DIALOG_MIN_WIDTH,
    });

    // This dialog doesn't respond with anything so no need to subscribe to the on close event.
  }
}
