import { Injectable } from '@angular/core';
import { arrayAdd } from '@datorama/akita';

import { CacheSession, CachedArchiveDocument } from 'src/app/models';

import { ArchiveSessionCacheStore } from './archive-document-cache.store';

/** Archive document cache service. */
@Injectable({ providedIn: 'root' })
export class ArchiveDocumentCacheService {
  constructor(private store: ArchiveSessionCacheStore) {}

  /**
   * Creates a new session or updates an existing session with the provided document.
   *
   * @param id Session id.
   * @param documents Array of documents to be added to the session.
   */
  createOrUpdateSession(id: string, documents: CachedArchiveDocument[]) {
    this.store.upsert(
      id,
      (state) => {
        return {
          documents: arrayAdd((state as CacheSession).documents, documents),
        };
      },
      (id, newState) => ({ id, ...newState })
    );
  }

  /**
   * Remove a session by id.
   *
   * @param id Session Id.
   */
  deleteSession(id: string) {
    this.store.remove(id);
  }

  /**
   * Deletes all sessions.
   */
  resetStore() {
    this.store.reset();
  }
}
