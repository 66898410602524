import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DocumentId,
  DocumentSecureId,
  UserActions,
  UserActionsProvider,
  UserFriendlyError,
} from 'models';
import { Observable, catchError, map, throwError } from 'rxjs';

import { QueuedDocument, createUserActions } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 Api User Actions Service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiUserActionsService implements UserActionsProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = `${apiUrl}`));
  }

  /** @inheritdoc */
  getActions(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string
  ): Observable<UserActions> {
    return this.http
      .get<QueuedDocument>(`${this.basePath}/useraction`, {
        params: new HttpParams()
          .set('database', databaseId)
          .set('archive', archiveId)
          .set('document', documentId)
          .set('secureid', secureId),
      })
      .pipe(map((queuedDocument) => createUserActions(queuedDocument)));
  }

  /** @inheritdoc */
  runAction(
    databaseId: number,
    archiveId: number,
    actionId: string,
    documents: Record<DocumentId, DocumentSecureId>
  ): Observable<void> {
    return this.http
      .put<void>(`${this.basePath}/useraction`, documents, {
        params: new HttpParams()
          .set('database', databaseId)
          .set('archive', archiveId)
          .set('actionid', actionId),
      })
      .pipe(
        catchError((userFriendlyError: UserFriendlyError) => {
          // It failed so just add a generic error message translation key.
          userFriendlyError.i18n = 'ERROR_FAILED_TO_RUN_USER_ACTION';
          userFriendlyError.i18nParameters = { actionName: actionId };
          return throwError(() => userFriendlyError);
        })
      );
  }
}
