import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import {
  StateToolbarButton,
  StateToolbarService,
} from 'src/app/services/state-toolbar.service';

@Component({
  selector: 'app-state-toolbar',
  templateUrl: './state-toolbar.component.html',
  styleUrls: ['./state-toolbar.component.scss'],
})
export class StateToolbarComponent {
  /** Observable of state toolbar buttons that should current be displayed. */
  stateToolbarButtons$ = this.stateToolbarService.stateToolbarButtons$;

  constructor(
    private logger: NGXLogger,
    private stateToolbarService: StateToolbarService
  ) {}

  /**
   * Determines if the button should be disabled.
   *
   * @param button State button.
   * @returns A boolean indicating if the button should be disabled.
   */
  disableButton(button: StateToolbarButton): boolean {
    return typeof button.disabled === 'boolean'
      ? button.disabled
      : button.disabled();
  }

  /**
   * Determines if the button should be rendered.
   *
   * @param button State button.
   * @returns A boolean indicating if the button should be rendered.
   */
  shouldRender(button: StateToolbarButton): boolean {
    return typeof button.shouldRender === 'boolean'
      ? button.shouldRender
      : button.shouldRender();
  }
}
