import {
  Field,
  FieldDataType,
  FieldProperties,
  FieldValue,
  FieldValues,
  ListType,
} from 'models';
import { S9ApiLiveField, createLiveField } from './live-field';
export interface ExtendedConfig {
  UseRegExValidation: boolean;
  LiveField?: S9ApiLiveField;
}

export interface S9ApiFieldValue {
  ID: number;
  MVAL: any[];
  VAL: string;
}

export declare type S9ApiFieldValues = S9ApiFieldValue[];

export interface S9ApiFieldDefinition {
  ExtendedConfig: ExtendedConfig;
  ID: number;
  List: ListType;
  ListF1: number;
  ListF2: number;
  Mask: string;
  Name: string;
  Parent: number;
  Prop: FieldProperties;
  RegEx: string;
  Size: number;
  Type: FieldDataType;
}

export interface S9ApiAdminFieldDefinition {
  ExtendedConfig: ExtendedConfig;
  Format: string;
  ID: number;
  Length: number;
  List: S9ApiFieldListProperties;
  MultiValue: boolean;
  Name: string;
  Regex: string;
  Required: boolean;
  SystemField: string;
  Type: string;
}

/**
 * Properties of an List associated with a Field from the Square 9 API.
 */
export interface S9ApiFieldListProperties {
  ListId: number;
  Mapping: any[];
  Primary: number;
  Secondary: number;
  Type: null;
}

/**
 * Create a `FieldValue` from a `S9ApiFieldValue`.
 *
 * @param s9ApiFieldValue Square 9 API field value.
 * @returns A new field value object.
 */
export const createFieldValue = (
  s9ApiFieldValue: S9ApiFieldValue
): FieldValue => ({
  id: s9ApiFieldValue.ID,
  value: s9ApiFieldValue.VAL,
  multiValue: s9ApiFieldValue.MVAL,
});

/**
 * Create a `Field` from a `S9ApiField`.
 *
 * @param s9ApiField Square 9 API field.
 * @returns A new field object.
 */
export const createField = (s9ApiField: S9ApiAdminFieldDefinition): Field => ({
  id: s9ApiField.ID,
  name: s9ApiField.Name,
  type: getFieldTypeFromString(s9ApiField.Type),
  length: s9ApiField.Length,
  format: s9ApiField.Format,
  list: {
    listId: s9ApiField.List.ListId,
    type:
      s9ApiField.List.Type === 'dropdown'
        ? ListType.dropdown
        : ListType.typeahead,
    primary: s9ApiField.List.Primary,
    secondary: s9ApiField.List.Secondary,
    mapping: s9ApiField.List.Mapping,
  },
  multiValue: s9ApiField.MultiValue,
  required: s9ApiField.Required,
  regex: s9ApiField.Regex,
  systemField: s9ApiField.SystemField,
  useRegExValidation: s9ApiField.ExtendedConfig.UseRegExValidation,
  liveField:
    !!s9ApiField.ExtendedConfig.LiveField &&
    typeof s9ApiField.ExtendedConfig.LiveField === 'object'
      ? createLiveField(s9ApiField.ExtendedConfig.LiveField)
      : undefined,
});

/**
 * Create a `S9ApiFieldValue` object from `FieldValue`.
 *
 * @param fieldValue Field Value.
 * @returns A new S9Api field object.
 */
export const createS9ApiFieldValue = (
  fieldValue: FieldValue
): S9ApiFieldValue => ({
  ID: fieldValue.id,
  MVAL: fieldValue.multiValue,
  VAL: fieldValue.value,
});

/**
 * Create an `S9ApiFieldValues` object from `FieldValues`.
 *
 * @param fieldValues Field values.
 * @returns A new S9Api field values object.
 */
export const createS9ApiFieldValues = (
  fieldValues: FieldValues
): S9ApiFieldValues => fieldValues.map(createS9ApiFieldValue);

const getFieldTypeFromString = (fieldTypeString: string): FieldDataType => {
  switch (fieldTypeString.toLowerCase()) {
    case 'numeric':
      return FieldDataType.integer;
    case 'decimal':
      return FieldDataType.decimal;
    case 'date':
      return FieldDataType.date;
    case 'character':
      return FieldDataType.character;
    default:
      throw new Error(
        `Field data type  ${fieldTypeString} is not a valid type.`
      );
  }
};
