import { ArchiveSearchRequestedDocument } from './archive-search-requested-document';
import { DocumentRequestMap } from './document-request-map';

/**
 * Archive search requested document map.
 */
export class ArchiveSearchRequestedDocumentMap extends DocumentRequestMap<ArchiveSearchRequestedDocument> {
  constructor(documents?: ArchiveSearchRequestedDocument[] | string) {
    super(documents);
  }
}
