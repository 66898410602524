import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import { Inbox, ScanResult, UserFriendlyError } from 'models';
import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { InboxesService } from 'src/app/state/inboxes/inboxes.service';

import { MenuBaseComponent } from '../menu/menu-base.component';
import { ScanDialogComponent } from '../scan-dialog/scan-dialog.component';

/** Inbox navigation menu. */
@Component({
  selector: 'app-navigation-inbox-menu',
  templateUrl: './navigation-inbox-menu.component.html',
  styleUrls: ['./navigation-inbox-menu.component.scss'],
})
export class NavigationInboxMenuComponent extends MenuBaseComponent {
  /** Event raised when the import button is clicked in the menu. */
  @Output()
  importFile = new EventEmitter();
  /** Inbox. */
  inbox: Inbox;
  /** Observable containing the GSE connection status. */
  isGseConnected$ = this.gseService.isConnected$;

  constructor(
    private logger: NGXLogger,
    private gseService: GseService,
    private inboxService: InboxesService,
    private notification: NotificationService,
    private dialog: MatDialog
  ) {
    super();
  }

  /**
   * The click event for the import button.
   */
  onClickImport(): void {
    this.importFile.emit();
  }

  /**
   * The click event for the import scan button.
   */
  onClickScan(): void {
    this.logger.debug('Scan clicked.', this.inbox);
    const scanDialogReference = this.dialog.open(ScanDialogComponent, {
      disableClose: true,
      data: 'inbox',
      minWidth: 250,
    });
    scanDialogReference
      .afterClosed()
      .subscribe((result: ScanResult) => this.onScanDialogClose(result));
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   * @param inbox Target inbox.
   */
  openMenu(mouseEvent: MouseEvent, inbox: Inbox): void {
    // Set the current inbox.
    this.inbox = inbox;
    this.open(mouseEvent);
  }

  private onScanDialogClose(result: ScanResult): void {
    this.logger.debug('Scan dialog closed.', result);
    if (result) {
      this.logger.debug(`Importing the document into inbox ${this.inbox.id}.`);
      this.inboxService
        .importFile(this.inbox.id, result.uploadId, 'scan.tif')
        .subscribe({
          next: (inboxFilename: string) => {
            this.logger.debug('Scan imported into inbox.', inboxFilename);
            // todo add the file to list of inbox files or trigger refresh
          },
          error: (error: UserFriendlyError) => {
            error.i18n = 'ERROR_IMPORT';
            this.notification.error(error);
          },
        });
    }
  }
}
