<h2 mat-dialog-title>{{ 'INSERT_FROM_SEARCH' | transloco | titlecase }}</h2>
<mat-dialog-content>
  <mat-stepper
    [orientation]="(useCompactLayout$ | async) ? 'vertical' : 'horizontal'"
    linear
    #stepper
  >
    <mat-step [stepControl]="searchSelectForm">
      <p
        class="description-text"
        transloco="INSERT_FROM_SEARCH_DESCRIPTION"
      ></p>
      <form [formGroup]="searchSelectForm">
        <ng-template matStepLabel>
          {{ 'SELECT_A_SEARCH' | transloco }}
        </ng-template>
        <mat-form-field>
          <mat-label transloco="SELECT_A_FAVORITE_SEARCH"></mat-label>
          <mat-select
            formControlName="search"
            (selectionChange)="onSearchSelectionChange($event)"
          >
            <mat-option
              *ngFor="let search of searches$ | async"
              [value]="search"
            >
              {{ search.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          [disabled]="!searchSelectForm.controls.search.value"
          (click)="onClickRefresh()"
          matTooltip="{{ 'REFRESH' | transloco }}"
          matTooltipShowDelay="1000"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        {{ 'SELECT_ONE_OR_MORE_DOCUMENTS' | transloco }}
      </ng-template>
      <div
        [ngClass]="{
          'full-panel-width': stepper.selectedIndex === 1,
          'vertical-stepper-grid-container':
            stepper.selectedIndex === 1 && (useCompactLayout$ | async) === true,
        }"
      >
        <mat-form-field *ngIf="archives.length > 1">
          <mat-label>Archive</mat-label>
          <mat-select
            [value]="currentArchiveId"
            (selectionChange)="onArchiveSelectionChange($event)"
          >
            <mat-option *ngFor="let archive of archives" [value]="archive.id">
              {{ archive.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-tab-view
          (activeTabChange)="onActiveTabChanged($event)"
          *ngIf="hasViewTabs"
        ></app-tab-view>
        <ag-grid-angular
          #searchGrid
          class="ag-theme-material"
          [ngStyle]="{ height: gridHeight }"
          #grid
          [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
        <app-search-result-paginator
          [page]="gridPage"
          (pageChange)="onPageChange($event)"
          [result-count]="searchResultCount"
        ></app-search-result-paginator>
        <form [formGroup]="insertOptionsForm">
          <mat-checkbox
            class="spaced-checkbox"
            formControlName="deleteMergeDocuments"
            (change)="showRememberDeleteSelection = true"
          >
            {{ 'REMOVE_FROM_ARCHIVE' | transloco }}
          </mat-checkbox>
          <mat-checkbox
            class="spaced-checkbox"
            formControlName="keepBaseDocument"
            (change)="showRememberDeleteSelection = true"
          >
            {{ 'KEEP_ORIGINAL' | transloco }}
          </mat-checkbox>
          <span [hidden]="hideRememberChoice">
            <mat-checkbox formControlName="rememberChoice">
              {{ 'REMEMBER_MY_CHOICE' | transloco }}
            </mat-checkbox>
          </span>
        </form>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'CANCEL' | transloco }}</button>
  <span class="spacer"></span>
  <ng-container *ngIf="isLastStep">
    <button
      [disabled]="appendButtonsDisabled"
      mat-button
      (click)="onClickAppend(true)"
    >
      {{ 'PREPEND' | transloco }}
    </button>
    <button
      [disabled]="appendButtonsDisabled"
      mat-button
      *ngIf="isLastStep"
      (click)="onClickAppend(false)"
    >
      {{ 'APPEND' | transloco }}
    </button>
  </ng-container>
</mat-dialog-actions>
