<svg
  class="logo-btn"
  version="1.1"
  viewBox="0 0 76.538753 88.377501"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(.055089 .04084)">
    <g transform="translate(12.5 -230.14)">
      <path
        d="m2.9337 261.32 22.75 13.304 22.782-13.269-22.782-13.168-22.75 13.132"
      />
      <path
        d="m56.004 291.57-30.256 17.469-30.275-17.418-0.0375 0.0213v-13.815l7.4775
                    4.2088v5.2275l22.828 13.125 22.784-13.154v-4e-3l0.0775 0.045v-17.282l-22.925
                    13.276-30.251-17.444v-8.9012l0.03 0.0175 4.5425-2.625 25.679-14.784 30.325 17.446v0.0712l-0.02625
                    0.015-0.01375 7e-3 0.04 0.0238v34.455l0.0025 1e-3v0.0163zm-30.29-61.472-38.269 22.094v44.19l38.269
                    22.094 38.27-22.094v-44.19l-38.27-22.094"
      />
    </g>
  </g>
</svg>
