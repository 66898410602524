import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DatabaseProvider, Databases } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S9ApiDatabase } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API Datbases.
 */
@Injectable({ providedIn: 'root' })
export class Square9ApiDatabaseService implements DatabaseProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe(
      (apiUrl) => (this.basePath = `${apiUrl}/dbs`)
    );
  }

  /**
   * Get all databases.
   *
   * @returns Observable list of users secured databases.
   */
  getAll(): Observable<Databases> {
    return this.http.get<any>(this.basePath).pipe(
      map((result) => result.Databases),
      map((databases: S9ApiDatabase[]) =>
        databases.map((d) => ({
          id: d.Id,
          name: d.Name,
        }))
      )
    );
  }
}
