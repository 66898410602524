import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';

import { TableFieldProvider } from 'models';
import { TABLE_FIELD_PROVIDER } from 'src/app/common/tokens';

import { TableFieldsStore } from './table-fields.store';

/** Table field service. */
@Injectable({
  providedIn: 'root',
})
export class TableFieldsService {
  constructor(
    private tableFieldsStore: TableFieldsStore,
    @Inject(TABLE_FIELD_PROVIDER) private tableFieldProvider: TableFieldProvider
  ) {}

  /**
   * Refresh the list of available table fields.
   *
   * @param databaseId Database ID.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }

    this.tableFieldProvider
      .getAll(databaseId)
      .pipe(setLoading(this.tableFieldsStore))
      .subscribe((tableFields) => this.tableFieldsStore.set(tableFields));
  }
}
