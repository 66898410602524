import { Component } from '@angular/core';

@Component({
  selector: 'ngx-logo-svg',
  templateUrl: './logo-svg.component.html',
  styleUrls: ['./logo-svg.component.scss'],
})
export class LogoSvgComponent {
  constructor() {}
}
