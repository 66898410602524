import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, map } from 'rxjs';

import { assert, assertExists } from 'common';
import { Archive, Field, Fields, TableFields } from 'models';
import { ArchiveRouteParametersTuple } from 'src/app/models';

import { FieldsQuery } from '../fields/fields.query';
import { TableFieldsQuery } from '../table-fields/table-fields.query';

import { AssertionError } from 'assert';
import { ArchivesState, ArchivesStore } from './archives.store';

/**
 * Archives query.
 */
@Injectable({ providedIn: 'root' })
export class ArchivesQuery extends QueryEntity<ArchivesState> {
  /** Observable of the active archive. */
  active$ = this.selectActive();
  /** True if the archive archive has view tabs. */
  activeHasViewTabs: boolean;
  /** Observable of if the archive archive has view tabs. */
  activeHasViewTabs$ = this.selectActive().pipe(
    map((archive) => {
      try {
        assertExists(archive);
        return archive.viewTabs.length > 0;
      } catch {
        return false;
      }
    })
  );

  /** Active archive Id. */
  activeId$ = this.selectActiveId();
  /**
   * Archive route parameters.
   *
   * @description Observable of Database and Archive ID tuple.
   */
  archiveRouteParams$: Observable<ArchiveRouteParametersTuple> =
    this.routerQuery.selectParams(['dbId', 'archiveId']).pipe(
      map(([databaseId, archiveId]) => {
        databaseId = databaseId ? Number(databaseId) : undefined;
        archiveId = archiveId ? Number(archiveId) : undefined;
        assert(
          typeof databaseId === 'number' || typeof databaseId !== 'undefined'
        );
        assert(
          typeof archiveId === 'number' || typeof archiveId === 'undefined'
        );
        return [databaseId ? Number(databaseId) : databaseId, archiveId];
      })
    );
  /** Observable of available archives. */
  archives$ = this.selectAll();
  /** Observable of archives loading state. */
  isLoading$ = this.selectLoading();

  /**
   * Currently active archive Id number.
   *
   * @returns Archive Id number.
   * @throws {TypeError} If current state archive Id does not return a number.
   */
  get activeId(): number {
    const id = this.getActiveId();
    if (typeof id !== 'number') {
      throw new TypeError('Archive Id was not a number.');
    }
    return id;
  }

  /**
   * Currently active archive.
   *
   * @returns Archive.
   * @throws {TypeError} If no archive is currently active.
   */
  get active(): Archive {
    return this.getArchive(this.activeId);
  }

  constructor(
    protected store: ArchivesStore,
    private tableFieldsQuery: TableFieldsQuery,
    private fieldsQuery: FieldsQuery,
    private routerQuery: RouterQuery
  ) {
    super(store);

    // Track immediate value of active archives view tabs status.
    this.activeHasViewTabs$.subscribe((hasViewTabs) => {
      this.activeHasViewTabs = hasViewTabs;
    });
  }

  /**
   * Get an archive.
   *
   * @param archiveId Archive Id.
   * @returns Archive.
   * @throws {RangeError} If the archive is not found.
   */
  getArchive(archiveId: number = this.activeId): Archive {
    const archive = this.getEntity(archiveId);
    if (typeof archive === 'undefined') {
      throw new RangeError('Archive was not found for the provided Id..');
    }
    return archive;
  }

  /**
   * Get all fields for an archive.
   *
   * @param archiveId Archive ID.
   * @returns A list of fields.
   */
  getFields(archiveId: number = this.activeId): Fields {
    return this.getArchive(archiveId)
      .fieldIds.filter((fieldId) => this.fieldsQuery.hasEntity(fieldId))
      .map((fieldId): Field => this.fieldsQuery.getField(fieldId));
  }

  /**
   * Get all table fields for an archive.
   *
   * @param archiveId Archive ID.
   * @returns A list of table fields.
   */
  getTableFields(archiveId: number = this.activeId): TableFields {
    const archive = this.getArchive(archiveId);
    return this.tableFieldsQuery
      .getAll()
      .filter((tf) => archive.tableFieldIds?.includes(tf.id) ?? false);
  }

  /**
   * Gets the Versions archive.
   *
   * @returns The versions archive.
   * @throws {AssertionError} If the versions archive does not exist.
   */
  getVersionsArchive(): Archive {
    const revisionArchive = this.getAll().find((a) => a.name === 'Versions');
    assertExists(revisionArchive, 'Versions archive must exist.');
    return revisionArchive;
  }
}
