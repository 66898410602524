import { assert, assertExists } from 'common';
import {
  LineCapStyle,
  PDFPage,
  PDFPageDrawLineOptions,
  PDFPageDrawSVGOptions,
  degrees,
} from 'pdf-lib';

import {
  ConvertableAnnotation,
  EndCapStyle,
  IDocumentAnnotationBase,
  LineAppearance,
  LineStyle,
  Point,
} from '.';

/**
 * Line annotation.
 */
export class LineAnnotation
  extends ConvertableAnnotation
  implements IDocumentAnnotationBase
{
  /** @inheritdoc */
  hasFont = false;

  constructor(
    public page: number,
    /** Starting X coordinate. */
    public x: number,
    /** Starting Y coordinate. */
    public y: number,
    /** Ending coordinates. */
    public end: Point,
    /** Line appearance. */
    public line: LineAppearance,
    /** Length. */
    public length: number,
    public visible: boolean = true
  ) {
    super();
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    const options = this.toPdfElement();
    if (scalePixelsToPoints) this.convertPixelsToPoints(options);
    this.adjustLineForRotation(page, options);
    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    // Draw the line on the page.
    page.drawLine(options);

    // Draw end caps, if required.
    // (Currently only supports arrows, which is the default line tool config).
    if (this.line.endCap === EndCapStyle.Arrow) {
      // Calculate the angle of the line. This lets us know which way to draw
      // the arrow head.
      const lineAngleRadians = Math.atan2(
        this.end.y - this.y,
        this.end.x - this.x
      );
      // Convert the line angle to degrees.
      const lineAngleDegrees = (lineAngleRadians * 180) / Math.PI;
      // Offset the angle rotation to match the drawing.
      const arrowAngle = lineAngleDegrees - 225;
      // Define the SVG path of the arrow
      const arrowPath = 'M0 0L2 5 5 2'; // Simple arrow facing bottom-right.
      // Offset the endpoint to ensure it points to the correct location.
      assertExists(options.thickness);
      const offsetEndpoint = this.resizeLineFromEnd(
        this.x,
        this.y,
        this.end.x,
        this.end.y,
        this.line.thickness * 3
      );
      const arrowOptions: PDFPageDrawSVGOptions = {
        // Adjust the position of the arrow to end at the end of the line.
        x: offsetEndpoint.x,
        y: offsetEndpoint.y,
        color: this.line.color.pdfColor,
        scale: this.line.thickness,
        rotate: degrees(arrowAngle * -1),
        opacity: options.opacity,
      };
      if (scalePixelsToPoints) this.convertPixelsToPoints(arrowOptions);
      this.adjustSvgForRotation(page, arrowOptions);
      if (convertToCartesian) {
        const height = page.getSize().height;
        assert(typeof height === 'number');
        this.convertToCartesian(height, arrowOptions);
      }
      // Draw the arrow on the page
      page.drawSvgPath(arrowPath, arrowOptions);
    }
  }

  private adjustLineForRotation(
    page: PDFPage,
    options: PDFPageDrawLineOptions
  ) {
    assertExists(options.start.x);
    assertExists(options.start.y);
    assertExists(options.end.x);
    assertExists(options.start.x);

    const pageRotation = page.getRotation();
    const xOrigStart = options.start.x;
    const xOrigEnd = options.end.x;
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        options.start.x = options.start.y;
        options.end.x = options.end.y;
        // The y value requires subtracting page height, and the object height
        // to offset corner.
        options.start.y = page.getHeight() - xOrigStart;
        options.end.y = page.getHeight() - xOrigEnd;
        break;
      case 180:
        // Subtract x value from page width.
        options.start.x = page.getWidth() - options.start.x;
        options.end.x = page.getWidth() - options.end.x;
        // Subtract y value from page height, then the height x2 to offset corner.
        options.start.y = page.getHeight() - options.start.y;
        options.end.y = page.getHeight() - options.end.y;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.start.x = page.getWidth() - options.start.y;
        options.end.x = page.getWidth() - options.end.y;
        // Subtract the object height from the original x coordinate to offset.
        options.start.y = xOrigStart;
        options.end.y = xOrigEnd;
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  private adjustSvgForRotation(page: PDFPage, options: PDFPageDrawSVGOptions) {
    assertExists(options.x);
    assertExists(options.y);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    options.rotate = degrees(
      ((options.rotate?.angle ?? 0) + pageRotation.angle) % 360
    );
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        options.x = options.y;
        // The y value requires subtracting page height.
        options.y = page.getHeight() - xOrig;
        break;
      case 180:
        // Subtract x value from page width.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height.
        options.y = page.getHeight() - options.y;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width to offset corner.
        options.x = page.getWidth() - options.y;
        options.y = xOrig;
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Change the length of a line segment from the end by a given distance.
   *
   * If the distance provided would shorten the line below its full length,
   * the starting coordinates will be returned as the new end to form a zero-
   * length line.
   *
   * @param xStart X coordinate of start.
   * @param yStart Y coordinate of start.
   * @param xEnd X coodrinate of end.
   * @param yEnd Y coodrinate of end.
   * @param distanceOffset Number of units to remove from the length.
   *
   * @returns Point for the new line ending.
   */
  private resizeLineFromEnd(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number,
    distanceOffset: number
  ): Point {
    // Calculate the length and direction of the line
    const dx = xEnd - xStart;
    const dy = yEnd - yStart;
    const length = Math.sqrt(dx * dx + dy * dy);
    const angle = Math.atan2(dy, dx);
    try {
      // Check if the offset would make the line shorter than 0.
      assert(
        length - distanceOffset > 0,
        'Shortening distance is longer than the provided line.'
      );
    } catch (error: any) {
      console.warn(error.message);
      return { x: xStart, y: yStart };
    }
    // Calculate the new length of the line
    const newLength = length + distanceOffset;
    // Calculate the new end point of the line
    const newX = xStart + newLength * Math.cos(angle);
    const newY = yStart + newLength * Math.sin(angle);
    // Return the new line end point
    return { x: newX, y: newY };
  }

  /**
   * Convert the annotation to PDF element drawing options.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfElement(): PDFPageDrawLineOptions {
    const options: PDFPageDrawLineOptions = {
      start: { x: this.x, y: this.y },
      end: { x: this.end.x, y: this.end.y },
      color: this.line.color.pdfColor,
      opacity: this.line.color.opacity,
      thickness: this.line.thickness,
      lineCap: LineCapStyle.Butt,
    };
    /*
    As implemented, the lines seem to end in a place that matches up with
    previous implementation in GSW, so no adjustment is needed here. However,
    The following form of the adjustment function can be used to change the
    line length for other/future cap styles.

    if (this.line.endCap !== EndCapStyle.None) {
      assertExists(options.thickness);
      options.end = this.resizeLineFromEnd(
        options.start.x,
        options.start.y,
        options.end.x,
        options.end.y,
        this.line.thickness * 3
      );
    }
    */
    switch (this.line.style) {
      case LineStyle.Solid:
        break;
      case LineStyle.Dotted:
        options.dashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.dashArray = [1, 2]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      case LineStyle.Dashed:
        options.dashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.dashArray = [2, 1]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      default:
        throw new Error(`Unknown LineStyle: ${this.line.style}`);
    }
    return options;
  }
}
