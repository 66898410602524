import { Character, Word, Words } from 'models';
/**
 * Convert 'Square9ApiKeyfreeWords' object to 'KeyfreeWords' object.
 *
 * @param keyfreeWords - Keyfree words object.
 * @returns - Usable keyfree word object.
 */
export const createKeyfreeWordsFromApi = (
  keyfreeWords: S9ApiKeyfreeWordsDefinition
): Words => ({
  words: keyfreeWords.Words.map(
    (w: S9ApiKeyfreeWordDefinition): Word => ({
      characters: w.Characters.map(
        (c: S9ApiKeyfreeCharacterDefinition): Character => ({
          bottom: c.Bottom,
          confidence: c.Confidence,
          left: c.Left,
          right: c.Right,
          top: c.Top,
          value: c.Value,
        })
      ),
      height: w.Height,
      left: w.Left,
      line: w.Line,
      top: w.Top,
      value: w.Value,
      width: w.Width,
    })
  ),
  rotation: keyfreeWords.Rotation,
});

/** S9API List. */
export interface S9ApiKeyfreeWordsDefinition {
  /** Was rotation done. */
  Rotation: string;
  /** Keyfree words. */
  Words: S9ApiKeyfreeWordDefinition[];
}

/** S9API List. */
export interface S9ApiKeyfreeWordDefinition {
  /** Characters that make up word.  */
  Characters: S9ApiKeyfreeCharacterDefinition[];
  /** Word height. */
  Height: number;
  /** Word left x-coordinate. */
  Left: number;
  /** Line number. */
  Line: number;
  /** Word top y-coordinate. */
  Top: number;
  /** Word. */
  Value: string;
  /** Pixel width of word. */
  Width: number;
}

/** S9API List. */
export interface S9ApiKeyfreeCharacterDefinition {
  /** Character bottom x-coordinate. */
  Bottom: number;
  /** Character confidence level of value. */
  Confidence: number;
  /** Character left x-coordinate. */
  Left: number;
  /** Character right x-coordinate. */
  Right: number;
  /** Character top y-coordinate. */
  Top: number;
  /** Character. */
  Value: string;
}
