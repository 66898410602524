import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';
/**
 * Square 9 API Share Service.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiShareService {
  private basePath: string;
  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.basePath = apiUrl;
    });
  }
  /** @inheritdoc */
  deleteToken(
    token: string,
    logout = false,
    deleteLicense = false
  ): Observable<any> {
    return this.http.delete(
      `${this.basePath}/licenses?id=${token}&logout=${logout}&deleteLicense=${deleteLicense}`
    );
  }

  /** @inheritdoc */
  getSecureId(
    databaseId: number,
    archiveId: number,
    documentId: number,
    token: string
  ): Observable<string> {
    return this.http.get(
      `${this.basePath}/dbs/${databaseId}/archives/${archiveId}?DocumentID=${documentId}&Token=${token}`,
      { responseType: 'text' }
    );
  }
  /** @inheritdoc */
  getToken(
    storeCredentials: boolean,
    databaseId: number,
    archiveId: number,
    documentId: number,
    expiration: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/licenses?storeCredentials=${storeCredentials}&database=${databaseId}&archive=${archiveId}&document=${documentId}&expires=${expiration}`
    );
  }
}
