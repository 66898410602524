import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import { ShareDocumentProvider } from 'models';
import { SharePromptComponent } from 'src/app/components/share-prompt/share-prompt.component';

import { SHARE_DOCUMENT_PROVIDER } from '../../common/tokens';

import { ApplicationQuery } from './application.query';

/* Share Document Service */
@Injectable({
  providedIn: 'root',
})
export class ShareDocumentService {
  constructor(
    private dialog: MatDialog,
    private appQuery: ApplicationQuery,
    private logger: NGXLogger,
    @Inject(SHARE_DOCUMENT_PROVIDER)
    private shareDocumentProvider: ShareDocumentProvider
  ) {}
  /**
   * Prompt user to share selected document.
   *
   * @param databaseId DatabaseId.
   * @param archiveId ArchiveId.
   * @param documentId DocumentId.
   */
  promptToShareDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    searchId: number
  ): void {
    this.dialog.open(SharePromptComponent, {
      width: '600px',
      data: {
        databaseId,
        archiveId,
        documentId,
        searchId,
      },
    });
  }
}
