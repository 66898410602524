import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LoggerModule } from 'ngx-logger';

@NgModule({
  imports: [LoggerModule.forChild()],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class GseApiModule {}
