import { HistoryEntry, HistoryPage } from 'models';

/** Display History Entry. */
export interface DisplayHistoryEntry extends HistoryEntry {
  /** Icon displayed in the UI. */
  actionIcon: string;
  /** Display Date. */
  displayDate: string;
  /** Name of the entry in the displayHistoryMap. */
  displayFilterName: string;
}

/**
 * Display History Page.
 */
export interface DisplayHistoryPage extends HistoryPage {
  /** Display history entries on the current page. */
  entries: DisplayHistoryEntry[];
}

/**
 * Converts a history page into a display history page.
 *
 * @param page A history page.
 * @returns A display history page.
 */
export const createDisplayHistoryPage = (
  page: HistoryPage
): DisplayHistoryPage => ({
  total: page.total,
  entries: page.entries.map(createDisplayHistoryEntry),
});

/**
 * Converts a history entry into a display history entry.
 *
 * @param historyEntry A history entry.
 * @returns A display history entry.
 */
export const createDisplayHistoryEntry = (
  historyEntry: HistoryEntry
): DisplayHistoryEntry => {
  const displayEntry = historyEntry as unknown as DisplayHistoryEntry;
  const entryDate = new Date(displayEntry.date);
  displayEntry.displayDate = entryDate.toLocaleString();
  for (const filter of HISTORY_FILTERS) {
    if (
      filter.values.some((value) =>
        historyEntry.action.toLowerCase().includes(value)
      )
    ) {
      displayEntry.actionIcon = filter.icon;
      displayEntry.displayFilterName = filter.name;
    }
  }

  if (!displayEntry.actionIcon) {
    displayEntry.actionIcon = OTHER_HISTORY_FILTER.icon;
    displayEntry.displayFilterName = OTHER_HISTORY_FILTER.name;
  }
  return displayEntry;
};

/**
 * Metadata for filtering and display.
 */
export interface HistoryFilter {
  /** Displayed Icon. */
  icon: string;
  /** Unique name for the filter. */
  name: string;
  /** Translation key for the filter. */
  translationKey: string;
  /** Possible Match Values. */
  values: string[];
}

/**
 * History filter used when an entry doesn't match any of the filters in HISTORY_FILTERS.
 */
export const OTHER_HISTORY_FILTER: HistoryFilter = {
  name: 'other',
  icon: 'description',
  translationKey: 'OTHER',
  // We don't match on values here since this is only used if the entry doesn't match any of the other filters.
  values: [],
};

/** An array of the available history filters. */
export const HISTORY_FILTERS: HistoryFilter[] = [
  {
    name: 'annotation',
    icon: 'rate_review',
    translationKey: 'ANNOTATIONS',
    values: ['annotation'],
  },
  {
    name: 'appended',
    icon: 'add',
    translationKey: 'APPENDED',
    values: ['appended'],
  },
  {
    name: 'checkedInOrOut',
    icon: 'archive',
    translationKey: 'CHECK_IN_CHECK_OUT',
    values: ['checked'],
  },
  {
    name: 'converted',
    icon: 'picture_as_pdf',
    translationKey: 'CONVERTED',
    values: ['converted'],
  },
  {
    name: 'copied',
    icon: 'content_copy',
    translationKey: 'COPIED',
    values: ['copied'],
  },
  {
    name: 'deleted',
    icon: 'delete',
    translationKey: 'DELETED',
    values: ['deleted'],
  },
  {
    name: 'download',
    icon: 'download',
    translationKey: 'DOWNLOADED',
    values: ['downloaded'],
  },
  {
    name: 'email',
    icon: 'email',
    translationKey: 'EMAIL',
    values: ['attached', 'mail', 'notification'],
  },
  {
    name: 'exported',
    icon: 'insert_chart',
    translationKey: 'EXPORTED',
    values: ['exported'],
  },
  {
    name: 'indexed',
    icon: 'exit_to_app',
    translationKey: 'INDEXED',
    values: ['indexed'],
  },
  {
    name: 'moved',
    icon: 'drive_file_move',
    translationKey: 'MOVED',
    values: ['moved'],
  },
  {
    name: 'printed',
    icon: 'print',
    translationKey: 'PRINTED',
    values: ['printed'],
  },
  {
    name: 'version',
    icon: 'source',
    translationKey: 'VERSIONING',
    values: ['version'],
  },
  {
    name: 'published',
    icon: 'public',
    translationKey: 'PUBLISHED_UNPUBLISHED',
    values: ['published', 'unpublished'],
  },
  {
    name: 'viewed',
    icon: 'visibility',
    translationKey: 'VIEWED',
    values: ['viewed'],
  },
  {
    name: 'updated',
    icon: 'edit',
    translationKey: 'REPLACED_UPDATED',
    values: ['replaced', 'updated'],
  },
  OTHER_HISTORY_FILTER,
];
