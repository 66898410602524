import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { NotificationService } from 'src/app/services/notification.service';

import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';

/** Decimal Cell Editor. */
@Component({
  selector: 'app-decimal-cell-editor',
  templateUrl: './decimal-cell-editor.component.html',
  styleUrls: ['./decimal-cell-editor.component.scss'],
})
export class DecimalCellEditorComponent extends FieldCellEditorBaseComponent {
  /** Currency symbol. */
  currencySymbol: string;

  constructor(translate: TranslocoService, notifications: NotificationService) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  getValue() {
    // Ensure that the value is returned as a string since all grid data here is strings.
    return this.formControl.value?.toString() ?? '';
  }
}
