import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

/** URL State. */
export interface UrlState {
  /** Array of previously used URLS. */
  urls: string[];
}

const createInitialState = (): UrlState => {
  return {
    urls: [],
  };
};

// export type UrlState = EntityState<Url>;
/** URL Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'LoginUrls' })
export class UrlStore extends Store<UrlState> {
  constructor() {
    super(createInitialState());
  }
}
