import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';

import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../components/confirmation-dialog/confirmation-dialog.component';
import { DirtyComponent } from '../models';

const confirmDialogData: ConfirmationDialogData = {
  cancelActionText: 'NO',
  confirmActionText: 'YES',
  contents: 'CONFIRM_DISCARD_CHANGES_MSG',
  title: 'DISCARD_CHANGES',
};

/**
 * Checks if the component is dirty and confirms the discard of changes.
 *
 * @param component Dirty component.
 * @returns A boolean indicating if the route should continue.
 */
export const dirtyCheckGuard = (
  component: DirtyComponent
): boolean | Observable<boolean> => {
  const dialog = inject(MatDialog);
  if (component.isDirty) {
    if (typeof component.dirtyFn === 'function') {
      return component.dirtyFn();
    }

    return dialog
      .open(ConfirmationDialogComponent, {
        data: confirmDialogData,
      })
      .afterClosed()
      .pipe(map((result) => !!result));
  }

  // Indicates that the route can deactivate since there are no unsaved changes.
  return true;
};
