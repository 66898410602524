<div *ngIf="inbox$ | async as inbox">
  <app-right-sidebar mode="inbox" [gridApi]="gridApi">
    <app-inbox-actions-toolbar
      [actionMenu]="actionMenuComponent"
      (refreshInbox)="onRefreshInbox()"
      (openSelectedDocuments)="onOpenDocumentSelectedDocuments($event)"
      [(editModeEnabled)]="editModeEnabled"
    ></app-inbox-actions-toolbar>
    <div class="view-container">
      <ag-grid-angular
        #inboxGrid
        [ngStyle]="gridStyle"
        class="ag-theme-material"
        [gridOptions]="gridOptions"
        (contextmenu)="onContextMenuClick($event)"
      >
      </ag-grid-angular>
    </div>
  </app-right-sidebar>
</div>
<ng-template #noInbox> Unable to open inbox for provided ID. </ng-template>

<app-inbox-actions-menu
  #actionMenuComponent
  (openSelectedDocuments)="onOpenDocumentSelectedDocuments($event)"
  (refreshInbox)="onRefreshInbox()"
></app-inbox-actions-menu>
