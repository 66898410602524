import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { AppConfig } from 'models';
import { environment } from 'src/environments/environment';

const createInitialState = (): AppConfig => defaultAppConfig;

/** Default app config. */
export const defaultAppConfig: AppConfig = {
  apiUrl: environment.apiUrl,
  allowGuestSquare9Viewer: false,
  viewerUrl: environment.viewerUrl,
  licenseManagerUrl: environment.licenseManagerUrl,
  userManagementUrl: environment.userManagementUrl,
  adminUrl: environment.adminUrl,
  globalCaptureUrl: environment.globalCaptureUrl,
  gseDownloadUrl: environment.gseDownloadUrl,
  s9HelpUrl: environment.s9HelpUrl,
  languageCdnUrl: 'https://cdn.square-9.com/i18n/ng',
  concurrentUploads: 3,
  customLinks: [],
  authenticationProviders: {},
  hideBranding: false,
  hideLoginInternalUsers: false,
  hideLoginSidePanel: false,
  loginSidePanelUrl: 'https://info.square-9.com/globalsearch-login',
  ntlmAuthentication: false,
  passwordRegex: '',
  passwordRegexValidationMessage: '',
  tosUrl: 'https://www.square-9.com/privacy/end-user-license-agreement/',
  dashboards: [],
  enableGuestLogin: false,
  usePdfJs: true,
  defaultShareDocumentExpirationDays: 3,
  sortArchivesTasksAndInboxesAlphabetically: true,
};

/** Application Config Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'AppConfig', resettable: true })
export class AppConfigStore extends Store<AppConfig> {
  constructor() {
    super(createInitialState());
  }
}
