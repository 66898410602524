<mat-progress-bar
  matLine
  mode="indeterminate"
  *ngIf="isAnnotationsLoading || isDocumentLoading"
></mat-progress-bar>

<!-- Hide the viewer module while we load the document and annotation data. -->
<div
  [ngClass]="{
    'hidden-viewer': isAnnotationsLoading || isDocumentLoading,
  }"
>
  <!-- Render the selected viewer module here. -->
  <ng-container
    *ngIf="usePdfJs; then pdfJsViewer; else baseViewer"
  ></ng-container>
</div>

<!-- Basic viewer module. @see ng2-pdf-viewer -->
<ng-template #baseViewer>
  <app-toolbar></app-toolbar>
  <mat-progress-bar
    *ngIf="loadingProgressPercentage !== 100"
    matLine
    [mode]="loadingProgressPercentage === 0 ? 'query' : 'determinate'"
    [value]="loadingProgressPercentage"
  ></mat-progress-bar>
  <pdf-viewer
    [src]="(pdfSrc$ | async) ?? ''"
    [render-text]="true"
    [original-size]="false"
    (after-load-complete)="onLoadComplete($event)"
    (on-progress)="onProgress($event)"
    [(page)]="pageNumber"
    [zoom]="zoomScale"
  ></pdf-viewer>
</ng-template>

<!-- PDF.js based viewer module (default, advanced). @see @kariudo/ng2-pdfjs-viewer -->
<ng-template #pdfJsViewer>
  <ng2-pdfjs-viewer
    viewerId="pdf-preview-viewer"
    downloadFileName="Square9Download.pdf"
    [pdfSrc]="(pdfSrc$ | async) ?? ''"
    [openFile]="false"
    (onDocumentLoad)="onPdfJsDocumentLoad()"
    [page]="this.goToFirstPageOnLoad ? 1 : pageNumber"
    #pdfPreviewViewer
  ></ng2-pdfjs-viewer>
</ng-template>
