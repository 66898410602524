import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

import { Field } from 'models';

import { FieldComponent } from '../../field/field.component';
import { AddMultiValueFieldEvent } from '../multi-value-field-menu/multi-value-field-menu.component';

/** MultiValue Field Component. */
@Component({
  selector: 'app-multi-value-field',
  templateUrl: './multi-value-field.component.html',
  styleUrls: ['./multi-value-field.component.scss'],
})
export class MultiValueFieldComponent implements AfterViewChecked {
  /** Field. */
  @Input()
  field: Field;
  /** Emits when the field is blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /** List of all the field components for the MV field. */
  @ViewChildren(FieldComponent)
  fieldComponents: QueryList<FieldComponent>;
  /** Emits when the field is focused. */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** MultiValue Field form control. */
  @Input()
  form: UntypedFormArray;
  /** Marks a subcomponent requesting focus. */
  private requestFocus?: Number;

  /**
   * Gets all the form controls in the form group.
   *
   * @returns An array of form controls.
   */
  get formControls(): UntypedFormControl[] {
    return this.form.controls.map((control) => control as UntypedFormControl);
  }

  constructor(private logger: NGXLogger) {}

  /**
   * Marks the component at the specified index for focus at next view check.
   *
   * @param index Index of the field control to focus.
   */
  focusComponentAt(index: number): void {
    // Set requestFocus to index for focus to occur after view checked.
    this.requestFocus = index;
  }

  ngAfterViewChecked(): void {
    if (typeof this.requestFocus === 'number') {
      if (this.requestFocus === this.fieldComponents.length) {
        this.fieldComponents.last.fieldComponent.focus();
      }
      this.fieldComponents
        .get(this.requestFocus as number)
        ?.fieldComponent.focus();
      this.requestFocus = undefined;
    }
  }

  /**
   * Handler for the add multi-value event.
   *
   * @param event Event.
   * @param control Form control.
   * @param skipEmptyCheck Whether to skip the check that sees if the existing control provided is empty.
   */
  onAddMultiValue(
    event: AddMultiValueFieldEvent,
    control: UntypedFormControl,
    skipEmptyCheck: boolean
  ): void {
    this.logger.debug('Add MV value event fired.', event, control);
    const index = this.form.controls.indexOf(control);
    this.logger.debug(`MV field form control found at index ${index}`);

    if (!skipEmptyCheck && !this.form.controls.at(index)?.value) {
      // Don't add an empty row if the one you are on is already empty.
      return;
    }

    let targetIndex = index;
    if (event.append) {
      targetIndex += 1;
    } else {
      targetIndex -= 1;
      if (targetIndex < 0) {
        targetIndex = 0;
      }
    }

    this.form.insert(targetIndex, new UntypedFormControl());
    this.form.markAsDirty();
    this.focusComponentAt(targetIndex);
  }

  /** Handler for the field blurred event. */
  onFieldBlurred(): void {
    this.fieldBlurred.emit(this.field);
  }

  /** Handler for the field focused event. */
  onFieldFocused(): void {
    this.fieldFocused.emit(this.field);
  }

  /**
   * Handler for the remove multi-value event.
   *
   * @param control Form control to remove.
   */
  onRemoveMultiValue(control: UntypedFormControl): void {
    const index = this.form.controls.indexOf(control);
    if (index === -1) {
      this.logger.warn('Could not find form control in form array.');
      return;
    }

    // If the element being removed is the only element then just clear it.
    if (this.form.controls.length === 1) {
      this.form.at(index).setValue('');
      this.form.markAsDirty();
      return;
    }

    // If we get here then we have at least two elements and can remove the control being requested for removal.
    this.form.removeAt(index);
    this.form.markAsDirty();
    this.focusComponentAt(index);
  }
}
