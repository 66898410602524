import { Inbox, InboxFile, createPermissions } from 'models';

import { S9ApiFieldValue } from './field';

/** Describes the response from the API containing inboxes. */
export interface S9ApiInboxResponse {
  Inboxes: S9ApiInbox[];
}

/**
 * Inbox object from Square 9 API.
 */
export interface S9ApiInbox {
  Count: number;
  Files: S9ApiInboxFile[];
  Id: number;
  Name: string;
  Path: string;
  Permissions: number;
}

/**
 * Inbox file object from Square 9 API.
 */
export interface S9ApiInboxFile {
  DateCreated: string;
  DateModified: string;
  Fields: S9ApiFieldValue[];
  FileName: string;
  FileType: string;
  NewName?: string;
  Permissions: number;
}

/**
 * Create an `Inbox` from an external API type.
 *
 * @param s9ApiInbox Inbox from Square9Api.
 * @returns `Inbox` object.
 */
export const createInbox = (s9ApiInbox: S9ApiInbox): Inbox => ({
  id: s9ApiInbox.Id,
  name: s9ApiInbox.Name,
  files: s9ApiInbox.Files.map(createInboxFile),
  permissions: createPermissions(s9ApiInbox.Permissions),
});

/**
 * Create an 'InboxFile' from an external API type.
 *
 * @param s9InboxFile Inbox file from Square9Api.
 * @returns 'InboxFile' object.
 */
export const createInboxFile = (s9InboxFile: S9ApiInboxFile): InboxFile => ({
  filename: s9InboxFile.FileName,
  fileType: s9InboxFile.FileType,
  dateCreated: s9InboxFile.DateCreated,
  dateModified: s9InboxFile.DateModified,
  permissions: createPermissions(s9InboxFile.Permissions),
  newName: s9InboxFile.NewName,
});

/**
 * Creates an 'S9ApiInboxFile' from an inbox file.
 *
 * @param inboxFile Inbox file.
 * @returns 'S9ApiInboxFile' object.
 */
export const createS9ApiInboxFile = (inboxFile: InboxFile): S9ApiInboxFile => ({
  FileName: inboxFile.filename,
  FileType: inboxFile.fileType,
  DateCreated: inboxFile.dateCreated,
  DateModified: inboxFile.dateModified,
  Fields: [], // Value ignored. Inboxes have no fields to index, but it's part of the route.
  Permissions: 0, // Value ignored. We have no reason to set a value here, but it's part of the required route object.
  NewName: inboxFile.newName,
});
