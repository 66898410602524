import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { PdfJsViewerModule } from '@kariudo/ng2-pdfjs-viewer';
import { HotkeysModule } from '@ngneat/hotkeys';
import { PdfViewerModule as Ng2PdfViewerModule } from 'ng2-pdf-viewer';
import { LoggerModule } from 'ngx-logger';

import { FindInFileDialogComponent } from './components/find-in-file-dialog/find-in-file-dialog.component';
import { PartialLoadSnackbarComponent } from './components/partial-load-snackbar/partial-load-snackbar.component';
import { PdfViewerComponent } from './components/pdf-viewer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

@NgModule({
  declarations: [
    PdfViewerComponent,
    FindInFileDialogComponent,
    ToolbarComponent,
    PartialLoadSnackbarComponent,
  ],
  imports: [
    CommonModule,
    Ng2PdfViewerModule,
    HotkeysModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule,
    FormsModule,
    LoggerModule.forChild(),
    PdfJsViewerModule,
  ],
  exports: [PdfViewerComponent, FindInFileDialogComponent],
})
export class PdfViewerModule {}
