import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  AgGridEvent,
  GridApi,
  GridOptions,
  RowDoubleClickedEvent,
  RowValueChangedEvent,
} from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { interval } from 'rxjs';

import { assert, assertExists } from 'common';
import {
  DocumentUpdateProvider,
  FieldValues,
  SearchResult,
  SearchResults,
  UserFriendlyError,
} from 'models';
import { getPageSizes } from 'src/app/common/page-sizes';
import { DOCUMENT_UPDATE_PROVIDER } from 'src/app/common/tokens';
import {
  ArchiveGridData,
  DocumentUpdateSession,
  PageChangeEvent,
  SearchResultDocumentOpenRequest,
} from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { FieldsQuery } from 'src/app/state/fields/fields.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

import { TaskActionMenuComponent } from '../task-action-menu/task-action-menu.component';

/**
 * Task Search View.
 */
@UntilDestroy()
@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {
  /** AG Grid instance. */
  @ViewChild('archiveGrid')
  archiveGrid: AgGridAngular;
  /** Task actions menu instance for context menu. */
  @ViewChild(TaskActionMenuComponent)
  taskActionsMenu: TaskActionMenuComponent;
  /** Archive id. */
  archiveId: number;
  /** Current grid page. */
  currentPage = 1;
  /** Determines if the grid columns are currently editable. */
  editModeEnabled = false;
  /** Tasks grid options. */
  gridOptions: GridOptions;
  /** Page size options. */
  pageSizes = getPageSizes(this.appQuery);
  /** Current number of records per page in the grid. */
  recordsPerPage = this.appQuery.archiveResultsPerPage;
  /** Search result count. */
  resultCount: number;
  /** Whether to show the table field grid. */
  showTableFieldGrid = false;
  /** Task ID. */
  taskId: string;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  private activeGridData: ArchiveGridData;
  private databaseId: number;

  constructor(
    private appQuery: ApplicationQuery,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery,
    private fieldsQuery: FieldsQuery,
    private taskSearchesQuery: TaskSearchesQuery,
    private taskSearchService: TaskSearchesService,
    private tableFieldUIService: TableFieldUIService,
    private viewerService: ViewerService,
    private auth: AuthenticationService,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider,
    private router: Router,
    private gridSettingsService: GridSettingsService,
    private gridHelper: GridHelperService,
    private layout: LayoutService,
    private notify: NotificationService,
    private logger: NGXLogger,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * Get the records per page setting.
   *
   * @returns A number.
   */
  get archiveResultsPerPage(): number {
    return this.appQuery.archiveResultsPerPage;
  }

  /**
   * Gets the grid api for the current grid.
   *
   * @returns An instance of GridApi or undefined if the grid does not exist.
   */
  get gridApi(): GridApi<any> | undefined {
    return this.archiveGrid?.api;
  }

  /**
   * Grid style attached to the AG grid element.
   *
   * Height will adjust for the presense of view tabs and table field controls.
   *
   * @returns CSS rule for the size of the grid control.
   */
  get gridStyle() {
    // Establish the height of all other controls (toolbars above and below).
    let totalControlHeight = 172;
    if (this.breakpointObserver.isMatched('(max-width: 599px)'))
      totalControlHeight -= 16;

    return {
      width: '100%',
      height: `calc(${
        this.showTableFieldGrid ? '50vh' : 'calc(100vh - 12px)'
      } - ${totalControlHeight}px)`,
    };
  }

  ngOnInit(): void {
    this.configureGrid();
  }

  /**
   * Right click event for the grid right click.
   *
   * @param event Mouse event.
   */
  onContextMenuClick(event: MouseEvent): void {
    this.gridHelper.openContextMenu(
      event,
      this.activeGridData.selectedRowNodes,
      (rowId) => this.archiveGrid.api?.getRowNode(rowId),
      this.taskActionsMenu
    );
  }

  /**
   * Handler for the open documents event.
   *
   * @param openRequest Optional array of SearchResults to open. Otherwise it uses the selected rows.
   */
  onOpenDocuments(openRequest?: SearchResultDocumentOpenRequest): void {
    this.logger.debug(
      'Open documents event received.',
      this.activeGridData.selectedRowNodes
    );
    const resultsToOpen = openRequest?.searchResults
      ? openRequest?.searchResults
      : this.activeGridData.selectedRowNodes.map(
          (row) => row.data as SearchResult
        );
    this.openDocuments(resultsToOpen, !!openRequest?.forceExternalViewer);
  }

  /**
   * Handler for the page change event.
   *
   * @param event Page change event.
   */
  onPageChange(event: PageChangeEvent): void {
    this.logger.debug('Page changed');
    this.currentPage = event.page;
    this.activeGridData.currentPage = event.page;
    this.recordsPerPage = event.pageSize;
    this.archiveGrid.api.updateGridOptions({
      paginationPageSize: event.pageSize,
    });
    this.refreshSearch();
  }

  /**
   * Handler for the refresh search event.
   */
  onRefreshSearch(): void {
    this.logger.debug('Refresh search event emitted. Refreshing task grid.');
    this.refreshSearch();
  }

  /**
   * Handler for toggle edit event.
   *
   * @param enabled Whether edit is enabled.
   */
  onToggleEdit(enabled: boolean): void {
    this.logger.debug('Toggling edit mode.');
    this.editModeEnabled = enabled;
    this.archiveGrid.api?.stopEditing();
  }

  private configureGrid(): void {
    this.gridOptions = this.gridHelper.createArchiveGridOptions({
      onRowDoubleClicked: (event) => this.onRowDoubleClicked(event),
      onGridReady: () => {
        this.listenToArchivesForColumns();
        this.listenForResultsPerPage();
        this.runSearchOrRedirectOnRouteChange();
        this.listenToTableFieldEvent();
      },
      onFirstDataRendered: (params) => this.resetStateIfNoneSaved(params),
      onRowDataUpdated: (params) => this.resetStateIfNoneSaved(params),
      onRowValueChanged: (params) => this.onRowValueChange(params),
      onColumnResized: (params) => {
        if (params.finished) {
          this.logger.debug('Grid column resized.', params);
          this.saveColumnState(params);
        }
      },
      onSortChanged: (params) => {
        this.logger.debug('Grid column sort changed.', params);
        this.saveColumnState(params);
      },
      onColumnVisible: (params) => this.saveColumnState(params),
      onColumnPinned: (params) => this.saveColumnState(params),
      onDragStopped: (params) => this.saveColumnState(params),
      onRowSelected: (event) => this.activeGridData.onRowSelected(event),
    });

    this.appQuery.doubleClickToOpenDocuments$
      .pipe(untilDestroyed(this))
      .subscribe((doubleClickToOpen) => {
        this.gridOptions.onRowDoubleClicked = doubleClickToOpen
          ? (dblClickEvent) => this.onRowDoubleClicked(dblClickEvent)
          : undefined;
      });
  }

  private listenForResultsPerPage() {
    this.appQuery.archiveResultsPerPage$.subscribe((resultsPerPage) => {
      if (this.recordsPerPage !== resultsPerPage) {
        // Only update paging if the value has changed.
        this.archiveGrid.api?.updateGridOptions({
          paginationPageSize: resultsPerPage,
        });
        this.currentPage = 1;
        this.recordsPerPage = resultsPerPage;
        this.refreshSearch();
      }
    });
  }

  private listenToArchivesForColumns(): void {
    this.archivesQuery.activeId$.subscribe((archiveId) => {
      assertExists(archiveId);

      const newCols = this.gridHelper.getArchiveColumnDefinitions(
        archiveId,
        () => this.editModeEnabled,
        true
      );

      this.activeGridData = this.gridSettingsService.getOrCreateArchiveGridData(
        this.databasesQuery.activeId,
        archiveId
      );

      // Ensure there are no previously selected rows.
      this.activeGridData.selectedRowNodes.length = 0;
      this.archiveGrid.api.deselectAll();

      this.archiveGrid.api?.updateGridOptions({ columnDefs: newCols });

      if (this.activeGridData.columnStates) {
        const columnStateUpdateSuccessful =
          this.archiveGrid.api?.applyColumnState({
            state: this.activeGridData.columnStates,
            applyOrder: true,
          });
        if (!columnStateUpdateSuccessful) {
          this.logger.error(
            'Column state update failed. This most likely occurred because one or more of the columns did not exist.'
          );
        }
      }
    });
  }

  private listenToTableFieldEvent(): void {
    this.tableFieldUIService.activeTableField$.subscribe((activeTableField) => {
      this.logger.debug('Active table field or row changed.', activeTableField);
      this.showTableFieldGrid = !!activeTableField;
    });
  }

  /**
   * Event handler for the row double click event.
   *
   * @todo DRY this out since this is a copy of basically the same function in the archive-view component.
   * @param rowDblClickEvent Row double click event.
   */
  private onRowDoubleClicked(rowDblClickEvent: RowDoubleClickedEvent): void {
    this.logger.debug('Row double clicked.', rowDblClickEvent);
    if (this.editModeEnabled) {
      this.logger.debug(
        'Edit mode is enabled. Double click to open is disabled.'
      );
      return;
    }
    this.logger.debug('Opening double clicked documents.');
    const mouseEvent = rowDblClickEvent.event as MouseEvent;
    const searchResults =
      rowDblClickEvent.node.isSelected() &&
      (mouseEvent.ctrlKey || mouseEvent.shiftKey)
        ? this.activeGridData.selectedRowNodes.map(
            (row) => row.data as SearchResult
          )
        : [rowDblClickEvent.node.data as SearchResult];
    this.openDocuments(searchResults);
  }

  private onRowValueChange(params: RowValueChangedEvent<SearchResult>): void {
    this.logger.debug('Row value changed.', params);
    assertExists(
      params.data,
      'Row data should be a search result and not null.'
    );
    const fieldValues: FieldValues = params.data.fields;
    const archiveTableFields = this.archivesQuery.getTableFields(
      this.archiveId
    );

    // Get the list of fields and filter out table field members.
    const archiveFields = this.archivesQuery
      .getFields(this.archiveId)
      .filter(
        (field) =>
          !archiveTableFields.some((tableField) =>
            tableField.fieldIds.includes(field.id)
          )
      );

    for (const field of archiveFields) {
      if (field.required) {
        const fieldValue = fieldValues.find((f) => f.id === field.id);
        if (
          !fieldValue ||
          (field.multiValue
            ? fieldValue.multiValue.length < 0
            : !fieldValue.value)
        ) {
          const error: UserFriendlyError = {
            description:
              'Unable to save row changes. Value was missing for the required field' +
              field.name,
            error: undefined,
            i18n: 'REQUIRED_FIELD_VALUE_MISSING',
            i18nParameters: { fieldName: field.name },
          };
          this.notify.error(error);
          return;
        }
      }
    }

    const updateSession = new DocumentUpdateSession(false, false, true, false);
    this.documentUpdateProvider
      .updateFieldData(
        this.databaseId,
        this.archiveId,
        params.data.id,
        params.data.secureId,
        fieldValues,
        updateSession
      )
      .subscribe({
        next: () => {
          this.notify.success('FIELD_DATA_UPDATED_SUCCESSFULLY_MESSAGE');
        },
        error: (error) => this.notify.error(error),
      });
  }

  private openDocuments(
    searchResults: SearchResults,
    forceExternalDocumentViewer = false
  ): void {
    const forceInternalDocumentViewer = this.auth.isGuest;
    if (!forceExternalDocumentViewer && !forceInternalDocumentViewer) {
      this.logger.warn(
        'Both internal and external viewer can not be forced for use here, external viewer will be selected.'
      );
    }
    const useInternalDocumentViewer =
      (this.appQuery.viewerUseInternal || forceInternalDocumentViewer) &&
      !forceExternalDocumentViewer;
    if (useInternalDocumentViewer) {
      if (this.appQuery.alwaysOpenNewTab) {
        const urlTree = this.router.createUrlTree([
          'db',
          this.databaseId,
          'archive',
          this.archiveId,
          'task',
          this.taskId,
          'document',
          searchResults.map((result) => result.id).join(','),
        ]);
        const viewerWindow = window.open(urlTree.toString(), '_blank');
        assertExists(
          viewerWindow,
          'Internal viewer window must exist to listen for close event.'
        );
        if (this.appQuery.refreshResultsWhenClosingDocumentTabs) {
          const intervalSubscription = interval(1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              if (viewerWindow.closed) {
                intervalSubscription.unsubscribe();
                this.refreshSearch();
              }
            });
        }
      } else {
        this.router.navigate(
          [
            'db',
            this.databaseId,
            'archive',
            this.archiveId,
            'task',
            this.taskId,
            'document',
            searchResults.map((result) => result.id).join(','),
          ],
          {
            queryParamsHandling: 'merge',
          }
        );
      }
    } else {
      this.viewerService
        .openSelectedArchiveDocuments(
          this.databasesQuery.activeId,
          this.archivesQuery.activeId,
          0,
          [],
          searchResults,
          this.appQuery.alwaysOpenNewTab
        )
        .subscribe({
          next: () => {
            this.logger.debug('Viewer closed. Refreshing task grid.');
            this.refreshSearch();
          },
          error: (error) =>
            this.logger.error('Unable to open documents', error),
        });
    }
  }

  private refreshSearch(): void {
    this.logger.debug('Refreshing search.');
    this.archiveGrid.api.deselectAll();
    this.runSearchOrRedirectOnRouteChange();
  }

  private resetStateIfNoneSaved(params: AgGridEvent) {
    if (!this.activeGridData.hasStoredColumnStates) {
      params.api.resetColumnState();
    }
  }

  private runSearchOrRedirectOnRouteChange(): void {
    this.taskSearchesQuery.taskSearchRouteParams$
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId, taskId]) => {
        assertExists(taskId);
        assertExists(databaseId);
        assertExists(archiveId);
        this.archiveId = Number(archiveId);
        this.databaseId = Number(databaseId);
        this.taskId = taskId;
        const taskIdSeparatorIndex = this.taskId.lastIndexOf('_');
        assert(
          taskIdSeparatorIndex >= 0,
          'Invalid task Id, task Id separator not found.'
        );
        const workflowId = this.taskId.slice(0, taskIdSeparatorIndex);
        const queueKey = this.taskId.slice(taskIdSeparatorIndex + 1);

        // We need to ensure the active archive is still in the task search.
        if (
          !this.taskSearchesQuery.active.archives.some(
            (a) => a.id === archiveId
          )
        ) {
          // If the archive is no longer in the task search try to load the first one in the task search.
          // This can occur if there are no longer documents belonging to the task search in the current archive because of running user actions.
          this.logger.debug('Active archive was not found in the task search.');
          if (this.taskSearchesQuery.active.archives.length > 0) {
            this.logger.debug(
              'Redirecting to the next archive in the task search.'
            );
            const nextArchive = this.taskSearchesQuery.active.archives[0];
            this.router.navigate([
              'db',
              this.databaseId,
              'archive',
              nextArchive.id,
              'task',
              taskId,
            ]);
            return;
          } else {
            this.logger.debug(
              'Task search does not contain any archive documents. Redirecting to the home page.'
            );
            this.router.navigate(['db', this.databaseId]);
            return;
          }
        }
        this.taskSearchService.api
          .getCount(databaseId, archiveId, workflowId, queueKey)
          .subscribe((count) => {
            this.resultCount = count;
          });
        this.taskSearchService
          .run(
            this.archiveId,
            workflowId,
            queueKey,
            this.currentPage,
            this.recordsPerPage
          )
          .subscribe((results) => {
            this.logger.debug('Task search results.', results);
            this.archiveGrid.api.updateGridOptions({ rowData: results });
            // Changes may not be detected in nested components
            // (i.e., table-field-button) for new rowdata, so refresh the rows.
            this.archiveGrid.api?.redrawRows();
          });
      });
  }

  private saveColumnState(params: AgGridEvent) {
    this.activeGridData.columnStates = params.api.getColumnState();
  }
}
