<!-- Lists -->
<ng-container *ngIf="fieldHasList; else notAList" [ngSwitch]="field.list.type">
  <app-dropdown-field
    #fieldComponent
    *ngSwitchCase="listType.dropdown"
    [form-control]="control"
    [field]="field"
    [mv-field-menu]="mvFieldMenu"
    (fieldFocused)="onFieldFocused()"
    (fieldBlurred)="onFieldBlurred()"
  ></app-dropdown-field>
  <app-typeahead-field
    #fieldComponent
    *ngSwitchCase="listType.typeahead"
    [form-control]="control"
    [field]="field"
    [mv-field-menu]="mvFieldMenu"
    (fieldFocused)="onFieldFocused()"
    (fieldBlurred)="onFieldBlurred()"
  ></app-typeahead-field>
</ng-container>

<!-- Non lists -->
<ng-template #notAList>
  <ng-container [ngSwitch]="field.type">
    <app-character-field
      *ngSwitchCase="fieldDataType.character"
      #fieldComponent
      [allowTextArea]="true"
      [form-control]="control"
      [field]="field"
      [mv-field-menu]="mvFieldMenu"
      (fieldFocused)="onFieldFocused()"
      (fieldBlurred)="onFieldBlurred()"
    ></app-character-field>
    <app-number-field
      *ngSwitchCase="fieldDataType.integer"
      #fieldComponent
      [form-control]="control"
      [field]="field"
      [mv-field-menu]="mvFieldMenu"
      (fieldFocused)="onFieldFocused()"
      (fieldBlurred)="onFieldBlurred()"
    ></app-number-field>
    <app-decimal-field
      *ngSwitchCase="fieldDataType.decimal"
      #fieldComponent
      [form-control]="control"
      [field]="field"
      [mv-field-menu]="mvFieldMenu"
      (fieldFocused)="onFieldFocused()"
      (fieldBlurred)="onFieldBlurred()"
    ></app-decimal-field>
    <app-date-field
      *ngSwitchCase="fieldDataType.date"
      #fieldComponent
      [form-control]="control"
      [field]="field"
      [mv-field-menu]="mvFieldMenu"
      (fieldFocused)="onFieldFocused()"
      (fieldBlurred)="onFieldBlurred()"
    >
    </app-date-field>
  </ng-container>
</ng-template>
