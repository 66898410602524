<div class="indexer-container">
  <form [formGroup]="indexerForm">
    <div class="field-container" *ngFor="let field of indexerFields">
      <app-field
        *ngIf="!field.multiValue"
        [field]="field"
        [form-control]="getFormControl(field.id)"
        (fieldFocused)="onFieldFocus($event)"
        (fieldBlurred)="onFieldBlur($event)"
      ></app-field>
      <app-multi-value-field
        *ngIf="field.multiValue"
        [field]="field"
        [form]="getMultiValueFormArray(field.id)"
        (fieldFocused)="onFieldFocus($event)"
        (fieldBlurred)="onFieldBlur($event)"
      ></app-multi-value-field>
      <ng-container
        *ngIf="
          !field.multiValue &&
          getAdvancedLinksForField(field.id) as advancedLinks
        "
      >
        <button
          *ngIf="advancedLinks.length > 0"
          mat-mini-fab
          mat-small-icon-button
          type="button"
          class="advanced-link-button"
          [matTooltip]="'OPEN_ADVANCED_LINKS' | transloco"
          (click)="onClickAdvancedLink(advancedLinks)"
        >
          <mat-icon>link</mat-icon>
        </button>
      </ng-container>
    </div>
  </form>
  <app-table-field-button
    *ngFor="let tableField of tableFields"
    [table-field]="tableField"
    [document-id]="id"
    [document-secure-id]="secureId"
  ></app-table-field-button>
</div>
