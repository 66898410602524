import { PDFDocument, PDFFont } from 'pdf-lib';

import { Dictionary } from 'models';

/** PDF document workload for annotation merging. */
export class PdfDocumentWorkload {
  /**
   * Create a new PDF workload.
   *
   * @param document PDF document.
   * @param embeddedFonts Embedded `PDFFont` resources.
   * @param annotationScale Scale factor for annotation coordinates.
   * (Defaults to 0.24 for Atalasoft PDF). May be a single value or an array of
   * values for each page.
   * @returns A new PDF document workload.
   */
  constructor(
    public document: PDFDocument,
    public embeddedFonts = [] as unknown as Dictionary<PDFFont>,
    public annotationScale: number | number[] = 0.24
  ) {}
}
