/** Merge Object. */
export interface MergeObject {
  /** Base Document being merged. */
  baseDocument: MergeDocument;
  /** Documents to be merged to the base document. */
  documents: MergeDocument[];
  /** Destination for the merged document. */
  target: MergeTarget;
  /** Merge type. */
  // type: string;
}

/** Merge Target. */
export interface MergeTarget {
  /** Append type. */
  appendType: AppendType;
  /** Inbox ID or Archive ID depending on merge target type. */
  id: number;
  /** Merge target type. */
  mergeTargetType: MergeTargetType;
}

/** Available destinations for a merged document. */
export enum MergeTargetType {
  /** Inbox. */
  inbox,
  /** Archive. */
  archive,
}

/** Append Types. */
export enum AppendType {
  /** Documents are appended to the end of the base document. */
  toEnd,
  /** Documents are appended to the beginning of the base document. */
  toBeginning,
}

/** Merge Document. */
export declare type MergeDocument = ArchiveMergeDocument | InboxMergeDocument;

/** Archive Merge Document. */
export class ArchiveMergeDocument {
  /** Type used for type name handling in the API. */
  readonly $type = 'Square9.Objects.ArchiveDocInfo, Square9.Objects';
  /** Archive ID. */
  archiveId: number;
  /** Document ID. */
  docId: number;

  constructor(archiveId: number, documentId: number) {
    this.archiveId = archiveId;
    this.docId = documentId;
  }
}

/** Inbox Merge Document. */
export class InboxMergeDocument {
  /** Type used for type name handling in the API. */
  readonly $type = 'Square9.Objects.InboxDocInfo, Square9.Objects';
  /** Inbox ID. */
  inboxId: number;
  /** Path to the inbox document. */
  path: string;

  constructor(inboxId: number, path: string) {
    this.inboxId = inboxId;
    this.path = path;
  }
}

/** Merge Result. */
export class MergeResult {
  /** Document ID of the new merged document or 0 if an inbox document. */
  documentId: number;
}
