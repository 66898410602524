/**
 * Annotation line styling.
 */
export enum LineStyle {
  /** Solid. */
  Solid = 'solid',
  /** Dashed. */
  Dashed = 'dashed',
  /** Dotted. */
  Dotted = 'dotted',
}
/**
 * Annotation line cap styling.
 */
export enum CapStyle {
  /** Butt. */
  Butt = 'butt',
  /** Projecting. */
  Projecting = 'projecting',
  /** Round. */
  Round = 'round',
}

/**
 * Annotation line ending style.
 */
export enum EndCapStyle {
  /** End of line has no particular style. */
  None = 'none',
  /** Line ends with an outward pointing arrow. */
  Arrow = 'arrow',
}
