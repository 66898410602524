import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig, Dictionary } from 'models';

import { AppConfigStore, defaultAppConfig } from './app-config.store';

/** Application Config Service. */
@Injectable({ providedIn: 'root' })
export class AppConfigService {
  constructor(
    private appConfigStore: AppConfigStore,
    private http: HttpClient,
    private logger: NGXLogger
  ) {}

  /**
   * Gets the app config from the instance.
   *
   * @param instanceUrl Instance URL.
   * @returns An observable app config.
   */
  get(instanceUrl: string): Observable<AppConfig> {
    return this.http
      .get<AppConfig>(`${instanceUrl}/public/config.json`, {
        headers: new HttpHeaders({
          // Request the file without cache.
          'Cache-Control': 'no-cache',
          // biome-ignore lint/style/useNamingConvention: HTTP Header
          Pragma: 'no-cache',
        }),
      })
      .pipe(
        setLoading(this.appConfigStore),
        map((appConfig) => {
          this.logger.debug('Loading configuration from instance.');
          /** Keys not present in the instance configuration that will be defaulted. */
          const defaultedKeys = Object.keys(defaultAppConfig).filter(
            (key) => !Object.hasOwn(appConfig, key)
          );

          /** Keys present in the instance configuration that are not part of our expected object. */
          const uknownKeys = Object.keys(appConfig).filter(
            (key) => !Object.hasOwn(defaultAppConfig, key)
          );

          // Warn for keys that will use default values.
          for (const defaultedKey of defaultedKeys) {
            this.logger.info(
              `Application configuration from instance did not contain a key for "${defaultedKey}", value will default to:`,
              JSON.stringify(
                (defaultAppConfig as Dictionary<any>)[defaultedKey]
              )
            );
          }

          // Warn for keys that were not expected.
          for (const uknownKey of uknownKeys) {
            this.logger.info(
              `Instance configuration contained an unknown key "${uknownKey}". Value will be ignored.`
            );
          }

          // Merge instance configuration against default configuration, then store it.
          const mergedConfig = { ...defaultAppConfig, ...appConfig };
          this.update(mergedConfig);
          return mergedConfig;
        })
      );
  }

  /**
   * Reset the app config back to its default state.
   */
  resetToDefault(): void {
    this.appConfigStore.reset();
  }

  /**
   * Updates the app config in the store.
   *
   * @param appConfig App config.
   */
  update(appConfig: Partial<AppConfig>) {
    this.appConfigStore.update(appConfig);
  }
}
