import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DocumentHistoryProvider, HistoryPage } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S9ApiHistoryResult, createHistoryPage } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API document history access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiHistoryService implements DocumentHistoryProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = `${apiUrl}`));
  }

  /**
   * Retrieve archive history.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param pageNumber Requested page number.
   * @param returnLimit Number of records returned.
   * @param documentIds An array of document Ids to filter history.
   * @returns An observable containing archive history.
   */
  getArchiveHistory(
    databaseId: number,
    archiveId: number,
    pageNumber: number,
    returnLimit: number,
    documentIds?: number[]
  ): Observable<HistoryPage> {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('doclimit', returnLimit.toString())
      .set('token', ''); // this seems to be required for routing
    if (documentIds && documentIds.length > 0) {
      params = params.set('documents', documentIds.join(','));
    }
    return this.http
      .get<S9ApiHistoryResult>(
        `${this.basePath}/dbs/${databaseId}/archives/${archiveId}/audit`,
        {
          params,
        }
      )
      .pipe(map(createHistoryPage));
  }
}
