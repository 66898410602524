<div class="container">
  <button
    aria-label="Open Multivalue Field Button"
    mat-icon-button
    (click)="onClickEdit()"
  >
    <mat-icon>list_alt</mat-icon>
  </button>
  <span>{{ displayValue }}</span>
</div>
<div class="container">
  <button mat-icon-button (click)="onClickEdit()">
    <mat-icon>list_alt</mat-icon>
  </button>
  <span>{{ displayValue }}</span>
</div>
