import { Component, Inject } from '@angular/core';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import { GSEProvider, UserFriendlyError } from 'models';
import { GSE_PROVIDER } from 'src/app/common/tokens';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { GSEDialogService } from 'src/app/services/gsedialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';

/** GlobalSearch Extensions Install Dialog. */
@Component({
  selector: 'app-gse-install-dialog',
  templateUrl: './gseinstall-dialog.component.html',
  styleUrls: ['./gseinstall-dialog.component.scss'],
})
export class GSEInstallDialogComponent {
  /** Is GlobalSearch Extensions currently being installed? */
  isGseInstalling: boolean;

  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private notify: NotificationService,
    private gseDialog: GSEDialogService,
    private dialog: MatDialogReference<GSEInstallDialogComponent>,
    @Inject(GSE_PROVIDER) private gse: GSEProvider
  ) {}

  /**
   * Install GlobalSearch Extensions.
   *
   * Initiates installation if unavailable.
   */
  installExtensions() {
    this.logger.debug('Downloading GSE...', this.appConfigQuery.gseDownloadUrl);
    this.isGseInstalling = true;
    window.open(this.appConfigQuery.gseDownloadUrl, '_blank');
    this.notify.info('GSE_SYNCING_IN_BACKGROUND');
    // GSE version of URL is not suffixed with '/api/'.
    const gseFriendlyUrl = this.appConfigQuery.appConfig.apiUrl
      .trim()
      .replace(/\/api\/?$/i, '');
    // Sync API url to GSE settings.
    this.gse.syncExtensions(gseFriendlyUrl).subscribe({
      next: () => {
        this.isGseInstalling = false;
        this.notify.info('GSE_SYNC_COMPLETE');
      },
      error: (error: UserFriendlyError) => {
        this.isGseInstalling = false;
        this.notify.error(error);
      },
    });
  }

  /** Handler for the configuration click event. */
  onClickConfiguration(): void {
    this.gseDialog.openConfiguration();
    this.dialog.close();
  }
}
