import { UploadOutput } from '@angular-ex/uploader';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

import { Archive, ArchiveImportSession } from 'models';
import { removeFileExtension } from 'src/app/common/utility';
import { Api } from 'src/app/models';
import { UploadService } from 'src/app/services/upload.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';

import { NavigationArchiveMenuComponent } from '../navigation-archive-menu/navigation-archive-menu.component';

/**
 * Sidebar archive component.
 */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-navigation-archive',
  templateUrl: './navigation-archive.component.html',
  styleUrls: ['./navigation-archive.component.scss'],
})
export class NavigationArchiveComponent implements OnDestroy {
  /** Archive object. */
  @Input() archive: Archive;
  /** Database ID. */
  @Input() dbId: number;
  /** Reference to the file input element. */
  @ViewChild('archiveFileUploader')
  fileUploader: ElementRef;
  /** An event raised when the component requests child archives. */
  @Output()
  loadChildArchives = new EventEmitter<Archive>();
  /** Reference to the menu. */
  @ViewChild(NavigationArchiveMenuComponent)
  menu: NavigationArchiveMenuComponent;

  /** Determines if a file is dragged over the component. */
  dragOver: boolean;
  /** Uploader attached to this navigation component. */
  uploader = this.uploadService.createUploader(
    this.appQuery.viewerUseInternalForImport ? Api.square9 : Api.viewer
  );
  /** Observable uploader options bound to the @angular-ex/uploader. */
  uploaderOptions$ = this.uploadService.uploaderOptions$;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private uploadService: UploadService,
    private viewerService: ViewerService,
    private grid: GridSettingsService,
    private appQuery: ApplicationQuery
  ) {}

  /**
   * The click event for the child archives button.
   */
  clickChildArchives(): void {
    this.logger.debug('Emit click of child archive.', this.archive);
    this.loadChildArchives.emit(this.archive);
  }

  ngOnDestroy(): void {
    this.uploadService.destroyUploader(this.uploader);
  }

  /**
   * Handler for opening the menu.
   *
   * @param event Mouse event.
   */
  onOpenMenu(event: MouseEvent): void {
    event.preventDefault();
    this.menu.openMenu(event, this.archive);
  }

  /**
   * The event function that runs whenever a change occurs in the @angular-ex/uploader component.
   *
   * @param output An instance of UploadOutput provided by @angular-ex/uploader.
   */
  onUploadOutput = (output: UploadOutput) => {
    const result = this.uploadService.onUploadOutput(output, this.uploader);
    this.dragOver = result.dragOver;
    if (result.isDone) {
      const filenames = this.uploader.flushCompletedFiles();
      if (result.isError) {
        this.logger.error('File upload failed.', filenames);
        return; // Do not create viewer session and redirect when upload failed.
      }
      this.logger.debug('File uploads are finished.');
      if (this.appQuery.viewerUseInternalForImport) {
        this.startInternalViewerImportSession(filenames);
      } else {
        this.startViewerImportSession(filenames);
      }
    }
  };

  private startInternalViewerImportSession(filenames: string[]): void {
    this.logger.debug(
      'File uploads will be attached to an internal viewer session.'
    );

    this.router.navigate(
      [
        'db',
        this.dbId,
        'archive',
        this.archive.id,
        'import',
        // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
        filenames.join(),
      ],
      { queryParamsHandling: 'merge' }
    );
  }

  private startViewerImportSession(filenames: string[]) {
    this.logger.debug(
      'File uploads will be attached to a viewer session.',
      filenames
    );
    const session: ArchiveImportSession = {
      database: this.dbId,
      documents: filenames.map((filename) => ({
        archiveId: this.archive.id,
        filename: removeFileExtension(filename),
      })),
    };
    this.viewerService.createSession(session).subscribe((sessionId) => {
      this.logger.debug('Viewer session created with session id:', sessionId);
      this.viewerService
        .openViewerSession(sessionId, this.appQuery.alwaysOpenNewTab)
        .subscribe(() => {
          this.logger.debug('Viewer closed.');
          this.grid.refreshArchiveGrid(this.dbId, this.archive.id);
        });
    });
  }
}
