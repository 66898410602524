import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { AdvancedLink } from 'models';

/**
 * Advanced Link state entity.
 */
export type AdvancedLinksState = EntityState<AdvancedLink, number>;

/**
 * Advanced Link store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'advancedLinks', resettable: true })
export class AdvancedLinksStore extends EntityStore<AdvancedLinksState> {
  constructor() {
    super();
  }
}
