<mat-progress-bar
  matLine
  mode="indeterminate"
  *ngIf="historyLoading"
></mat-progress-bar>

<div class="history-container">
  <ng-container
    *ngIf="historyPage$ | async as historyPage; else noHistory"
    class="history-list-container"
  >
    <div class="history-list">
      <div class="search-container">
        <mat-form-field id="search-field" class="full-width">
          <mat-label>Archive History Filter</mat-label>
          <input matInput [(ngModel)]="searchFilter" />
        </mat-form-field>
      </div>
      <mat-list dense>
        <mat-list-item
          *ngFor="
            let entry of historyPage.entries
              | HistoryFilter: filterActionsList.value : searchFilter
          "
        >
          <mat-icon matListItemIcon class="history-type-icon">
            {{ entry.actionIcon }}
          </mat-icon>
          <span
            matListItemLine
            [matTooltip]="entry.action + ' (' + entry.documentId + ')'"
          >
            {{ entry.action + ' (' + entry.documentId + ')' }}
          </span>
          <span
            matListItemLine
            *ngIf="
              (showUserDomain$ | async)
                ? entry.username
                : (entry.username | UserWithoutDomain) as username
            "
            [matTooltip]="username"
          >
            {{ username }}
          </span>
          <span matListItemLine [matTooltip]="entry.displayDate">
            {{ entry.displayDate | DateAgo }}
          </span>
        </mat-list-item>
      </mat-list>
      <mat-paginator
        [length]="historyPage.total"
        [pageSize]="pageSize"
        hidePageSize
        [pageIndex]="currentPageIndex"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </ng-container>
  <div class="filter-controls-container">
    <mat-action-list class="static-filter-actions-list">
      <button
        mat-list-item
        [matTooltip]="'EXPORT_HISTORY' | transloco"
        matTooltipPosition="left"
        (click)="clickDownloadHistory()"
      >
        <mat-icon>download</mat-icon>
      </button>
      <button
        mat-list-item
        [matTooltip]="'REFRESH' | transloco"
        matTooltipPosition="left"
        (click)="clickRefresh()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        mat-list-item
        [matTooltip]="'FILTER_ALL_ON' | transloco"
        matTooltipPosition="left"
        (click)="clickToggleAllFilters()"
      >
        <mat-icon>filter_alt</mat-icon>
      </button>
    </mat-action-list>
    <mat-divider></mat-divider>
    <mat-action-list class="filter-controls-scroll">
      <button
        aria-label="Scroll Up Button"
        mat-list-item
        (click)="clickFilterScroll('up')"
      >
        <mat-icon>expand_less</mat-icon>
      </button>
    </mat-action-list>
    <mat-button-toggle-group
      class="filters-action-list"
      #filterActionsList="matButtonToggleGroup"
      [(value)]="historyFilterValues"
      vertical
      multiple
      hideMultipleSelectionIndicator
    >
      <mat-button-toggle
        *ngFor="let filter of historyFilters"
        [aria-label]="filter.translationKey | transloco"
        [value]="filter.name"
        [matTooltip]="filter.translationKey | transloco"
        matTooltipPosition="left"
      >
        <mat-icon>{{ filter.icon }}</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-action-list class="filter-controls-scroll">
      <button
        aria-label="Grid Settings Tab"
        mat-list-item
        (click)="clickFilterScroll('down')"
      >
        <mat-icon>expand_more</mat-icon>
      </button>
    </mat-action-list>
  </div>
</div>

<ng-template #noHistory>
  <div class="no-history-container">
    <div *ngIf="!historyLoading">No History loaded.</div>
  </div>
</ng-template>
