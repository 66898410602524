import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { TableFieldsState, TableFieldsStore } from './table-fields.store';

/** Table field store query. */
@Injectable({ providedIn: 'root' })
export class TableFieldsQuery extends QueryEntity<TableFieldsState> {
  /** Observable of all available table fields. */
  tableFields$ = this.selectAll();

  constructor(protected store: TableFieldsStore) {
    super(store);
  }
}
