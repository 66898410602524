<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Admin menu"
  [matTooltip]="'ADMINISTRATION' | transloco"
>
  <mat-icon>lock</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openAdmin()">
    <mat-icon>admin_panel_settings</mat-icon>
    <span transloco="ADMINISTRATION"></span>
  </button>
  <button mat-menu-item (click)="openUserManagement()">
    <mat-icon>groups</mat-icon>
    <span transloco="USER_MENU"></span>
  </button>
  <button
    mat-menu-item
    (click)="openGlobalActionDesign()"
    *ngIf="isGlobalActionDesignConfigured"
    [matTooltip]="('DATABASE_SELECTION_REQUIRED' | transloco) + '.'"
    [matTooltipDisabled]="isGlobalActionDesignEnabled$ | async"
    [disabled]="(isGlobalActionDesignEnabled$ | async) !== true"
  >
    <mat-icon>account_tree</mat-icon>
    <span transloco="GLOBALACTION_DESIGN_MENU"></span>
  </button>
  <button mat-menu-item (click)="openLicenseManager()">
    <mat-icon>local_activity</mat-icon>
    <span transloco="LICENSE_MANAGER"></span>
  </button>
</mat-menu>
