<mat-toolbar>
  <mat-icon>dns</mat-icon>
  <span transloco="DATABASES"></span>
</mat-toolbar>
<mat-progress-bar
  *ngIf="isDatabasesLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<div class="container">
  <button
    mat-raised-button
    *ngFor="let database of databases$ | async"
    [routerLink]="['db', database.id]"
  >
    <span class="mat-headline-6">{{
      database.name | replace: databaseNameReplacement : ' '
    }}</span>
    <span class="spacer"></span>
  </button>
</div>
