<h1 mat-dialog-title>Scan</h1>
<div mat-dialog-content>
  <div>
    <p>{{ displayMessage }}</p>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="showProgress"
    ></mat-progress-bar>
  </div>
</div>
<div mat-dialog-actions align="end">
  <ng-container *ngIf="showScanActions">
    <button mat-button (click)="continueScanning()">
      {{ 'YES' | transloco }}
    </button>
    <button mat-button (click)="completeScan()">{{ 'NO' | transloco }}</button>
  </ng-container>
</div>
