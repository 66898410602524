<h2 mat-dialog-title>
  {{ 'DXC_SELECT_MATCH' | transloco }}
</h2>
<mat-dialog-content>
  <div class="full-panel-width">
    <ag-grid-angular
      class="ag-theme-material"
      #dxcMultiMatchGrid
      [gridOptions]="gridOptions"
    >
    </ag-grid-angular>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'CANCEL' | transloco }}</button>
  <span class="spacer"></span>
  <button mat-button [disabled]="disableSelect" (click)="onClickSelect()">
    {{ 'OK' | transloco }}
  </button>
</mat-dialog-actions>
