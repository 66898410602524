<!--
  This component requires two mat-form-fields in order to properly blur and focus the masked and raw inputs.
  We cannot have two inputs in the same mat-form-field.
  We also cannot have a mat-form-field with an ng-template that inserts two different inputs because mat-form-field
  throws an error saying that the field must have a control.
-->

<!-- This is the raw input field where a user types new or updated values. -->
<mat-form-field #rawField *ngIf="focused">
  <mat-label>{{ field.name }}</mat-label>
  <input
    #input
    matInput
    type="number"
    [formControl]="control"
    (blur)="onBlur()"
    (focus)="onFocus()"
  />
  <button
    *ngIf="field.multiValue"
    [disabled]="control.disabled"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
    [appMultiValueHotkeys]="mvFieldMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>

<!-- This is the masked field that shows the data with its mask when the user is NOT focused into it. -->
<mat-form-field #maskedField *ngIf="!focused">
  <mat-label>{{ field.name }}</mat-label>
  <input
    #maskedInput
    matInput
    [disabled]="control.disabled"
    type="text"
    [value]="maskedValue"
    (focus)="onMaskControlFocus()"
  />
  <button
    *ngIf="field.multiValue"
    [disabled]="control.disabled"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
    [appMultiValueHotkeys]="mvFieldMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>
