import { ArchiveRevisionRequestedDocument } from './archive-revision-requested-document';
import { DocumentRequestMap } from './document-request-map';

/**
 * Archive revision requested document map.
 */
export class ArchiveRevisionRequestedDocumentMap extends DocumentRequestMap<ArchiveRevisionRequestedDocument> {
  constructor(documents?: ArchiveRevisionRequestedDocument[] | string) {
    super(documents);
  }
}
