import { Injectable } from '@angular/core';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { QueryEntity } from '@datorama/akita';
import { distinctUntilChanged, filter, map } from 'rxjs';

import { DatabasesState, DatabasesStore } from './databases.store';

/**
 * Databases query.
 */
@Injectable({ providedIn: 'root' })
export class DatabasesQuery extends QueryEntity<DatabasesState> {
  /** Observable of the active database's ID. */
  activeDbId$ = this.selectActiveId();
  /** Observable of all loaded databases. */
  databases$ = this.selectAll();
  /** Observable of whether the current route is the home database route. */
  isDatabaseRoute$ = this.router.events.pipe(
    filter((navEvent) => navEvent.type === EventType.NavigationEnd),
    map((navigationEvent) => {
      const navigationEndEvent = navigationEvent as NavigationEnd;
      return /\/db\/\d+(\?|$)/i.test(navigationEndEvent.urlAfterRedirects);
    }),
    distinctUntilChanged()
  );
  /** Observable of database loading state. */
  isLoading$ = this.selectLoading();

  /**
   * Currently active database Id number.
   *
   * @returns Database Id number.
   * @throws {TypeError} If current state database Id does not return a number.
   */
  get activeId(): number {
    const id = this.getActiveId();
    if (typeof id !== 'number') {
      throw new TypeError('Database Id was not a number.');
    }
    return id;
  }

  constructor(
    protected store: DatabasesStore,
    private router: Router
  ) {
    super(store);
  }
}
