<button
  mat-icon-button
  [matMenuTriggerFor]="relatedSearchesMenu"
  [matTooltip]="'RELATED_SEARCHES' | transloco"
  matTooltipShowDelay="1000"
  matTooltipPosition="left"
>
  <mat-icon>plagiarism</mat-icon>
</button>
<mat-menu #relatedSearchesMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let search of searches$ | async"
    (click)="onClickSearch(search)"
  >
    {{ search.name }}
  </button>
</mat-menu>
