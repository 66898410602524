import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ChangePasswordRequest, UserProfileProvider } from 'models';
import { Observable } from 'rxjs';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** User self update service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiUserProfileService implements UserProfileProvider {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.apiUrl = apiUrl));
  }

  /** @inheritdoc */
  changePassword(request: ChangePasswordRequest): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/users`,
      {
        currentPassword: request.currentPassword,
        password: request.newPassword,
      },
      { params: new HttpParams().set('isSelfPasswordChange', true) }
    );
  }
}
