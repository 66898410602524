<section id="tasks" *ngIf="anyEnabledTaskSearches$ | async">
  <div class="sidebar-group mat-elevation-z1">
    <mat-icon>tasks</mat-icon>
    <span transloco="TASKS"></span>
    <span class="spacer"></span>
    <app-navigation-expansion-button
      [isExpanded]="(isExpanded$ | async)!"
      (isExpandedChange)="onExpandChanged()"
    ></app-navigation-expansion-button>
  </div>
  <mat-progress-bar
    *ngIf="isTaskSearchesLoading$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
  <div id="task-items" *ngIf="isExpanded$ | async">
    <app-navigation-task-search
      *ngFor="let taskSearch of taskSearches$ | async"
      [dbId]="(databaseId$ | async)!"
      [taskSearch]="taskSearch"
    ></app-navigation-task-search>
  </div>
</section>
