import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { IndexerState, IndexerStateStore } from './indexer.store';

/** Indexer state query. */
@Injectable({ providedIn: 'root' })
export class IndexerStateQuery extends QueryEntity<IndexerState> {
  constructor(protected store: IndexerStateStore) {
    super(store);
  }

  /**
   * Gets the indexer data for the given id.
   *
   * @param id Id.
   * @returns Indexer data for the given id.
   */
  getIndexerValues(id: string) {
    return this.getEntity(id);
  }
}
