import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { assertExists } from 'common';
import {
  IDocumentUpdateSession,
  ImportedArchiveDocument,
  Inbox,
  InboxDocumentTransferResult,
  InboxFiles,
  InboxMergeDocument,
  InboxProvider,
  Inboxes,
  MergeObject,
  MergeResult,
  MergeTarget,
  UserFriendlyError,
} from 'models';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';

import {
  S9ApiInbox,
  S9ApiInboxFile,
  S9ApiInboxResponse,
  createInbox,
  createInboxFile,
  createS9ApiInboxFile,
} from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API Inbox http access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiInboxService implements InboxProvider {
  private baseInboxPath: string;
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.basePath = apiUrl;
      this.baseInboxPath = `${apiUrl}/inboxes`;
    });
  }

  /** @inheritdoc */
  deleteDocument(inboxId: number, filename: string): Observable<void> {
    return this.http.delete<void>(`${this.baseInboxPath}/${inboxId}`, {
      params: new HttpParams().set('filepath', filename),
    });
  }

  /** @inheritdoc */
  exportDocument(inboxId: number, inboxFiles: InboxFiles): Observable<void> {
    const apiFiles = inboxFiles.map(createS9ApiInboxFile);
    const download =
      inboxFiles.length === 1
        ? this.exportFile(inboxId, apiFiles[0])
        : this.exportFiles(inboxId, apiFiles);
    return download;
  }

  /** @inheritdoc */
  get(id: number): Observable<Inbox> {
    return this.http
      .get<S9ApiInbox>(`${this.baseInboxPath}/${id}`)
      .pipe(map(createInbox));
  }

  /** @inheritdoc */
  getAll(): Observable<Inboxes> {
    return this.http
      .get<S9ApiInboxResponse>(this.baseInboxPath, {
        params: new HttpParams().set('all', 'true'),
      })
      .pipe(map((response) => response.Inboxes.map(createInbox)));
  }

  /** @inheritdoc */
  importFile(
    id: number,
    cacheFilename: string,
    desiredFilename: string
  ): Observable<string> {
    return this.http.post<string>(
      `${this.baseInboxPath}/${id}`,
      {},
      {
        params: new HttpParams()
          .set('filepath', cacheFilename)
          .set('newfilename', desiredFilename),
      }
    );
  }

  /** @inheritdoc */
  indexToArchive(
    id: number,
    filename: string,
    destinationDatabase: number,
    destinationArchiveId: number,
    session: IDocumentUpdateSession
  ): Observable<ImportedArchiveDocument> {
    return this.http
      .post(
        `${this.baseInboxPath}/${id}`,
        {},
        {
          params: new HttpParams()
            .set('filename', filename)
            .set('destinationdatabaseid', destinationDatabase)
            .set('destinationarchiveid', destinationArchiveId)
            .set('token', ''),
          observe: 'response',
          headers: new HttpHeaders()
            .set('session-id', session.id)
            .set('session-actions', session.actions),
        }
      )
      .pipe(
        catchError((userFriendlyError: UserFriendlyError) => {
          if (
            userFriendlyError.error.error ===
            'Unregistered document limit reached'
          ) {
            userFriendlyError.i18n = 'UNREGISTERED_DOCUMENT_LIMIT_REACHED';
          }
          return throwError(() => userFriendlyError);
        }),
        map((response) => {
          //("Doc-Hashes", $"{docId},{sha256($"{destinationArchiveId}_{docId}{token}")}");
          const documentAndSecureIdHeader = response.headers.get('Doc-Hashes');
          assertExists(
            documentAndSecureIdHeader,
            'Doc-Hashes header must contain a value.'
          );

          // Read doc hashes header which is formated as $"{docId},{sha256($"{destinationArchiveId}_{docId}{token}")}"
          const documentAndSecureIdArray = documentAndSecureIdHeader.split(',');
          const id = Number(documentAndSecureIdArray[0]);
          if (Number.isNaN(id)) {
            throw new TypeError('Document id must be a number.');
          }
          return {
            id,
            secureId: documentAndSecureIdArray[1],
          };
        })
      );
  }

  /** @inheritdoc */
  mergeDocuments(
    databaseId: number,
    target: MergeTarget,
    baseDocument: InboxMergeDocument,
    additionalDocuments: InboxMergeDocument[]
  ): Observable<MergeResult> {
    const mergeObject: MergeObject = {
      baseDocument,
      documents: additionalDocuments,
      target,
    };

    return this.http
      .post<number>(`${this.basePath}/dbs/${databaseId}/merge`, mergeObject)
      .pipe(map(() => ({ documentId: 0 })));
  }

  /** @inheritdoc */
  renameDocuments(id: number, inboxFiles: InboxFiles): Observable<InboxFiles> {
    const apiFiles = inboxFiles.map(createS9ApiInboxFile);
    return this.http
      .put<S9ApiInboxFile[]>(`${this.baseInboxPath}/${id}`, apiFiles)
      .pipe(map((apiInboxFiles) => apiInboxFiles.map(createInboxFile)));
  }

  /** @inheritdoc */
  transferDocument(
    id: number,
    filename: string,
    targetId: number,
    deleteOriginal: boolean
  ): Observable<InboxDocumentTransferResult> {
    return this.http
      .get<string>(`${this.baseInboxPath}/${id}`, {
        params: new HttpParams()
          .set('filepath', filename)
          .set('targetInboxId', `${targetId}`)
          .set('deleteOriginal', `${deleteOriginal}`),
      })
      .pipe(
        map(
          (newFilePath): InboxDocumentTransferResult => ({
            filepath: newFilePath,
          })
        )
      );
  }

  private downloadFile(responseBlob: Blob, filename: string) {
    const downloadLink = document.createElement('a');
    const objectUrl = URL.createObjectURL(responseBlob);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    downloadLink.click();
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * The 'exportFile' and 'exportFiles' functions below are here because the export flow for inbox files
   * is essentially split into two different paths despite using the same API call.
   *
   * If only 1 file is provided then that file is simply returned.
   * However, if multiple files are provided then the call returns a job id
   * which the client has to make a separate call for in order to retrieve a zip file containing all the exported files.
   */

  /**
   * Export a single file.
   *
   * @param inboxId Inbox Id.
   * @param inboxFile Inbox file.
   * @returns An observable that completes when the download has finished.
   */
  private exportFile(
    inboxId: number,
    inboxFile: S9ApiInboxFile
  ): Observable<void> {
    return this.http
      .post<Blob>(
        `${this.baseInboxPath}/${inboxId}`,
        [{ ...inboxFile, FileName: inboxFile.FileName + inboxFile.FileType }],
        {
          responseType: 'blob' as 'json',
        }
      )
      .pipe(
        map((responseBlob) => {
          this.downloadFile(responseBlob, inboxFile.FileName);
        })
      );
  }

  /**
   * Export multiple files.
   *
   * @param inboxId Inbox Id.
   * @param inboxFiles Inbox files array.
   * @returns An observable that completes when the download as finished.
   * @throws {Error} If less than 2 files are provided. The exportFile function should be used if there is only one file.
   */
  private exportFiles(
    inboxId: number,
    inboxFiles: S9ApiInboxFile[]
  ): Observable<void> {
    if (inboxFiles.length < 2) {
      throw new Error(
        'Export files should NOT be called with less than two files.'
      );
    }
    return this.http
      .post<string>(`${this.baseInboxPath}/${inboxId}`, inboxFiles)
      .pipe(
        switchMap((jobId) => {
          return this.http
            .get<Blob>(`${this.baseInboxPath}`, {
              responseType: 'blob' as 'json',
              params: new HttpParams().set('jobid', jobId),
            })
            .pipe(
              map((response) => {
                this.downloadFile(response, 'export');
              })
            );
        })
      );
  }
}
