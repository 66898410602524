import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AppConfigQuery } from '../modules/app-config';
import { AuthenticationService } from '../services/authentication.service';
import { ApplicationQuery } from '../state/application/application.query';

/**
 * Basic authentication interceptor.
 */
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private authenticationService: AuthenticationService
  ) {}

  /** @inheritdoc */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.authenticationService.isLoggedIn) return next.handle(request);
    // Add header with basic auth credentials if user is logged in and request is to the api url.
    const user = this.authenticationService.user;
    // TODO: Support host aliases in config since this is so strict (i.e., localhost = myhost.domain = 127.0.0.1, etc.)
    const isApiUrl = request.url.startsWith(
      this.appConfigQuery.appConfig.apiUrl
    );
    if (isApiUrl) {
      this.logger.trace(
        `Authenticating intercepted request with stored user: ${user.name}`,
        user
      );
      request = request.clone({
        setHeaders: {
          'auth-token': user.token,
          client: environment.appName,
          'client-version': environment.version,
        },
      });

      if (user.provider) {
        request = request.clone({
          setHeaders: {
            'authenticated-provider': user.provider,
          },
        });
      }

      if (user.authData) {
        request = request.clone({
          setHeaders: {
            authorization: `Basic ${user.authData}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
