/**
 * Application compile time environment. Production.
 */
export const environment = {
  production: true,
  appName: 'GlobalSearch',
  instanceUrl: '',
  apiUrl: 'http://localhost/square9api/api',
  viewerUrl: 'http://localhost/square9viewer',
  globalCaptureUrl: '',
  licenseManagerUrl: 'http://localhost/square9web/GSWLicenseManager/',
  userManagementUrl: 'http://localhost/square9web/UserManagement/',
  adminUrl: 'http://localhost/square9web/#/admin/full',
  gseUrlDefault: 'https://127.0.0.1:9443',
  gseDownloadUrl:
    'https://cdn.square-9.com/Installer/Extensions_Releases/Production/Stable/Setup.exe',
  s9HelpUrl: 'https://knowledge.square-9.com/',
  version: '0.0.0',
};
