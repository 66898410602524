import { HistoryEntry, HistoryPage } from 'models';

/**
 * Creates a history page from an API response.
 *
 * @param historyApiResponse API response object.
 * @returns A history page.
 */
export const createHistoryPage = (
  historyApiResponse: S9ApiHistoryResult
): HistoryPage => ({
  total: historyApiResponse.Total,
  entries: createHistoryEntries(historyApiResponse),
});

/**
 * Creates a list of history entries from an API response.
 *
 * @param historyApiResponse API response object.
 * @returns An array of history entries.
 */
export const createHistoryEntries = (
  historyApiResponse: S9ApiHistoryResult
): HistoryEntry[] =>
  historyApiResponse.Items.map((entry) => ({
    action: entry.Action,
    archiveId: entry.Archive_ID,
    date: entry.Date,
    documentId: entry.Document_ID,
    username: entry.UserName,
  }));

export interface S9ApiHistoryResult {
  Info: Response;
  Items: S9ApiHistoryEntry[];
  Total: number;
}

export interface S9ApiHistoryResponse {
  Message: null;
  OK: boolean;
}

export interface S9ApiHistoryEntry {
  Action: string;
  Archive_ID: number;
  Date: string;
  Document_ID: number;
  UserName: string;
}
