<mat-drawer-container autosize="true">
  <mat-drawer
    #drawer
    [mode]="(drawerMode$ | async) ?? 'side'"
    (closed)="onClose()"
    [opened]="indexerSidebarOpen$ | async"
    [position]="position"
    fixedInViewport
    mwlResizable
    [ngStyle]="resizeStyle"
    (resizing)="onResize($event)"
    (resizeStart)="onResizeStart()"
    (resizeEnd)="onResizeEnd($event)"
    (window:resize)="onWindowResize($event)"
    [validateResize]="onResizeValidate"
    [enableGhostResize]="false"
    disableClose="true"
  >
    <!-- TODO resize is not working correctly so it is disabled for now. -->
    <!-- <div
      class="resize-handle-left"
      [ngClass]="{ 'full-width-resize-overlay': resizeInProgress }"
      mwlResizeHandle
      [resizeEdges]="{ left: true }"
      (dblclick)="onResizeHandleDoubleClick()"
    ></div> -->
    <!--  [selectedIndex]="selectedTabIndex$ | async" -->
    <mat-tab-group
      #tabs
      color="accent"
      mat-align-tabs="center"
      class="mat-tab-fill-height"
      (selectedIndexChange)="onTabChange($event)"
      preserveContent
    >
      <mat-tab aria-label="Indexer Tab" *ngIf="!isGuest">
        <ng-template mat-tab-label>
          <mat-icon>assignment</mat-icon>
        </ng-template>
        <ng-template matTabContent>
          <div class="indexer-contents">
            <div class="indexer-section">
              <app-indexer
                #indexer
                [archiveId]="archiveId"
                [id]="documentId"
                [secureId]="documentSecureId"
                [fieldValues]="fieldValues"
                [uniqueId]="uniqueId"
                [viewerPageNumber]="viewerPageNumber"
                [permissions]="permissions"
                (fieldFocused)="onIndexerFieldFocused($event)"
                (fieldBlurred)="onIndexerFieldBlurred($event)"
              ></app-indexer>
            </div>
            <div class="workflow-section" *ngIf="workflowProcessId > 0">
              <app-user-actions-panel
                [documentId]="documentId"
                [documentSecureId]="documentSecureId"
                (actionExecuted)="onUserActionExecuted($event)"
              ></app-user-actions-panel>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab aria-label="Document History Tab" *ngIf="showDocumentHistory">
        <ng-template mat-tab-label>
          <mat-icon>folder</mat-icon>
        </ng-template>
        <ng-template matTabContent>
          <app-document-history
            [selected-document-ids]="[documentId]"
          ></app-document-history>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
