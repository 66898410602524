<mat-form-field>
  <mat-label>{{ fieldLabel }}</mat-label>
  <input
    #input
    type="text"
    matInput
    [formControl]="control"
    [matAutocomplete]="autocomplete"
    [appMultiValueHotkeys]="mvFieldMenu"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    (optionSelected)="onSelectionChange()"
  >
    <mat-option
      *ngFor="let value of filteredListValues$ | async"
      [matTooltipDisabled]="value.length <= 16"
      matTooltipShowDelay="1000"
      [matTooltip]="value"
      [value]="value"
    >
      {{ value }}
    </mat-option>
  </mat-autocomplete>
  <button
    *ngIf="field.multiValue && mvFieldMenu"
    [disabled]="control.disabled"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>
