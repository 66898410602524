<mat-spinner
  *ngIf="isDatabasesLoading$ | async; else loaded"
  diameter="24"
  color="accent"
></mat-spinner>
<ng-template #loaded>
  <div *ngIf="databases$ | async as databases">
    <div *ngIf="databases.length > 1">
      <button
        mat-icon-button
        [matTooltip]="'DATABASE_SELECT' | transloco"
        [matMenuTriggerFor]="menu"
        aria-label="Database select"
      >
        <mat-icon>dns</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          *ngFor="let database of databases"
          [routerLink]="['db', database.id]"
          routerLinkActive="active"
        >
          <span>{{
            database.name | replace: databaseNameReplacement : ' '
          }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</ng-template>
