import { Pipe, PipeTransform } from '@angular/core';

/** Replace pipe. */
@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  /**
   * Perform a replacement on a string value.
   *
   * @param value Value.
   * @param pattern Replacement pattern.
   * @param replaceValue The replacement text.
   *
   * @returns Updated string.
   */
  transform(value: string, pattern: string, replaceValue: string): string {
    if (!value || !pattern || !replaceValue) {
      return value;
    }

    return value.replace(new RegExp(pattern, 'g'), replaceValue);
  }
}
