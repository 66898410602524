<button
  class="button mat-elevation-z2 mdc-button"
  [style.background-color]="backgroundColor"
  [style.color]="color"
  [class.spinner]="loading"
  [class.default-button-background-color]="!backgroundColor"
  [class.default-button-color]="!color"
  [disabled]="disabled"
  matRipple
>
  <div class="button-icon">
    <ng-content select="[button-icon]"></ng-content>
  </div>
  <div class="button-text">
    <ng-content select="[button-text]"></ng-content>
  </div>
</button>
