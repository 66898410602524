import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest, interval, of } from 'rxjs';
import {
  finalize,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { assertExists } from 'common';
import {
  CSVSettings,
  ExportDocuments,
  ExportProvider,
  ExportSettings,
  ExportStatus,
  Field,
  Fields,
  UserFriendlyError,
} from 'models';
import { EXPORT_PROVIDER } from 'src/app/common/tokens';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';

/** Export Dialog Data. */
export interface EnhancedExportDialogData {
  /** Database ID. */
  databaseId: number;
  /** Documents to be exported. */
  exportDocuments: ExportDocuments;
}

/** Describes enhanced Export Form. */
interface EnhancedExportForm {
  /**
   * Whether to convert to black and white.
   */
  convertToBlackAndWhite: FormControl<boolean>;
  /** Whether to convert to PDF. */
  convertToPdf: FormControl<boolean>;
  /** Export filename. */
  exportedFilename: FormControl<string>;
  /** Whether to include annotations. */
  includeAnnotations: FormControl<boolean>;
}

/** Describes the CSV settings form for enhanced export. */
interface EnhancedExportCsvSettingsForm {
  /** Column delimiter. */
  columnDelimiter: FormControl<string>;
  /** Field Ids to be included. */
  fieldIds: FormControl<number[]>;
  /** CSV filename. */
  filename: FormControl<string>;
  /** Whether to include all fields. */
  includeAllFields: FormControl<boolean>;
  /** Whether to include data. */
  includeData: FormControl<boolean>;
  /** Whether to include column headers. */
  includeHeader: FormControl<boolean>;
  /** String delimiter. */
  stringDelimiter: FormControl<string>;
}

/** Enhanced Export Component. */
@Component({
  selector: 'app-enhanced-export-dialog',
  templateUrl: './enhanced-export-dialog.component.html',
  styleUrls: ['./enhanced-export-dialog.component.scss'],
})
export class EnhancedExportDialogComponent implements OnInit {
  /** Fields input. */
  @ViewChild('fieldsInput') fieldsInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;
  /** Archive Fields. */
  archiveFields: Fields;
  /** CSVSettings form group. */
  csvSettingsForm: FormGroup<EnhancedExportCsvSettingsForm>;
  /** Determines if the export zip from a job is being downloaded. */
  downloadingExportZip = false;
  /** Determines if an export is in progress. */
  exportInProgress = false;
  /** Current export's status. */
  exportStatus?: ExportStatus;
  /** Array of fields sutable for filenames. */
  filenameArchiveFields: Fields;
  /** Observable filtered array of fields. */
  filteredArchiveFields$: Observable<Field[]>;
  /** Include data form control. */
  includeDataFormControl = new FormControl(
    {
      value: false,
      disabled: !this.archivesQuery.active.permissions.exportData,
    },
    { nonNullable: true }
  );
  /** Determines if the export mode is advanced. */
  isAdvancedExport = false;
  /** Observable state of if we are on a mobile handset sized output. */
  isHandset$ = this.layout.isHandset$;
  /** Selected fields. */
  selectedFields: Fields = [];
  /** Form control for the chip search control. */
  selectedFieldsChipForm = new FormControl('');
  /** Separator keycodes. */
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /** Export settings form. */
  settingsForm: FormGroup<EnhancedExportForm>;

  private currentExportId: string;

  /**
   * CSV Settings Form Controls.
   *
   * @returns A dictionary of the form controls.
   */
  get csvSettingsControls(): EnhancedExportCsvSettingsForm {
    return this.csvSettingsForm.controls;
  }

  /**
   * Determines if export should be disabled.
   *
   * @returns A boolean indicating if export should be displayed.
   */
  get disableExport(): boolean {
    return (
      this.exportInProgress ||
      this.settingsForm.invalid ||
      (this.includeData && this.csvSettingsForm.invalid)
    );
  }

  /**
   * Gets the export filename control.
   *
   * @returns The export filename form control.
   */
  get exportedFilenameControl(): FormControl<string> {
    return this.settingsForm.controls.exportedFilename;
  }

  /**
   * Determines if include data is checked.
   *
   * @returns A boolean.
   */
  get includeData(): boolean {
    return this.includeDataFormControl.value;
  }

  constructor(
    private logger: NGXLogger,
    private layout: LayoutService,
    private archivesQuery: ArchivesQuery,
    private formBuilder: FormBuilder,
    private dialog: MatDialogReference<EnhancedExportDialogComponent>,
    private notifications: NotificationService,
    @Inject(MAT_DIALOG_DATA) private dialogData: EnhancedExportDialogData,
    @Inject(EXPORT_PROVIDER) private exportProvider: ExportProvider
  ) {}

  /**
   * Add a field chip.
   *
   * @param event Chip input event.
   */
  addField(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    const field = this.archiveFields.find((f) => f.name === value);
    assertExists(field);

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedFields.push(field);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.selectedFieldsChipForm.setValue('');
  }

  ngOnInit(): void {
    this.settingsForm = this.formBuilder.group<EnhancedExportForm>({
      convertToBlackAndWhite: new FormControl(false, { nonNullable: true }),
      convertToPdf: new FormControl(false, { nonNullable: true }),
      exportedFilename: new FormControl('', { nonNullable: true }),
      includeAnnotations: new FormControl(false, { nonNullable: true }),
    });

    this.csvSettingsForm =
      this.formBuilder.group<EnhancedExportCsvSettingsForm>({
        includeData: new FormControl(false, { nonNullable: true }),
        columnDelimiter: new FormControl(',', {
          nonNullable: true,
          validators: Validators.required,
        }),
        fieldIds: new FormControl([], { nonNullable: true }),
        filename: new FormControl('field-data.csv', {
          nonNullable: true,
          validators: Validators.required,
        }),
        includeHeader: new FormControl(false, { nonNullable: true }),
        includeAllFields: new FormControl(false, { nonNullable: true }),
        stringDelimiter: new FormControl(`"`, {
          nonNullable: true,
          validators: Validators.required,
        }),
      });

    this.archiveFields = this.archivesQuery.getFields();
    const tableFields = this.archivesQuery.getTableFields();
    this.filenameArchiveFields = this.archiveFields.filter(
      (field) =>
        !field.multiValue &&
        // Exclude fields that are already in the table
        !tableFields.some((tableField) =>
          tableField.fieldIds.includes(field.id)
        )
    );
    this.listenToIncludeAllFields();
    this.listenToSelectFieldForm();
    this.listenForConvertToPdfRequirement();
  }

  /**
   * Handler for the add all fields click event.
   */
  onClickAddAllFields(): void {
    this.selectedFields = [...this.archiveFields];
    this.archiveFields = [];
  }

  /**
   *  Handler for the add field click event.
   *
   * @param field Field to add.
   */
  onClickAddField(field: Field): void {
    this.selectedFields.push(field);
    const index = this.archiveFields.indexOf(field);
    this.archiveFields.splice(index, 1);
  }

  /**
   * Handler for the click export event.
   *
   */
  onClickExport(): void {
    if (this.settingsForm.invalid) return;
    if (this.includeData && this.csvSettingsForm.invalid) {
      return;
    }

    const exportSettings = this.createSettingsFromForms();
    this.exportInProgress = true;
    this.settingsForm.disable();
    this.csvSettingsForm.disable();
    // do the export
    this.exportDocuments(exportSettings)
      .pipe(
        finalize(() => {
          this.settingsForm.enable();
          this.csvSettingsForm.enable();
          this.exportInProgress = false;
        })
      )
      .subscribe((jobStatus) => {
        this.logger.debug('Export status updated', jobStatus);
        this.exportStatus = jobStatus;
        if (jobStatus.done && !jobStatus.cancelled) {
          this.logger.debug('Download export zip.');
          this.downloadingExportZip = true;
          this.exportProvider
            .downloadExportZip(this.currentExportId)
            .pipe(finalize(() => (this.downloadingExportZip = false)))
            .subscribe({
              next: () => {
                this.logger.debug(
                  'Export zip has been downloaded. Deleting the job.'
                );
                this.exportProvider.deleteJob(this.currentExportId).subscribe({
                  next: () => {
                    this.logger.debug('Job deleted. Closing the dialog.');
                    this.notifications.success('ENHANCED_EXPORT_COMPLETE');
                    this.dialog.close();
                  },
                  error: (error) => {
                    // there isn't anything the user can really do to correct this so just close.
                    this.logger.error('Export job failed to delete.', error);
                    this.dialog.close();
                  },
                });
              },
              error: (error: UserFriendlyError) => {
                error.i18n = 'ERROR_DOWNLOADING_ENHANCED_EXPORT_ZIP';
                this.notifications.error(error);
              },
            });
        }
      });
  }

  /**
   * Handler for the click remove all fields event.
   */
  onClickRemoveAllFields(): void {
    this.archiveFields = this.archivesQuery.getFields();
    this.selectedFields = [];
  }

  /**
   * Handler for the remove field click event.
   *
   * @param field Field to remove.
   */
  onClickRemoveField(field: Field): void {
    this.archiveFields.push(field);
    const index = this.selectedFields.indexOf(field);
    this.selectedFields.splice(index, 1);
  }

  /**
   * Handler for the drop selected field event.
   *
   * @param event Drop event.
   */
  onDropSelectedField(event: CdkDragDrop<Field[]>): void {
    moveItemInArray(
      this.selectedFields,
      event.previousIndex,
      event.currentIndex
    );
  }

  /**
   * Remove a selected field.
   *
   * @param field Field to be removed.
   */
  removeField(field: Field): void {
    const index = this.selectedFields.indexOf(field);
    this.selectedFields.splice(index, 1);
  }

  /**
   * Handler for the select field event.
   *
   * @param event Autocomplete selection event.
   */
  selectedField(event: MatAutocompleteSelectedEvent): void {
    this.selectedFields.push(event.option.value);
    this.fieldsInput.nativeElement.value = '';
    this.selectedFieldsChipForm.setValue('');
  }

  private createCSVSettingsFromForm(): CSVSettings {
    const controls = this.csvSettingsControls;
    const fieldIds = (
      controls.includeAllFields.value ? this.archiveFields : this.selectedFields
    ).map((field) => field.id);
    return {
      columnDelimiter: controls.columnDelimiter.value,
      fieldIds,
      filename: controls.filename.value,
      includeHeader: controls.includeHeader.value,
      stringDelimiter: controls.stringDelimiter.value,
    };
  }

  private createSettingsFromForms(): ExportSettings {
    const settingsControls = this.settingsForm.controls;
    return {
      convertToBlackAndWhite: settingsControls.convertToBlackAndWhite.value,
      convertToPdf: settingsControls.convertToPdf.value,
      csvSettings: this.includeData
        ? this.createCSVSettingsFromForm()
        : undefined,
      databaseId: Number(this.dialogData.databaseId),
      exportDocuments: this.dialogData.exportDocuments,
      exportedFilename: settingsControls.exportedFilename.value,
      includeAnnotations: settingsControls.includeAnnotations.value,
      includeData: this.includeData,
    };
  }

  private exportDocuments(settings: ExportSettings): Observable<ExportStatus> {
    return this.exportProvider.createJob(settings).pipe(
      tap((jobId) => (this.currentExportId = jobId)),
      switchMap((jobId) => this.exportProvider.getJobStatus(jobId)),
      mergeMap((jobStatus) =>
        jobStatus.done
          ? of(jobStatus)
          : interval(2000).pipe(
              switchMap(() =>
                this.exportProvider.getJobStatus(this.currentExportId)
              ),
              takeWhile((status) => status.started && !status.done, true)
            )
      )
    );
  }

  private filterArchiveFields(
    value: string | Field | undefined | null
  ): Field[] {
    const availableFields = this.archiveFields.filter(
      (f) => !this.selectedFields.some((sf) => sf.id === f.id)
    );
    if (!value || typeof value !== 'string') return [...availableFields];
    const filterValue = value.toLowerCase();

    return availableFields.filter(
      (f) => f.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private listenForConvertToPdfRequirement(): void {
    combineLatest([
      this.settingsForm.controls.convertToBlackAndWhite.valueChanges.pipe(
        startWith(false)
      ),
      this.settingsForm.controls.includeAnnotations.valueChanges.pipe(
        startWith(false)
      ),
    ]).subscribe(([convertToBlackAndWhite, includeAnnotations]) => {
      const convertToPdf = this.settingsForm.controls.convertToPdf.value;
      if (!convertToPdf && (convertToBlackAndWhite || includeAnnotations)) {
        this.settingsForm.controls.convertToPdf.setValue(true);
      }
    });

    this.settingsForm.controls.convertToPdf.valueChanges.subscribe(
      (convertToPdf) => {
        if (!convertToPdf) {
          // setting emitEvent to false prevents the above valuechange watchers
          // which are unnecessary when resetting these values.
          this.settingsForm.controls.includeAnnotations.reset(false, {
            emitEvent: false,
          });
          this.settingsForm.controls.convertToBlackAndWhite.reset(false, {
            emitEvent: false,
          });
        }
      }
    );
  }

  private listenToIncludeAllFields(): void {
    this.csvSettingsControls.includeAllFields.valueChanges.subscribe(
      (includeAllFields) => {
        if (!includeAllFields) {
          this.selectedFields = []; // clear the selected fields when checkbox changes state.
        }
      }
    );
  }

  private listenToSelectFieldForm(): void {
    this.filteredArchiveFields$ = this.selectedFieldsChipForm.valueChanges.pipe(
      startWith(''),
      map((value: string | null) => this.filterArchiveFields(value))
    );
  }
}
