import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, combineLatest, debounceTime, map } from 'rxjs';

import { assert } from 'common';
import { Search, SearchPrompt } from 'models';
import { SearchRouteParametersTuple } from 'src/app/models';

import { ArchivesQuery } from '../archives/archives.query';

import { SearchesState, SearchesStore } from './searches.store';

/** Searches Query. */
@Injectable({ providedIn: 'root' })
export class SearchesQuery extends QueryEntity<SearchesState> {
  /** Observable of the active search or undefined if there is no active search. */
  active$ = this.selectActive();
  /** Observable of dashboard searches. */
  dashboardSearches$ = this.selectAll().pipe(
    map((searches) =>
      searches.filter((search) => search.settings.isDashboardSearch)
    )
  );
  /** Observable of favorited searches. */
  favoritedSearches$ = this.selectAll().pipe(
    map((searches) => searches.filter((search) => search.isFavorite))
  );
  /** Observable of dashboard search filter. */
  filter$ = this.routerQuery.selectQueryParams<string>('filter');
  /** Observable of searches loading state. */
  isLoading$ = this.selectLoading();
  /** Observable of current search prompts. */
  searchPromptParams$ = this.routerQuery
    .selectQueryParams<string>('prompts')
    .pipe(map((promptString) => this.parseSearchPrompt(promptString)));
  /**
   * Search route parameters.
   *
   * @description Observable of Datbase, Archive, Search ID, and SearchPrompt[] tuple.
   */
  searchRouteParams$: Observable<SearchRouteParametersTuple> = combineLatest([
    this.routerQuery.selectParams(['dbId', 'archiveId', 'searchId']),
    this.searchPromptParams$,
  ]).pipe(
    debounceTime(1),
    map(([[databaseId, archiveId, searchId], searchPrompts]) => {
      // Ensure all values are either number or undefined.
      databaseId = databaseId ? Number(databaseId) : undefined;
      archiveId = archiveId ? Number(archiveId) : undefined;
      searchId = searchId ? Number(searchId) : undefined;
      assert(
        typeof databaseId === 'number' || typeof databaseId === 'undefined'
      );
      assert(typeof archiveId === 'number' || typeof archiveId === 'undefined');
      assert(typeof searchId === 'number' || typeof searchId === 'undefined');
      return [
        databaseId ? Number(databaseId) : databaseId,
        archiveId,
        searchId,
        searchPrompts,
      ];
    })
  );

  constructor(
    protected store: SearchesStore,
    private archives: ArchivesQuery,
    private routerQuery: RouterQuery
  ) {
    super(store);
  }

  /**
   * Currently active search.
   *
   * @returns Search.
   * @throws {TypeError} If no search is currently active.
   */
  get active(): Search {
    return this.getSearch(this.activeId);
  }

  /**
   * Currently active search Id number.
   *
   * @returns Search Id number.
   * @throws {TypeError} If current state archive Id does not return a number.
   */
  get activeId(): number {
    const id = this.getActiveId();
    if (typeof id !== 'number') {
      throw new TypeError('Search Id was not a number.');
    }
    return id;
  }

  /** Get the current search prompts.
   *
   * @returns An array of search prompts.
   */
  get currentSearchPrompts(): SearchPrompt[] {
    return this.parseSearchPrompt(
      this.routerQuery.getQueryParams<string>('prompts') ?? ''
    );
  }

  /** Get the current filter.
   *
   * @returns A filter string.
   */
  get filter(): string {
    return this.routerQuery.getQueryParams<string>('filter') ?? '';
  }

  /**
   * Get the default search for an archive.
   *
   * @param archiveId Archive ID.
   * @returns A search.
   */
  getDefault(archiveId: number = this.archives.activeId) {
    return this.getAll().find(
      (search: Search) => search.isDefault && search.parentArchive === archiveId
    );
  }

  /**
   * Get a search.
   *
   * @param searchId Search Id.
   * @returns Search.
   * @throws {RangeError} If the search is not found.
   */
  getSearch(searchId: number = this.activeId): Search {
    const search = this.getEntity(searchId);
    if (typeof search === 'undefined') {
      throw new RangeError('Search was not found for the provided Id..');
    }
    return search;
  }

  /**
   * Select the list of searches defined for a given archive.
   *
   * @param archiveId Archive ID.
   * @returns Observable list of searches.
   */
  selectForParent(archiveId: number) {
    return this.selectAll({
      filterBy: ({ parentArchive }) => parentArchive === archiveId,
    });
  }

  private parseSearchPrompt(promptString: string): SearchPrompt[] {
    if (!promptString) {
      return [];
    }
    promptString = atob(promptString);
    return JSON.parse(promptString) as SearchPrompt[];
  }
}
