import { InstanceProvider } from '../modules/login';
import { ApplicationQuery } from '../state/application/application.query';
import { ApplicationService } from '../state/application/application.service';

/**
 * Instance Configuration.
 *
 * @param appQuery Application Query.
 * @param appService Application Service.
 * @returns An InstanceConfig.
 */
export const instanceProviderFactory = (
  appQuery: ApplicationQuery,
  appService: ApplicationService
): InstanceProvider => {
  return {
    instanceUrl$: appQuery.instanceUrl$,
    update: (instanceUrl: string) => appService.setInstanceUrl(instanceUrl),
  };
};
