const logo = [
  '%c                       ▄▄▄▄▄\n\
  %c                 ▄▄█████████▄▄\n\
%c               ▄▄████████████████▄▄\n\
%c            ▄█████████▀▀  ▀▀█████████▄\n\
%c        ▄▄████████▀▀          ▀█████████▄▄\n\
%c    ▄▄█████████▀                  ▀█████████▄▄                                                                                                                                     %c▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄\n\
%c▄▄█████████▀         %c▄▓▓▓▓▓▓▄         %c▀█████████▄▄                                                                                                                                %c▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓\n\
%c███████▀▀        %c▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄        %c▀▀███████          ▄██████████████▄   ▄████████████████▌  ████          ████   ████████████████▄    ▄████████████▌  ▄███████████████▄   %c▐▓▓▓▌             ▓▓▓▓\n\
%c█████▌       %c▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄       %c▐█████         █████████████████▌  █████████████████▌  ████          ████   ██████████████████  ▐█████████████▌ ▐█████████████████   %c▐▓▓▓▌             ▓▓▓▓\n\
%c█████▌     %c▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓     %c▐█████        ▐███▌               ▐███▌         ▐███▌  ████          ████                 ████  ▐███▌           ▐███▌         ▐███▌   %c▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓\n\
%c█████▌        %c▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀        %c▐█████         ███████████████▄▄  ▐███▌         ▐███▌  ████          ████   ██████████████████  ▐███▌           ▐█████████████████▌    %c▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓\n\
%c███████▄▄         %c▀▓▓▓▓▓▓▓▓▓▓▓▓▀            %c▐█████          ▀███████████████▌ ▐███▌         ▐███▌  ████          ████   ██████████████████  ▐███▌           ▐█████████████████▌                    %c▓▓▓▓\n\
%c███████████▄▄        %c▀▓▓▓▓▓▓▀        %c▄▄█    ▐█████         ▄▄▄          ▐███▌ ▐███▌         ▐███▌  ████          ████   ████          ████  ▐███▌           ▐███▌                                  %c▓▓▓▓\n\
%c███████████████▄▄                ▄▄█████    ▐█████         █████████████████▌  █████████████████▌  ██████████████████   ██████████████████  ▐███▌           ▐█████████████████▌   %c▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓\n\
%c█████▀▀████████████▄▄        ▄▄█████████    ▐█████          ▀█████████████▀▀    ▀███████████████▌   ▀██████████████▀     ▀████████████████  ▐███▌             ▀███████████████▌    %c▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀\n\
%c█████   ▀██████████████▄  ▄█████████████    ▐█████                                          ▐███▌\n\
█████    ███████████████████████████████    ▐█████                                          ▐███▌                            ▄▄▄▄    ▄                                             ▄\n\
█████      ▀▀████████████████████████▀▀     ▐█████                                          ▐███▌     ▄▄▄▄▄▄      ▄▄▄▄▄▄    ▐█▄▄▄   ▐█▄▄▄    ▄     ▄      ▄    ▄▄▄▄▄▄      ▄▄▄▄▄   █▌     ▄     ▄▄▄▄▄▄\n\
██████▄        ▀▀████████████████▀▀        ▄██████                                                  ▐█     ▀▀   ▐█      █   ▐█      ▐█       █▄   █▀█    █    █      █    █        █▌   ▄▀    ▐█     ▀▀\n\
█████████▄▄        ▀██████████▀        ▄▄█████████                                                   █▄▄▄▄▄▄▄   ▐█      █   ▐█      ▐█        █▄ █▀  █  █▀    █      █    █        ██▄██       █▄▄▄▄▄▄▄\n\
  ▀▀█████████▄         ▀██▀        ▄▄█████████▀▀                                                     ▄      █   ▐█      █   ▐█      ▐█         ██▀    ██▌     █      █    █        █▌   █▄     ▄      █\n\
      ▀▀█████████▄              ▄█████████▀▀                                                         ▀▀▀▀▀▀▀     ▀▀▀▀▀▀▀     ▀       ▀▀▀▀       ▀      ▀       ▀▀▀▀▀▀▀    ▀        ▀      ▀    ▀▀▀▀▀▀▀\n\
          ▀▀█████████▄      ▄█████████▀▀\n\
              ▀█████████▄▄█████████▀\n\
                  ▀████████████▀\n\
                     ▀▀████▀▀',
  'color:#607d8b;',
  'color:#607d8b',
  'color:#607d8b',
  'color:#607d8b',
  'color:#607d8b',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
  'color:#f3661c',
  'color:#607d8b',
];

/** Fancy Square 9 browser defbug console logo. */
export class ConsoleLogo {
  constructor() {}

  /** Print the logo. */
  static print() {
    console.log(...logo);
  }
}
