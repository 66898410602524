import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SQUARE9_API_CONFIG, Square9ApiConfig } from 'square9api';

import { DateFormatsStore } from './date-formats.store';

/** Date Formats Service. */
@Injectable({ providedIn: 'root' })
export class DateFormatsService {
  private baseUrl: string;

  constructor(
    private dateFormatsStore: DateFormatsStore,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig,
    private http: HttpClient
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.baseUrl = apiUrl));
  }

  /**
   * Get a MomentJS date format from a .NET format.
   *
   * @param format .NET date format.
   * @returns An observable MomentJS date format.
   */
  get(format: string): Observable<string> {
    const value = this.dateFormatsStore.getValue()[format];
    if (value) return of(value);
    return this.http
      .get<string>(`${this.baseUrl}/moment?format=${format}`)
      .pipe(
        tap((momentFormat) => {
          const values = { ...this.dateFormatsStore.getValue() };
          values[`${format}`] = momentFormat;
          this.dateFormatsStore.update(values);
        })
      );
  }
}
