import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { AppConfigQuery } from './modules/app-config';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private appConfigQuery = inject(AppConfigQuery);
  private cdnUrl = this.appConfigQuery.languageCdnUrl;
  private http = inject(HttpClient);

  constructor() {
    this.appConfigQuery.languageCdnUrl$.subscribe(
      (cdnUrl) => (this.cdnUrl = cdnUrl)
    );
  }

  getTranslation(lang: string) {
    return this.http.get(`${this.cdnUrl}/${lang.toLowerCase()}.json`);
  }
}
