<mat-tab-group
  color="accent"
  [(selectedIndex)]="selectedIndex"
  (selectedIndexChange)="onTabChanged($event)"
  animationDuration="0"
>
  <mat-tab *ngFor="let tab of viewTabs" [label]="tab.description.label">
    <ng-template mat-tab-label>
      <span>{{ tab.description.label }}</span>
      <div class="tab-badge" [style.backgroundColor]="tab.description.color">
        <ng-container *ngIf="!tab.isLoading; else Loading">
          <ng-container *ngIf="tab.count$ | async as tabCount; else None">
            {{ getTabCountString(tabCount) }}
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<ng-template #Loading>&hellip;</ng-template>
<ng-template #None>0</ng-template>
