import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ScanProvider,
  ScanResult,
  ScanStatus,
  ScanUploadRequest,
} from 'models';

import { SCAN_PROVIDER } from '../common/tokens';

/**
 * GSE Scan Service.
 */
@Injectable({
  providedIn: 'root',
})
export class ScanService {
  constructor(@Inject(SCAN_PROVIDER) private scanProvider: ScanProvider) {}

  /**
   * Completes a scan session by uploading the files to the target.
   *
   * @param sessionId ID for the scan session.
   * @param uploadRequest The ScanUploadRequest indicating where the scan will be uploaded.
   * @returns An observable which returns a ScanResult once completed.
   */
  public completeScan(
    sessionId: string,
    uploadRequest: ScanUploadRequest
  ): Observable<ScanResult> {
    return this.scanProvider.complete(sessionId, uploadRequest);
  }

  /**
   * Contune a scan session with a new scan.
   *
   * @param sessionId ID for the existing scan session.
   * @returns An observable which returns a ScanStatus once completed.
   */
  public continueScan(sessionId: string): Observable<ScanStatus> {
    return this.scanProvider.continue(sessionId);
  }

  /**
   * Starts a new scanning session.
   *
   * @returns An observable which returns a ScanStatus once completed.
   */
  public scan(): Observable<ScanStatus> {
    return this.scanProvider.start(true);
  }
}
