<button mat-icon-button *ngIf="isDocumentLocked" [matMenuTriggerFor]="lockMenu">
  <mat-icon>info</mat-icon>
</button>

<mat-menu #lockMenu>
  <ng-container *ngIf="isWorkflowLocked">
    <div
      [matTooltip]="'LOCKED_IN_GLOBALACTION' | transloco"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
    >
      <button mat-menu-item disabled>
        <mat-icon>lock</mat-icon>
        {{ 'LOCKED_IN_WORKFLOW' | transloco }}
      </button>
    </div>
    <button mat-menu-item (click)="onCopyProcessIdToClipboard()">
      <mat-icon>account_tree</mat-icon>
      {{ 'PROCESS_ID' | transloco }}: {{ searchResult.workflowProcessId }}
    </button>
  </ng-container>
  <ng-container *ngIf="isPdfConversionLocked">
    <div
      [matTooltip]="'PDF_CONVERT_WARNING' | transloco"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
    >
      <button mat-menu-item disabled>
        <mat-icon>lock</mat-icon>
        {{ 'PDF_CONVERSION_IN_PROGRESS' | transloco }}
      </button>
    </div>
  </ng-container>
</mat-menu>
