<ng-container *ngIf="!isLoading; else loading">
  <!-- Single Archive Result Set: Show just a button. -->
  <button
    mat-button
    [matTooltip]="taskSearch.archives[0].name"
    *ngIf="archiveSets === 'single'"
  >
    <span *ngIf="(isHandset$ | async) === false" style="margin-left: 5px">{{
      taskSearch.archives[0].name
    }}</span>
  </button>
  <button
    mat-button
    [matTooltip]="archive.name"
    *ngIf="archiveSets === 'multi'"
    [matMenuTriggerFor]="archiveSelectMenu"
  >
    <mat-icon [matBadge]="archive.processCount" matBadgePosition="above before"
      >expand_circle_down</mat-icon
    >
    <span *ngIf="(isHandset$ | async) === false" style="margin-left: 5px">{{
      archive.name
    }}</span>
  </button>
  <mat-menu #archiveSelectMenu="matMenu">
    <ng-template matMenuContent>
      <ng-container *ngFor="let taskArchive of taskSearch.archives">
        <button
          *ngIf="taskArchive.id !== archive.id"
          mat-menu-item
          (click)="onClickSelectArchive(taskArchive.id)"
        >
          <mat-icon
            [matBadge]="taskArchive.processCount"
            matBadgePosition="above before"
            >inventory_2</mat-icon
          >
          <span
            *ngIf="(isHandset$ | async) === false"
            style="margin-left: 5px"
            >{{ taskArchive.name }}</span
          >
        </button>
      </ng-container>
    </ng-template>
  </mat-menu>
</ng-container>

<ng-template #loading>
  <mat-spinner diameter="24" color="accent"></mat-spinner>
</ng-template>
