import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { CustomLink, CustomLinkMenuItem } from 'models';
import { AppConfigQuery } from 'src/app/modules/app-config';

/** Describes valid link types. */
export enum LinkType {
  /** A divider. */
  divider,
  /** Menu Header. */
  header,
  /** Link. */
  link,
}

@Component({
  selector: 'app-custom-links-menu',
  templateUrl: './custom-links-menu.component.html',
  styleUrls: ['./custom-links-menu.component.scss'],
})
export class CustomLinksMenuComponent {
  /** Observable of custom links. */
  customLinks$ = this.appConfigQuery.customLinks$;
  /** Describes valid link types. */
  readonly linkType = LinkType;

  constructor(
    private logger: NGXLogger,
    private appConfigQuery: AppConfigQuery
  ) {}

  /**
   * Gets the type of the link.
   *
   * @param item Link Item.
   * @returns A link type.
   */
  getLinkItemType(item: CustomLinkMenuItem): LinkType {
    // We tell people the string must be '---' but it is currently irrelevant really.
    if (typeof item === 'string') {
      return LinkType.divider;
    }
    if (typeof item.url === 'string' && item.url.length > 0) {
      return LinkType.link;
    }

    return LinkType.header;
  }

  /**
   * Checks if the menu item should be a divider.
   *
   * @param customLink Custom link.
   * @returns A boolean determining if the menu item is a divider.
   */
  isLinkADivider(customLink: CustomLinkMenuItem): boolean {
    return typeof customLink === 'string' && customLink === '---';
  }

  /**
   * Opens the url in a custom link.
   *
   * @param customLink Custom link.
   */
  onClickLink(customLink: CustomLink): void {
    if (customLink.url) {
      window.open(customLink.url, '_blank');
    }
  }
}
