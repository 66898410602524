<div
  role="group"
  class="mention-input-container"
  [attr.aria-labelledby]="formField.getLabelId()"
>
  <input
    class="mention-input-element"
    matInput
    type="text"
    [placeholder]="placeholder"
    #mentionInput
    [formControl]="mentionFormControl"
    (keypress)="onKeyPress($event)"
  />
  <div [matMenuTriggerFor]="mentionMenu" style="position: absolute"></div>
  <mat-menu #mentionMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let mention of mentions"
      (click)="setMentionValue(getMentionValue(mention))"
    >
      {{ getMentionValue(mention) }}
    </button>
  </mat-menu>
</div>
