import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-expansion-button',
  templateUrl: './navigation-expansion-button.component.html',
  styleUrls: ['./navigation-expansion-button.component.scss'],
})
export class NavigationExpansionButtonComponent {
  /** Whether the element should be expanded. */
  @Input()
  isExpanded = true;
  /** Event triggered when isExpanded changes. */
  @Output()
  isExpandedChange = new EventEmitter<boolean>();

  constructor() {}

  /**
   * Handler for the click event.
   */
  onClickToggle(): void {
    this.isExpanded = !this.isExpanded;
    this.isExpandedChange.emit(this.isExpanded);
  }
}
