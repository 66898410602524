<mat-toolbar>
  <button
    mat-icon-button
    (click)="onClickZoomDecrease()"
    [disabled]="zoomScale <= 0.1"
    [matTooltip]="'ZOOM_OUT' | transloco"
  >
    <mat-icon>zoom_out</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="onClickZoomIncrease()"
    [matTooltip]="'ZOOM_IN' | transloco"
  >
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="onClickZoomToFit()"
    [matTooltip]="'FIT_TO_PAGE' | transloco"
    [disabled]="zoomScale === 1"
  >
    <mat-icon>zoom_out_map</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="onClickSearch()"
    [matTooltip]="'FIND_IN_FILE' | transloco"
  >
    <mat-icon>find_in_page</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="onClickNextResult()"
    [matTooltip]="'NEXT_RESULT' | transloco"
    [disabled]="isDisabledNextResult"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
  <div class="spacer"></div>
  <ng-container *ngIf="pageCount > 1">
    <input
      class="inline-toolbar-input"
      type="number"
      [(ngModel)]="pageNumber"
      #page="ngModel"
      min="1"
    />
    <span class="of-pages">
      &nbsp;{{ 'OF' | transloco | lowercase }} {{ pageCount }}
    </span>
  </ng-container>
</mat-toolbar>
