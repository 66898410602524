import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Database } from 'models';

export interface DatabasesState
  extends EntityState<Database, number>,
    ActiveState<number> {}

/**
 * Databases store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'databases', resettable: true })
export class DatabasesStore extends EntityStore<DatabasesState> {
  constructor() {
    super();
  }
}
