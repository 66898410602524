import { assert, assertExists } from 'common';
import {
  PDFFont,
  PDFPage,
  PDFPageDrawRectangleOptions,
  PDFPageDrawTextOptions,
  StandardFonts,
  degrees,
} from 'pdf-lib';

import {
  Color,
  ConvertableAnnotation,
  IDocumentAnnotationBase,
  LineAppearance,
  LineStyle,
} from '.';

/**
 * Text annotation.
 *
 * @todo Still missing support for styles.
 */
export class TextAnnotation
  extends ConvertableAnnotation
  implements IDocumentAnnotationBase
{
  /** @inheritdoc */
  hasFont = true;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    /** Width of the text area. */
    public width: number,
    /** Height of the text area. */
    public height: number,
    /** Font size. */
    public fontSize: number,
    /** Text. */
    public text: string,
    /** Color. */
    public color: Color = new Color(0, 0, 0),
    /**
     * Font.
     *
     * @todo We may want to implement our own list of fonts rather than the PDF-Lib ones.
     */
    public font: StandardFonts = StandardFonts.TimesRoman,
    /**
     * Border.
     *
     * Defaults to none.
     */
    public border: LineAppearance = new LineAppearance(
      undefined,
      undefined,
      undefined,
      0
    ),
    /**
     * Fill color.
     *
     * Defaults to none.
     */
    public fillColor: Color = new Color(0, 0, 0, 0),
    public rotation: number = 0,
    public visible: boolean = true,
    /**
     * Embedded font.
     *
     * If the value is left undefined, the document default will be used.
     */
    private embeddedFont?: PDFFont
  ) {
    super();
  }

  /** @inheritdoc */
  get requiredFonts(): StandardFonts[] {
    return [this.font];
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    // Generate options.
    const textOptions = this.toPdfTextOptions();
    const rectangleOptions = this.toPdfRectangleElement();
    // Update scale.
    if (scalePixelsToPoints) {
      this.convertPixelsToPoints(textOptions);
      this.convertPixelsToPoints(rectangleOptions);
    }

    this.adjustTextForRotation(page, textOptions);
    this.adjustRectangleForRotation(page, rectangleOptions);

    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, textOptions);
      this.convertToCartesian(height, rectangleOptions);
    }
    // Render on page.
    page.drawRectangle(rectangleOptions);
    this.text = this.replaceInvalidText(this.text);
    page.drawText(this.text, textOptions);
  }

  /**
   * Set the font embedded for this annotation.
   *
   * @param font Embedded font.
   */
  embedFont(font: PDFFont) {
    this.embeddedFont = font;
  }

  private adjustRectangleForRotation(
    page: PDFPage,
    options: PDFPageDrawRectangleOptions
  ) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.width);
    assertExists(options.height);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    const widthOrig = options.width;

    /** Swap the width and height values. */
    const swapWidthHeight = () => {
      options.width = options.height;
      options.height = widthOrig;
    };

    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        options.x = options.y;
        // The x value requires subtracting page height, and the object height
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.width;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 180:
        // Subtract x value from page width, then the width to offset corner.
        options.x = page.getWidth() - options.x - options.width;
        // Subtract y value from page height, then the height to offset corner.
        options.y = page.getHeight() - options.y - options.height;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.x = page.getWidth() - options.y - options.height;
        options.y = xOrig;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  private adjustTextForRotation(
    page: PDFPage,
    options: PDFPageDrawTextOptions
  ) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.size);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    // Rotate text.
    options.rotate = degrees(
      ((options.rotate?.angle ?? 0) + pageRotation.angle) % 360
    );
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        // The x value needs the size added to it to adjust the corner.
        options.x = options.y + options.size;
        // The y value requires subtracting page height, and the text size
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.size;
        break;
      case 180:
        // Subtract x value from page width, then the width to offset corner.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height, then the text size x2 to offset corner.
        options.y = page.getHeight() - options.y - options.size * 2;

        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and text height to offset corner.
        options.x = page.getWidth() - options.y - options.size;
        options.y = xOrig - (options.size ?? 0);
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  private replaceInvalidText(text: string): string {
    // This regex matches characters outside the WinAnsi range.
    const regex = /[^\n\u0020-\u007E]/g;
    // Replace matched characters with a symbol that exists in the WinAnsi range.
    return text.replace(regex, '¤');
  }

  /**
   * Convert the annotation to PDF rectangtle element drawing options.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfRectangleElement(): PDFPageDrawRectangleOptions {
    const options: PDFPageDrawRectangleOptions = {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      color: this.fillColor.pdfColor,
      opacity: this.fillColor.opacity,
      borderColor: this.border.color.pdfColor,
      borderWidth: this.border.thickness,
      borderOpacity: this.border.color.opacity,
      rotate: degrees(this.rotation),
    };
    switch (this.border.style) {
      case LineStyle.Solid:
        break;
      case LineStyle.Dotted:
        options.borderDashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.borderDashArray = [1, 2]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      case LineStyle.Dashed:
        options.borderDashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.borderDashArray = [2, 1]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      default:
        throw new Error(`Unknown border LineStyle: ${this.border.style}`);
    }
    return options;
  }

  /**
   * Convert the annotation to PDF element drawing options as text.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfTextOptions(): PDFPageDrawTextOptions {
    const options: PDFPageDrawTextOptions = {
      font: this.embeddedFont,
      x: this.x,
      y: this.y,
      maxWidth: this.width,
      size: this.fontSize,
      color: this.color.pdfColor,
      opacity: this.color.opacity,
      rotate: degrees(this.rotation),
    };
    return options;
  }
}
