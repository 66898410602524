import { List } from 'models';

/**
 * Converts an API list object to a list.
 *
 * @param apiList API List.
 * @returns A list.
 */
export const createListFromApi = (apiList: S9ApiList): List => ({
  id: apiList.ID,
  name: apiList.Name,
  values: apiList.Items,
});

/** S9API List. */
export interface S9ApiList {
  /** ID. */
  ID: number;
  /** Items. */
  Items: string[];
  /** Name. */
  Name: string;
}
