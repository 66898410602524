import {
  ArchiveSecurityProperties,
  FieldValue,
  Search,
  SearchParameter,
  SearchProperties,
  SearchResult,
  SearchResultResponse,
  SearchResults,
  SecurityTypes,
  createPermissions,
  hasFlag,
} from 'models';

import { S9ApiFieldDefinition, S9ApiFieldValue } from './field';

export interface S9ApiSearch {
  Archives: S9ApiSearchArchive[];
  Description: string;
  Detail: S9ApiSearchDetail[];
  Fuzzy: number;
  Grouping: string;
  Hash: string;
  Icon: string;
  Id: number;
  Name: string;
  Parent: number;
  Props: SearchProperties;
  Settings: SecurityTypes;
}

export interface S9ApiSearchArchive {
  ID: number;
  Name: string;
}

export interface S9ApiSearchDetail {
  FID: number;
  FieldType: number;
  ID: number;
  ListF1: number;
  ListF2: number;
  ListID: number;
  Mask: string;
  Operator: number;
  Parent: number;
  Prompt: string;
  Prop: number;
  VAL: string;
}

export interface S9ApiSearchResults {
  Count: number;
  Docs: S9ApiDocumentResult[];
  Fields: S9ApiFieldDefinition[];
  Terms: S9ApiContentSearchTerms[];
}

export interface S9ApiContentSearchTerms {
  Terms: S9ApiContentSearchTerm[];
}

export interface S9ApiContentSearchTerm {
  Value: string;
}

export interface S9ApiDocumentResult {
  Fields: S9ApiFieldValue[];
  FileType: string;
  Hash: string;
  //TODO this needs a type
  Hits: number;
  Id: number;
  IsConvertingToPdf: boolean;
  Permissions: ArchiveSecurityProperties;
  RevisionOptions: number;
  Revisions: unknown;
  RootVersionID: number;
  TID: number;
  User_Name: unknown;
  Version: number;
  // TODO not sure if this is used
  VersionArchive: number;
  WorkflowProcessId: number;
}

export interface S9ApiSearchResultCount {
  ArchiveID: number;
  Count: number;
}

/**
 * Create `Search` from `S9ApiSearch`.
 *
 * @param s Square 9 API Search object.
 * @returns A converted search object.
 */
export const createSearch = (s: S9ApiSearch): Search => ({
  id: s.Id,
  name: s.Name,
  parentArchive: s.Parent,
  secureIdHash: s.Hash,
  archives: s.Archives.map((a) => a.ID),
  advancedGrouping: s.Grouping,
  isFavorite: hasFlag(s.Settings, SecurityTypes.queueSearch),
  isDefault: hasFlag(s.Settings, SecurityTypes.defaultSearch),
  description: s.Description,
  icon: s.Icon || 'search',
  settings: {
    contentSearch: {
      enabled: hasFlag(s.Props, SearchProperties.contentSearch),
      fuzzy: !!s.Fuzzy,
      stemming: hasFlag(s.Props, SearchProperties.stemming),
    },
    isDirectAccessEnabled: hasFlag(s.Settings, SecurityTypes.directSearch),
    isDashboardSearch: hasFlag(s.Props, SearchProperties.dashboardSearch),
    ixEnabled: hasFlag(s.Props, SearchProperties.ixEnabled),
    multiValue: hasFlag(s.Props, SearchProperties.multiValue),
    dashboardSearchShouldAlwaysPrompt: hasFlag(
      s.Props,
      SearchProperties.dashboardAlwaysPrompt
    ),
    displayViewTabs: hasFlag(s.Props, SearchProperties.displayViewTabs),
    dateFieldsUseTime: hasFlag(s.Props, SearchProperties.dateFieldsUseTime),
  },
  parameters: s.Detail.map(
    (p: S9ApiSearchDetail): SearchParameter => ({
      id: p.ID,
      fieldId: p.FID,
      operator: p.Operator,
      prompt: p.Prompt,
      value: p.VAL,
    })
  ),
});

const createSearchResult = (
  result: S9ApiDocumentResult,
  terms: string[]
): SearchResult => ({
  id: result.Id,
  isConvertingToPdf: result.IsConvertingToPdf,
  secureId: result.Hash,
  permissions: createPermissions(result.Permissions),
  tabId: result.TID,
  fileType: result.FileType,
  contentSearch: {
    hits: result.Hits,
    terms,
  },
  fields: result.Fields.map(
    (field): FieldValue => ({
      id: field.ID,
      value: field.VAL,
      multiValue: field.MVAL,
    })
  ),
  workflowProcessId: result.WorkflowProcessId,
});

/**
 * Create search results from an S9ApiSearchResults object.
 *
 * @param result API search result.
 * @returns Search Results.
 */
export const createSearchResults = (
  result: S9ApiSearchResults
): SearchResults => {
  const terms = flattenContentSearchTerms(result.Terms ?? []);
  return result.Docs.map((d) => createSearchResult(d, terms));
};

const flattenContentSearchTerms = (
  apiTerms: S9ApiContentSearchTerms[]
): string[] => {
  const flattenedTerms: string[] = [];
  for (const contentSearchTerms of apiTerms) {
    for (const term of contentSearchTerms.Terms) {
      flattenedTerms.push(term.Value);
    }
  }
  return flattenedTerms;
};

/**
 * Create a search result response from an S9ApiSearchResults object.
 *
 * @param result API search result.
 * @returns Search result.
 */
export const createSearchResultResponse = (
  result: S9ApiSearchResults
): SearchResultResponse => {
  return {
    count: result.Count,
    searchResults: createSearchResults(result),
  };
};
