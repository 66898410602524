<ng-container *ngIf="archive$ | async as archive">
  <ng-container *ngIf="search$ | async as search; else noSearch">
    <app-right-sidebar mode="archive" [gridApi]="gridApi">
      <app-archive-actions-toolbar
        #archiveActionsToolbar
        [actionMenu]="actionMenuComponent"
        [searchResults]="(results$ | async)!"
        (refineSearch)="onRefineSearch()"
        (refreshSearch)="onRefreshSearch()"
        (editModeEnabledChange)="onToggleEdit($event)"
        (downloadSelectedRowsAsCsv)="onDownloadSelectedRowsAsCsv($event)"
        (openSelectedDocuments)="onOpenDocumentSelectedDocuments($event)"
      ></app-archive-actions-toolbar>
      <div class="full-width">
        <app-tab-view
          (activeTabChange)="onActiveTabChanged($event)"
          *ngIf="hasViewTabs$ | async"
        ></app-tab-view>
        <ag-grid-angular
          #archiveGrid
          [ngStyle]="gridStyle"
          class="ag-theme-material"
          [gridOptions]="gridOptions"
          (contextmenu)="onContextMenuClick($event)"
        >
        </ag-grid-angular>
        <app-search-result-paginator
          [page]="currentPage"
          [result-count]="resultCount"
          (pageChange)="onPageChange($event)"
        ></app-search-result-paginator>
        <app-table-field-grid
          *ngIf="showTableFieldGrid"
          [archiveId]="archive.id"
          [editModeEnabled]="editModeEnabled"
        ></app-table-field-grid>
      </div>
    </app-right-sidebar>
  </ng-container>
</ng-container>

<ng-template #noSearch>
  <app-dashboard *ngIf="noDefaultSearch"></app-dashboard>
</ng-template>

<app-archive-actions-menu
  #actionMenuComponent
  (openSelectedDocuments)="onOpenDocumentSelectedDocuments($event)"
  (downloadSelectedRowsAsCsv)="onDownloadSelectedRowsAsCsv($event)"
  (refreshSearch)="onRefreshSearch()"
></app-archive-actions-menu>
