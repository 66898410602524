import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { CacheSession } from 'src/app/models';

import { syncState } from '../sync-state';

/** Archive Cache Sttore. */
export interface ArchiveSessionCacheState
  extends EntityState<CacheSession, string> {}

/** Archive Cache Session Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'archiveSessionCache', resettable: true })
export class ArchiveSessionCacheStore extends EntityStore<ArchiveSessionCacheState> {
  constructor() {
    super();
    syncState(this);
  }
}
