<h2 mat-dialog-title>Keyboard Shortcuts</h2>
<mat-dialog-content>
  <div class="hotkey-group" *ngFor="let hotkeyGroup of hotkeyGroups">
    <div class="mat-hint">{{ hotkeyGroup.group }}</div>
    <div class="hotkey-group-contents">
      <div class="shortcut-key-row" *ngFor="let hotkey of hotkeyGroup.hotkeys">
        <div class="hotkey-description">{{ hotkey.description }}</div>
        <span class="spacer"></span>
        <div class="shortcut-key mat-elevation-z2">
          <kbd [innerHTML]="hotkey.keys | hotkeysShortcut"></kbd>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
