<ng-container *ngIf="list; else loading">
  <mat-form-field *ngIf="!isLargelist; else typeaheadDropdown">
    <mat-label>{{ fieldLabel }}</mat-label>
    <mat-select
      #select
      [formControl]="control"
      [appMultiValueHotkeys]="mvFieldMenu"
      typeaheadDebounceInterval="1000"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (selectionChange)="onSelectionChange()"
    >
      <mat-option></mat-option>
      <mat-option
        *ngFor="let value of list.values"
        [matTooltipDisabled]="value.length <= 16"
        matTooltipShowDelay="1000"
        [matTooltip]="value"
        [value]="value"
        >{{ value }}</mat-option
      >
    </mat-select>
    <button
      *ngIf="field.multiValue && mvFieldMenu"
      [disabled]="control.disabled"
      tabIndex="-1"
      mat-icon-button
      type="button"
      matSuffix
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="mvFieldMenu.matMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-error
      *ngIf="control.invalid"
      class="ellipsis"
      [matTooltip]="errorMessage"
      >{{ errorMessage }}
    </mat-error>
  </mat-form-field>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<ng-template #typeaheadDropdown>
  <mat-form-field>
    <mat-label>{{ fieldLabel }}</mat-label>
    <input
      #input
      type="text"
      matInput
      [formControl]="control"
      [matAutocomplete]="auto"
      [appMultiValueHotkeys]="mvFieldMenu"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelectionChange()"
    >
      <mat-option
        *ngFor="let option of filteredValues$ | async"
        [value]="option"
        [matTooltipDisabled]="option.length <= 16"
        matTooltipShowDelay="1000"
        [matTooltip]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <button
      *ngIf="field.multiValue && mvFieldMenu"
      [disabled]="control.disabled"
      tabIndex="-1"
      mat-icon-button
      type="button"
      matSuffix
      [matMenuTriggerFor]="mvFieldMenu.matMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-error
      *ngIf="control.invalid"
      class="ellipsis"
      [matTooltip]="errorMessage"
      >{{ errorMessage }}
    </mat-error>
  </mat-form-field>
</ng-template>
