<ng-container *ngIf="showPanel$ | async">
  <div class="sidebar-group mat-elevation-z1">
    <mat-icon>star</mat-icon>
    <span transloco="FAVORITES"></span>
    <span class="spacer"></span>
    <app-navigation-expansion-button
      [isExpanded]="(isExpanded$ | async)!"
      (isExpandedChange)="onExpandChanged()"
    ></app-navigation-expansion-button>
  </div>
  <mat-progress-bar
    *ngIf="isSearchesLoading$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
  <div id="favorite-search-items" *ngIf="isExpanded$ | async">
    <app-navigation-favorite-search
      *ngFor="let search of favoriteSearches$ | async"
      [databaseId]="database.id"
      [search]="search"
    ></app-navigation-favorite-search>
  </div>
</ng-container>
