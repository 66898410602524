import { DocumentRequestMap } from './document-request-map';
import { InboxRequestedDocument } from './inbox-requested-document';

/**
 * Inbox requested document map.
 */
export class InboxRequestedDocumentMap extends DocumentRequestMap<InboxRequestedDocument> {
  constructor(documents?: InboxRequestedDocument[] | string) {
    super(documents);
  }
}
