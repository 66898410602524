import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QuickbooksProvider, UserFriendlyError } from 'models';
import { Observable, catchError } from 'rxjs';

import { GseApiConfig } from './gse-api-config.model';
import { GSE_API_CONFIG } from './gse-api-config.token';
import { handleError } from './handle-error';

/** Gse Quickbooks Service. */
@Injectable({
  providedIn: 'root',
})
export class GseQuickbooksService implements QuickbooksProvider {
  /** @inheritdoc */
  private config = {} as GseApiConfig;

  constructor(
    private http: HttpClient,
    @Inject(GSE_API_CONFIG) private config$: Observable<GseApiConfig>
  ) {
    this.config$.subscribe((config) => Object.assign(this.config, config));
  }

  private get extensionsUrl(): string {
    return `${this.config.apiUrl}/api`;
  }

  /** @inheritdoc */
  startPush(
    databaseId: number,
    archiveId: number,
    documentId: number
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.extensionsUrl}/quickbooks`,
        {},
        {
          params: new HttpParams()
            .set('database', databaseId)
            .set('archive', archiveId)
            .set('document', documentId),
        }
      )
      .pipe(catchError((error: UserFriendlyError) => handleError(error)));
  }
}
