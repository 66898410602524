<div class="card-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ 'INTERNAL_VIEWER_PREVIEW_UNAVAILABLE_HEADER' | transloco }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ 'INTERNAL_VIEWER_PREVIEW_UNAVAILABLE_MESSAGE' | transloco }}
      </p>
    </mat-card-content>
  </mat-card>
</div>
