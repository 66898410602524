import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Search, SearchPrompt } from 'models';
import { AddFavoriteSearchDialogComponent } from 'src/app/components/add-favorite-search-dialog/add-favorite-search-dialog.component';
import { CommandService } from 'src/app/modules/command-palette';
import { SearchUIService } from 'src/app/services/search-ui.service';

import { SearchesStore } from '../searches/searches.store';

import { ApplicationQuery } from './application.query';
import { ApplicationStore } from './application.store';

/** Favorite Search. */
export interface FavoriteSearch {
  /** User defined label for the search. */
  label: string;
  /** Search prompts. */
  searchPrompts: SearchPrompt[];
  /** Display prompt when search is run. */
  showPrompt: boolean;
  /** Target archive for which to run the search. */
  targetArchiveId: number;
}

/**
 * Favorite search collection.
 *
 * @description The key is the search id and the value is a 'FavoriteSearch'.
 * @see FavoriteSearch
 */
export declare type FavoriteDatabaseSearches = {
  [searchId: string]: FavoriteSearch;
};

/** Favorites service. */
@Injectable({
  providedIn: 'root',
})
export class FavoriteSearchesService {
  constructor(
    private dialog: MatDialog,
    private appStore: ApplicationStore,
    private searchUIService: SearchUIService,
    private searchesStore: SearchesStore,
    private commandService: CommandService,
    private appQuery: ApplicationQuery
  ) {}

  /**
   * Add a favorite search.
   *
   * @param search Search.
   * @param newFavorite Favorite search.
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   */
  addFavoriteSearch(
    search: Search,
    newFavorite: FavoriteSearch,
    databaseId: number,
    archiveId: number
  ): void {
    this.addToStore(`${databaseId}`, `${search.id}`, newFavorite);
    this.addToCommandPalette(search, databaseId, archiveId, newFavorite.label);
  }

  /**
   * Add a search to the command palette.
   *
   * @param search Search.
   * @param databaseId DatabaseId.
   * @param archiveId Archive ID.
   * @param customLabel Custom label for the search.
   */
  addToCommandPalette(
    search: Search,
    databaseId: number,
    archiveId: number,
    customLabel: string = ''
  ): void {
    const name = customLabel ? customLabel : search.name;
    this.commandService.register(
      name,
      'Run search',
      () => this.runFavoritedSearch(databaseId, search),
      'Search'
    );
  }

  /**
   * Check if a search is favorited.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   * @returns If the search is favorited.
   *
   * @see ApplicationQuery.isFavoritedSearch
   */
  getIsFavoriteSearch(databaseId: string, searchId: string): boolean {
    return this.appQuery.isFavoritedSearch(databaseId, searchId);
  }

  /**
   * Prompt the user to set properties and add a favorite search.
   *
   * @param search Search.
   * @param databaseId Database Id.
   * @param archiveId Archive ID.
   */
  promptToAddFavoriteSearch(
    search: Search,
    databaseId: number,
    archiveId: number
  ): void {
    const dialog = this.dialog.open(AddFavoriteSearchDialogComponent, {
      data: search.name,
    });
    dialog.afterClosed().subscribe((newFavorite: FavoriteSearch) => {
      if (!newFavorite) {
        return;
      }

      this.addFavoriteSearch(search, newFavorite, databaseId, archiveId);
    });
  }

  /**
   * Remove a favorite search.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   */
  removeFavoriteSearch(databaseId: number, searchId: number): void {
    const favoriteSearch = this.appQuery.getFavoritedSearchById(
      `${databaseId}`,
      `${searchId}`
    );
    if (!favoriteSearch) {
      return; // TODO should we throw an error?
    }

    this.removeFromStore(`${databaseId}`, `${searchId}`);
    this.removeFromCommandPalette(favoriteSearch.label);
  }

  /**
   * Remove a search from the command palette.
   *
   * @param searchCommandLabel Label used to add the search to the command palette.
   */
  removeFromCommandPalette(searchCommandLabel: string): void {
    this.commandService.unregister(searchCommandLabel, 'Search');
  }

  /**
   * Runs a favorited search.
   *
   * @param databaseId Database ID.
   * @param search Search.
   */
  runFavoritedSearch(databaseId: number, search: Search): void {
    const favoriteSearch = this.appQuery.getFavoritedSearchById(
      `${databaseId}`,
      `${search.id}`
    );

    if (favoriteSearch.showPrompt) {
      this.searchUIService.runWithPrompt(
        search,
        databaseId,
        favoriteSearch.targetArchiveId,
        favoriteSearch.searchPrompts
      );
    } else {
      this.searchUIService.redirectToSearch(
        databaseId,
        favoriteSearch.targetArchiveId,
        search.id,
        favoriteSearch.searchPrompts
      );
    }
  }

  /**
   * Add a favorited search.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   * @param favoriteSearch Favorite search.
   */
  private addToStore(
    databaseId: string,
    searchId: string,
    favoriteSearch: FavoriteSearch
  ): void {
    const favoritedSearches = this.deepyCopy<{
      [databaseId: string]: FavoriteDatabaseSearches;
    }>(this.appStore.getValue().favoritedSearches);
    if (!favoritedSearches[databaseId]) {
      favoritedSearches[databaseId] = {};
    }
    favoritedSearches[databaseId][searchId] = favoriteSearch;
    this.appStore.update({ favoritedSearches });
    this.searchesStore.update(Number(searchId), {
      isFavorite: true,
    });
  }

  private deepyCopy<T>(object: any): T {
    return JSON.parse(JSON.stringify(object)) as T;
  }

  /**
   * Remove a favorited search.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   */
  private removeFromStore(databaseId: string, searchId: string): void {
    const favoritedSearches = this.deepyCopy<{
      [databaseId: string]: FavoriteDatabaseSearches;
    }>(this.appStore.getValue().favoritedSearches);
    delete favoritedSearches[databaseId][searchId];
    this.appStore.update({ favoritedSearches });
    this.searchesStore.update(Number(searchId), {
      isFavorite: false,
    });
  }
}
