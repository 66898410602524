import { BatchPrint } from 'models';

/**
 * Converts an API BatchPrint object to an BatchPrint.
 *
 * @param apiBatchPrint BatchPrint object.
 * @returns A list.
 */
export const createBatchPrintFromApi = (
  apiBatchPrint: S9ApiBatchPrintDefinition
): BatchPrint => ({
  annotations: apiBatchPrint.Annotations,
  archiveDocuments: apiBatchPrint.ArchiveDocuments,
});
/** S9API Print. */
export interface S9ApiBatchPrintDefinition {
  /** Archive Id. */
  Annotations: boolean;
  /** Field Id. */
  ArchiveDocuments: any;
}
