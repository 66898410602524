import { Pipe, PipeTransform } from '@angular/core';

/** Pipe for removing domain from username. */
@Pipe({
  name: 'UserWithoutDomain',
  pure: false,
})
export class UserWithoutDomainPipe implements PipeTransform {
  constructor() {}

  /** @inheritdoc */
  transform(userWithDomain: string): string {
    return userWithDomain.slice(Math.max(0, userWithDomain.indexOf('\\') + 1));
  }
}
