import { DocumentRevision } from 'models';

export interface S9ApiDocumentRevision {
  /** Document id for the revision in the Versions archive. */
  DocId: number;
  /** Document secure id for the revision in the Versions archive. */
  SecureId: string;
  /** Version number of the revision. */
  Version: number;
}

/**
 * Creates a document revision from the API object.
 * @param apiRevision API document revision object.
 * @returns A document revision.
 */
export const createDocumentRevisionFromApi = (
  apiRevision: S9ApiDocumentRevision
): DocumentRevision => ({
  documentId: apiRevision.DocId,
  secureId: apiRevision.SecureId,
  version: apiRevision.Version,
});
