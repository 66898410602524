import { License } from 'models';

export interface S9ApiLicense {
  Active?: boolean;
  AuthServer: string;
  DateAccessed: string;
  DateCreated: string;
  Domain: string;
  Expires?: null;
  IPAddress: string;
  Password?: null;
  Provider?: null;
  Reg: number;
  ResourceIdentityKey?: S9ApiResourceIdentityKey;
  Token: string;
  Type: number;
  Username: string;
}

export interface S9ApiResourceIdentityKey {
  Archive: null;
  Database: null;
  Document: null;
}

/**
 * Creates a license from an API license.
 *
 * @param apiLicense API license.
 * @returns A license.
 */
export const createLicenseFromApi = (apiLicense: S9ApiLicense): License => ({
  token: apiLicense.Token,
  type: apiLicense.Type,
  username: apiLicense.Username,
});
