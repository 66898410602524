<!-- A hidden div is created to set the position of appearance of the menu-->
<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="actionMenu"
></div>
<mat-menu #actionMenu="matMenu" (closed)="onMenuClosed()">
  <button mat-menu-item (click)="onClickOpenDocuments()" *ngIf="!hideOpen">
    <span transloco="OPEN_DOCUMENTS"></span>
  </button>
  <button
    *ngIf="!hideOpenFullViewer"
    mat-menu-item
    (click)="onClickOpenDocuments(true)"
  >
    <span transloco="OPEN_IN_FULL_VIEWER"></span>
  </button>
  <ng-container *ngIf="showUserActions">
    <mat-divider *ngIf="!hideOpen || !hideOpenFullViewer"></mat-divider>
    <button
      mat-menu-item
      *ngFor="let userAction of userActions"
      (click)="onClickUserAction(userAction)"
    >
      <mat-icon>call_split</mat-icon>
      {{ userAction.name }}
    </button>
  </ng-container>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="onClickCopy()">
    <span transloco="COPY"></span>
  </button>
</mat-menu>
