import { CapStyle, Color, EndCapStyle, LineStyle } from '.';

/**
 * Line appearance.
 */
export class LineAppearance {
  constructor(
    /** Cap style. */
    public caps: CapStyle = CapStyle.Butt,
    /** Color. */
    public color: Color = new Color(0, 0, 0),
    /** Line style. */
    public style: LineStyle = LineStyle.Solid,
    /** Line thickness. */
    public thickness: number = 1,
    /** Style of the line ending. */
    public endCap?: EndCapStyle
  ) {}
}
