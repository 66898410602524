<h2 mat-dialog-title transloco="EDIT_KFI_SELECTION"></h2>
<mat-dialog-content>
  <mat-form-field class="form-field-width">
    <mat-label> {{ data.fieldName }}</mat-label>
    <input
      matInput
      [(ngModel)]="fieldValue"
      *ngIf="!fieldValue.includes('\n')"
    />
    <textarea
      class="text-area-height"
      matInput
      [(ngModel)]="fieldValue"
      *ngIf="fieldValue.includes('\n')"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    transloco
    (click)="cancelDialog()"
    transloco="CANCEL"
  ></button>
  <button
    mat-button
    mat-dialog-close
    transloco="CONFIRM"
    (click)="confirmDialog()"
  ></button>
</mat-dialog-actions>
