import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import {
  ArchiveImportSession,
  ArchiveSession,
  ScanResult,
  TranslationParameters,
  UserFriendlyError,
} from 'models';
import { removeFileExtension } from 'src/app/common/utility';
import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';
import { SearchesService } from 'src/app/state/searches/searches.service';

import {
  ArchiveRequestedDocument,
  ArchiveRequestedDocumentMap,
} from 'src/app/models';
import { ScanDialogComponent } from '../scan-dialog/scan-dialog.component';

/** Direct Access Types. */
enum DirectAccessType {
  /** Document. */
  document = 'document',
  /** Scan. */
  scan = 'scan',
  /** Document via share link. */
  share = 'share',
}

/** Direct Access Component. */
@UntilDestroy()
@Component({
  selector: 'app-direct-access',
  templateUrl: './direct-access.component.html',
  styleUrls: ['./direct-access.component.scss'],
})
export class DirectAccessComponent implements OnInit {
  /** Indicates if the progress component should be displayed. */
  showProgress = true;
  private archiveId: number;
  private databaseId: number;
  private queryParams: Params = this.getCaseInsensitiveQueryParams();
  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private appQuery: ApplicationQuery,
    private searchesService: SearchesService,
    private viewerService: ViewerService,
    private gseService: GseService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.databasesQuery.selectActiveId(),
      this.archivesQuery.selectActiveId(),
    ])
      .pipe(first())
      .subscribe(([databaseId, archiveId]) => {
        this.logger.debug('Active database ID', databaseId);
        this.logger.debug('Active archive ID', archiveId);
        if (
          typeof databaseId === 'undefined' ||
          databaseId === null ||
          typeof archiveId === 'undefined' ||
          archiveId === null
        ) {
          throw new TypeError(
            'Values are required for both database and archive Id.'
          );
        }
        this.databaseId = databaseId;
        this.archiveId = archiveId;
        // Ensure that the archive and search stores are finished loading before attempting direct access.
        combineLatest([
          this.searchesQuery.isLoading$.pipe(
            untilDestroyed(this),
            first((isLoading) => !isLoading)
          ),
          this.archivesQuery.isLoading$.pipe(
            untilDestroyed(this),
            first((isLoading) => !isLoading)
          ),
        ]).subscribe(() => this.runRedirect());
      });
  }

  private getCaseInsensitiveQueryParams(): Params {
    const lowerParameters: Params = {};
    for (const key in this.route.snapshot.queryParams) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.route.snapshot.queryParams,
          key
        )
      ) {
        lowerParameters[key.toLowerCase()] =
          this.route.snapshot.queryParams[key];
      }
    }

    return lowerParameters;
  }

  private handleDocument(): void {
    this.logger.debug('Parsing direct document access.');
    const documentId = this.queryParams.documentid;
    // This mirrors the current behavior of the s9 api route controller. We just take the first direct access search.
    const search = this.searchesQuery.getAll().find(
      (s) =>
        // Ensure we get a search that includes the archive and is a direct access search.
        s.archives.includes(this.archiveId) && s.settings.isDirectAccessEnabled
    );
    if (!search) {
      this.handleError('NO_DIRECT_ACCESS_SEARCH_IS_AVAILABLE');
      return;
    }

    const searchOptions = {
      page: 1,
      countOnly: false,
      recordsPerPage: 50,
      searchCriteria: '',
      sort: '',
      tabId: 0,
      documentId,
      targetArchiveId: this.archiveId,
    };

    this.searchesService
      .run(search, searchOptions)
      .subscribe((resultResponse) => {
        if (resultResponse.searchResults.length === 1) {
          if (this.appQuery.viewerUseInternal) {
            this.router.navigate(
              [
                'db',
                this.databaseId,
                'archive',
                this.archiveId,
                'search',
                search.id,
                'document',
                resultResponse.searchResults[0].id,
              ],
              { queryParamsHandling: 'merge' }
            );
          } else {
            const session: ArchiveSession = {
              database: this.databaseId,
              searchId: search.id,
              documents: [
                {
                  archiveId: this.archiveId,
                  id: documentId,
                  hash: resultResponse.searchResults[0].secureId,
                },
              ],
            };

            this.viewerService.createSession(session).subscribe((sessionId) => {
              this.viewerService
                .openViewerSession(sessionId, false)
                .subscribe();
            });
          }
        } else {
          this.handleError('ERROR_DIRECT_ACCESS_DOCUMENT_NOT_FOUND');
        }
      });
  }

  private handleShareDocument(): void {
    // links should be `/db/{databaseId}/archive/{archiveId}/direct/share?documentid={documentId}&documentsecureid={secureId}`
    const documentIdString = this.queryParams.documentid as string;
    const documentSecureId = this.queryParams.documentsecureid as string;
    const useLegacyViewer = Boolean(this.queryParams.uselegacyviewer);

    const documentId = Number(documentIdString);

    if (useLegacyViewer) {
      this.logger.debug('Using legacy viewer to open shared link.');
      const session: ArchiveSession = {
        database: this.databaseId,
        searchId: 0,
        documents: [
          {
            archiveId: this.archiveId,
            id: documentId,
            hash: documentSecureId,
          },
        ],
      };

      this.viewerService.createSession(session).subscribe((sessionId) => {
        this.viewerService.openViewerSession(sessionId, false).subscribe();
      });
    } else {
      this.logger.debug('Using internal viewer to open shared link.');
      const requestedDocument = new ArchiveRequestedDocument(
        documentId,
        documentSecureId
      );
      const requestedDocuments = new ArchiveRequestedDocumentMap([
        requestedDocument,
      ]);
      const routeCommands = [
        'db',
        this.databaseId,
        'archive',
        this.archiveId,
        'document',
        requestedDocuments.toString(),
      ];
      this.router.navigate(routeCommands, {
        queryParamsHandling: 'merge',
      });
    }
  }

  private handleError(i18n: string, i18nParameters?: TranslationParameters) {
    this.showProgress = false;
    this.notify.error({
      description: '',
      error: undefined,
      i18n,
      i18nParameters,
    });
  }

  private handleScan(): void {
    this.logger.debug('Checking GSE connection status.');
    this.gseService.isConnected$.pipe(first()).subscribe((isConnected) => {
      if (!isConnected) {
        this.handleError('DIRECT_ACCESS_SCAN_ERROR_GSE_NOT_CONNECTED');
        return;
      }

      this.logger.debug('Parsing direct access scan.');
      const fieldData = this.queryParams.fielddata
        ? JSON.parse(atob(this.queryParams.fielddata))
        : undefined;

      const scanDialogReference = this.dialog.open(ScanDialogComponent, {
        disableClose: true,
        data: 'archive',
        minWidth: 250,
      });
      scanDialogReference
        .afterClosed()
        .subscribe((result: ScanResult) =>
          this.onScanDialogClose(result, fieldData)
        );
    });
  }

  private onScanDialogClose(
    result: ScanResult,
    fieldData?: { [key: number]: string }
  ): void {
    this.logger.debug('Scan dialog closed.', result);
    if (result) {
      if (this.appQuery.viewerUseInternalForImport) {
        this.logger.debug('Creating a new internal viewer session.');
        this.router.navigate(
          [
            'db',
            this.databaseId,
            'archive',
            this.archiveId,
            'import',
            // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
            result.uploadId,
          ],
          {
            queryParamsHandling: 'merge',
            queryParams: { fielddata: btoa(JSON.stringify(fieldData)) },
          }
        );
      } else {
        this.logger.debug('Creating a new square 9 viewer session.');
        const session: ArchiveImportSession = {
          database: this.databaseId,
          documents: [
            {
              archiveId: this.archiveId,
              fieldData,
              filename: removeFileExtension(result.uploadId),
            },
          ],
        };
        this.viewerService.createSession(session).subscribe({
          next: (sessionId) =>
            this.viewerService
              .openViewerSession(sessionId, false)
              .subscribe(() => {
                this.logger.debug('Viewer closed.');
              }),
          error: (error: UserFriendlyError) =>
            this.notify.error({
              ...error,
              i18n: 'ERROR_CREATE_SESSION_MSG',
            }),
        });
      }
    }
  }

  private runRedirect(): void {
    const type = this.route.snapshot.params.type;
    this.logger.debug('Direct access component reached.', type);
    switch (type.toLowerCase()) {
      case DirectAccessType.document:
        this.handleDocument();
        break;
      case DirectAccessType.scan:
        this.handleScan();
        break;
      case DirectAccessType.share:
        this.handleShareDocument();
        break;
      default:
        this.notify.error({
          description: '',
          error: undefined,
          i18n: 'UNSUPPORTED_DIRECT_ACCESS_TYPE',
          i18nParameters: { accessType: type },
        });
        this.handleError('UNSUPPORTED_DIRECT_ACCESS_TYPE', {
          accessType: type,
        });
        break;
    }
  }
}
