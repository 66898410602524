<!-- Dashboard Header Card -->
<div
  class="dashboard-header"
  *ngIf="dashboardConfig$ | async as dashboardConfig"
>
  <mat-card class="background-accent">
    <mat-card-header>
      <mat-card-title>
        <div class="header-title mat-headline-6">
          {{ dashboardConfig.title }}
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <img
        *ngIf="dashboardConfig.image"
        class="header-image"
        [src]="dashboardConfig.image"
      />
      <div class="mat-body-1">
        <div
          *ngIf="dashboardConfig.useMarkdown; else plainText"
          [innerHtml]="dashboardConfig.message | markdown"
        ></div>
        <ng-template #plainText>
          <div class="header-message">{{ dashboardConfig.message }}</div>
        </ng-template>
      </div>
      <div class="clear"></div>
    </mat-card-content>
    <mat-card-footer *ngIf="isGuest">
      <div class="header-credit">
        {{ 'POWERED_BY_GLOBALSEARCH' | transloco }}
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<ng-container *ngIf="searches$ | async as searches">
  <!-- Search Filter -->
  <ng-container *ngIf="searches.length > 4">
    <mat-form-field appearance="outline" class="search-filter">
      <input
        matInput
        placeholder="{{
          (dashboardConfig$ | async)?.filterLabel ??
            ('FILTER_SEARCHES' | transloco)
        }}"
        [(ngModel)]="searchFilter"
      />
      <button
        aria-label="Clear Search Filter"
        mat-icon-button
        matSuffix
        (click)="searchFilter = ''"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>
  <!-- Card Grid -->
  <div class="card-flow fill">
    <mat-grid-list #grid rowHeight="82px" [cols]="gridCols">
      <mat-grid-tile
        *ngFor="
          let search of searches
            | filterName: searchFilter
            | orderBy: 'name' : 'asc'
        "
      >
        <mat-card
          class="search-card"
          mat-ripple
          (click)="
            onSearchClick(
              $event,
              search,
              search.settings.dashboardSearchShouldAlwaysPrompt
            )
          "
          [matTooltip]="
            search.name + (search.description ? ': ' + search.description : '')
          "
          matTooltipShowDelay="700"
        >
          <mat-card-content>
            <mat-icon>{{ search.icon }}</mat-icon>
            <span class="search-card-label">{{ search.name }}</span>
          </mat-card-content>

          <mat-card-subtitle>{{ search.description }}</mat-card-subtitle>
          <button
            *ngIf="!search.settings.dashboardSearchShouldAlwaysPrompt"
            title="{{ 'ADVANCED' | transloco }}"
            mat-icon-button
            class="search-with-properties top-right-corner"
            (click)="onSearchClick($event, search, true)"
          >
            <mat-icon>tune</mat-icon>
          </button>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="logo-container">
      <app-logo-svg logo-size="256" monochrome></app-logo-svg>
    </div>
  </div>
</ng-container>
