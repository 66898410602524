import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { assert, assertExists } from 'common';
import { GSEProvider, UserFriendlyError } from 'models';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, EMPTY, Observable, of, switchMap, timer } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';

import { GseAppManager } from '../models';

import { GseApiConfig } from './gse-api-config.model';
import { GSE_API_CONFIG } from './gse-api-config.token';
import { HttpErrorHandler } from './handle-error';

/**
 * GlobalSearch Extensions API service.
 */
@Injectable({
  providedIn: 'root',
})
export class GseApiService implements GSEProvider {
  /** @inheritdoc */
  isConnected$: Observable<boolean>;
  /** @inheritdoc */
  isLaunchAvailable$: Observable<boolean>;
  /** @inheritdoc */
  isQuickbooksAvailable$: Observable<boolean>;

  private config = {} as GseApiConfig;
  private errorHandler: HttpErrorHandler;
  private isConnectedSource = new BehaviorSubject<boolean>(false);
  private isLaunchAvailableSource = new BehaviorSubject<boolean>(false);
  private isQuickbooksAvailableSource = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    @Inject(GSE_API_CONFIG) private config$: Observable<GseApiConfig>
  ) {
    this.config$.subscribe((config) => {
      Object.assign(this.config, config);
    });
    this.errorHandler = new HttpErrorHandler(this.logger);
    this.isConnected$ = this.isConnectedSource.asObservable();
    this.isLaunchAvailable$ = this.isLaunchAvailableSource.asObservable();
    this.isQuickbooksAvailable$ =
      this.isQuickbooksAvailableSource.asObservable();
    this.testConnection().subscribe();
    this.testLaunch().subscribe();
    this.testQuickbooks().subscribe();
  }

  private get extensionsUrl(): string {
    return `${this.config.apiUrl}/api`;
  }

  /** @inheritdoc */
  open(): Observable<void> {
    this.logger.debug('Opening GSE.');
    const openWindow$ = this.isConnected$.pipe(
      map((isConnected) => {
        // Require connection.
        assert(isConnected);
        // Require an API URL to be configured.
        assertExists(this.config.apiUrl);
        // Open UI in new tab.
        window.open(this.config.apiUrl, '_blank');
      })
    );
    return openWindow$;
  }

  /** @inheritdoc */
  syncExtensions(square9ApiUrl: string): Observable<void> {
    return this.http.get(`${this.extensionsUrl}/management/test`).pipe(
      retry({
        delay: () => {
          this.logger.error('Failed GSE connection. Retrying in 2 seconds...');
          return timer(2000);
        },
      }),
      mergeMap(() => this.saveApiUrl(square9ApiUrl)),
      tap(() => {
        this.isConnectedSource.next(true);
      }),
      map(() => void 0)
    );
  }

  /** @inheritdoc */
  testConnection(): Observable<boolean> {
    return this.http.get(`${this.extensionsUrl}/management/test`).pipe(
      map(() => {
        this.isConnectedSource.next(true);
        return true;
      }),
      catchError((errorResponse: UserFriendlyError) => {
        this.isConnectedSource.next(false);
        if (errorResponse.error.status) {
          return this.errorHandler.handleError(
            'Unable to connect to GSE.',
            'GSE_UNABLE_TO_CONNECT_ERR',
            errorResponse.error
          );
        }
        this.logger.debug('GSE did not respond to connection test.');
        return EMPTY;
      })
    );
  }

  /** @inheritdoc */
  testLaunch(): Observable<boolean> {
    return this.isConnected$.pipe(
      switchMap((isConnected) =>
        isConnected
          ? this.http.get(`${this.extensionsUrl}/launch/test`).pipe(
              map(() => {
                this.isLaunchAvailableSource.next(true);
                return true;
              }),
              catchError((errorResponse: UserFriendlyError) => {
                this.isLaunchAvailableSource.next(false);
                if (errorResponse.error.status !== 405) {
                  this.errorHandler.handleError(
                    'Unable to connect to GSE Launch.',
                    'GSE_LAUNCH_UNABLE_TO_CONNECT',
                    errorResponse.error
                  );
                  return EMPTY;
                }
                this.logger.debug('GSE Launch is not enabled.');
                return EMPTY;
              })
            )
          : of(false)
      )
    );
  }

  /** @inheritdoc */
  testNewUrl(url: string): Observable<boolean> {
    return this.http.get(`${url}/api/management/test`).pipe(map(() => true));
  }

  /** @inheritdoc */
  testQuickbooks(): Observable<boolean> {
    //https://127.0.0.1:9443/api/management/CheckPackages
    return this.isConnected$.pipe(
      switchMap((isConnected) =>
        !isConnected
          ? of(false)
          : this.http
              .get<GseAppManager>(
                `${this.extensionsUrl}/management/checkpackages`
              )
              .pipe(
                map((appManager) => appManager.AppList),
                map((gseApps) => {
                  const quickbooksAppIndex = gseApps.findIndex(
                    (app) => app.Name === 'QUICKBOOKS'
                  );
                  const isQuickbooksAvailable =
                    quickbooksAppIndex > -1 &&
                    gseApps[quickbooksAppIndex].Installed;
                  this.isQuickbooksAvailableSource.next(isQuickbooksAvailable);
                  return isQuickbooksAvailable;
                }),
                catchError((errorResponse: UserFriendlyError) => {
                  this.isQuickbooksAvailableSource.next(false);
                  if (errorResponse.error.status !== 405) {
                    this.errorHandler.handleError(
                      'Unable to connect to GSE.',
                      'GSE_UNABLE_TO_CONNECT_ERR',
                      errorResponse.error
                    );
                    return EMPTY;
                  }
                  this.logger.debug('GSE Launch is not enabled.');
                  return EMPTY;
                })
              )
      )
    );
  }

  private saveApiUrl(square9ApiUrl: string): Observable<string> {
    return this.http.post<string>(
      `${this.extensionsUrl}/management/updateapi`,
      JSON.stringify(square9ApiUrl),
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }
}
