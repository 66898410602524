import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, combineLatest, map, startWith } from 'rxjs';

import { UserFriendlyError } from 'models';
import { AppConfigQuery, AppConfigService } from 'src/app/modules/app-config';
import { NotificationService } from 'src/app/services/notification.service';

import { UrlQuery } from '../../state/url.query';
import { UrlService } from '../../state/url.service';

/** API Url Field Component. */
@UntilDestroy()
@Component({
  selector: 'app-api-url-field',
  templateUrl: './api-url-field.component.html',
  styleUrls: ['./api-url-field.component.scss'],
})
export class ApiUrlFieldComponent implements OnInit {
  /** If the url is valid. */
  @Output()
  isValidChanged = new EventEmitter<boolean>();
  /**
   * Currently selected API URL.
   *
   * @returns URL string.
   */
  get url() {
    return this.urlValue;
  }
  /**
   * Set the API URL.
   *
   * @param value URL to set.
   */
  @Input()
  set url(value: string) {
    this.urlValue = value;
    // If there is no value, then enable edit mode.
    if (!value) this.isEditMode = true;
  }
  /** Currently selected API URL. */
  @Output()
  urlChange = new EventEmitter<string>();
  /** Observable collection of filtered options. */
  filteredOptions: Observable<string[]>;
  /** If the URL is in edit mode. */
  isEditMode: boolean;
  /** If the URL is valid. */
  isValid: boolean;
  /** Observable of if the app config is currently loading. */
  loadingConfig$ = this.appConfigQuery.loading$;
  /** Should the button to clear auto-complete be displayed. */
  shouldShowClearAutoComplete: boolean;
  /** API Url form control. */
  urlControl = new UntypedFormControl('', Validators.required);
  /** Observable array of URLS. */
  urls$ = this.urlQuery.urls$;

  private urlValue: string;

  constructor(
    private urlQuery: UrlQuery,
    private urlService: UrlService,
    private appConfigQuery: AppConfigQuery,
    private appConfigService: AppConfigService,
    private translateService: TranslocoService,
    private notify: NotificationService,
    private logger: NGXLogger
  ) {}

  /**
   * Get instance input error.
   *
   * @returns A string containing the error to display or an empty string if there is no error.
   */
  get inputError(): string {
    if (this.urlControl.hasError('required')) {
      return this.translateService.translate('INSTANCE_URL_IS_REQUIRED');
    }

    if (this.urlControl.hasError('invalid')) {
      return this.translateService.translate('INSTANCE_INVALID_OR_UNAVAILABLE');
    }

    return '';
  }

  ngOnInit(): void {
    // Use the url to the value currently set if there is one.
    if (this.url) {
      this.urlControl.setValue(this.url);
      this.saveUpdatedUrl(this.url, true);
    }

    this.filteredOptions = this.urls$.pipe(
      (startWith(''),
      map((urls) =>
        urls.filter(
          (option) => option.toLowerCase().indexOf(option.toLowerCase()) === 0
        )
      ))
    );

    // Check if clear auto-complete button should be shown.
    combineLatest([this.loadingConfig$, this.urls$])
      .pipe(untilDestroyed(this))
      .subscribe(([loadingConfig, urls]) => {
        this.shouldShowClearAutoComplete = !loadingConfig && urls.length > 0;
      });
  }

  /**
   * Event handler for the blur event.
   *
   * @param checkValidity Should validity be checked before firing.
   * @param autoCompleteReference Material Auto-Complete component reference.
   */
  onAutoCompleteBlur(
    checkValidity = false,
    autoCompleteReference?: MatAutocomplete
  ): void {
    // Do nothing if invalid.
    if (checkValidity && !this.isValid) return;
    // Save updated URL.
    this.saveUpdatedUrl(this.urlControl.value);
    if (typeof autoCompleteReference != 'undefined') {
      autoCompleteReference._isOpen = false;
    }
  }

  /**
   * Event handler for auto complete option changes.
   *
   * @param event Autocomplete event.
   */
  onAutoCompleteSelected(event: MatAutocompleteSelectedEvent): void {
    this.saveUpdatedUrl(event.option.value);
  }

  /**
   * Event handler for the close button on the autocomplete input.
   */
  onClearAutoComplete(): void {
    this.url = '';
    this.appConfigService.resetToDefault();
    this.setValid(false);
    this.urlControl.setValue(this.url);
    this.urlService.update({ urls: [] });
  }

  /**
   * Event handler for continue click.
   */
  onContinueClick(): void {
    // If the input field was not focused at time of click, force attempt.
    this.saveUpdatedUrl(this.urlControl.value);
  }

  /**
   * Parse a hostname from a provided URL string.
   *
   * Cloud instances will return the subdomain only.
   *
   * @param url URL to parse.
   * @returns Parsed hostname and if it is a cloud instance..
   */
  parseHost(url: string):
    | {
        /** Hostname. */
        host: string;
        /** Host is a cloud address. */
        isCloud: boolean;
      }
    | undefined {
    try {
      // Parse out the host.
      const hostname = new URL(url).host;
      // Determine if the host is a "Cloud" address.
      const cloudDomainIndex = hostname.toLowerCase().indexOf('.mysquare9.com');
      const isCloud = cloudDomainIndex > 0;
      const host = isCloud
        ? hostname.slice(0, Math.max(0, cloudDomainIndex))
        : hostname;
      if (!host) throw new Error('Unable to parse hostname from URL.');
      return {
        host,
        isCloud,
      };
    } catch (error: any) {
      this.logger.error(error.message, url);
      return undefined;
    }
  }

  /**
   * Alert that the URL is invalid and set the form validity.
   *
   * @param error Error.
   */
  private errorInstanceInvalid(error: Error) {
    this.notify.error(
      new UserFriendlyError(
        error,
        'Unable to retrieve configuration from the provided instance.',
        'INSTANCE_INVALID_OR_UNAVAILABLE'
      )
    );
    this.setValid(false);
  }

  /**
   * Save the updated URL.
   *
   * @param valueSource Source for value.
   * @param force Force attempt.
   */
  private saveUpdatedUrl(valueSource: string, force?: boolean): void {
    if (this.url === valueSource && !force) return; // Ignore if no change.

    // Set the local value.
    this.url = valueSource;

    // Clear the current server configuration when discarding the previous instance if any.
    this.appConfigService.resetToDefault();
    if (this.url) {
      // Sanitize & determine a list of possible interpretations of input.
      const cleanedInput = this.url.trim().replace(/\/+$/, '');
      let parsedUrl: URL;

      try {
        if (!/http(s)?:\/\//.test(cleanedInput)) {
          throw new Error('Protocol required for reliable parsing of URL.');
        }
        parsedUrl = new URL(cleanedInput);
      } catch {
        // Try with current protocol.
        parsedUrl = new URL(`${window.location.protocol}//${cleanedInput}/`);
      }
      const providedCompleteUrl = parsedUrl
        .toString()
        .trim()
        .replace(/\/+$/, '');
      const providedHostOnly = `${parsedUrl.protocol}//${parsedUrl.host}/square9api`;

      // If our current protocol is HTTPS, enforce that as a requirement.
      if (
        window.location.protocol === 'https:' &&
        parsedUrl.protocol === 'http:'
      ) {
        this.errorInstanceInvalid(
          new Error('HTTPS instance is required if protocol is HTTPS.')
        );
        return;
      }

      // Load configuration from a possible instance.
      this.logger.debug(
        'Attemping to get instance configuration from complete URL:',
        providedCompleteUrl
      );
      // Assume first a complete URL.
      this.tryUrl(providedCompleteUrl)
        .pipe(
          catchError(() => {
            this.logger.debug(
              'Failed to get config with complete URL, attemping as host only:',
              providedHostOnly
            );
            // If that errors, try as a host only.
            return this.tryUrl(providedHostOnly);
          })
        )
        .subscribe({
          error: (error) => {
            // If both methods fail, alert the user.
            this.errorInstanceInvalid(error);
          },
        });
    }
  }

  private setValid(state: boolean) {
    this.isValidChanged.emit(state);
    this.isValid = state;
    if (!state) {
      this.urlControl.setErrors({ invalid: true });
    } else {
      this.isEditMode = false;
    }
  }

  private tryUrl(url: string) {
    return this.appConfigService.get(url).pipe(
      map((_config) => {
        this.logger.debug('Successfully retreived configuration.');
        // Store the URL history if needed when the config fetch is successful.
        const previousUrls = [...this.urlQuery.urls];
        if (!this.urlQuery.urls.includes(url)) {
          previousUrls.unshift(url);
          this.urlService.update({ urls: previousUrls });
        }
        // Emit that the instance URL is valid.
        this.setValid(true);
        // Emit the value for ouput and ensure UI matches value.
        this.url = url;
        this.urlChange.emit(url);
        return url;
      })
    );
  }
}
