import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NGXLogger } from 'ngx-logger';

import { getPageSizes } from 'src/app/common/page-sizes';
import { PageChangeEvent } from 'src/app/models';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';

@Component({
  selector: 'app-search-result-paginator',
  templateUrl: './search-result-paginator.component.html',
  styleUrls: ['./search-result-paginator.component.scss'],
})
export class SearchResultPaginatorComponent {
  /** Current page. */
  @Input()
  /** Current grid page. */
  page = 1;
  /** Current page change event. */
  @Output()
  pageChange = new EventEmitter<PageChangeEvent>();
  /** Total number of results. */
  @Input('result-count')
  resultCount: number;
  /** Page size options. */
  pageSizes = getPageSizes(this.appQuery);
  /** Records per page. */
  recordsPerPage: number;
  /** Observable records per page setting. */
  recordsPerPage$ = this.appQuery.archiveResultsPerPage$;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  private currentPageSize = this.appQuery.archiveResultsPerPage;

  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private layout: LayoutService
  ) {}

  /**
   * Handler for the page change event.
   *
   * @param event Page event.
   */
  onPageChange(event: PageEvent): void {
    this.logger.debug('Page changed', event);
    if (this.currentPageSize !== event.pageSize) {
      // Go back to the first page if changing page size.
      this.page = 1;
    } else {
      this.page = event.pageIndex + 1;
    }
    this.currentPageSize = event.pageSize;
    this.pageChange.emit({ page: this.page, pageSize: event.pageSize });
  }
}
