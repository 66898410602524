import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Search,
  SearchOptions,
  SearchProvider,
  SearchResult,
  SearchResultResponse,
  SearchResults,
  Searches,
} from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  S9ApiSearch,
  S9ApiSearchResultCount,
  S9ApiSearchResults,
  createSearch,
  createSearchResultResponse,
  createSearchResults,
} from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API searches.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiSearchService implements SearchProvider {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.baseUrl = apiUrl));
  }

  /**
   * Get all searches for an archive.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @returns Observable list of matching searches.
   */
  getAll(databaseId: number, archiveId?: number): Observable<Searches> {
    const url = `${this.baseUrl}/dbs/${databaseId}/archives/${
      archiveId ?? -1
    }/searches`;
    return this.http
      .get<S9ApiSearch[]>(url)
      .pipe(map((searches) => searches.map(createSearch)));
  }

  /** @inheritdoc */
  getDocumentData(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string
  ): Observable<SearchResult> {
    //$resource(config.square9ApiUrl + '/dbs/:db/archives/:archive/documents/:id'
    return this.http
      .get<S9ApiSearchResults>(
        `${this.baseUrl}/dbs/${databaseId}/archives/${archiveId}/documents/${documentId}`,
        {
          params: new HttpParams().set('secureid', secureId),
        }
      )
      .pipe(
        map((apiResults) => {
          const results = createSearchResults(apiResults);
          return results[0]; // There should only ever be a single document.
        })
      );
  }

  /** @inheritdoc */
  getTableData(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string
  ): Observable<SearchResults> {
    const url = `${this.baseUrl}/dbs/${databaseId}/archives/${archiveId}/documents/${documentId}`;
    const params = new HttpParams().set('secureid', secureId);
    return this.http
      .get<S9ApiSearchResults>(url, { params })
      .pipe(map((results) => createSearchResults(results)));
  }

  /**
   * Run a search.
   *
   * @param databaseId Database ID.
   * @param search Search object.
   * @param options Search configuration options.
   * @returns An observable list of search results.
   */
  run(
    databaseId: number,
    search: Search,
    options: SearchOptions
  ): Observable<SearchResultResponse> {
    const url = `${this.baseUrl}/dbs/${databaseId}/searches/${search.id}/archive/${options.targetArchiveId}/documents`;
    let params = new HttpParams()
      .set('count', String(options.countOnly))
      .set('secureId', search.secureIdHash)
      .set('tabId', options.tabId?.toString())
      .set('searchCriteria', options.searchCriteria)
      .set('sort', options.sort)
      .set('page', options.page.toString())
      .set('recordsPerPage', options.recordsPerPage.toString());
    if (options.documentId) {
      params = params.set('docid', options.documentId.toString());
    }
    if (options.includeExtendedData) {
      params = params.set('includeExtendedData', options.includeExtendedData);
    }
    return this.http
      .get<S9ApiSearchResults>(url, { params })
      .pipe(map((results) => createSearchResultResponse(results)));
  }

  /** @inheritdoc */
  runForCount(
    databaseId: number,
    search: Search,
    options: SearchOptions
  ): Observable<number> {
    const url = `${this.baseUrl}/dbs/${databaseId}/searches/${search.id}/archive/${options.targetArchiveId}/documents`;
    const params = new HttpParams()
      .set('count', String(options.countOnly))
      .set('secureId', search.secureIdHash)
      .set('tabId', options.tabId?.toString())
      .set('searchCriteria', options.searchCriteria)
      .set('sort', options.sort)
      .set('page', options.page.toString())
      .set('recordsPerPage', options.recordsPerPage.toString());
    return this.http
      .get<S9ApiSearchResultCount>(url, { params })
      .pipe(map((resultObject) => resultObject.Count));
  }
}
