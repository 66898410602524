import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Archive } from 'models';

export interface ArchivesState
  extends EntityState<Archive>,
    ActiveState<number> {}

/** Archives Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'archives', resettable: true })
export class ArchivesStore extends EntityStore<ArchivesState> {
  constructor() {
    super();
  }
}
