import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, RowNode } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs';

import { assertExists } from 'common';
import {
  AppendType,
  Archive,
  Permissions,
  Search,
  SearchOptions,
  SearchResult,
  createApiSearchPromptString,
} from 'models';
import { PageChangeEvent, ViewTabItem } from 'src/app/models';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { FavoriteSearch } from 'src/app/state/application/favorite-searches.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';
import { SearchesService } from 'src/app/state/searches/searches.service';

import { SELECTION_ORDER_COLUMN_ID } from '../grid-cell-components/cell-selectors';
import {
  SearchPromptComponent,
  SearchPromptDialogData,
  SearchPromptResult,
} from '../search-prompt/search-prompt.component';

/** Search Select Form. */
interface SearchSelectForm {
  /** Selected search. Undefined if set. */
  search: FormControl<Search | undefined>;
}

/** Insert options dialog. */
interface InsertOptionsForm {
  /** If documents merged should be deleted from the source. */
  deleteMergeDocuments: FormControl<boolean>;
  /** If the base document should be deleted. */
  keepBaseDocument: FormControl<boolean>;
  /** If we should remember the user's selection. */
  rememberChoice: FormControl<boolean>;
}

/** Insert from search dialog result. */
export interface InsertFromSearchDialogResult {
  /** Append type. */
  appendType: AppendType;
  /** Archive Id where the documents to merge are located. */
  archiveId: number;
  /** If the files merged should be deleted from their source. */
  deleteFiles: boolean;
  /** A map of document Id keys, with secure Id hash as the value. */
  documentIdSecureIdMap: { [documentId: number]: string };
  /** If the base file should be kept. */
  keepBaseFile: boolean;
}

/** Insert from search dialog. */
@UntilDestroy()
@Component({
  selector: 'app-insert-from-search-dialog',
  templateUrl: './insert-from-search-dialog.component.html',
  styleUrls: ['./insert-from-search-dialog.component.scss'],
})
export class InsertFromSearchDialogComponent implements OnInit {
  /** AG Grid instance. */
  @ViewChild('searchGrid')
  grid: AgGridAngular;
  /** Stepper instance. */
  @ViewChild('stepper') stepper: MatStepper;
  /** Current target archive Id. */
  currentArchiveId: number;
  /** Current step index. */
  // currentStepIndex = 0;
  /** Grid configuration. */
  gridOptions: GridOptions;
  /** Current grid page. */
  gridPage = 1;
  /** Whether view tabs are enabled. */
  hasViewTabs = false;
  /** Insert options form. */
  insertOptionsForm: FormGroup<InsertOptionsForm>;
  /** Total number of search results. */
  searchResultCount: number;
  /** Search Select Form. */
  searchSelectForm: FormGroup<SearchSelectForm>;
  /** Observable array of available searches. */
  searches$ = this.searchesQuery.favoritedSearches$.pipe(
    map((searches) => {
      searches = searches.map((search) => {
        const favoriteSearch = this.appQuery.getFavoritedSearchById(
          this.databasesQuery.activeId.toString(),
          search.id.toString()
        );

        return { ...search, name: favoriteSearch.label };
      });

      return searches;
    })
  );
  /** Show the remember delete inbox document option. */
  showRememberDeleteSelection = false;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  private activeViewTab: ViewTabItem | undefined;
  private recordsPerPage = this.appQuery.archiveResultsPerPage;
  private selectedFavoriteSearch: FavoriteSearch;
  private selectedRowNodes: RowNode[] = [];

  /**
   * Determines if append buttons should be disabled.
   *
   * @returns A boolean indicating if the append buttons should be disabled.
   */
  get appendButtonsDisabled(): boolean {
    return this.selectedRowNodes.length === 0;
  }

  /**
   * Determines if the user has delete permissions to the base document.
   *
   * @returns True if the user has delete permissions to the base document.
   */
  get baseDocumentHasDeletePermissions(): boolean {
    assertExists(
      this.baseSearchDocument,
      'Base search document must be provided.'
    );

    return this.baseSearchDocument.permissions.deleteDocuments;
  }

  /**
   * Determines if user can advance to the next step.
   *
   * @returns A boolean indicating if the user can advance to the next step.
   */
  get canGoToNext(): boolean {
    if (!this.stepper) return false;
    switch (this.stepper.selectedIndex) {
      case 0:
        return this.searchSelectForm.valid;
      case 1:
        return this.selectedRowNodes.length > 0;
      default:
        this.logger.error('Unhandled step');
        return true;
    }
  }

  /**
   * The grid height.
   *
   * @returns The height css string for the grid.
   */
  get gridHeight(): string {
    let reservedPixels = 212;
    if (this.archives.length > 1) {
      reservedPixels += 78;
    }

    if (this.hasViewTabs) {
      reservedPixels += 49;
    }

    return `calc(65vh - ${reservedPixels}px)`;
  }

  /**
   * Determines if the remember choice checkbox should be hidden.
   *
   * @returns True if the remember choice checkbox should be hidden.
   */
  get hideRememberChoice(): boolean {
    return (
      !this.showRememberDeleteSelection ||
      !this.selectedDocumentsHaveDeletePermissions ||
      !this.baseDocumentHasDeletePermissions
    );
  }

  /**
   * Determines if the stepper is on the last step.
   *
   * @returns A boolean indicating if the stepper is on the last step.
   */
  get isLastStep(): boolean {
    if (!this.stepper) return false;
    return this.stepper.steps.length - 1 === this.stepper.selectedIndex;
  }

  /**
   * Determines if all selected documents have delete document permissions.
   *
   * @returns True if the user has delete document permissions to all selected documents.
   */
  get selectedDocumentsHaveDeletePermissions(): boolean {
    return this.selectedRowNodes.every(
      (rowNode) => (rowNode.data.permissions as Permissions).deleteDocuments
    );
  }

  /**
   * Gets an array of archives available to the selected search.
   *
   * @returns An array of archives or an empty array if no search is selected.
   */
  get archives(): Archive[] {
    const search = this.searchSelectForm.controls.search.value;
    if (!search) return [];
    return this.archivesQuery
      .getAll()
      .filter((archive) => search.archives.includes(archive.id));
  }

  /**
   * Gets the currently selected search from the form.
   *
   * @returns A search.
   */
  get selectedSearch(): Search {
    const search = this.searchSelectForm.controls.search.value;
    assertExists(search, 'Search must be provided.');
    return search;
  }

  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private searchesService: SearchesService,
    private gridHelper: GridHelperService,
    private layout: LayoutService,
    private dialogReference: MatDialogReference<InsertFromSearchDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private baseSearchDocument: SearchResult
  ) {}

  ngOnInit(): void {
    // Create search select form.
    this.searchSelectForm = new FormGroup<SearchSelectForm>({
      search: new FormControl(undefined, { nonNullable: true }),
    });
    // Create insert options form.
    this.insertOptionsForm = new FormGroup<InsertOptionsForm>({
      deleteMergeDocuments: new FormControl(
        this.appQuery.deleteSearchDocumentOnInsert,
        { nonNullable: true }
      ),
      keepBaseDocument: new FormControl(
        {
          value:
            !this.baseDocumentHasDeletePermissions ||
            this.appQuery.keepBaseSearchDocumentOnInsert,
          disabled: !this.baseDocumentHasDeletePermissions,
        },
        { nonNullable: true }
      ),
      rememberChoice: new FormControl(false, { nonNullable: true }),
    });
    // Configure the AG-Grid for use.
    this.configureGrid();
  }

  /**
   * Handler for the tab change event.
   *
   * @param viewTabItem New view tab.
   */
  onActiveTabChanged(viewTabItem: ViewTabItem): void {
    this.logger.debug('View tab changed', viewTabItem);
    if (this.activeViewTab?.description.id === viewTabItem.description.id) {
      this.logger.debug(
        'View tab change event fired but view tab did not change.'
      );
      return;
    }

    this.activeViewTab = viewTabItem;
    this.runSearch();
  }

  /**
   * Handler for the archive selection change event.
   *
   * @param event Select change event.
   */
  onArchiveSelectionChange(event: MatSelectChange): void {
    const archiveId = Number(event.value);
    if (Number.isNaN(archiveId)) {
      this.logger.error(`${archiveId} is not a valid archive id.`);
      return;
    }
    this.gridPage = 1;
    this.currentArchiveId = archiveId;
    this.setHasViewTabs();
    this.loadColumns();
    this.runSearch();
  }

  /**
   * Event handler for the append button click events.
   *
   * @param prepend Determines if append should be to the beginning.
   */
  onClickAppend(prepend: boolean): void {
    this.logger.debug('Append clicked.', prepend);
    if (this.insertOptionsForm.controls.rememberChoice.value) {
      this.appService.applyUserUiSettings({
        deleteSearchDocumentsOnInsert:
          this.insertOptionsForm.controls.deleteMergeDocuments.value,
        keepBaseSearchDocumentOnInsert:
          this.insertOptionsForm.controls.keepBaseDocument.value,
      });
    }
    const result: InsertFromSearchDialogResult = {
      archiveId: this.currentArchiveId,
      appendType: prepend ? AppendType.toBeginning : AppendType.toEnd,
      keepBaseFile: this.insertOptionsForm.controls.keepBaseDocument.value,
      deleteFiles: this.insertOptionsForm.controls.deleteMergeDocuments.value,
      documentIdSecureIdMap: {},
    };
    for (const rowNode of this.selectedRowNodes) {
      result.documentIdSecureIdMap[`${rowNode.data.id as number}`] =
        rowNode.data.secureId;
    }
    this.dialogReference.close(result);
  }

  /**
   * Event handler for the refresh search click event.
   */
  onClickRefresh(): void {
    this.logger.debug('Refresh clicked.');
    this.prepareSearch();
  }

  /**
   *  Handler for the page change event.
   *
   * @param event Page change event.
   */
  onPageChange(event: PageChangeEvent): void {
    this.gridPage = event.page;
    this.recordsPerPage = event.pageSize;
    this.runSearch();
  }

  /**
   * Event handler for search selection changes.
   *
   * @param event Select change event.
   */
  onSearchSelectionChange(event: MatSelectChange): void {
    this.logger.debug('Selection changed.', event.value);
    const search = event.value as Search;
    this.selectedFavoriteSearch = {
      ...this.appQuery.getFavoritedSearchById(
        this.databasesQuery.activeId.toString(),
        search.id.toString()
      ),
    };

    this.prepareSearch();
  }

  /**
   * Event handler for the step selection change event.
   *
   * @param event Stepper Selection Event.
   */
  onStepSelectionChange(event: StepperSelectionEvent): void {
    this.logger.debug('Step changed', event);
    // this.currentStepIndex = event.selectedIndex;
  }

  /**
   * Configure the grid.
   */
  private configureGrid(): void {
    this.gridOptions = this.gridHelper.createArchiveGridOptions({
      onGridReady: () => this.listenForRecordsPerPage(),
      onFirstDataRendered: (params) => params.api.autoSizeAllColumns(),
      onRowDataUpdated: (params) => params.api.autoSizeAllColumns(),
      onRowSelected: (params) => {
        this.gridHelper.onRowSelected(params, this.selectedRowNodes);
        this.resetSelections();
        // check permissions and set values and disable checkboxes
        if (!this.selectedDocumentsHaveDeletePermissions) {
          this.insertOptionsForm.controls.deleteMergeDocuments.setValue(false);
          this.insertOptionsForm.controls.deleteMergeDocuments.disable();
        }
      },
    });
  }

  /**
   * Starts a listener that monitors the archive records per page user setting and adjusts the grid page size to match.
   */
  private listenForRecordsPerPage(): void {
    this.appQuery.archiveHistoryResultsPerPage$
      .pipe(untilDestroyed(this))
      .subscribe((recordsPerPage) => {
        this.grid.api?.updateGridOptions({
          paginationPageSize: recordsPerPage,
        });
      });
  }

  /**
   * Loads the current archive fields into grid columns.
   */
  private loadColumns(): void {
    assertExists(this.currentArchiveId, 'Current archive id must be set.');
    const columns = this.gridHelper
      .getArchiveColumnDefinitions(this.currentArchiveId, () => false)
      .filter((column) => !column.field?.startsWith('tablefield'));
    columns.unshift(this.gridHelper.getSelectionColumn());
    this.grid.api?.updateGridOptions({ columnDefs: columns });
  }

  /**
   * Prepares the search to run. This includes opening the search prompt if necessary.
   */
  private prepareSearch(): void {
    // Resets the search after prompt.
    const resetSearch = () => {
      this.currentArchiveId = this.selectedFavoriteSearch.targetArchiveId;
      this.setHasViewTabs();
      this.loadColumns();
      this.gridPage = 1;
      if (!this.hasViewTabs) {
        // If the search does not have view tabs run it otherwise let the 'onActiveTabChanged' handler run the search.
        this.runSearch();
      }
      this.stepper.next();
    };
    if (this.selectedFavoriteSearch.showPrompt) {
      const searchPromptData: SearchPromptDialogData = {
        search: this.selectedSearch,
        searchPrompts: this.selectedFavoriteSearch.searchPrompts,
      };
      const promptDialog = this.dialog.open(SearchPromptComponent, {
        data: searchPromptData,
      });
      promptDialog.afterClosed().subscribe((result: SearchPromptResult) => {
        if (!result) {
          this.logger.debug('Search prompt dialog cancelled.');
          return;
        }
        this.logger.debug('Search prompt closed', result);
        this.selectedFavoriteSearch.searchPrompts = result.prompts;
        resetSearch();
      });
    } else {
      resetSearch();
    }
  }

  /**
   * Reset grid row selections to ensure order is correct.
   */
  private resetSelections(): void {
    for (let index = 0; index < this.selectedRowNodes.length; ++index) {
      this.selectedRowNodes[index].setDataValue(
        SELECTION_ORDER_COLUMN_ID,
        index + 1
      );
    }
  }

  /**
   * Runs the current search and populates grid data.
   *
   */
  private runSearch() {
    this.logger.debug('Running search.');

    // Deselect the documents.
    this.grid.api?.deselectAll();

    // Prepare search options.
    const searchOptions: SearchOptions = {
      countOnly: false,
      page: this.gridPage,
      recordsPerPage: this.recordsPerPage,
      searchCriteria: this.selectedFavoriteSearch.searchPrompts
        ? createApiSearchPromptString(this.selectedFavoriteSearch.searchPrompts)
        : '',
      sort: '',
      tabId: this.activeViewTab ? this.activeViewTab.description.id : 0,
      targetArchiveId: this.currentArchiveId,
    };

    this.grid.api?.showLoadingOverlay();

    this.searchesService
      .run(this.selectedSearch, searchOptions)
      .subscribe((resultResponse) => {
        this.logger.debug('Search finished', resultResponse);
        // Load count
        this.searchResultCount = resultResponse.count;
        // load search result data
        if (this.activeViewTab) {
          this.activeViewTab.count = resultResponse.count;
        }
        this.grid.api.updateGridOptions({
          rowData: resultResponse.searchResults,
        });
      });
  }

  private setHasViewTabs(): void {
    this.activeViewTab = undefined;
    if (!this.selectedSearch || !this.currentArchiveId) {
      this.hasViewTabs = false;
      return;
    }

    const archive = this.archivesQuery.getEntity(this.currentArchiveId);
    if (!archive) {
      this.hasViewTabs = false;
      return;
    }

    this.hasViewTabs =
      this.selectedSearch.settings.displayViewTabs &&
      archive.viewTabs.length > 0;
    this.logger.debug('set view has view tabs', this.hasViewTabs);
  }
}
