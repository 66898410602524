import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from 'models';

/** Login Provider. */
export interface LoginProvider {
  /**
   * Login using basic authentication.
   *
   * @param name Username.
   * @param password Password.
   * @returns An observable of the new logged in user.
   */
  basicLogin(name: string, password: string): Observable<User>;

  /**
   * Login using a license token.
   *
   * @param licenseToken License token.
   */
  licenseTokenLogin(licenseToken: string): Observable<User>;
  /**
   * Login using Windows NTLM authentication.
   */
  ntlmLogin(): Observable<User>;
  /**
   * Login using provider authentication.
   *
   * @param jwtToken JWT Token.
   * @param provider Authentication provider.
   * @returns An observable of the new logged in user.
   */
  tokenLogin(jwtToken: string, provider: string): Observable<User>;

  /**
   * Validates the provided license token.
   *
   * @param licenseToken License token.
   * @returns An observable of True if the license token is valid.
   */
  validateLicenseToken(licenseToken: string): Observable<boolean>;
}

/** Login Provider. */
export const LOGIN_PROVIDER = new InjectionToken<LoginProvider>(
  'LOGIN_PROVIDER'
);
