import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { GseApiConfig } from './gse-api-config.model';

/**
 * GlobalSearch Extensions API configuration injection token.
 */
export const GSE_API_CONFIG = new InjectionToken<Observable<GseApiConfig>>(
  'GSE_API_CONFIG'
);
