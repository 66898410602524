<h1 mat-dialog-title>
  <span transloco="EXPORT_DOCUMENTS"></span>
</h1>
<mat-dialog-content>
  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="settingsForm" label="Select file options">
      <form [formGroup]="settingsForm">
        <div class="first-stepper-control">
          <mat-form-field class="full-width-form-field">
            <mat-label transloco="EXPORTED_FILENAME"></mat-label>
            <app-mention-form-field
              [mentionFormControl]="exportedFilenameControl"
              placeholder="Exported filename template. (Optional)"
              mentionTriggerChar="<"
              mentionReplacementStartChar="<"
              mentionReplacementEndChar=">"
              [mentions]="filenameArchiveFields"
              mentionDisplayKey="name"
            ></app-mention-form-field>
            <mat-hint>
              {{ 'EXPORT_FILENAME_HINT' | transloco }}
            </mat-hint>
          </mat-form-field>
        </div>
        <div id="fileOptionsContainer">
          <p>
            <mat-checkbox formControlName="convertToPdf">
              <span transloco="CONVERT_PDF"></span>
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="convertToBlackAndWhite"
              ><span transloco="CONVERT_TO_BLACKWHITE"></span>
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="includeAnnotations">{{
              'INCLUDE_ANNOTATIONS' | transloco
            }}</mat-checkbox>
          </p>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="csvSettingsForm" label="Select field data options">
      <mat-checkbox
        class="first-stepper-control include-data-checkbox"
        [formControl]="includeDataFormControl"
      >
        <span transloco="INCLUDE_FIELD_DATA"></span>
      </mat-checkbox>
      <form [formGroup]="csvSettingsForm" *ngIf="includeData">
        <div>
          <mat-form-field class="full-width-form-field">
            <mat-label transloco="CSV_FILENAME"></mat-label>
            <input
              matInput
              placeholder="field-data.csv"
              formControlName="filename"
            />
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label transloco="DELIMITER"></mat-label>
          <input matInput placeholder="," formControlName="columnDelimiter" />
        </mat-form-field>
        <mat-form-field class="csv-options-field">
          <mat-label transloco="STRING_DELIMITER"></mat-label>
          <input matInput placeholder='"' formControlName="stringDelimiter" />
        </mat-form-field>
        <div>
          <mat-checkbox formControlName="includeHeader">{{
            'LITERAL_INCLUDE_HEADERS' | transloco
          }}</mat-checkbox>
          <mat-checkbox
            class="include-all-fields-checkbox"
            formControlName="includeAllFields"
          >
            {{ 'INCLUDE_ALL_FIELDS' | transloco }}
          </mat-checkbox>
        </div>
        <div>
          <mat-form-field
            id="fieldSelect"
            class="full-width-form-field"
            *ngIf="!csvSettingsControls.includeAllFields.value"
          >
            <mat-label>Exported Fields</mat-label>
            <mat-chip-grid
              #fieldsChipList
              cdkDropList
              cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="onDropSelectedField($event)"
            >
              <mat-chip-row
                *ngFor="let field of selectedFields"
                [value]="field"
                cdkDrag
                (removed)="removeField(field)"
              >
                {{ field.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                [placeholder]="'FIELDS' | transloco"
                #fieldsInput
                [formControl]="selectedFieldsChipForm"
                [matAutocomplete]="auto"
                [matChipInputFor]="fieldsChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addField($event)"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectedField($event)"
            >
              <mat-option
                *ngFor="let field of filteredArchiveFields$ | async"
                [value]="field"
              >
                {{ field.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
  <div *ngIf="exportInProgress">
    <h4>{{ 'EXPORTING_DOCUMENTS' | transloco }}...</h4>
    <mat-progress-bar
      mode="determinate"
      [value]="exportStatus?.progress"
    ></mat-progress-bar>
  </div>
  <div *ngIf="downloadingExportZip">
    <h4>{{ 'DOWNLOADING_EXPORT_ZIP' | transloco }}...</h4>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close transloco="CANCEL"></button>
  <span class="spacer"></span>
  <button
    mat-button
    [disabled]="disableExport"
    (click)="onClickExport()"
    transloco="EXPORT"
  ></button>
</mat-dialog-actions>
