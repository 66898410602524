<h1 mat-dialog-title>
  <span transloco="IMPORT_DATA_AND_DOCS_JOB_MANAGER"></span>
</h1>
<mat-dialog-content>
  <mat-accordion *ngIf="jobIds$ | async as jobIds; else noJobs" multi>
    <ng-container *ngFor="let jobId of jobIds">
      <ng-container *ngIf="selectJob$(jobId) | async as job">
        <mat-expansion-panel
          (opened)="onOpenJobPanel(jobId)"
          (closed)="onCloseJobPanel(jobId)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ job.startTime.toLocaleString() }}
            </mat-panel-title>
            <mat-panel-description>
              {{ getJobStatusMessage(job.status) }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <mat-progress-bar
              mode="determinate"
              [value]="job.status.progress"
            ></mat-progress-bar>
            <p style="margin-top: 12px">
              {{ getJobProgressText(job.status) }}
            </p>
            <p
              *ngIf="job.status.erroredRows > 0"
              transloco
              [translocoParams]="{ erroredRowTotal: job.status.erroredRows }"
            >
              ERRORED_ROWS_FAILED_TO_IMPORT
            </p>
            <div
              *ngIf="jobResults[jobId] as jobResult"
              style="width: 100%; align-content: center"
            >
              <h3 transloco="RESULTS"></h3>
              <mat-form-field
                *ngIf="jobResult.errorMessages.length"
                appearance="outline"
                style="width: 100%"
              >
                <mat-label>Errors</mat-label>
                <textarea
                  cdkTextareaAutosize
                  matInput
                  readonly
                  [value]="jobResult.errorMessages.join('\n')"
                ></textarea>
              </mat-form-field>
              <mat-form-field appearance="outline" class="result-form-field">
                <mat-label>Imported rows</mat-label>
                <input matInput readonly [value]="jobResult.importedRows" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="result-form-field">
                <mat-label>Errored rows</mat-label>
                <input matInput readonly [value]="jobResult.erroredRows" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="result-form-field">
                <mat-label>Total rows</mat-label>
                <input matInput readonly [value]="jobResult.totalRows" />
              </mat-form-field>
            </div>
            <div class="job-action-row">
              <button
                mat-icon-button
                [disabled]="getDisableResults(job.status)"
                [matTooltip]="'RESULTS' | transloco"
                matTooltipShowDelay="1000"
                (click)="onClickResult(jobId)"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <button
                mat-icon-button
                [disabled]="getDisableResults(job.status)"
                [matTooltip]="'DOWNLOAD' | transloco"
                matTooltipShowDelay="1000"
                (click)="onClickDownloadResults(jobId)"
              >
                <mat-icon>download</mat-icon>
              </button>
              <span class="spacer"></span>
              <button
                mat-button
                [disabled]="
                  job.status.cancelled || job.status.done || !job.status.started
                "
                (click)="onClickCancel(jobId)"
              >
                <span transloco="CANCEL"></span>
              </button>
              <button
                mat-button
                [disabled]="getDisableDelete(job.status)"
                (click)="onClickDelete(jobId)"
              >
                <span transloco="DELETE"></span>
              </button>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span transloco="CLOSE"></span></button>
</mat-dialog-actions>

<ng-template #noJobs>
  <p transloco="NO_IMPORT_JOBS_TO_DISPLAY"></p>
</ng-template>
