import { Archive, ViewTab, ViewTabs, createPermissions } from 'models';

/**
 * GlobalSearch API Archive object with PascalCase properties.
 */
export interface S9ApiArchive {
  ArchiveProperties: number;
  BasePath: string;
  DBProperties: number;
  FieldIds: number[];
  FullTextPath: string;
  Id: number;
  Name: string;
  Parent: number;
  Permissions: number;
  Properties: number;
  TableFieldIds: number[];
}

/**
 * GlobalSearch API View Tabs object with PascalCase properties.
 */
export interface S9ApiViewTab {
  Color: string;
  ID: number;
  Label: string;
  Value: string;
}

/**
 * Create `ViewTabs` from an array of `S9ApiViewTab`.
 *
 * @param s9ApiViewTabs Square9Api view tab objects.
 * @returns  A new `ViewTabs` array.
 */
export const createViewTabs = (s9ApiViewTabs: S9ApiViewTab[]): ViewTabs => {
  return s9ApiViewTabs.map<ViewTab>((t: S9ApiViewTab) => ({
    color: t.Color,
    label: t.Label,
    id: t.ID,
  }));
};

/**
 * Create an `Archive` from a `S9ApiArchive`.
 *
 * @param s9ApiArchive Square9Api archive object.
 * @param s9ApiViewTabs Square9Api view tab objects.
 * @returns `Archive` object.
 */
export const createArchive = (
  s9ApiArchive: S9ApiArchive,
  s9ApiViewTabs: S9ApiViewTab[] = []
): Archive => ({
  id: s9ApiArchive.Id,
  name: s9ApiArchive.Name,
  children: [],
  parentId: s9ApiArchive.Parent,
  permissions: createPermissions(s9ApiArchive.Permissions),
  fieldIds: s9ApiArchive.FieldIds,
  tableFieldIds: s9ApiArchive.TableFieldIds,
  viewTabs: createViewTabs(s9ApiViewTabs),
  isCheckInCheckOut: (s9ApiArchive.ArchiveProperties & 1) === 1,
  isVersioning: (s9ApiArchive.ArchiveProperties & 4) === 4,
});
