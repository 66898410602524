import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
})
export class LoginButtonComponent {
  /** Optional background color for the button. */
  @Input('background-color')
  backgroundColor = '';
  /** Optional color for button. */
  @Input()
  color = '';
  /** Whether the button should be disabled. */
  @Input()
  disabled: boolean;
  /** Whether the loading state should be displayed. */
  @Input()
  loading: boolean;
}
