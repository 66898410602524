import {
  UploadFile,
  UploadInput,
  UploadOutput,
  UploaderOptions,
} from '@angular-ex/uploader';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserFriendlyError } from 'models';

import { Api } from '../models/api.enum';
import { Uploader } from '../models/uploader';
import { AppConfigQuery } from '../modules/app-config';

import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification.service';

/**
 * An upload event result returned by the `UploadService.onUploadOutput` function.
 */
export interface UploadEventResult {
  /** Indicates if the upload target is currently dragged over. */
  dragOver: boolean;
  /** Indicates that the upload is complete. */
  isDone: boolean;
  /** Indicates that the upload resulted in an error. */
  isError: boolean;
}

/** API Upload Service. */
@Injectable({ providedIn: 'root' })
export class UploadService {
  /** Stores all available uploaders. */
  allUploads: Uploader[] = [];
  /** Observable Uploader options for use in the @angular-ex/uploader components. */
  uploaderOptions$: Observable<UploaderOptions> =
    this.appConfigQuery.concurrentUploads$.pipe(
      map((concurrentUploads) => {
        const uploaderOptions: UploaderOptions = {
          concurrency: concurrentUploads,
        };
        return uploaderOptions;
      })
    );

  constructor(
    private logger: NGXLogger,
    private auth: AuthenticationService,
    private appConfigQuery: AppConfigQuery,
    private notify: NotificationService
  ) {}

  /**
   * Checks if there are any uploads currently in progress.
   *
   * @returns Whether or not any upload is in progress.
   */
  anyUploadsInProgress(): boolean {
    return this.allUploads.some((upload) => upload.inProgress);
  }

  /**
   * Create a new uploader.
   *
   * @param api API to upload the files to.
   * @returns New `Uploader` instance.
   * @throws {Error} If the API type is unknown or unsupported.
   */
  createUploader(api: Api) {
    let url: string;
    switch (api) {
      case Api.viewer:
        url = `${this.appConfigQuery.appConfig.viewerUrl}/api/upload/import`;
        break;
      case Api.square9:
        url = `${this.appConfigQuery.appConfig.apiUrl}/files`;
        break;
      default:
        throw new Error(
          'Attempted to create an uploader for an unknown or unsupported API.'
        );
    }
    const upload = new Uploader(this, url);
    this.allUploads.push(upload);
    return upload;
  }

  /**
   * Remove an `Uploader` registered to the service.
   *
   * @param uploader Uploader object to destroy.
   * @description Since uploaders are created in components that may be created or destroyed, they should be cleaned up on destroy.
   * @example
   * // Implement OnDestroy in the component and invoke the method.
   * ngOnDestroy(): void {
   *   this.uploadService.disposeUploader(this.uploader);
   * }
   */
  destroyUploader(uploader: Uploader) {
    const index = this.allUploads.indexOf(uploader);
    if (index >= 0) {
      this.allUploads.splice(index, 1);
    } else {
      this.logger.warn('Can not dispose of uploader, it was not found.');
    }
  }

  /**
   * Parse the output event triggered by @angular-ex/uploader.
   *
   * @param output An instance of UploadOutput provided by @angular-ex/uploader.
   * @param uploader An instance of Uploader.
   * @returns An UploadEventResult.
   * @throws {Error} If file is not defined when the specified output type requires it.
   */
  onUploadOutput(output: UploadOutput, uploader: Uploader): UploadEventResult {
    this.logger.debug('On upload triggered.', output);
    let dragOver = false;
    let error = false;
    switch (output.type) {
      case 'start':
        this.logger.debug('File upload started.', output);
        break;
      case 'allAddedToQueue':
        this.logger.debug(
          'All files were added to the queue. Starting upload.'
        );
        this.startUpload(uploader);
        break;
      case 'addedToQueue':
        this.addUploadFile(uploader.files, output);
        break;
      case 'uploading':
        this.updateUploadFile(uploader.files, output);
        break;
      case 'removed': {
        // remove file from array when removed
        if (typeof output.file === 'undefined') {
          throw new TypeError('File must be defined.');
        }
        const index = uploader.files.indexOf(output.file);
        uploader.files = uploader.files.slice(index, 1);
        break;
      }
      case 'rejected': {
        const friendlyError: UserFriendlyError = {
          error: undefined,
          description: 'The file was rejected for upload.',
          i18n: 'ERROR_FILE_UPLOAD_FAILED',
        };
        this.notify.error(friendlyError);
        break;
      }
      case 'dragOver':
        dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        dragOver = false;
        break;
      case 'done':
        this.logger.debug('File upload complete.', output);
        if (typeof output.file === 'undefined') {
          throw new TypeError('File must be defined.');
        }
        if (output.file.responseStatus === 406) {
          // Handle infected file response with specific message.
          error = true;
          this.notify.error({
            error: undefined,
            description: `The ${output.file.name} file appears to be infected.`,
            i18n: 'ERROR_FILE_UPLOAD_INFECTED',
          });
        } else if (output.file.responseStatus !== 200) {
          // Handle all other non-successe responses.
          error = true;
          this.notify.error({
            error: undefined,
            description: `The ${output.file.name} file could not be uploaded.`,
            i18n: 'ERROR_FILE_UPLOAD_FAILED',
          });
        }
        break;
    }

    return {
      dragOver,
      isDone: uploader.isDone,
      isError: error,
    };
  }

  /**
   * Checks if an upload is in progress.
   *
   * @param uploader Instance of uploader.
   * @returns Whether or not upload is in progress.
   */
  uploadInProgress(uploader: Uploader): boolean {
    return uploader.files.length > 0 && this.uploadProgress(uploader) < 100;
  }

  /**
   * Gets the current progress for an upload.
   *
   * @param uploader The instance of uploader.
   * @returns Number representing progress.
   */
  uploadProgress(uploader: Uploader): number {
    let progress = 0;
    for (const uploadFile of uploader.files) {
      if (uploadFile.progress) {
        progress += uploadFile.progress.data?.percentage ?? 0;
      }
    }

    progress = progress / uploader.files.length;
    return progress;
  }

  /**
   * Adds an upload file to the list of files.
   *
   * @param uploadFiles An array of UploadFile.
   * @param uploadOutput An instance of UploadOutput.
   */
  private addUploadFile(
    uploadFiles: UploadFile[],
    uploadOutput: UploadOutput
  ): void {
    if (typeof uploadOutput.file === 'undefined') {
      this.logger.debug(
        '[Upload] Add upload file was called without a file.',
        uploadOutput
      );
      return;
    }

    if (uploadOutput.file.size === 0) {
      const error = new UserFriendlyError(
        `Cannot upload the file ${uploadOutput.file.name} because its size is ${uploadOutput.file.size}.`,
        '',
        'FILE_UPLOAD_SKIPPED_EMPTY_FILE',
        { filename: uploadOutput.file.name }
      );
      this.notify.error(error);
      return;
    }

    this.logger.debug('File added to queue.', uploadOutput.file);
    uploadFiles.push(uploadOutput.file);
  }

  /**
   * Gets the index of the file in UploadOutput in the array of UploadFile.
   *
   * @param uploadFiles An array of UploadFile.
   * @param fileUpload An instance of UploadOutput.
   * @returns The index of the file in the UploadFile array.
   */
  private getUploadFileIndex(
    uploadFiles: UploadFile[],
    fileUpload: UploadOutput
  ): number {
    return uploadFiles.findIndex(
      (file) =>
        typeof fileUpload.file !== 'undefined' && file.id === fileUpload.file.id
    );
  }

  private startUpload(uploader: Uploader): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: uploader.url,
      method: 'POST',
      headers: {
        'AUTH-TOKEN': this.auth.user.token,
      },
    };

    uploader.input.emit(event);
  }

  /**
   * Updates the stored UploadFile.
   *
   * @param uploadFiles An array of UploadFile.
   * @param uploadOutput An instance of UploadOutput.
   */
  private updateUploadFile(
    uploadFiles: UploadFile[],
    uploadOutput: UploadOutput
  ) {
    if (typeof uploadOutput.file !== 'undefined') {
      const index = this.getUploadFileIndex(uploadFiles, uploadOutput);
      uploadFiles[index] = uploadOutput.file;
    } else {
      this.logger.debug(
        '[Upload] Update upload file was called without a file.',
        uploadOutput
      );
    }
  }
}
