import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KeyfreeProvider, Words } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  S9ApiKeyfreeWordsDefinition,
  createKeyfreeWordsFromApi,
} from '../models/keyfree';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';
/**
 * Square 9 API Keyfree Service.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiKeyfreeService implements KeyfreeProvider {
  private basePath: string;

  constructor(
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig,
    private http: HttpClient
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.basePath = apiUrl;
    });
  }

  /** @inheritdoc */
  getWords(secureId: string, pageNumber: number): Observable<Words> {
    return this.http
      .post<S9ApiKeyfreeWordsDefinition>(
        `${this.basePath}/keyfree/${secureId}/page/${pageNumber}`,
        {}
      )
      .pipe(map((keyfreeWords) => createKeyfreeWordsFromApi(keyfreeWords)));
  }
}
