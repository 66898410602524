import { AfterContentInit, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

import { ScanUploadRequest, UserFriendlyError } from 'models';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ScanService } from 'src/app/services/scan.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';

/**
 * Current scan operation.
 */
enum CurrentOperation {
  /** A scan is in progress. */
  scanning,
  /** Waiting for user to either continue or finish the scan session. */
  waitingUserInput,
  /** A scan upload is in progress. */
  uploading,
}

/**
 * Scan dialog for handling GSE scans.
 */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-scan-dialog',
  templateUrl: './scan-dialog.component.html',
  styleUrls: ['./scan-dialog.component.scss'],
})
export class ScanDialogComponent implements AfterContentInit {
  /** Current scan operation. */
  currentOperation: CurrentOperation;
  /** Scan session ID. */
  sessionId = '';
  /** Upload request to be sent to GSE upon scan session upload. */
  uploadRequest: ScanUploadRequest;

  constructor(
    private logger: NGXLogger,
    private scanService: ScanService,
    private notificationService: NotificationService,
    private auth: AuthenticationService,
    private appConfigQuery: AppConfigQuery,
    private appQuery: ApplicationQuery,
    private dialogReference: MatDialogReference<ScanDialogComponent>,
    private translate: TranslocoService,
    @Inject(MAT_DIALOG_DATA) private scanTarget: 'archive' | 'inbox'
  ) {
    this.uploadRequest = {
      scanIds: [],
      targetHeaders: {},
      targetUrl:
        this.scanTarget === 'archive'
          ? `${this.appConfigQuery.appConfig.viewerUrl}/api/upload/import`
          : `${this.appConfigQuery.appConfig.apiUrl}/files`,
    };

    if (this.scanTarget === 'inbox') {
      if (typeof this.uploadRequest.targetHeaders === 'undefined') {
        throw new TypeError(
          'TargetHeaders proprty was undefined on the upload request.'
        );
      }
      this.uploadRequest.targetHeaders.Authorization = `Basic ${this.auth.user.authData}`;
    }

    if (
      this.scanTarget === 'archive' &&
      this.appQuery.viewerUseInternalForImport
    ) {
      if (typeof this.uploadRequest.targetHeaders === 'undefined') {
        throw new TypeError(
          'TargetHeaders proprty was undefined on the upload request.'
        );
      }
      this.uploadRequest.targetHeaders.Authorization = `Basic ${this.auth.user.authData}`;
      this.uploadRequest.targetUrl = `${this.appConfigQuery.appConfig.apiUrl}/files`;
    }
  }

  /**
   * Gets the current display message for the dialog.
   *
   * @returns A string with the message displayed in the dialog.
   */
  get displayMessage(): string {
    let message = '';
    switch (this.currentOperation) {
      case CurrentOperation.scanning:
        message = `${this.translate.translate('SCANNING_PAGE')}...`;
        break;
      case CurrentOperation.waitingUserInput:
        message = this.translate.translate('SCAN_COMPLETE_SCAN_ANOTHER_PAGE');
        break;
      case CurrentOperation.uploading:
        message = `${this.translate.translate('UPLOADING_SCANNED_DOCUMENT')}...`;
        break;
    }

    return message;
  }

  /**
   * Determines if progress can be displayed.
   *
   * @returns A boolean indicating if progress can be displayed.
   */
  get showProgress(): boolean {
    return (
      this.currentOperation === CurrentOperation.scanning ||
      this.currentOperation === CurrentOperation.uploading
    );
  }

  /**
   * Determines if scan user actions should display.
   *
   * @returns A boolean indicating if user actions should display.
   */
  get showScanActions(): boolean {
    return this.currentOperation === CurrentOperation.waitingUserInput;
  }

  /**
   * Click event for the no button which will complete the scan session.
   */
  completeScan(): void {
    this.logger.debug('Ending scan session...');
    this.currentOperation = CurrentOperation.uploading;
    this.scanService
      .completeScan(this.sessionId, this.uploadRequest)
      .subscribe({
        next: (scanResult) => {
          this.logger.debug('Scan completed. Closing the dialog.', scanResult);
          this.dialogReference.close(scanResult);
        },
        error: (error: UserFriendlyError) => this.handleError(error),
      });
  }

  /**
   * Click event for the yes button which will start another scan.
   */
  continueScanning(): void {
    this.logger.debug('Starting another scan...');
    this.currentOperation = CurrentOperation.scanning;
    this.scanService.continueScan(this.sessionId);
  }

  ngAfterContentInit(): void {
    this.logger.debug('Running initial scan...');
    this.currentOperation = CurrentOperation.scanning;
    this.scanService
      .scan()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (status) => {
          if (!status) {
            return;
          }
          if (status.progress === 'Completed') {
            this.logger.debug(
              'Scan completed. Prompting to see if another should be started.',
              status
            );
            if (typeof status.sessionId === 'undefined') {
              throw new TypeError('Session Id was not defined.');
            }
            this.sessionId = status.sessionId;
            this.uploadRequest.scanIds.push(status.scanId);
            this.currentOperation = CurrentOperation.waitingUserInput;
          } else if (status.progress === 'ErrorInScan') {
            const error: UserFriendlyError = {
              error: undefined,
              description: `An error occurred in session ${status.sessionId} while scanning to scan ${status.scanId}. `,
              i18n: 'ERROR_SCAN_ERROR',
            };
            this.handleError(error);
          }
        },
        error: (error: UserFriendlyError) => this.handleError(error),
      });
  }

  private handleError(error: UserFriendlyError): void {
    this.logger.error(error);
    this.notificationService.error(error);
    this.dialogReference.close();
  }
}
