import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, finalize, throwError } from 'rxjs';

import { UserFriendlyError } from 'models';

import { GUEST_USERNAME } from '../common/constants';
import { AppConfigQuery } from '../modules/app-config';
import { AuthenticationService } from '../services/authentication.service';

/** Error Interceptor. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private logoutInProgress = false;
  /**
   * Gets if the current route is the login page.
   *
   * @returns A boolean representing if the current route is the login page.
   */
  private get onLoginPage(): boolean {
    return this.router.url.startsWith('/login');
  }
  constructor(
    private authenticationService: AuthenticationService,
    private appConfigQuery: AppConfigQuery,
    private router: Router
  ) {}

  /**
   * Intercepts an HTTP error and creates UserFriendlyError.
   *
   * @param request Current HttpRequest.
   * @param next HttpHandler.
   * @returns An observable HttpEvent.
   * @see UserFriendlyError
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // Handle errors.
      catchError((error) => {
        // TODO: Support host aliases in config since this is so strict (i.e., localhost = myhost.domain = 127.0.0.1, etc.)
        const isApiUrl = request.url.startsWith(
          this.appConfigQuery.appConfig.apiUrl
        );
        const userFriendlyError: UserFriendlyError = new UserFriendlyError(
          error
        );
        switch (error.status) {
          case 0: // No response
            userFriendlyError.i18n = 'HTTP_NORESPONSE_ERR';
            userFriendlyError.description =
              'The server did not respond to the request';
            break;
          case 400:
            if (error.error === 'All licenses are in use.') {
              userFriendlyError.i18n = 'HTTP_ALL_LICENSES_IN_USE_ERR';
              userFriendlyError.description = 'All licenses are in use.';
              this.logoutInProgress = true;
              this.authenticationService
                .logout(true, userFriendlyError)
                .pipe(finalize(() => (this.logoutInProgress = false)))
                .subscribe();
            } else {
              userFriendlyError.i18n = 'HTTP_BADREQUEST_ERR';
              userFriendlyError.description =
                'Bad request HTTP response was intercepted.';
            }
            break;
          case 401: // Unauthorized
          case 403: // Forbidden
            userFriendlyError.i18n = 'HTTP_UNAUTHORIZED_ERR';
            if (this.onLoginPage || !isApiUrl) {
              userFriendlyError.description =
                'Unauthorized HTTP response was intercepted.';
            } else {
              userFriendlyError.description =
                'Unauthorized HTTP response was intercepted. User will be logged out.';
              this.authenticationService.logout(true).subscribe();
            }
            break;
          case 402:
            userFriendlyError.i18n = 'HTTP_EXPIRED_LEASE';
            userFriendlyError.description =
              'Please contact your sales representative.';
            break;
          case 404: // Not found
            userFriendlyError.i18n = 'HTTP_NOTFOUND_ERR';
            userFriendlyError.description = 'HTTP resource was not found.';
            break;
          case 405: // Method Not Allowed
            /*
            Note:
            There is a bit of a long standing issue in our existing Square9Api where a former employee started an
            incorrect pattern with using `Method Not Allowed` as some sort of generic error contrary to its proper use.

            Therefore this switch case includes some special case parsing to deal with transforming some assorted generic
            errors and rejections into more usable errors.
            */

            if (error.error === 'LicenseExpired') {
              return this.licenseExpired(userFriendlyError);
            }

            break;
          case 499: // License Expired
            if (request.url.toLowerCase().includes('inboxes')) {
              error.description = 'License expired.';
              error.i18n = 'HTTP_LICENSEDEXPIRED_ERR';
              break;
            }
            return this.licenseExpired(userFriendlyError);
          case 500: // Internal Server Error
          case 503: // Service Unavailable
            userFriendlyError.i18n = 'HTTP_SERVER_ERR';
            userFriendlyError.description = 'A server error has occurred.';
            break;
          default:
            // Unknown errors.
            userFriendlyError.description =
              error.error?.message || error.statusText || 'Unknown Error';
            userFriendlyError.i18n = 'HTTP_UNKNOWN_ERR';
        }

        return throwError(() => userFriendlyError);
      })
    );
  }

  /**
   * Handle expired license states.
   *
   * @param error The response's error object.
   * @description User will be prompted to logout, if they cancel subsequent requests will continue to error and prompt.
   * @returns An emitted error to the observable.
   */
  private licenseExpired(error: UserFriendlyError) {
    const isGuestUrl = window.location.search
      ?.toLowerCase()
      .includes('user=' + GUEST_USERNAME);
    // Only show confirmation when a logout has not already started, and the URL is not a guest URL.
    // Guest URL will attempt a new login automatically.
    const confirmLogout = !this.logoutInProgress && !isGuestUrl;
    if (
      confirmLogout &&
      confirm(
        'Unable to complete the request, license is no longer valid. Logout?'
      )
    ) {
      this.logoutInProgress = true;
      this.authenticationService
        .logout(true)
        .pipe(finalize(() => (this.logoutInProgress = false)))
        .subscribe();
    }
    error.description = 'License expired.';
    error.i18n = 'HTTP_LICENSEDEXPIRED_ERR';
    return throwError(() => error);
  }
}
