import { AfterViewInit, Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';

import { NotificationService } from 'src/app/services/notification.service';
import { FieldsQuery } from 'src/app/state/fields/fields.query';
import { ListsService } from 'src/app/state/lists/lists.service';

import { DropdownFieldComponent } from '../../field-components/dropdown-field/dropdown-field.component';
import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';
import { FieldCellEditorParameters } from '../field-cell-editor-parameters';

/** Dropdown Cell Editor. */
@Component({
  selector: 'app-dropdown-cell-editor',
  templateUrl: './dropdown-cell-editor.component.html',
  styleUrls: ['./dropdown-cell-editor.component.scss'],
})
export class DropdownCellEditorComponent
  extends FieldCellEditorBaseComponent
  implements AfterViewInit
{
  private editType: 'fullRow' | undefined;
  constructor(
    private logger: NGXLogger,
    private fieldsQuery: FieldsQuery,
    notifications: NotificationService,
    translate: TranslocoService,
    private listsService: ListsService
  ) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  agInit(params: FieldCellEditorParameters): void {
    super.agInit(params);
    this.editType = params.api.getGridOption('editType');
  }

  ngAfterViewInit(): void {
    // window.setTimeout(() => {});
    super.ngAfterViewInit();
    if (this.cellParams.field.list.primary > 0) {
      this.setupDynamicField();
    }

    // If in full row edit we need to listen for changes to this field and search other columns
    // to determine if they depend on the value here.
    if (this.editType !== 'fullRow') {
      this.listenForChanges();
    }
  }

  private getPrimaryFilterEditor(): DropdownCellEditorComponent {
    if (
      typeof this.cellParams.api === 'undefined' ||
      this.cellParams.api === null
    ) {
      throw new TypeError('Grid cell API was not defined.');
    }
    const primaryFilterEditorInstances =
      this.cellParams.api.getCellEditorInstances({
        columns: [`field_${this.cellParams.field.list.primary}`],
      });
    if (primaryFilterEditorInstances.length === 0) {
      const errorMessage = 'Editor for primary field not found';
      this.logger.error(errorMessage, this.cellParams.field.list.primary);
      throw new Error(errorMessage);
    }
    // According to the docs for ag-grid 28+ the getCellEditorInstances() now returns the angular instance.
    // https://ag-grid.com/angular-data-grid/component-cell-editor/#accessing-cell-editor-instances
    return primaryFilterEditorInstances[0] as DropdownCellEditorComponent;
  }

  private getSeconaryFilterEditor(): DropdownCellEditorComponent {
    if (
      typeof this.cellParams.api === 'undefined' ||
      this.cellParams.api === null
    ) {
      throw new TypeError('Grid cell API was not defined.');
    }
    const secondaryEditorInstances = this.cellParams.api.getCellEditorInstances(
      { columns: [`field_${this.cellParams.field.list.secondary}`] }
    );
    if (secondaryEditorInstances.length === 0) {
      const errorMessage = 'Editor for primary field not found';
      this.logger.error(errorMessage, this.cellParams.field.list.secondary);

      throw new Error(errorMessage);
    }
    // According to the docs for ag-grid 28+ the getCellEditorInstances() now returns the angular instance.
    // https://ag-grid.com/angular-data-grid/component-cell-editor/#accessing-cell-editor-instances
    return secondaryEditorInstances[0] as DropdownCellEditorComponent;
  }

  /**
   * Listens to this fields form value change and clears other columns if this field is either the primary or secondary list filter.
   *
   * This is a little heavy handed so it should only be used when there isn't another option such as in table field grids where
   * we do not use row editting and cannot get the other open cell editors.
   */
  private listenForChanges(): void {
    this.fieldComponent.control.valueChanges.subscribe((newValue) => {
      // Don't clear dynamic list fields if the 'new' value emitted here is the same as it is in the cell already.
      if (this.cellParams.value === newValue) return;
      // Loop the columns and clear fields that are dependent on this one.
      for (const column of this.cellParams.api.getAllGridColumns()) {
        const fieldId = Number(column.getColId().split('_')[1]);
        const field = this.fieldsQuery.getField(fieldId);
        if (
          field.list.primary === this.cellParams.field.id ||
          field.list.secondary === this.cellParams.field.id
        ) {
          const rowData = this.cellParams.data;
          rowData[`field_${fieldId}`] = '';
          this.cellParams.node.setData(rowData);
        }
      }
    });
  }

  private setupDynamicField(): void {
    if (this.editType === 'fullRow') {
      const primaryCellEditor = this.getPrimaryFilterEditor();
      const initialPrimaryValue = primaryCellEditor.formControl.value;

      // Ensure second leveldynamic list controls are only enabled if the primary list has a value.
      if (!initialPrimaryValue) {
        this.formControl.disable();
      }

      let initialSecondaryValue = '';
      const primaryValueChanges$ = primaryCellEditor.formControl.valueChanges;
      let secondaryValueChanges$ = of('');
      if (this.cellParams.field.list.secondary > 0) {
        const secondaryValueEditor = this.getSeconaryFilterEditor();
        initialSecondaryValue = secondaryValueEditor.formControl.value;
        // Ensure third level dynamic list controls are only enabled if the primary and secondary fields have values.
        if (!initialSecondaryValue) {
          this.formControl.disable();
        }
        secondaryValueChanges$ = secondaryValueEditor.formControl.valueChanges;
      }
      const dropdownFieldComponent = this
        .fieldComponent as DropdownFieldComponent;
      dropdownFieldComponent.loadDynamicList(
        initialPrimaryValue,
        initialSecondaryValue
      );
      combineLatest([primaryValueChanges$, secondaryValueChanges$]).subscribe(
        ([primaryValue, secondaryValue]) => {
          // Disable this control if primary value is empty or and if secondary value is empty where applicable.
          if (
            !primaryValue ||
            (this.cellParams.field.list.secondary > 0 && !secondaryValue)
          ) {
            this.formControl.disable();
          } else {
            this.formControl.enable();
          }
          dropdownFieldComponent.loadDynamicList(primaryValue, secondaryValue);
        }
      );
    } else {
      // We are in single cell editting mode meaning no other cell editors can be open so don't try to find other cell editors.
      const initialPrimaryValue =
        this.cellParams.data[`field_${this.cellParams.field.list.primary}`];

      const initialSecondaryValue =
        this.cellParams.field.list.secondary > 0
          ? this.cellParams.data[
              `field_${this.cellParams.field.list.secondary}`
            ]
          : '';

      // Disable this control if primary value is empty or and if secondary value is empty where applicable
      if (
        !initialPrimaryValue ||
        (this.cellParams.field.list.secondary > 0 && !initialSecondaryValue)
      ) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }

      // Do not attempt to load the dynamic list if the primary and secondary fields do not have values.
      if (
        initialPrimaryValue &&
        (this.cellParams.field.list.secondary === 0 || initialSecondaryValue)
      ) {
        const dropdownFieldComponent = this
          .fieldComponent as DropdownFieldComponent;
        dropdownFieldComponent.loadDynamicList(
          initialPrimaryValue,
          initialSecondaryValue
        );
      }
    }
  }
}
