<h2 mat-dialog-title>{{ 'INSERT_FROM_INBOX' | transloco | titlecase }}</h2>
<mat-dialog-content>
  <mat-stepper
    (selectionChange)="onStepSelectionChange($event)"
    [orientation]="(useCompactLayout$ | async) ? 'vertical' : 'horizontal'"
    linear
    #stepper
  >
    <mat-step [stepControl]="inboxSelectForm">
      <form [formGroup]="inboxSelectForm">
        <ng-template matStepLabel>{{
          'SELECT_AN_INBOX' | transloco
        }}</ng-template>
        <mat-form-field>
          <mat-label transloco="SELECT_AN_INBOX"></mat-label>
          <mat-select
            formControlName="selectedInbox"
            (selectionChange)="onInboxSelectionChange()"
          >
            <!-- TODO disable inboxes the user does not have view permissions to-->
            <mat-option
              *ngFor="let inbox of inboxes$ | async"
              [value]="inbox"
              >{{ inbox.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </form>
    </mat-step>
    <mat-step [completed]="documentsSelected">
      <ng-template matStepLabel>Select one or more documents</ng-template>
      <div
        [ngClass]="{
          'full-panel-width': currentStepIndex === 1,
          'vertical-stepper-grid-container':
            currentStepIndex === 1 && (useCompactLayout$ | async) === true,
        }"
      >
        <ag-grid-angular
          class="ag-theme-material"
          #inboxGrid
          [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
        <form [formGroup]="insertOptionsForm">
          <mat-checkbox
            formControlName="deleteInboxDocumentOnInsert"
            (change)="showRememberDeleteSelection = true"
            >{{ 'REMOVE_FROM_INBOX' | transloco }}</mat-checkbox
          >
          <span
            style="margin-left: 12px"
            [hidden]="!showRememberDeleteSelection"
          >
            <mat-checkbox formControlName="rememberDeleteOnInsertSelection">{{
              'REMEMBER_MY_CHOICE' | transloco
            }}</mat-checkbox>
          </span>
        </form>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'CANCEL' | transloco }}</button>
  <span class="spacer"></span>
  <button
    mat-button
    *ngIf="!isLastStep"
    [disabled]="!canGoToNext"
    (click)="stepper.next()"
  >
    {{ 'NEXT' | transloco }}
  </button>
  <ng-container *ngIf="isLastStep">
    <button
      [disabled]="appendButtonsDisabled"
      mat-button
      (click)="onClickAppend(true)"
    >
      {{ 'PREPEND' | transloco }}
    </button>
    <button
      [disabled]="appendButtonsDisabled"
      mat-button
      *ngIf="isLastStep"
      (click)="onClickAppend(false)"
    >
      {{ 'APPEND' | transloco }}
    </button>
  </ng-container>
</mat-dialog-actions>
