import { assert, assertExists } from 'common';
import { PDFImage, PDFPage, PDFPageDrawImageOptions, degrees } from 'pdf-lib';

import { ConvertableAnnotation, IRectangularAnnotationBase } from '.';

/**
 * Image annotation.
 */
export class ImageAnnotation
  extends ConvertableAnnotation
  implements IRectangularAnnotationBase
{
  /** @inheritdoc */
  hasFont = false;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    public height: number,
    public width: number,
    /** Image. */
    public image: string,
    /** Image height. */
    public imageHeight: number,
    /** Image width. */
    public imageWidth: number,
    public rotation: number = 0,
    public opacity: number = 1,
    public visible: boolean = true,
    private embeddedImage?: PDFImage
  ) {
    super();
  }

  /**
   * Convert an AtalaImage bitmap hex encoded byte string to a PNG data URL.
   *
   * @param hexString AtalaImage bitmap hex string.
   * @param height Height of image.
   * @param width Width of Image.
   *
   * @returns Data URL.
   */
  static convertAtalaImageToPng(
    hexString: string,
    height: number,
    width: number
  ): string {
    // Create a clamped byte array from the hex string.
    const clampedByteArray = new Uint8ClampedArray(hexString.length / 2);
    // Red and blue are transposed by Atalasoft, fix it.
    for (let index = 0, k = 0; index < hexString.length; index += 8) {
      const getIntFromOffset = (offset: number): number =>
        Number.parseInt(
          hexString.slice(index + offset, index + offset + 2),
          16
        );
      clampedByteArray[k] /* Red. */ = getIntFromOffset(4); // From blue.
      clampedByteArray[k + 1] /* Green. */ = getIntFromOffset(2); // From green.
      clampedByteArray[k + 2] /* Blue. */ = getIntFromOffset(0); // From red.
      clampedByteArray[k + 3] /* Alpha. */ = getIntFromOffset(6); // From alpha.
      // Advance by colorspace.
      k += 4;
    }

    // Create a canvas to draw to, to the provided dimensions.
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.height = height;
    canvas.width = width;
    // Get the 2D context.
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    // Create a bitmap image using the byte array, wrapped at the known width.
    const imageData = new ImageData(clampedByteArray, width);
    context.putImageData(imageData, 0, 0);
    // Draw the canvas to a data URL.
    const fromCanvas = canvas.toDataURL('image/png', 0.5);
    return fromCanvas;
  }

  /**
   * Set the image property of the annotation to a PNG created from an
   * AtalaImage's hex string encoding.
   *
   * @param hexString AtalaImage hex string.
   */
  createPngFromAtalaImage(hexString: string): void {
    this.image = ImageAnnotation.convertAtalaImageToPng(
      hexString,
      this.imageHeight,
      this.imageWidth
    );
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    const options = this.toPdfImageOptions();
    if (scalePixelsToPoints) this.convertPixelsToPoints(options);
    this.adjustForRotation(page, options);
    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    assertExists(
      this.embeddedImage,
      'No image was embedded for this annotaion.'
    );
    page.drawImage(this.embeddedImage, options);
  }

  /**
   * Set the image embedded for this annotation.
   *
   * @param image Embedded image.
   */
  embedImage(image: PDFImage) {
    this.embeddedImage = image;
  }

  /**
   * Convert the image to a PNG, and get the data URL.
   *
   * @returns Data URL of PNG.
   */
  toPng() {
    return this.image;
  }

  private adjustForRotation(page: PDFPage, options: PDFPageDrawImageOptions) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.width);
    assertExists(options.height);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    options.rotate = degrees(
      ((options.rotate?.angle ?? 0) + pageRotation.angle) % 360
    );
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        // The x value requires adding the image height to correct corner.
        options.x = options.y + options.height;
        // The y value requires subtracting page height, and the object height
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.height;
        break;
      case 180:
        // Subtract x value from page width.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height, then the height x2 to offset corner.
        options.y = page.getHeight() - options.y - options.height * 2;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.x = page.getWidth() - options.y - options.height;
        // Subtract the object height from the original x coordinate to offset.
        options.y = xOrig - options.height;
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Convert the annotation to PDF element drawing options as image.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfImageOptions(): PDFPageDrawImageOptions {
    const options: PDFPageDrawImageOptions = {
      x: this.x,
      y: this.y,
      height: this.height,
      width: this.width,
      rotate: degrees(this.rotation * -1),
    };
    return options;
  }
}
