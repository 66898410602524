import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

const createInitialState = () => ({});

/** Date formats dictionary. */
export type DateFormats = { [dotnetFormat: string]: string };

/** Date Formats Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'DateFormats' })
export class DateFormatsStore extends Store<DateFormats> {
  constructor() {
    super(createInitialState());
  }
}
