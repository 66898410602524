import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CSVImportJob,
  CSVImportJobResult,
  CSVImportMappings,
  CSVImportProvider,
  CreateCSVJobRequest,
  JobFileUploadRequest,
} from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  S9APICSVImportJob,
  S9APICSVimportJobResult,
  S9ApiCSVImportMappings,
  createCSVImportJobFromApi,
  createCSVImportMappingsFromApi,
  createCSVImportResultFromApi,
} from '../models/csv-import';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** CSV Import Service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiCSVImportService implements CSVImportProvider {
  private basePath: string;

  constructor(
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig,
    private http: HttpClient
  ) {
    this.config.apiUrl$.subscribe(
      (apiUrl) => (this.basePath = `${apiUrl}/csvimport`)
    );
  }

  /** @inheritdoc */
  cancelJob(jobId: string): Observable<void> {
    return this.http.put<void>(
      `${this.basePath}/cancel`,
      {},
      {
        params: new HttpParams().set('jobId', jobId),
      }
    );
  }

  /** @inheritdoc */
  deleteJob(jobId: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}`, {
      params: new HttpParams().set('jobId', jobId),
    });
  }

  /** @inheritdoc */
  downloadJobResult(jobId: string): Observable<void> {
    return this.http
      .get<any>(`${this.basePath}/resultpackage`, {
        params: new HttpParams().set('jobId', jobId),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((response) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: dataType,
            })
          );
          document.body.append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        })
      );
  }

  /** @inheritdoc */
  getCreateJobRequest(
    databaseId: number,
    archiveId: number,
    columnDelimiter: string,
    stringDelimiter: string,
    containsColumnHeaders: boolean,
    token: string
  ): CreateCSVJobRequest {
    const httpParameters = new HttpParams()
      .set('databaseId', databaseId.toString())
      .set('archiveId', archiveId.toString())
      .set('columnDelimiter', columnDelimiter)
      .set('stringDelimiter', stringDelimiter)
      .set('containsColumnHeaders', containsColumnHeaders.toString());
    return {
      url: `${this.basePath}?${httpParameters.toString()}`,
      method: 'POST',
      headers: {
        'AUTH-TOKEN': token,
      },
    };
  }

  /** @inheritdoc */
  getJob(jobId: string): Observable<CSVImportJob> {
    return this.http
      .get<S9APICSVImportJob>(`${this.basePath}/status`, {
        params: new HttpParams().set('jobId', jobId),
      })
      .pipe(map((apiJob) => createCSVImportJobFromApi(apiJob)));
  }

  /** @inheritdoc */
  getJobColumnMappings(jobId: string): Observable<CSVImportMappings> {
    return this.http
      .get<S9ApiCSVImportMappings>(`${this.basePath}/columnmappings`, {
        params: new HttpParams().set('jobId', jobId),
      })
      .pipe(
        map((apiColumnMappings) =>
          createCSVImportMappingsFromApi(apiColumnMappings)
        )
      );
  }

  /** @inheritdoc */
  getJobFileUploadRequest(jobId: string, token: string): JobFileUploadRequest {
    const httpParameters = new HttpParams().set('jobId', jobId);
    return {
      url: `${this.basePath}?${httpParameters.toString()}`,
      method: 'POST',
      headers: {
        'AUTH-TOKEN': token,
      },
    };
  }

  /** @inheritdoc */
  getJobResult(jobId: string): Observable<CSVImportJobResult> {
    return this.http
      .get<S9APICSVimportJobResult>(`${this.basePath}/result`, {
        params: new HttpParams().set('jobId', jobId),
      })
      .pipe(map((apiJobResult) => createCSVImportResultFromApi(apiJobResult)));
  }

  /** @inheritdoc */
  getJobs(): Observable<CSVImportJob[]> {
    return this.http
      .get<S9APICSVImportJob[]>(`${this.basePath}`)
      .pipe(map((jobs) => jobs.map(createCSVImportJobFromApi)));
  }

  /** @inheritdoc */
  startImport(
    jobId: string,
    columnMappings: CSVImportMappings
  ): Observable<void> {
    return this.http.put<void>(`${this.basePath}/start`, columnMappings, {
      params: new HttpParams().set('jobId', jobId),
    });
  }
}
