import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { assertTypeByKey } from 'common';
import {
  InboxIndexToArchiveSession,
  InboxSession,
  Session,
  ViewerSessionProvider,
} from 'models';
import { Observable, map } from 'rxjs';

import { createS9ApiInboxSession, createS9ApiSession } from '../models';
import { createS9ApiInboxIndexToArchiveSession } from '../models/inbox-index-to-archive-session';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API Viewer Session http access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiSessionService implements ViewerSessionProvider {
  private basePath: string;
  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe(
      (apiUrl) => (this.basePath = `${apiUrl}/session`)
    );
  }

  /** @inheritdoc */
  createInboxSession(session: InboxSession): Observable<string> {
    const apiSession = createS9ApiInboxSession(session);
    return this.http.post(`${this.basePath}`, apiSession).pipe(this.mapToId());
  }

  /** @inheritdoc */
  createIndexFromInboxToArchiveSession(
    session: InboxIndexToArchiveSession
  ): Observable<string> {
    const apiSession = createS9ApiInboxIndexToArchiveSession(session);
    return this.http.post(`${this.basePath}`, apiSession).pipe(this.mapToId());
  }

  /** @inheritdoc */
  createSession(session: Session): Observable<string> {
    const apiSession = createS9ApiSession(session);
    return this.http.post(`${this.basePath}`, apiSession).pipe(this.mapToId());
  }

  /**
   * Maps create session response to a session Id.
   *
   * @returns An observable session Id.
   */
  private mapToId() {
    return map((s: any) => {
      /** API response object. */
      interface Response {
        /** Session Identifier. */
        _id: string;
      }

      assertTypeByKey<Response>(s, '_id', 'string');
      return s._id;
    });
  }
}
