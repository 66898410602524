import { SearchPrompt } from './search';
import {
  ArchiveSessionDocument,
  ImportArchiveSessionDocument,
  InboxSessionDocument,
} from './session-document';

/**
 * Base Session.
 */
interface BaseSession {
  /** Database ID. */
  database: number;
}

/**
 * GlobalSearch Archive Session.
 */
export interface ArchiveSession extends BaseSession {
  /** An array of Archive Session Document objects. */
  documents: ArchiveSessionDocument[];
  /** Search criteria used to obtain this document. */
  searchCriteria?: { [key: number]: string };
  /** Search ID. */
  searchId: number;
}

/**
 * GlobalSearch Archive Import Session.
 */
export interface ArchiveImportSession extends BaseSession {
  /** An array of Import Archive Session Document ojbects. */
  documents: ImportArchiveSessionDocument[];
}

/**
 * GlobalSearch Inbox Session.
 */
export interface InboxSession extends BaseSession {
  /**
   * An array of Inbox Session Document objects.
   */
  documents: InboxSessionDocument[];
}

/**
 * Session.
 */
export declare type Session =
  | ArchiveSession
  | ArchiveImportSession
  | InboxSession;

/**
 * Create a criteria dictionary from an array of search prompts.
 *
 * @param searchPrompts Search prompts.
 * @returns A dictionary of promptId: promptValue.
 */
export const createSessionSearchCriteria = (
  searchPrompts: SearchPrompt[]
): { [key: number]: string } => {
  const criteria: { [key: number]: string } = {};
  for (const prompt of searchPrompts) {
    criteria[prompt.id] = prompt.value;
  }

  return criteria;
};
