import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { NGXLogger } from 'ngx-logger';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';

/** Copy link to clipboard, icon button. */
@Component({
  selector: 'app-copy-link-button',
  templateUrl: './copy-link-button.component.html',
  styleUrls: ['./copy-link-button.component.scss'],
})
export class CopyLinkButtonComponent {
  /** Include instance in link. */
  @Input()
  includeInstance = true;
  /** Include user in link. */
  @Input()
  includeUser = false;
  /** Position for matTooltip. */
  @Input()
  matTooltipPosition: TooltipPosition = 'below';

  constructor(
    private logger: NGXLogger,
    private notify: NotificationService,
    private clipboard: Clipboard,
    private auth: AuthenticationService,
    private app: ApplicationQuery
  ) {}

  /** Event handler for click of copy link to clipboard button. */
  onClickCopyLinkToClipboard() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (this.includeUser) {
      params.set('user', this.auth.user.name);
    } else {
      params.delete('user');
      // If the user is not included, and the link consumer is not logged in,
      // then the returnUrl should get completed by the guard, and the user
      // shown a login before beign directed to the link.
    }
    if (this.includeInstance) {
      params.set('instance', this.app.instanceUrl);
    } else {
      params.delete('instance');
    }
    url.search = params.toString();
    const urlString = url.toString();
    if (this.clipboard.copy(urlString)) {
      this.notify.success('LINK_COPIED_TO_CLIPBOARD');
      this.logger.debug('Link copied to clipboard:', urlString);
    } else {
      this.notify.error('LINK_FAILED_TO_CLIPBOARD');
      this.logger.error('Link failed to copy to clipboard:', urlString);
    }
  }
}
