<!-- todo make history doc selection work -->
<app-right-sidebar mode="archive" [gridApi]="gridApi">
  <app-task-actions-toolbar
    [actionMenuComponent]="actionMenuComponent"
    (editModeEnabledChange)="onToggleEdit($event)"
    (openSelectedDocuments)="onOpenDocuments($event)"
    (refreshSearch)="onRefreshSearch()"
  ></app-task-actions-toolbar>
  <div class="view-container">
    <ag-grid-angular
      #archiveGrid
      [ngStyle]="gridStyle"
      class="ag-theme-material"
      [gridOptions]="gridOptions"
      (contextmenu)="onContextMenuClick($event)"
    >
    </ag-grid-angular>
    <app-search-result-paginator
      [page]="currentPage"
      [result-count]="resultCount"
      (pageChange)="onPageChange($event)"
    ></app-search-result-paginator>
    <app-table-field-grid
      *ngIf="showTableFieldGrid"
      [archiveId]="archiveId"
      [editModeEnabled]="editModeEnabled"
    ></app-table-field-grid>
  </div>
</app-right-sidebar>

<app-task-action-menu
  #actionMenuComponent
  (openSelectedDocuments)="onOpenDocuments($event)"
  (refreshSearch)="onRefreshSearch()"
></app-task-action-menu>
