import { Store } from '@datorama/akita';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, finalize, skip, tap } from 'rxjs';

export interface SyncStateOptions {
  /** Channel name. Defaults to `${store.storeName}@store'. */
  channel?: string;
  /** Function run to determine if the sync state listeners should be configured. */
  runGuard?: () => boolean;
}

/**
 * Starts syncing the state between windows and tabs using the broadcast API.
 *
 * This function is modeled off an open source project built for elf which can be found at
 * https://github.com/RicardoJBarrios/elf-sync-state.
 *
 * @param store Store.
 * @param options Optional sync state options.
 * @returns The broadcast channel being used or undefined if the channel wasn't created.
 */
export const syncState = <T>(
  store: Store<T>,
  options?: SyncStateOptions
): BroadcastChannel | undefined => {
  const channelName = options?.channel ?? `${store.storeName}@store`;
  const runGuard =
    typeof options?.runGuard === 'function'
      ? options.runGuard
      : () =>
          typeof window !== 'undefined' &&
          typeof window.BroadcastChannel !== 'undefined';

  if (!runGuard()) {
    return;
  }

  const stateChannel = new BroadcastChannel(channelName);
  // Determines if a message should be posted to the channel.
  // This prevents an infinite loop of updates to the store and subsequent posts to the channel.
  let isPostable = true;

  // Listens to the channel for messages and updates the store.
  stateChannel.addEventListener('message', (event: MessageEvent<T>) => {
    const data = event.data;
    isPostable = false;
    store.update((state) => ({ ...state, ...data }));
  });

  // Listens to the store and posts updated data to the channel.
  store
    ._select((state) => state)
    .pipe(
      skip(1),
      distinctUntilChanged(isEqual),
      tap((data: T) => {
        if (isPostable) {
          stateChannel.postMessage(data);
        } else {
          isPostable = true;
        }
      }),
      finalize(() => stateChannel.close())
    )
    .subscribe();

  return stateChannel;
};
