import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Search } from 'models';

export interface SearchesState
  extends EntityState<Search, number>,
    ActiveState<number> {}

/**
 * Searches state store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'searches', resettable: true })
export class SearchesStore extends EntityStore<SearchesState> {
  constructor() {
    super();
  }
}
