@if (archive().isVersioning) {
  @if (documentRevisionsLoading()) {
    <mat-spinner diameter="24" color="accent"></mat-spinner>
  } @else {
    <button
      mat-icon-button
      [disabled]="!hasPermissionToViewRevisions()"
      [color]="showingPreviousRevision() ? 'warn' : undefined"
      [matTooltip]="'VIEWING_REVISION_IN_READONLY_MODE' | transloco"
      [matTooltipDisabled]="viewingCurrentRevision()"
      [matMenuTriggerFor]="revisionsMenu"
      (click)="onClickRevisionMenu()"
    >
      <mat-icon
        [matBadge]="badgeLabel()"
        [matBadgeColor]="badgeColor()"
        matBadgeSize="small"
        matBadgeOverlap="true"
        >history</mat-icon
      >
    </button>
  }
}
<mat-menu #revisionsMenu="matMenu">
  <button
    mat-menu-item
    [disabled]="viewingCurrentRevision()"
    (click)="onClickGoToCurrentRevision()"
  >
    Current version
  </button>
  <button
    mat-menu-item
    [disabled]="revision.version === activeVersion()"
    *ngFor="let revision of documentRevisions()"
    mat-menu-item
    (click)="onClickRevision(revision)"
  >
    <span>{{ revision.version }}</span>
  </button>
</mat-menu>
