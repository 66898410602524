<mat-dialog-content>
  <div class="logo-container">
    <app-logo-svg logo-size="50" primary-color-light="#000"></app-logo-svg>
    <div class="logo-name-container">
      <app-name-svg logo-text-color-light="#000"></app-name-svg>
      <span class="version" *ngIf="aboutApplication">
        {{ aboutApplication.buildIdentifier }}
      </span>
    </div>
  </div>

  <div class="about-contents">
    <p>{{ 'SERVER_VERSION' | transloco }}: {{ apiVersion }}</p>
    <p>Copyright &copy; {{ year }} by Square 9 Softworks</p>
    <div>
      <button mat-button (click)="onClickEula()">
        {{ 'END_USER_LICENSE_AGREEMENT' | transloco }}
      </button>
    </div>
    <div>
      <button mat-button (click)="onClickLicenses()">
        {{ '3RD_PARTY_LICENSES' | transloco }}
      </button>
    </div>

    <ng-container
      *ngIf="
        aboutApplication &&
        aboutApplication.changelog &&
        aboutApplication.changelog.length > 0
      "
    >
      <h3 transloco="RECENT_CHANGES"></h3>
      <ul>
        <li *ngFor="let change of aboutApplication.changelog">{{ change }}</li>
      </ul>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <span class="spacer"></span>
  <button mat-flat-button mat-dialog-close cdkFocusInitial>
    {{ 'CLOSE' | transloco }}
  </button>
</mat-dialog-actions>
