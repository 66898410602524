/**
 * Assert a condition is true.
 *
 * @param condition Condition to assert.
 * @param message Message to return in error.
 * @throws {AssertionError} If condition is false.
 */
export const assert: (
  condition: boolean,
  message?: string
) => asserts condition = (
  condition: boolean,
  message?: string
): asserts condition => {
  if (!condition) throw new AssertionError(message);
};

/**
 * Assert a value exists.
 *
 * The value is not null or undefined.
 *
 * @param value Value to type narrow.
 * @param message Message to return in error.
 * @throws {AssertionError} If condition is false.
 */
export const assertExists: <T>(
  value: T,
  message?: string
) => asserts value is NonNullable<T> = <T>(
  value: T,
  message?: string
): asserts value is NonNullable<T> => {
  message = message ?? `Value "${value}" must exist (not null or undefined).`;
  if (value === null || typeof value === 'undefined')
    throw new AssertionError(message);
};

/**
 * Assert an object has a specific property.
 *
 * @param obj Object to test.
 * @param property Property key.
 * @param propertyType Type of property.
 * @param message Message to return in error. Defaults to `Response must be an object with an "${property}" property of type "${propertyType}".`.
 * @throws {AssertionError} If the value is not an object or the property is not avaialble.
 */
export const assertTypeByKey: <T>(
  // biome-ignore lint/suspicious/noExplicitAny: Purpose of function is to define types.
  obj: any,
  property: string,
  propertyType:
    | 'undefined'
    | 'object'
    | 'boolean'
    | 'number'
    | 'bigint'
    | 'string'
    | 'symbol'
    | 'function',
  message?: string
) => asserts obj is T = <T>(
  // biome-ignore lint/suspicious/noExplicitAny: Purpose of function is to define types.
  obj: any,
  property: string,
  propertyType:
    | 'undefined'
    | 'object'
    | 'boolean'
    | 'number'
    | 'bigint'
    | 'string'
    | 'symbol'
    | 'function',
  message?: string
): asserts obj is T => {
  const validTypes = [
    'undefined',
    'object',
    'boolean',
    'number',
    'bigint',
    'string',
    'symbol',
    'function',
  ];
  if (!validTypes.includes(propertyType)) {
    throw new TypeError(`"${propertyType}" is not a valid type.`);
  }
  message =
    message ??
    `Value must be an object with an "${property}" property of type "${propertyType}".`;
  if (
    typeof obj !== 'object' ||
    !(obj as object).hasOwnProperty(property) ||
    typeof obj[property] !== propertyType
  )
    throw new Error(message);
};

/**
 * Error thrown by assertion failure.
 */
export class AssertionError extends Error {
  constructor(message: string = 'Assertion failed.') {
    super(message);
  }
}
