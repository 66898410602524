import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerComponent as NgPdfViewerComponent } from 'ng2-pdf-viewer';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

import { assertExists } from 'common';

import { FindInFileDialogComponent } from '../components/find-in-file-dialog/find-in-file-dialog.component';

/**
 * PDF Viewer Service.
 *
 * Currently this library supports two PDF viewer components.
 *
 * @see https://github.com/kariudo/ng2-pdfjs-viewer
 * @see https://github.com/VadimDez/ng2-pdf-viewer
 *
 * The former is the default, and more rich viewer. The latter is considered
 * "basic", and requires implementing behaviors manually. Some methods here are
 * only required for this mode. The basic mode support has been left as an
 * option to support possible future PDF display needs that we do not want all
 * of the PDF.js rich controls to be included on.
 *
 * The richer PDF.js wrapping viewer tools, default, provide searching and
 * thumbnails etc. without us needing to implement those manually. We just have
 * to feed it the PDF bytes and wrap onto it as needed.
 */
@Injectable()
export class PdfViewerService {
  /** Page count. */
  pageCount = 1;
  /** Current page number. */
  pageNumber = 1;
  /** Text to search for. */
  searchText = '';
  /** Observable of the PDF URL to display. */
  url$: Observable<string>;
  /** Zoom scale of the viewer (1=100%).*/
  zoomScale = 1;

  /** PDF Component. */
  private pdfComponent: NgPdfViewerComponent;

  /** URL behavior subject source. */
  private urlSource = new BehaviorSubject<string>('');

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog
  ) {
    this.url$ = this.urlSource.asObservable();
  }

  /**
   *  PDF URL to display.
   *
   * @returns URL.
   */
  get url(): string {
    return this.urlSource.value;
  }

  /**
   * Set the PDF view url.
   *
   * @param url New PDF url.
   */
  set url(url: string) {
    this.logger.debug('Set pdf view url:', url);
    this.urlSource.next(url ?? '');
  }

  /**
   * Prompt the user for search input.
   */
  promptSearch(): void {
    assertExists(this.pdfComponent);
    this.logger.debug('Prompting user for search criteria.');
    this.dialog
      .open(FindInFileDialogComponent, {
        width: '300px',
        data: {
          searchText: this.searchText,
        },
      })
      .afterClosed()
      .subscribe((result: string) => {
        this.searchText = result;
        this.search(this.searchText);
        this.logger.debug(
          this.searchText === ''
            ? 'Search cancelled.'
            : `Searching for: ${this.searchText}`
        );
      });
  }

  /**
   * Register this instances PDF viewer component.
   *
   * Must be called before the service can be used.
   *
   * @param pdfComponent PDF component.
   */
  registerPdfComponent(pdfComponent: NgPdfViewerComponent): void {
    this.logger.debug('Registered PDF component.');
    this.pdfComponent = pdfComponent;
  }

  /**
   * Search for text in the open PDF.
   *
   * @param stringToSearch Text to search for.
   */
  search(stringToSearch: string) {
    assertExists(this.pdfComponent);
    this.pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch,
      type: 'again',
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
    });
  }

  /**
   * Decrease the zoom scale of the document.
   */
  zoomDecrease(): void {
    if (this.zoomScale <= 0.1) return;
    this.zoomScale *= 0.75;
    this.logger.debug('PDF zoom decreased to:', this.zoomScale);
  }

  /**
   * Increase the zoom scale of the document.
   */
  zoomIncrease(): void {
    this.zoomScale *= 1.25;
    this.logger.debug('PDF zoom increased to:', this.zoomScale);
  }
}
