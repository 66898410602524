import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { List, ListProvider } from 'models';
import { LIST_PROVIDER } from 'src/app/common/tokens';

import { DatabasesQuery } from '../databases/databases.query';

import { ListsQuery } from './lists.query';
import { ListsStore } from './lists.store';

/** List Service. */
@Injectable({ providedIn: 'root' })
export class ListsService {
  constructor(
    private listsStore: ListsStore,
    private listsQuery: ListsQuery,
    private databasesQuery: DatabasesQuery,
    @Inject(LIST_PROVIDER) private listProvider: ListProvider
  ) {
    this.reset();
  }

  /**
   * Gets a list ID from the cache or from the API.
   *
   * @param listId List ID.
   * @returns A list.
   */
  get(listId: number): Observable<List> {
    const value = this.listsQuery.getEntity(listId);
    if (value) {
      return of(value);
    }

    return this.listProvider
      .getList(this.databasesQuery.activeId, listId)
      .pipe(tap((l) => this.listsStore.add(l)));
  }

  /**
   * Get a dynamic list.
   *
   * @param fieldId Field ID.
   * @param filter1 Primary field filter.
   * @param filter2 Secondary field filter.
   * @returns A list.
   */
  getDynamicList(
    fieldId: number,
    filter1: string,
    filter2: string
  ): Observable<List> {
    return this.listProvider.getDynamicList(
      this.databasesQuery.activeId,
      fieldId,
      filter1,
      filter2
    );
  }

  /**
   * Resets the list store.
   */
  reset(): void {
    this.listsStore.reset();
  }
}
