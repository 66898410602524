import { Injectable } from '@angular/core';
import { ColumnState } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

import { Dictionary } from 'models';
import { ArchiveGridData, InboxGridData } from 'src/app/models';

import { GridStatesQuery } from './grid-states.query';
import { GridStateStore } from './grid-states.store';

/**
 * Grid settings service.
 */
@Injectable({ providedIn: 'root' })
export class GridSettingsService {
  private archiveGridDataDictionary: Dictionary<ArchiveGridData> = {};
  private inboxGridDataDictionary: Dictionary<InboxGridData> = {};

  constructor(
    private logger: NGXLogger,
    private gridStatesQuery: GridStatesQuery,
    private store: GridStateStore
  ) {}

  /**
   * Creates an instance of archive grid data.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @returns An instance of archive grid data.
   */
  createArchiveGridData(
    databaseId: number,
    archiveId: number
  ): ArchiveGridData {
    const archiveGridData = new ArchiveGridData(
      databaseId,
      archiveId,
      this.logger,
      this.gridStatesQuery,
      this
    );

    this.archiveGridDataDictionary[archiveGridData.id] = archiveGridData;
    return this.archiveGridDataDictionary[archiveGridData.id];
  }

  /**
   * Creates an instance of inbox grid data.
   *
   * @param inboxId Inbox Id.
   * @returns An instance of inbox grid data.
   */
  createInboxGridData(inboxId: number): InboxGridData {
    const gridData = new InboxGridData(
      inboxId,
      this.logger,
      this.gridStatesQuery,
      this
    );

    this.inboxGridDataDictionary[gridData.id] = gridData;
    return this.inboxGridDataDictionary[gridData.id];
  }

  /**
   * Get an existing instance of archive grid data.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @returns The stored instance of archive grid data.
   * @throws {Error} If no grid data for the database and archive.
   */
  getArchiveGridData(databaseId: number, archiveId: number): ArchiveGridData {
    const id = `${databaseId}_${archiveId}`;
    if (!this.archiveGridDataDictionary[id]) {
      throw new Error(`No grid data was found with the id ${id}`);
    }

    return this.archiveGridDataDictionary[id];
  }

  /**
   * Gets an existing instance of inbox grid data.
   *
   * @param inboxId Inbox Id.
   * @returns An instance of inbox grid data.
   * @throws {Error} If no grid data for the inbox.
   */
  getInboxGridData(inboxId: number): InboxGridData {
    const id = `inbox_${inboxId}`;
    if (!this.inboxGridDataDictionary[id]) {
      throw new Error(`No grid data was found with the id ${id}`);
    }

    return this.inboxGridDataDictionary[id];
  }

  /**
   * Gets an existing instance of archive grid data or creates one if it doesn't exist.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @returns An instance of archive grid data.
   */
  getOrCreateArchiveGridData(
    databaseId: number,
    archiveId: number
  ): ArchiveGridData {
    try {
      return this.getArchiveGridData(databaseId, archiveId);
    } catch {
      return this.createArchiveGridData(databaseId, archiveId);
    }
  }

  /**
   * Gets an existing instance of inbox grid data or creates one if it doesn't exist.
   *
   * @param inboxId Inbox Id.
   * @returns An instance of inbox grid data.
   */
  getOrCreateInboxgridData(inboxId: number): InboxGridData {
    try {
      return this.getInboxGridData(inboxId);
    } catch {
      return this.createInboxGridData(inboxId);
    }
  }

  /**
   * Emit the gridRefresh event on an archive grid.
   *
   * Does nothing if a grid for the archive was not created yet.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   */
  refreshArchiveGrid(databaseId: number, archiveId: number): void {
    try {
      const gridData = this.getArchiveGridData(databaseId, archiveId);
      // If we get here it means there was a grid so refresh it.
      gridData.refreshGrid();
    } catch {
      // If there is no grid an error is thrown but we don't need it to refresh.
      this.logger.debug(
        `Refresh archive grid was requested on archive ${archiveId} but a grid for it does not exist. No action will be taken.`
      );
    }
  }

  /**
   * Emit the gridRefresh event on an inbox grid.
   *
   * @param inboxId Inbox Id.
   */
  refreshInboxGrid(inboxId: number): void {
    try {
      const gridData = this.getInboxGridData(inboxId);
      // If we get here it means there was a grid so refresh it.
      gridData.refreshGrid();
    } catch {
      // If there is no grid an error is thrown but we don't need it to refresh.
      this.logger.debug(
        `Refresh inbox grid was requested on inbox ${inboxId} but a grid for it does not exist. No action will be taken.`
      );
    }
  }

  /**
   * Save the grid column state.
   *
   * @param id Unique ID for the grid settings.
   * @param newColumnStates Column states.
   */
  updateActiveColumnState(id: string, newColumnStates: ColumnState[]): void {
    this.store.upsert(
      id,
      { columnStates: newColumnStates },
      (newId, newState) => ({ id: newId, ...newState })
    );
  }

  /**
   * Save the column states for the archive.
   *
   * @param databaseId Database ID.
   * @param archiveId ArchiveId.
   * @param newColumnStates Column states.
   */
  updateArchiveColumnState(
    databaseId: number,
    archiveId: number,
    newColumnStates: ColumnState[]
  ): void {
    this.updateActiveColumnState(`${databaseId}_${archiveId}`, newColumnStates);
  }
}
