import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';

import { AdvancedLink, AdvancedLinkProvider } from 'models';

import { ADVANCED_LINK_PROVIDER } from '../../common/tokens';

import { AdvancedLinksStore } from './advanced-links.store';

/**
 * Advanced Link Service.
 */
@Injectable({ providedIn: 'root' })
export class AdvancedLinksService {
  constructor(
    private advancedLinksStore: AdvancedLinksStore,
    @Inject(ADVANCED_LINK_PROVIDER)
    private advancedLinkProvider: AdvancedLinkProvider
  ) {}

  /**
   * Add a new advanced link to the store.
   *
   * @param advancedLink Advanced Link.
   */
  add(advancedLink: AdvancedLink) {
    this.advancedLinksStore.add(advancedLink);
  }
  /**
   * Refresh the list of available advanced links.
   *
   * @param databaseId Database Id.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }

    this.advancedLinkProvider
      .getAll(databaseId)
      .pipe(setLoading(this.advancedLinksStore))
      .subscribe((advancedLinks) => {
        this.advancedLinksStore.set(advancedLinks);
      });
  }

  /**
   * Remove an advanced link from the store.
   *
   * @param id Id.
   */
  remove(id: number) {
    this.advancedLinksStore.remove(id);
  }

  /**
   * Update Advanced Link.
   *
   * @param id Id.
   * @param advancedLink Advanced Link.
   */
  update(id: number, advancedLink: Partial<AdvancedLink>) {
    this.advancedLinksStore.update(id, advancedLink);
  }
}
