<mat-drawer-container autosize="true">
  <mat-drawer
    #drawer
    position="end"
    [mode]="(drawerMode$ | async) ?? 'side'"
    [opened]="sidebarOpen$ | async"
    (closed)="onClosed()"
    fixedInViewport
    [ngClass]="{ 'pdf-view': isPdfViewSelected }"
    mwlResizable
    [ngStyle]="resizeStyle"
    (resizing)="onResize($event)"
    (resizeStart)="onResizeStart()"
    (resizeEnd)="onResizeEnd($event)"
    (window:resize)="onWindowResize($event)"
    [validateResize]="onResizeValidate"
    [enableGhostResize]="false"
  >
    <div
      class="resize-handle-left"
      [ngClass]="{ 'full-width-resize-overlay': resizeInProgress }"
      mwlResizeHandle
      [resizeEdges]="{ left: true }"
      (dblclick)="onResizeHandleDoubleClick()"
    ></div>
    <mat-tab-group
      #tabs
      color="accent"
      mat-align-tabs="center"
      class="mat-tab-fill-height"
      [selectedIndex]="selectedTabIndex$ | async"
      (selectedIndexChange)="onTabChange($event)"
    >
      <mat-tab aria-label="Grid Settings Tab">
        <ng-template mat-tab-label>
          <mat-icon [matTooltip]="'GRID_SETTINGS' | transloco"
            >settings</mat-icon
          >
        </ng-template>
        <ng-template matTabContent>
          <app-grid-settings [gridApi]="gridApi"></app-grid-settings>
        </ng-template>
      </mat-tab>
      <mat-tab
        aria-label="PDF Preview Tab"
        [disabled]="isPdfViewAvailable === false"
      >
        <ng-template mat-tab-label>
          <mat-icon [matTooltip]="'PDF_PREVIEW' | transloco"
            >picture_as_pdf</mat-icon
          >
        </ng-template>
        <ng-template matTabContent>
          <app-pdf-viewer
            *ngIf="pdfOptions$ | async as pdfOptions"
            [go-to-first-page-on-load]="
              (viewerGoToFirstPageOnLoad$ | async) ?? false
            "
            [pdf-options]="pdfOptions"
          ></app-pdf-viewer>
        </ng-template>
      </mat-tab>
      <mat-tab
        aria-label="Document History Tab"
        *ngIf="shouldShowDocumentHistory"
      >
        <ng-template mat-tab-label>
          <mat-icon [matTooltip]="'DOCUMENT_HISTORY' | transloco"
            >folder</mat-icon
          >
        </ng-template>
        <ng-template matTabContent>
          <app-document-history
            [selected-document-ids]="(selectedDocumentIds$ | async)!"
          ></app-document-history>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
