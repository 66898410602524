<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="menu"
></div>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <ng-container *ngIf="inbox?.permissions?.addNewDocuments">
    <button mat-menu-item (click)="onClickImport()">
      <mat-icon>archive</mat-icon>
      <span transloco="IMPORT"></span>
    </button>
    <ng-container *ngIf="isGseConnected$ | async">
      <button mat-menu-item (click)="onClickScan()">
        <mat-icon>scanner</mat-icon>
        <span transloco="IMPORT_SCAN"></span>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
