<mat-toolbar>
  <app-left-sidebar-toggle-button
    *ngIf="(isHandset$ | async) === false"
  ></app-left-sidebar-toggle-button>
  <app-task-search-archive-selector></app-task-search-archive-selector>
  <button
    mat-icon-button
    [matTooltip]="'REFRESH' | transloco"
    (click)="onClickRefreshSearch()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="'TOGGLE_EDIT' | transloco"
    (click)="onClickToggleEdit()"
  >
    <mat-icon [class.edit-enabled]="editModeEnabled">edit</mat-icon>
  </button>
  <button
    mat-icon-button
    [disabled]="isNoneSelected"
    [matTooltip]="'ACTIONS' | transloco"
    (click)="onClickActionMenu($event)"
  >
    <mat-icon [matBadge]="selectedCount" [matBadgeHidden]="isNoneSelected"
      >assignment_turned_in</mat-icon
    >
  </button>
  <span class="spacer"></span>
  <app-right-sidebar-toggle-button></app-right-sidebar-toggle-button>
</mat-toolbar>
