import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RefreshCellsParams } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

/**
 * Selection Cell Renderer Parameters.
 *
 */
export interface SelectionCellRendererParameters extends ICellRendererParams {
  /** Optional boolean or function determining if the selection should be enabled. */
  disabled?: boolean | ((params: SelectionCellRendererParameters) => boolean);
  /** Selection options to display in the dropdown. */
  selectionOptions: SelectionCellOption[];
}

/** Selection Option. */
export interface SelectionCellOption {
  /** Value displayed to the user. */
  display: any;
  /** Actual value. */
  value: any;
}

/**
 * Selection cell renderer.
 *
 * Displays a mat select with the provided options.
 */
@Component({
  selector: 'app-selection-cell-renderer',
  templateUrl: './selection-cell-renderer.component.html',
  styleUrls: ['./selection-cell-renderer.component.scss'],
})
export class SelectionCellRendererComponent
  implements ICellRendererAngularComp
{
  /** Cell renderer parameters. */
  cellParameters: SelectionCellRendererParameters;
  /** Whether the checkbox should be disabled. */
  disabled: boolean;

  constructor(private logger: NGXLogger) {}

  /** @inheritdoc */
  agInit(params: SelectionCellRendererParameters): void {
    this.updateCell(params);
  }

  /**
   * Handler for the mat select change event.
   *
   * @param event Change event.
   * @throws {TypeError} If column for event is undefined.
   */
  onSelectionChanged(event: MatSelectChange): void {
    this.logger.debug('Selected value changed', event);
    const rowNode = this.cellParameters.node;
    if (typeof this.cellParameters.column === 'undefined') {
      throw new TypeError(
        'Column can not be undefined during selection change.'
      );
    }
    const columnId = this.cellParameters.column?.getColId();
    rowNode.setDataValue(columnId, event.value);
    const refreshCells: RefreshCellsParams = {
      rowNodes: [rowNode],
      force: false,
    };
    this.cellParameters.api.refreshCells(refreshCells);
  }

  /** @inheritdoc */
  refresh(params: SelectionCellRendererParameters): boolean {
    this.updateCell(params);
    return true;
  }

  private updateCell(params: SelectionCellRendererParameters): void {
    this.cellParameters = params;
    this.disabled =
      typeof this.cellParameters.disabled === 'function'
        ? this.cellParameters.disabled(this.cellParameters)
        : !!this.cellParameters.disabled;
  }
}
