import { Injectable, Signal, computed } from '@angular/core';
import { signal } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DocumentViewComponent } from '../components/document-view/document-view.component';
import { IndexerComponent } from '../components/indexer/indexer.component';
import { TableFieldGridComponent } from '../components/table-field-grid/table-field-grid.component';

/**
 * The state of the registered components.
 */
export interface RegisteredComponentState {
  indexer: IndexerComponent | undefined;
  tableField: TableFieldGridComponent | undefined;
  documentViewer: DocumentViewComponent | undefined;
}

/**
 * Provides access to the current UI components.
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /** The registered components. */
  private readonly registeredComponents = {
    indexer: signal<IndexerComponent | undefined>(undefined),
    tableField: signal<TableFieldGridComponent | undefined>(undefined),
    documentViewer: signal<DocumentViewComponent | undefined>(undefined),
  };

  /** The state of the registered components. */
  readonly state: Signal<RegisteredComponentState> = computed(() => {
    return {
      indexer: this.registeredComponents.indexer(),
      tableField: this.registeredComponents.tableField(),
      documentViewer: this.registeredComponents.documentViewer(),
    };
  });

  constructor(private logger: NGXLogger) {}

  /**
   * Registers a component with the UI service.
   *
   * @param {IndexerComponent | TableFieldGridComponent | DocumentViewComponent} component - The component to register.
   */
  register(
    component:
      | IndexerComponent
      | TableFieldGridComponent
      | DocumentViewComponent
  ): void {
    switch (component.constructor) {
      case IndexerComponent:
        this.registeredComponents.indexer.set(component as IndexerComponent);
        this.logger.debug('Registered indexer component.');
        break;
      case TableFieldGridComponent:
        this.registeredComponents.tableField.set(
          component as TableFieldGridComponent
        );
        this.logger.debug('Registered table field grid component.');
        break;
      case DocumentViewComponent:
        this.registeredComponents.documentViewer.set(
          component as DocumentViewComponent
        );
        this.logger.debug('Registered document viewer component.');
        break;
    }
  }

  /**
   * Unregisters a component with the UI service.
   *
   * @param {IndexerComponent | TableFieldGridComponent | DocumentViewComponent} component - The component to unregister.
   */
  unregister(
    component:
      | IndexerComponent
      | TableFieldGridComponent
      | DocumentViewComponent
  ): void {
    switch (component.constructor) {
      case IndexerComponent:
        this.logger.debug('Unregistered indexer component.');
        this.registeredComponents.indexer.set(undefined);
        break;
      case TableFieldGridComponent:
        this.logger.debug('Unregistered table field grid component.');
        this.registeredComponents.tableField.set(undefined);
        break;
      case DocumentViewComponent:
        this.logger.debug('Unregistered document viewer component.');
        this.registeredComponents.documentViewer.set(undefined);
        break;
    }
  }
}
