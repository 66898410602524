import { ApplicationQuery } from 'src/app/state/application/application.query';

/** Default page options. */
const defaultPageSizeOptions = [25, 50, 100, 1000];

/**
 * Gets the page sizes that should be displayed to the user.
 *
 * @param appQuery Application Query.
 *
 * @returns An array of page sizes.
 */
export const getPageSizes = (appQuery: ApplicationQuery): number[] => {
  const pageSizes = [...defaultPageSizeOptions];
  if (!pageSizes.includes(appQuery.archiveResultsPerPage)) {
    pageSizes.push(appQuery.archiveResultsPerPage);
    pageSizes.sort((a, b) => a - b);
  }

  return pageSizes;
};
