<mat-menu #mvActionMenu="matMenu">
  <button mat-menu-item type="button" (click)="onAddMultiValueClicked(false)">
    {{ 'INSERT_NEW_VALUE_ABOVE' | transloco }}
  </button>
  <button mat-menu-item type="button" (click)="onAddMultiValueClicked(true)">
    {{ 'INSERT_NEW_VALUE_BELOW' | transloco }}
  </button>
  <button mat-menu-item type="button" (click)="onRemoveMultiValueClick()">
    {{ 'REMOVE_VALUE' | transloco }}
  </button>
</mat-menu>
