import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QueryParamsHandling, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { Search, SearchPrompt } from 'models';

import { Observable, from } from 'rxjs';
import {
  SearchPromptComponent,
  SearchPromptDialogData,
  SearchPromptResult,
} from '../components/search-prompt/search-prompt.component';
import { CommandService } from '../modules/command-palette';

const MIN_SEARCH_PROMPT_WIDTH = 400;

/** Search UI Service. */
@Injectable({
  providedIn: 'root',
})
export class SearchUIService {
  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    private commandService: CommandService,
    private router: Router
  ) {}

  /**
   * Add a search to the command palette.
   *
   * @param search Search.
   * @param databaseId DatabaseId.
   * @param archiveId Archive ID.
   * @param showPrompt Whether to show the prompt.
   * @param overwriteExisting Replaces the existing command if it exists.
   * @param customLabel Custom label for the search.
   * @param group Group label.
   * @param filterPrefix Optional filter prefix.
   */
  addToCommandPalette(
    search: Search,
    databaseId: number,
    archiveId: number,
    showPrompt: boolean,
    overwriteExisting: boolean,
    customLabel: string = '',
    group: string = 'Search',
    filterPrefix?: string | undefined
  ): void {
    const name = customLabel ? customLabel : search.name;
    if (overwriteExisting) {
      this.commandService.unregister(name, group);
    }

    this.commandService.register(
      name,
      'Run search',
      () =>
        showPrompt
          ? this.runWithPrompt(search, databaseId, archiveId)
          : this.redirectToSearch(databaseId, archiveId, search.id),
      group,
      filterPrefix
    );
  }

  /**
   * Redirect to the provided search.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param searchId Search ID.
   * @param searchPrompts Search prompts.
   * @param queryParametersHandling Query parameters handling for the router.navigate function.
   */
  redirectToSearch(
    databaseId: number,
    archiveId: number,
    searchId: number,
    searchPrompts?: SearchPrompt[],
    queryParametersHandling: QueryParamsHandling | null = null
  ): void {
    this.redirectToSearch$(
      databaseId,
      archiveId,
      searchId,
      searchPrompts,
      queryParametersHandling
    ).subscribe();
  }

  /**
   * Redirect to the provided search.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param searchId Search ID.
   * @param searchPrompts Search prompts.
   * @param queryParametersHandling Query parameters handling for the router.navigate function.
   *
   * @returns Observable that contains whether navigation was successful.
   */
  redirectToSearch$(
    databaseId: number,
    archiveId: number,
    searchId: number,
    searchPrompts?: SearchPrompt[],
    queryParametersHandling: QueryParamsHandling | null = null
  ): Observable<boolean> {
    if (typeof searchPrompts !== 'undefined' && searchPrompts.length > 0) {
      const searchPromptString = btoa(JSON.stringify(searchPrompts));
      return from(
        this.router.navigate(
          ['db', databaseId, 'archive', archiveId, 'search', searchId],
          {
            queryParams: { prompts: searchPromptString },
            queryParamsHandling: queryParametersHandling,
          }
        )
      );
    } else {
      // If no prompts are provided, just redirect to the search.
      return from(
        this.router.navigate(
          ['db', databaseId, 'archive', archiveId, 'search', searchId],
          {
            queryParamsHandling: queryParametersHandling,
          }
        )
      );
    }
  }

  /**
   * Prompt for criteria and then run the search.
   *
   * @param search Search.
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param searchPrompts Search prompts used to populate the dialog prompt. Overrides prompt values from url.
   */
  runWithPrompt(
    search: Search,
    databaseId: number,
    archiveId: number,
    searchPrompts?: SearchPrompt[]
  ): void {
    // Filter out static prompts.
    const userInteractivePrompts = search.parameters.filter(
      (parameter) => !parameter.value
    );

    if (userInteractivePrompts.length === 0) {
      this.logger.debug(
        'No user interactive prompts. Skipping prompts and running search...'
      );

      const searchPrompts = search.parameters.map((parameter) => ({
        id: parameter.id,
        prompt: parameter.prompt,
        value: parameter.value,
      }));
      this.redirectToSearch(databaseId, archiveId, search.id, searchPrompts);
      return;
    }

    const promptDialogData: SearchPromptDialogData = {
      search,
      searchPrompts,
    };

    const promptDialog = this.dialog.open(SearchPromptComponent, {
      minWidth: MIN_SEARCH_PROMPT_WIDTH,
      data: promptDialogData,
    });

    promptDialog.afterClosed().subscribe((result: SearchPromptResult) => {
      if (!result) {
        this.logger.debug('Search prompt dialog was cancelled.');
        return;
      }

      this.logger.debug('Search prompt dialog was closed.', result);
      this.redirectToSearch(databaseId, archiveId, search.id, result.prompts);
    });
  }
}
