<ng-container *ngIf="(jobsLoading$ | async) === false; else loading">
  <ng-container *ngIf="jobs$ | async as jobs">
    <button mat-icon-button *ngIf="jobs.length > 0" (click)="onClick()">
      <mat-icon [matBadge]="jobs.length" matBadgeColor="accent">work</mat-icon>
    </button>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-progress-spinner></mat-progress-spinner>
</ng-template>
