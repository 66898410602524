<ng-container *ngIf="!isLoading; else loading">
  <!-- Single Archive Result Set: Show just a button. -->
  <button
    mat-button
    [matTooltip]="archive.name"
    matTooltipShowDelay="1000"
    *ngIf="archiveSets === 'single'"
  >
    <mat-icon
      [matBadge]="currentArchiveCount"
      [matBadgeHidden]="isSearchCountEnabled !== true"
      matBadgePosition="above before"
      >inventory_2</mat-icon
    >
    <div
      *ngIf="(useCompactLayout$ | async) === false"
      class="archive-name ellipsis"
    >
      {{ archive.name }}
    </div>
  </button>
  <!-- Mutliple Archives Result Sets: Show a dropdown menu control. -->
  <button
    mat-button
    [matTooltip]="archive.name"
    *ngIf="archiveSets === 'multi'"
    [matMenuTriggerFor]="archiveSelectMenu"
  >
    <mat-icon
      [matBadge]="currentArchiveCount"
      [matBadgeHidden]="isSearchCountEnabled !== true"
      matBadgePosition="above before"
      class="chevron-icon"
      >expand_circle_down</mat-icon
    >
    <div
      *ngIf="(useCompactLayout$ | async) === false"
      class="archive-name ellipsis"
    >
      {{ archive.name }}
    </div>
  </button>
  <mat-menu #archiveSelectMenu="matMenu">
    <ng-template matMenuContent>
      <button
        *ngFor="let archiveResultCount of archiveResultCounts"
        mat-menu-item
        (click)="onClickSelectArchive(archiveResultCount.archiveId)"
      >
        <mat-icon
          [matBadge]="archiveResultCount.count"
          [matBadgeHidden]="isSearchCountEnabled !== true"
          matBadgePosition="above before"
          >inventory_2</mat-icon
        >
        <span class="archive-result-menu-item-name">{{
          archiveResultCount.archiveName
        }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>

<ng-template #loading>
  <mat-spinner diameter="24" color="accent"></mat-spinner>
</ng-template>
