import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';

import { assertExists } from 'common';

import {
  ArchiveSessionCacheState,
  ArchiveSessionCacheStore,
} from './archive-document-cache.store';

/** Archive sessioncache query. */
@Injectable({ providedIn: 'root' })
export class ArchiveSessionCacheQuery extends QueryEntity<ArchiveSessionCacheState> {
  constructor(protected store: ArchiveSessionCacheStore) {
    super(store);
  }

  /**
   * Gets a cached session.
   *
   * @param id Session Id.
   * @returns A cached sesion.
   */
  getSession(id: string) {
    const session = this.getEntity(id);
    assertExists(session, `Session with the id '${id}' must exist.`);
    return session;
  }

  /**
   * Gets an observable for the provided session.
   *
   * @param id Session Id.
   * @returns An observable for the provided session.
   */
  selectSession(id: string) {
    return this.selectEntity(id).pipe(
      map((session) => {
        assertExists(session, `Session with the id '${id}' must exist.`);
        return session;
      })
    );
  }
}
