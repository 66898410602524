<mat-dialog-content
  *ngIf="commands | matchesCommand: commandQuery as filteredCommands"
>
  <mat-form-field appearance="fill">
    <mat-label transloco="COMMAND"></mat-label>
    <input
      matInput
      autocomplete="off"
      [(ngModel)]="commandQuery"
      placeholder="Search commands by name"
      (keyup.arrowdown)="focusFirst()"
      (keyup.enter)="clickFirst($event)"
    />
    <!-- TODO this needs a translation that contains replacements. -->
    <mat-hint
      >{{ filteredCommands.length }} of {{ commands.length }} matching
      commands</mat-hint
    >
  </mat-form-field>
  <mat-selection-list
    [multiple]="false"
    hideSingleSelectionIndicator
    #commandSelect
  >
    <mat-list-option
      *ngFor="let command of filteredCommands; first as isFirst; let i = index"
      [tabindex]="100 + i"
      [class.first]="isFirst"
      (click)="executeCommand(command)"
      (keyup.enter)="executeCommand(command)"
    >
      <div class="command-row">
        <div class="category">{{ command.group }}</div>
        <div
          class="command"
          [innerHtml]="command | highlightCommand: commandQuery"
        ></div>
        <div class="spacer"></div>
        <div class="description mat-hint">{{ command.description }}</div>
      </div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
