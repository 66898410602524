import { assert, assertExists } from 'common';
import { PDFPage, PDFPageDrawRectangleOptions, degrees } from 'pdf-lib';

import {
  Color,
  ConvertableAnnotation,
  IRectangularAnnotationBase,
  LineAppearance,
  LineStyle,
} from '.';

/**
 * Rectangle annotation.
 */
export class RectangleAnnotation
  extends ConvertableAnnotation
  implements IRectangularAnnotationBase
{
  /** @inheritdoc */
  hasFont = false;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    public height: number,
    public width: number,
    /** Border. */
    public border: LineAppearance,
    /** Color. */
    public color: Color,
    public rotation: number = 0,
    public visible: boolean = true
  ) {
    super();
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    const options = this.toPdfElement();

    if (scalePixelsToPoints) this.convertPixelsToPoints(options);

    this.adjustForRotation(page, options);

    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    page.drawRectangle(options);
  }

  private adjustForRotation(
    page: PDFPage,
    options: PDFPageDrawRectangleOptions
  ) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.width);
    assertExists(options.height);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    const widthOrig = options.width;

    /** Swap the width and height values. */
    const swapWidthHeight = () => {
      options.width = options.height;
      options.height = widthOrig;
    };

    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        options.x = options.y;
        // The y value requires subtracting page height, and the object height
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.width;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 180:
        // Subtract x value from page width, then the width to offset corner.
        options.x = page.getWidth() - options.x - options.width;
        // Subtract y value from page height, then the height to offset corner.
        options.y = page.getHeight() - options.y - options.height;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.x = page.getWidth() - options.y - options.height;
        options.y = xOrig;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Convert the annotation to PDF element drawing options.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfElement(): PDFPageDrawRectangleOptions {
    const options: PDFPageDrawRectangleOptions = {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      color: this.color.pdfColor,
      opacity: this.color.opacity,
      borderColor: this.border.color.pdfColor,
      borderWidth: this.border.thickness,
      borderOpacity: this.border.color.opacity,
      rotate: degrees(this.rotation),
    };
    switch (this.border.style) {
      case LineStyle.Solid:
        break;
      case LineStyle.Dotted:
        options.borderDashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.borderDashArray = [1, 2]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      case LineStyle.Dashed:
        options.borderDashPhase = 1; // The borderDashPhase option specifies how far into the dash pattern to start drawing
        options.borderDashArray = [2, 1]; // The borderDashArray option specifies an array of numbers that define the dash pattern, such as [2, 3] for a dash of 2 units followed by a gap of 3 units.
        break;
      default:
        throw new Error(`Unknown border LineStyle: ${this.border.style}`);
    }
    return options;
  }
}
