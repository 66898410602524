<div *ngIf="customLinks$ | async as customLinks">
  <div *ngIf="customLinks.length > 0">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Custom Links Menu"
    >
      <mat-icon>link</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container
        *ngFor="let link of customLinks"
        [ngSwitch]="getLinkItemType(link)"
      >
        <button
          *ngSwitchCase="linkType.link"
          mat-menu-item
          (click)="onClickLink(link)"
        >
          {{ link.label }}
        </button>
        <div
          *ngSwitchCase="linkType.header"
          mat-menu-item
          disableRipple
          class="custom-link-header"
          (click)="$event.stopPropagation()"
        >
          {{ link.label }}
        </div>
        <mat-divider *ngSwitchCase="linkType.divider"></mat-divider>
      </ng-container>
    </mat-menu>
  </div>
</div>

<ng-template #divider>
  <mat-divider></mat-divider>
</ng-template>
