import { InjectionToken } from '@angular/core';

import {
  AdvancedLinkProvider,
  ApiMetadataProvider,
  ArchiveProvider,
  AuthenticationProvider,
  AuthorizationProvider,
  CSVImportProvider,
  DatabaseProvider,
  DocumentHistoryProvider,
  DocumentProvider,
  DocumentUpdateProvider,
  ExportProvider,
  FieldProvider,
  GSEProvider,
  InboxProvider,
  KeyfreeProvider,
  LaunchDocumentProvider,
  ListProvider,
  PrintProvider,
  QuickbooksProvider,
  ScanProvider,
  SearchProvider,
  ShareDocumentProvider,
  TableFieldProvider,
  TaskSearchesProvider,
  UserActionsProvider,
  UserProfileProvider,
  ViewerSessionProvider,
} from 'models';

/** Database Provider. */
export const DATABASE_PROVIDER = new InjectionToken<DatabaseProvider>(
  'DATABASE_PROVIDER'
);

/** Inbox Provider. */
export const INBOX_PROVIDER = new InjectionToken<InboxProvider>(
  'INBOX_PROVIDER'
);

/** Search Provider. */
export const SEARCH_PROVIDER = new InjectionToken<SearchProvider>(
  'SEARCH_PROVIDER'
);

/** Global Action Task Search Provider. */
export const TASK_SEARCH_PROVIDER = new InjectionToken<TaskSearchesProvider>(
  'TASK_SEARCH_PROVIDER'
);

/** Field Provider. */
export const FIELD_PROVIDER = new InjectionToken<FieldProvider>(
  'FIELD_PROVIDER'
);

/** Table Field Provider. */
export const TABLE_FIELD_PROVIDER = new InjectionToken<TableFieldProvider>(
  'TABLE_FIELD_PROVIDER'
);

/** Document History Provider. */
export const DOCUMENT_HISTORY_PROVIDER =
  new InjectionToken<DocumentHistoryProvider>('DOCUMENT_HISTORY_PROVIDER');

/** Scan Provider. */
export const SCAN_PROVIDER = new InjectionToken<ScanProvider>('SCAN_PROVIDER');

/** Archive Provider. */
export const ARCHIVE_PROVIDER = new InjectionToken<ArchiveProvider>(
  'ARCHIVE_PROVIDER'
);

/** Authentication Provider. */
export const AUTHENTICATION_PROVIDER =
  new InjectionToken<AuthenticationProvider>('AUTHENTICATION_PROVIDER');

/** Authorization Provider. */
export const AUTHORIZATION_PROVIDER = new InjectionToken<AuthorizationProvider>(
  'AUTHENTICATION_PROVIDER'
);

/** GSE Provider. */
export const GSE_PROVIDER = new InjectionToken<GSEProvider>('GSE_PROVIDER');

/** GSE Launch Document Provider. */
export const LAUNCH_DOCUMENT_PROVIDER =
  new InjectionToken<LaunchDocumentProvider>('LAUNCH_DOCUMENT_PROVIDER');

/**
 * GSE Quickbooks Provider.
 */
export const QUICKBOOKS_PROVIDER = new InjectionToken<QuickbooksProvider>(
  'QUICKBOOKS_PROVIDER'
);

/** Viewer Session Provider. */
export const VIEWER_SESSION_PROVIDER =
  new InjectionToken<ViewerSessionProvider>('VIEWER_SESSION_PROVIDER');

/** CSV Import Provider. */
export const CSV_IMPORT_PROVIDER = new InjectionToken<CSVImportProvider>(
  'CSV_IMPORT_PROVIDER'
);

/** CSV Import Provider. */
export const KEYFREE_PROVIDER = new InjectionToken<KeyfreeProvider>(
  'KEYFREE_PROVIDER'
);
/** Document Update Provider. */
export const DOCUMENT_UPDATE_PROVIDER =
  new InjectionToken<DocumentUpdateProvider>('DOCUMENT_UPDATE_PROVIDER');

/** List Provider. */
export const LIST_PROVIDER = new InjectionToken<ListProvider>('LIST_PROVIDER');

/** Document Provider. */
export const DOCUMENT_PROVIDER = new InjectionToken<DocumentProvider>(
  'DOCUMENT_PROVIDER'
);

/** Advanced Link Provider. */
export const ADVANCED_LINK_PROVIDER = new InjectionToken<AdvancedLinkProvider>(
  'ADVANCED_LINK_PROVIDER'
);

/** Share Document Provider. */
export const SHARE_DOCUMENT_PROVIDER =
  new InjectionToken<ShareDocumentProvider>('SHARE_DOCUMENT_PROVIDER');

/** Print Provider. */
export const PRINT_PROVIDER = new InjectionToken<PrintProvider>(
  'PRINT_PROVIDER'
);

/** Export Provider. */
export const EXPORT_PROVIDER = new InjectionToken<ExportProvider>(
  'EXPORT_PROVIDER'
);

/** User Actions Provider. */
export const USER_ACTIONS_PROVIDER = new InjectionToken<UserActionsProvider>(
  'USER_ACTIONS_PROVIDER'
);

/** User Self Update Provider. */
export const USER_PROFILE_PROVIDER = new InjectionToken<UserProfileProvider>(
  'USER_SELF_UPDATE_PROVIDER'
);

/** Api Metadata Provider. */
export const API_METADATA_PROVIDER = new InjectionToken<ApiMetadataProvider>(
  'API_METADATA_PROVIDER'
);
