import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { CSVImportJobState, CSVImportJobStore } from './csvimport-job.store';

/** CSV Import Job Query. */
@Injectable({ providedIn: 'root' })
export class CSVImportJobQuery extends QueryEntity<CSVImportJobState> {
  /** Observable array of jobs. */
  jobs$ = this.selectAll();
  /** Observable loading state of the jobs store. */
  loading$ = this.selectLoading();
  constructor(protected store: CSVImportJobStore) {
    super(store);
  }
}
