import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

/** Service containing general functions that use the window. */
@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(private http: HttpClient) {}

  /**
   * Fetch and download a file.
   *
   * Creates an invisible link to the provided file, using a blob, to be
   * downloaded as the provided name.
   *
   * @param downloadFileUrl Download URL.
   * @param filename Filename used to save the file locally.
   * @returns An observable that completes once the file is downloaded.
   */
  fetchAndDownloadUrl(
    downloadFileUrl: string,
    filename: string
  ): Observable<void> {
    return this.http
      .get<any>(downloadFileUrl, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.hidden = true;
          link.download = filename;
          document.body.append(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
        })
      );
  }
}
