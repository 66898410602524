import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';

import { NotificationService } from 'src/app/services/notification.service';

import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';

/** Numeric Cell Editor. */
@Component({
  selector: 'app-numeric-cell-editor',
  templateUrl: './numeric-cell-editor.component.html',
  styleUrls: ['./numeric-cell-editor.component.scss'],
})
export class NumericCellEditorComponent extends FieldCellEditorBaseComponent {
  constructor(
    private logger: NGXLogger,
    notifications: NotificationService,
    translate: TranslocoService
  ) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  getValue() {
    // Ensure that the value is returned as a string since all grid data here is strings.
    return this.formControl.value?.toString() ?? '';
  }

  /** @inheritdoc */
  isCancelAfterEnd?(): boolean {
    const value = this.getValue();
    if (
      value !== this.cellParams.value &&
      (this.formControl.invalid || Number.isNaN(value))
    ) {
      this.showError();
      return true;
    } else if (this.cellParams.field.required && !value) {
      this.showError();
      return true;
    }

    return this.formControl.invalid;
  }

  /**
   * Event handler for the keypress event.
   *
   * @param event Keyboard event.
   *
   * @returns A boolean determining if the value is valid.
   */
  onKeyPress(event: KeyboardEvent): boolean {
    this.logger.debug('on input', event);
    const key = Number(event.key);
    // Only Numbers 0-9
    if (Number.isNaN(key)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
