<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="menu"
></div>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <!-- Import Actions-->
  <ng-container *ngIf="hasAddNewDocumentPermissions">
    <button
      mat-menu-item
      *ngIf="isGseConnected$ | async"
      (click)="onClickScan()"
    >
      <mat-icon>scanner</mat-icon>
      <span transloco="IMPORT_SCAN"></span>
    </button>
    <button mat-menu-item (click)="onClickImport()">
      <mat-icon>archive</mat-icon>
      <span transloco="IMPORT"></span>
    </button>
    <button mat-menu-item (click)="onClickImportCSV()">
      <mat-icon>cloud_upload</mat-icon>
      <span transloco="IMPORT_CSV"></span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>
  <button
    *ngFor="let search of searches"
    mat-menu-item
    [ngClass]="{ active: getIsActiveSearch(search.id) }"
    (click)="onClickSearch(search)"
  >
    <mat-icon>search</mat-icon>
    <span>{{ search.name }}</span>
  </button>
  <div mat-menu-item *ngIf="searches?.length === 0">
    <span transloco="NO_SEARCHES_AVAILABLE"></span>
  </div>
</mat-menu>
