import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { assertTypeByKey } from 'common';
import { AppConfigQuery } from '../modules/app-config';

// biome-ignore lint/style/useNamingConvention: External
type LanguageMap = { LanguageKeys: string[] };

/**
 * Languages Service.
 */
@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private cdnUrl: string;
  private cachedLanguages?: string[];

  constructor(
    private http: HttpClient,
    private appConfigQuery: AppConfigQuery
  ) {
    this.appConfigQuery.languageCdnUrl$.subscribe(
      (cdnUrl) => (this.cdnUrl = cdnUrl)
    );
  }

  /**
   * Gets available languages.
   *
   * @returns An array of strings representing the available languages.
   */
  getAvailableLanguages(): Observable<string[]> {
    // If we already cached a result, return it.
    if (this.cachedLanguages) {
      return of(this.cachedLanguages);
    }
    // Get the language map.
    return this.http.get<LanguageMap>(`${this.cdnUrl}/languageMap.json`).pipe(
      map((languageObject) => {
        assertTypeByKey<LanguageMap>(languageObject, 'LanguageKeys', 'object');
        this.cachedLanguages = languageObject.LanguageKeys;
        return languageObject.LanguageKeys;
      })
    );
  }
}
