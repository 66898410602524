import {
  InboxIndexToArchiveSession,
  InboxIndexToArchiveSessionDocument,
} from 'models';

export interface S9ApiInboxIndexToArchiveSession {
  Database: number;
  Archive: number;
  InboxId: number;
  Documents: S9ApiInboxIndexToArchiveSessionDocument[];
}

export interface S9ApiInboxIndexToArchiveSessionDocument {
  Archive: number;
  InboxFileName: string;
  InboxId: number;
}

export const createS9ApiInboxIndexToArchiveSession = (
  session: InboxIndexToArchiveSession
): S9ApiInboxIndexToArchiveSession => ({
  Database: session.databaseId,
  InboxId: session.id,
  Archive: session.archiveId,
  Documents: session.documents.map((document) =>
    createS9ApiInboxIndexToArchiveSessionDocument(document)
  ),
});

export const createS9ApiInboxIndexToArchiveSessionDocument = (
  sessionDocument: InboxIndexToArchiveSessionDocument
): S9ApiInboxIndexToArchiveSessionDocument => ({
  Archive: sessionDocument.archiveId,
  InboxFileName: sessionDocument.filename,
  InboxId: sessionDocument.id,
});
