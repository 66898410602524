/**
 * Check bitwise values for a flag.
 *
 * @param value Value to check for flag.
 * @param flag Flag to check for.
 * @returns True if flag is in value.
 */
export const hasFlag = (value: number, flag: number): boolean => {
  return (value & flag) === flag;
};
