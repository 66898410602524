import { ArchiveImportRequestedDocument } from './archive-import-requested-document';
import { DocumentRequestMap } from './document-request-map';

/**
 * Archive import requested document map.
 */
export class ArchiveImportRequestedDocumentMap extends DocumentRequestMap<ArchiveImportRequestedDocument> {
  constructor(documents?: ArchiveImportRequestedDocument[] | string) {
    super(documents);
  }
}
