import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { IndexerData, IndexerStateStore } from './indexer.store';

/** Indexer state service. */
@Injectable({ providedIn: 'root' })
export class IndexerStateService {
  constructor(
    private logger: NGXLogger,
    private store: IndexerStateStore
  ) {}

  /**
   * Sets indexer data for the given id.
   *
   * @param id Id.
   * @param newState New indexer data.
   */
  set(id: string, newState: IndexerData) {
    this.store.upsert(id, newState, (id, newState) => ({ id, ...newState }));
  }
}
