import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';

import { AssertionError } from 'common';
import { assertExists } from 'lib/common/src/public-api';
import { Inbox } from 'models';

import { InboxesState, InboxesStore } from './inboxes.store';

/** Inboxes Query. */
@Injectable({ providedIn: 'root' })
export class InboxesQuery extends QueryEntity<InboxesState> {
  /**
   * Observable of the active inbox.
   *
   * @throws {AssertionError} If no active inbox exists.
   */
  activeInbox$ = this.selectActive().pipe(
    map((activeInbox) => {
      assertExists(activeInbox);
      return activeInbox;
    })
  );
  /** Observable of inboxes. */
  inboxes$ = this.selectAll();
  /** Observable of inbox loading state. */
  isLoading$ = this.selectLoading();

  /**
   * Currently active inbox.
   *
   * @returns Inbox.
   * @throws {AssertionError} If current state inbox is undefined.
   */
  get active(): Inbox {
    const inbox = this.getActive();
    assertExists(inbox);
    return inbox;
  }

  /**
   * Currently active inbox Id number.
   *
   * @returns Inbox Id number.
   * @throws {TypeError} If current state inbox Id does not return a number.
   */
  get activeId(): number {
    const id = this.getActiveId();
    if (typeof id !== 'number') {
      throw new TypeError('Archive Id was not a number.');
    }
    return id;
  }

  constructor(protected store: InboxesStore) {
    super(store);
  }
}
