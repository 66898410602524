import { assert, assertExists } from 'common';
import { PDFPage, PDFPageDrawEllipseOptions, degrees } from 'pdf-lib';

import {
  Color,
  ConvertableAnnotation,
  IDocumentAnnotationBase,
  LineAppearance,
} from '.';

/**
 * Ellipse annotation.
 */
export class EllipseAnnotation
  extends ConvertableAnnotation
  implements IDocumentAnnotationBase
{
  /** @inheritdoc */
  hasFont = false;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    public xScale: number,
    public yScale: number,
    /** Border. */
    public border: LineAppearance,
    /** Color. */
    public color: Color,
    public rotation: number = 0,
    public visible: boolean = true
  ) {
    super();
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    const options = this.toEllipseOptions();
    if (scalePixelsToPoints) this.convertPixelsToPoints(options);
    this.adjustForRotation(page, options);
    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    page.drawEllipse(options);
  }

  private adjustForRotation(page: PDFPage, options: PDFPageDrawEllipseOptions) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.xScale);
    assertExists(options.yScale);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    const xScaleOrig = options.xScale;

    /** Swap the width and height values. */
    const swapWidthHeight = () => {
      options.xScale = options.yScale;
      options.yScale = xScaleOrig;
    };

    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        options.x = options.y;
        // Subtract the x value from the page height.
        options.y = page.getHeight() - xOrig;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 180:
        // Subtract x value from page width to offset corner.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height to offset corner.
        options.y = page.getHeight() - options.y;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width to offset corner.
        options.x = page.getWidth() - options.y;
        options.y = xOrig;
        // Swap width and height.
        swapWidthHeight();
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Convert the annotation to PDF element drawing options.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toEllipseOptions(): PDFPageDrawEllipseOptions {
    const options: PDFPageDrawEllipseOptions = {
      x: this.x,
      y: this.y,
      xScale: this.xScale,
      yScale: this.yScale,
      borderColor: this.border.color.pdfColor,
      borderWidth: this.border.thickness,
      borderOpacity: this.border.color.opacity,
      color: this.color.pdfColor,
      rotate: degrees(this.rotation * -1),
      opacity: this.color.opacity,
    };
    return options;
  }
}
