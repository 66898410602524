import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { List } from 'models';

/** Lists state. */
export type ListsState = EntityState<List>;

/** Lists Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'Lists',
  resettable: true,
})
export class ListsStore extends EntityStore<ListsState> {
  constructor() {
    super();
  }
}
