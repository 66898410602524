<section id="inboxes" *ngIf="showPanel$ | async">
  <div class="sidebar-group mat-elevation-z1">
    <mat-icon>inbox</mat-icon><span transloco="INBOXES"></span>
    <span class="spacer"></span>
    <app-navigation-expansion-button
      [isExpanded]="(isExpanded$ | async)!"
      (isExpandedChange)="onExpandChanged()"
    ></app-navigation-expansion-button>
  </div>
  <mat-progress-bar
    *ngIf="isInboxesLoading$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
  <section id="inbox-items" *ngIf="isExpanded$ | async">
    <app-navigation-inbox
      *ngFor="let inbox of inboxes$ | async"
      [dbId]="(dbId$ | async)!"
      [inbox]="inbox"
    ></app-navigation-inbox>
  </section>
</section>
