<mat-expansion-panel
  expanded="true"
  class="user-actions-panel"
  *ngIf="shouldShowActions"
>
  <mat-expansion-panel-header>
    <mat-panel-title transloco="ACTIONS"></mat-panel-title>
  </mat-expansion-panel-header>
  <div class="workflow-actions">
    <button
      class="action-button"
      [disabled]="disableButtons"
      mat-button
      *ngFor="let action of userActions"
      (click)="onClickUserAction(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-expansion-panel>
