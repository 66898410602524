<button
  *ngIf="sources && sources.length > 0"
  mat-icon-button
  [matMenuTriggerFor]="dxcSourceMenu"
>
  <mat-icon>sync_alt</mat-icon>
</button>
<mat-menu #dxcSourceMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let source of sources"
    (click)="onClickDxcSource(source)"
  >
    {{ source.name }}
  </button>
</mat-menu>
