<div
  matRipple
  class="highlight-hover sidebar-group-item"
  [routerLink]="['db', dbId, 'archive', archive.id]"
  routerLinkActive="active"
  matTooltip="{{ archive.name }}"
  matTooltipShowDelay="1000"
  matTooltipPosition="right"
  ngFileDrop
  [options]="(uploaderOptions$ | async)!"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploader.input"
  [class.drag-over]="dragOver"
  (contextmenu)="onOpenMenu($event)"
>
  <span class="ellipsis">{{ archive.name }}</span>
  <span class="spacer"></span>
  <button
    mat-icon-button
    aria-label="Child Archives"
    *ngIf="archive.children.length > 0"
    (click)="clickChildArchives()"
  >
    <mat-icon>folder</mat-icon>
  </button>
  <button
    mat-icon-button
    aria-label="More archive options"
    (click)="onOpenMenu($event)"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</div>
<mat-progress-bar
  *ngIf="uploader.inProgress"
  mode="determinate"
  [value]="uploader.progress"
></mat-progress-bar>
<!-- Hidden uploader input element -->
<input
  #archiveFileUploader
  type="file"
  ngFileSelect
  [options]="(uploaderOptions$ | async)!"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploader.input"
  (click)="archiveFileUploader.value = ''"
  multiple
  class="hidden"
/>

<app-navigation-archive-menu
  (importFile)="archiveFileUploader.click()"
></app-navigation-archive-menu>
