import moment from 'moment';

/**
 * Covert a string to a `number` or `undefined` explicitly.
 *
 * @param str String to convert.
 * @returns A `number` if possible, otherwise `undefined`.
 */
export const toNumberOrUndefined = (str: string): number | undefined =>
  str ? Number(str) : undefined;

/**
 * Test if a string is a valid email address.
 *
 * @param str String to test.
 * @returns `true` if string is email.
 */
export const isEmail = (str: string): boolean =>
  new RegExp(
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
  ).test(str);

/**
 * Retrieve the filename without its extension.
 *
 * @param filename Filename with extension.
 * @returns Filename without the extension.
 */
export const removeFileExtension = (filename: string): string =>
  filename.slice(0, Math.max(0, filename.lastIndexOf('.')));

/**
 * Add a string between two indexes.
 *
 * @param origin Original string.
 * @param start Start index.
 * @param end End index.
 * @param insertion Value to be inserted.
 * @returns A string.
 */
export const replaceBetweenIndexes = (
  origin: string,
  start: number,
  end: number,
  insertion: string
): string => {
  return (
    origin.slice(0, Math.max(0, start)) +
    insertion +
    origin.slice(Math.max(0, end))
  );
};

/**
 * Checks if one date string is equal to a another.
 *
 * @param oldValue Old value.
 * @param newValue New value.
 * @returns A boolean indicating if the strings are the same moment.
 */
export const isSameMoment = (oldValue: string, newValue: string): boolean => {
  // create a default date to use if one of the values is empty.
  const defaultDate = moment().subtract(1, 'days');
  const oldDate = oldValue ? moment(oldValue) : defaultDate;
  const newDate = newValue ? moment(newValue) : defaultDate;
  return oldDate.isSame(newDate);
};

/**
 * Filters a list by the value provided.
 *
 * @param value Value for which to filter the list.
 * @param list List to search.
 * @param maxMatches Max number of matches to return. Defaults to the length of the list provided.
 * @returns An array of list values where the value is included in the entry.
 */
export const filterList = (
  value: string,
  list: string[],
  maxMatches = list.length
): string[] => {
  if (!value) return [];

  value = value.toLowerCase();
  const matchedListValues: string[] = [];

  for (
    let index = 0, count = list.length;
    index < count && matchedListValues.length <= maxMatches;
    ++index
  ) {
    if (list[index].toLowerCase().includes(value)) {
      matchedListValues.push(list[index]);
    }
  }

  return matchedListValues;
};
