import { ArchiveRequestedDocument } from './archive-requested-document';
import { DocumentRequestMap } from './document-request-map';

/**
 * Archive requested document map.
 */
export class ArchiveRequestedDocumentMap extends DocumentRequestMap<ArchiveRequestedDocument> {
  constructor(documents?: ArchiveRequestedDocument[] | string) {
    super(documents);
  }
}
