import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import {
  TaskSearch,
  TaskSearchesProvider,
  UserActionsProvider,
  filterCheckInCheckOutPermissions,
  filterReadOnlyPermissions,
} from 'models';
import { NO_ACTIVE_ID } from 'src/app/common/constants';
import { AppConfigQuery } from 'src/app/modules/app-config';

import {
  TASK_SEARCH_PROVIDER,
  USER_ACTIONS_PROVIDER,
} from '../../common/tokens';
import { AuthenticationService } from '../../services/authentication.service';
import { ApplicationQuery } from '../application/application.query';
import { ArchivesQuery } from '../archives/archives.query';
import { DatabasesQuery } from '../databases/databases.query';

import { TaskSearchesQuery } from './task-searches.query';
import { TaskSearchesStore } from './task-searches.store';

// Task view search settings.
const FIRST_PAGE = 1;
const UNSORTED = '';

/**
 * Task Searches Service.
 */
@Injectable({ providedIn: 'root' })
export class TaskSearchesService {
  /** Task searches provider api. */
  api = this.taskSearchesProvider;
  /** User actions api. */
  userActionsApi = this.userActionsProvider;

  constructor(
    private auth: AuthenticationService,
    private taskSearchesStore: TaskSearchesStore,
    @Inject(TASK_SEARCH_PROVIDER)
    private taskSearchesProvider: TaskSearchesProvider,
    @Inject(USER_ACTIONS_PROVIDER)
    private userActionsProvider: UserActionsProvider,
    private taskSearchesQuery: TaskSearchesQuery,
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private routerQuery: RouterQuery,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery
  ) {
    this.routerQuery
      .selectParams('taskId')
      .pipe(distinctUntilChanged())
      .subscribe((taskId) => {
        this.taskSearchesStore.setActive(taskId ?? NO_ACTIVE_ID);
        this.logger.debug(
          typeof taskId === 'undefined'
            ? 'No task search active.'
            : `Task search ${taskId} set as active.`
        );
      });
  }

  /**
   * Refresh all task searches for a database.
   *
   * @param databaseId Database ID.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }
    if (this.auth.isGuest) {
      // Ignore requests in guest context, they are not available, so no need to request data.
      return;
    }
    this.taskSearchesProvider
      .getAll(databaseId)
      .pipe(
        setLoading(this.taskSearchesStore),
        map((taskSearches) => {
          if (this.appConfigQuery.sortArchivesTasksAndInboxesAlphabetically) {
            // Sort task searches alphabetically.
            taskSearches = taskSearches.sort((a, b) => {
              const aName = a.title.toLowerCase();
              const bName = b.title.toLowerCase();
              return aName < bName ? -1 : aName > bName ? 1 : 0;
            });
          }
          return taskSearches;
        })
      )
      .subscribe((taskSearches) => {
        this.logger.debug('Refreshing task searches.');
        this.taskSearchesStore.set(taskSearches);
      });
  }

  /**
   * Get a task search.
   *
   * @param databasesId Database Id.
   * @param workflowId Workflow Id.
   * @param queueKey Queue key.
   * @returns An observable task search.
   */
  getById(
    databasesId: number,
    workflowId: string,
    queueKey: string
  ): Observable<TaskSearch> {
    return this.api.getById(databasesId, workflowId, queueKey).pipe(
      setLoading(this.taskSearchesStore),
      tap((taskSearch) => {
        this.logger.debug('Storing updated task search', taskSearch);
        this.taskSearchesStore.update(`${workflowId}_${queueKey}`, taskSearch);
      })
    );
  }

  /**
   * Get queued document search result.
   *
   * @param databaseId Database id.
   * @param archiveId Archive id.
   * @param documentId Document id.
   *
   * @returns An observable search result.
   */
  getQueuedDocument(databaseId: number, archiveId: number, documentId: number) {
    return this.api.getQueuedDocument(databaseId, archiveId, documentId).pipe(
      map((searchResult) => {
        const targetArchive = this.archivesQuery.getArchive(archiveId);
        if (targetArchive.isCheckInCheckOut) {
          searchResult.permissions = filterCheckInCheckOutPermissions(
            searchResult.permissions
          );
        }

        if (this.appQuery.isReadOnlyLicense) {
          searchResult.permissions = filterReadOnlyPermissions(
            searchResult.permissions
          );
        }
        return searchResult;
      })
    );
  }

  /**
   * Run a task search.
   *
   * @param archiveId Archive ID.
   * @param workflowId Workflow ID.
   * @param queueKey Queue Key.
   * @param pageNumber Page number.
   * @param recordsPerPage Records per page.
   * @param sort Sort.
   * @param databaseId Database ID.
   * @returns Observable list of search results.
   */
  run(
    archiveId: number,
    workflowId: string,
    queueKey: string,
    pageNumber: number = FIRST_PAGE,
    recordsPerPage: number = this.appQuery.archiveResultsPerPage,
    sort: string = UNSORTED,
    databaseId: number = this.databasesQuery.activeId
  ) {
    return this.taskSearchesProvider
      .runSearch(
        databaseId,
        archiveId,
        workflowId,
        queueKey,
        pageNumber,
        recordsPerPage,
        sort
      )
      .pipe(
        map((results) => {
          const targetArchive = this.archivesQuery.getArchive(archiveId);
          if (
            targetArchive.isCheckInCheckOut ||
            this.appQuery.isReadOnlyLicense
          ) {
            results = results.map((result) => {
              if (targetArchive.isCheckInCheckOut) {
                result.permissions = filterCheckInCheckOutPermissions(
                  result.permissions
                );
              }

              if (this.appQuery.isReadOnlyLicense) {
                result.permissions = filterReadOnlyPermissions(
                  result.permissions
                );
              }
              return result;
            });
          }
          return results;
        }),
        tap((results) => {
          this.logger.debug(
            `Task search completed for workflow ${workflowId} with ${results.length} resutls.`
          );
        })
      );
  }

  /**
   * Run the active task search.
   *
   * @returns Observable list of search results.
   * @throws {TypeError} If no active task search.
   */
  runActive() {
    const activeTask = this.taskSearchesQuery.active;
    const initialArchiveId = activeTask.archives[0].id;
    this.logger.debug(
      `Running the default search for the currently active task.`
    );
    return this.run(
      initialArchiveId,
      activeTask.workflowId,
      activeTask.key
    ).pipe(
      tap((result) => {
        this.logger.debug('Task search results.', result);
      })
    );
  }
}
