import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { Observable, interval, throwError } from 'rxjs';
import { catchError, share, switchMap, takeWhile, tap } from 'rxjs/operators';

import {
  CSVImportJob,
  CSVImportMappings,
  CSVImportProvider,
  UserFriendlyError,
} from 'models';
import { CSV_IMPORT_PROVIDER } from 'src/app/common/tokens';

import { CSVImportJobStore } from './csvimport-job.store';

/** CSV Import Job Service. */
@Injectable({ providedIn: 'root' })
export class CSVImportJobService {
  constructor(
    private logger: NGXLogger,
    private csvImportJobStore: CSVImportJobStore,
    @Inject(CSV_IMPORT_PROVIDER) private csvImportProvider: CSVImportProvider
  ) {
    this.refreshAllJobs();
  }

  /**
   * Cancel a job.
   *
   * @param jobId Job ID.
   * @returns An observable that resolves when the task completes.
   */
  cancelJob(jobId: string): Observable<void> {
    return this.csvImportProvider.cancelJob(jobId);
  }

  /**
   * Delete a job that has been cancelled or completed.
   *
   * @param jobId Job ID.
   * @returns An observable that resolves when the task completes.
   */
  deleteJob(jobId: string): Observable<void> {
    return this.csvImportProvider.deleteJob(jobId).pipe(
      tap(() => this.csvImportJobStore.remove(jobId)),
      catchError((error: UserFriendlyError) => {
        this.logger.error(
          'An error occurred while attempting to delete job',
          jobId,
          error
        );

        if (error.i18n === 'HTTP_NOTFOUND_ERR') {
          this.logger.debug(
            'The job was not found on the server so it is assumed to be complete. Removing from the store.'
          );
          this.csvImportJobStore.remove(jobId);
        }

        // Pass the error down the pipe.
        return throwError(() => error);
      })
    );
  }

  /**
   * Get a job.
   *
   * @param jobId Job ID.
   * @returns An observable job.
   */
  getJob(jobId: string): Observable<CSVImportJob> {
    return this.csvImportProvider.getJob(jobId).pipe(
      tap((job) =>
        this.csvImportJobStore.upsert(jobId, job, (id, newJob) => ({
          ...newJob,
          id,
        }))
      )
    );
  }

  /**
   * Get the job listener for the job.
   *
   * @param jobId Job ID.
   * @returns An observable listener that checks the job status every few seconds.
   */
  getJobListener = (jobId: string) =>
    interval(5000).pipe(
      switchMap(() => this.getJobStatus(jobId)),
      takeWhile(
        (job) => job.status.started && !job.status.done,
        true // makes takeWhile emit the last value which matched the above condition
      ),
      share()
    );

  /**
   * Gets the job and also saves its updated status to the store.
   *
   * @param jobId Job ID.
   * @returns An observable job.
   */
  getJobStatus(jobId: string): Observable<CSVImportJob> {
    return this.csvImportProvider.getJob(jobId).pipe(
      tap((job) =>
        this.csvImportJobStore.upsert(jobId, (existingJob) => ({
          ...existingJob,
          status: job.status,
        }))
      )
    );
  }

  /**
   * Gets all jobs the user has started.
   *
   * @returns An observable array of jobs.
   */
  getJobs(): Observable<CSVImportJob[]> {
    return this.csvImportProvider.getJobs().pipe(
      setLoading(this.csvImportJobStore),
      tap((jobs) => {
        this.csvImportJobStore.reset();
        this.csvImportJobStore.add(jobs);
      })
    );
  }

  /**
   * Start a job.
   *
   * @param jobId Job ID.
   * @param columnMappings Column mappings.
   * @returns An observable job.
   */
  startJob(
    jobId: string,
    columnMappings: CSVImportMappings
  ): Observable<CSVImportJob> {
    return this.csvImportProvider
      .startImport(jobId, columnMappings)
      .pipe(switchMap(() => this.getJob(jobId)));
  }

  private refreshAllJobs(): void {
    this.logger.debug('Refreshing all CSV import jobs.');
    // get all jobs once when the application starts.
    this.getJobs()
      .pipe(setLoading(this.csvImportJobStore))
      .subscribe((jobs) => {
        this.csvImportJobStore.reset();
        this.csvImportJobStore.add(jobs);
      });
  }
}
