import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * Check for updates.
 */
@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(
    appReference: ApplicationRef,
    updates: SwUpdate,
    private snackbar: MatSnackBar,
    private translate: TranslocoService,
    private zone: NgZone,
    private logger: NGXLogger
  ) {
    // Check that the service worker is registered.
    // It will not be available in development environments (ng serve).
    if (!('serviceWorker' in navigator)) {
      this.logger.warn(
        'Update Check: Service worker is not available, we will not check for updates.'
      );
      return;
    }
    // Check that service worker updates are enabled.
    // They will be disabled or unsupported in incognito browsers.
    if (!updates.isEnabled) {
      this.logger.warn(
        'Update Check: Service worker updates are not enabled. Browser does not support them or they are disabled, this includes private/incognito sessions. '
      );
      return;
    }
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()` where we check every 15 minutes.
    const appIsStable$ = appReference.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const fifteenMinutesMs = 15 * 60 * 1000;
    const everyFifteenMinutes$ = interval(fifteenMinutesMs);
    const everyFifteenMinutesOnceAppIsStable$ = concat(
      appIsStable$,
      everyFifteenMinutes$
    );

    everyFifteenMinutesOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        this.logger.debug(
          updateFound
            ? 'Update Check: A new version is available.'
            : 'Update Check: Already on the latest version.'
        );
        if (updateFound) {
          // Run in the Angular zone, so appropriate change detection fires, needed for positioning.
          this.zone.run(() => {
            const snack = this.snackbar.open(
              this.translate.translate('UPDATE_AVAILABLE'),
              this.translate.translate('RELOAD'),
              {
                duration: fifteenMinutesMs,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              }
            );
            snack.onAction().subscribe(() => {
              window.location.reload();
            });
          });
        }
      } catch (error) {
        this.logger.error('Failed to check for updates:', error);
      }
    });
  }
}
