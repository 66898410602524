import { DXCMatch, DXCSource } from 'models';

import { S9ApiFieldValues, createFieldValue } from './field';

export interface S9ApiDataXChangeSource {
  ArchiveID: number;
  ExactMatch: boolean;
  ID: number;
  OnAdd: boolean;
  OnUpdate: boolean;
  SourceName: string;
}

export interface S9ApiDataXChangeMatch {
  Match: S9ApiFieldValues;
}

/**
 * Converts the api object into a DataXChangeSource object.
 *
 * @param apiSource S9ApiDataXChangeSource.
 * @returns A DataXChangeSource.
 */
export const createDXCSourceFromApi = (
  apiSource: S9ApiDataXChangeSource
): DXCSource => ({
  archiveId: apiSource.ArchiveID,
  exactMatch: apiSource.ExactMatch,
  id: apiSource.ID,
  onAdd: apiSource.OnAdd,
  onUpdate: apiSource.OnUpdate,
  name: apiSource.SourceName,
});

export const createDXCMatch = (apiMatch: S9ApiDataXChangeMatch): DXCMatch => ({
  values: apiMatch.Match.map((apiFieldValues) =>
    createFieldValue(apiFieldValues)
  ),
});
