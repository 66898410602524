import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

/** Describes the config result form the export dialog. */
export interface ExportConfigResult {
  /** Field delimiter. */
  fieldDelimiter: string;
  /** Determines if column headers should be included. */
  includeHeaders: boolean;
  // /** Determines if table data for each document should be included. */
  // includeTableData: boolean;
}

/** Export Config Dialog. */
@Component({
  selector: 'app-export-config-dialog',
  templateUrl: './export-config-dialog.component.html',
  styleUrls: ['./export-config-dialog.component.scss'],
})
export class ExportConfigDialogComponent implements OnInit {
  /** Export config form. */
  exportConfigForm: UntypedFormGroup;

  /**
   * Get Export config form controls.
   *
   * @returns Export config form controls.
   */
  get controls() {
    return this.exportConfigForm.controls;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogReference: MatDialogReference<ExportConfigDialogComponent>,
    private translate: TranslocoService
  ) {}

  /** Event handler for the cancel button click. */
  clickCancel(): void {
    this.dialogReference.close();
  }

  /**
   * Gets the form error message for a control.
   *
   * @param control Form control.
   * @returns Error message.
   */
  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return this.translate.translate('REQUIRED');
    }

    return '';
  }

  ngOnInit(): void {
    this.exportConfigForm = this.formBuilder.group({
      fieldDelimiter: new UntypedFormControl(',', [Validators.required]),
      withHeaders: new UntypedFormControl(true),
    });
  }

  /**
   * Event handler for onSubmit.
   *
   * @listens this.onSubmit
   */
  onSubmit(): void {
    if (this.exportConfigForm.invalid) {
      return;
    }

    const result: ExportConfigResult = {
      fieldDelimiter: this.controls.fieldDelimiter.value,
      includeHeaders: this.controls.withHeaders.value,
    };

    this.dialogReference.close(result);
  }
}
