<div>
  <mat-toolbar>
    <span>{{ tableField?.name }}</span>
    <span class="spacer"></span>
    <ng-container *ngIf="!isGuest">
      <button
        mat-icon-button
        [disabled]="disableEdit"
        [matTooltip]="'ADD_ROW' | transloco"
        (click)="onClickAddRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="disableEdit"
        [matTooltip]="'DELETE_ROW' | transloco"
        (click)="onClickDelete()"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="showSave"
        [matTooltip]="'SAVE' | transloco"
        [disabled]="disableEdit || !isDirty"
        (click)="onClickSave()"
      >
        <mat-icon>save</mat-icon>
      </button>
    </ng-container>
    <button
      mat-icon-button
      [matTooltip]="'CLOSE' | transloco"
      (click)="onClickClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <ag-grid-angular
    #tableFieldGrid
    class="ag-theme-material"
    [gridOptions]="gridOptions"
  >
  </ag-grid-angular>
</div>
