import { TableField, TableFields } from 'models';

/**
 * Converts an API table field to a table field.
 *
 * @param apiTableField S9API Table Field.
 * @returns A table field.
 */
export const createTableFieldFromApi = (
  apiTableField: S9ApiTableField
): TableField => ({
  id: apiTableField.ID,
  name: apiTableField.Name,
  fieldIds: apiTableField.Fields,
});

/**
 * Converts an array of API table fields to an array of table fields.
 *
 * @param apiTableFields Array of API table fields.
 * @returns An array of table fields.
 */
export const createTableFieldsFromApi = (
  apiTableFields: S9ApiTableField[]
): TableFields => apiTableFields.map(createTableFieldFromApi);

export interface S9ApiTableField {
  Fields: number[];
  ID: number;
  Name: string;
}
