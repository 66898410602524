<button
  aria-label="Open Table Field Button"
  *ngIf="!showLabel"
  [disabled]="disabled"
  mat-icon-button
  (click)="onTableFieldClicked($event)"
>
  <mat-icon [ngClass]="{ 'active-table-field': isActive$ | async }"
    >table_view</mat-icon
  >
</button>
<button
  aria-label="Open Table Field Button"
  *ngIf="showLabel"
  [disabled]="disabled"
  mat-button
  (click)="onTableFieldClicked($event)"
>
  <mat-icon [ngClass]="{ 'active-table-field': isActive$ | async }"
    >table_view</mat-icon
  >
  <span>{{ tableField.name }}</span>
</button>
