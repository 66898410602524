<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<mat-dialog-content>
  <div class="full-panel-width">
    <app-multi-value-field [field]="field" [form]="formControlArray">
    </app-multi-value-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    <span transloco="CANCEL"></span>
  </button>
  <ng-container *ngIf="showEditControls">
    <button mat-button (click)="onClear()">
      <span transloco="CLEAR"></span>
    </button>
    <span class="spacer"></span>
    <button mat-button *ngIf="showEditControls" (click)="onAddClicked()">
      <span transloco="ADD"></span>
    </button>
    <button mat-button [disabled]="disableSave" (click)="onSave()">
      <span transloco="SAVE"></span>
    </button>
  </ng-container>
</mat-dialog-actions>
