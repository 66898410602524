import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';

import { assert, assertExists } from 'common';
import {
  AdvancedLink,
  DXCMatch,
  DXCSource,
  Field,
  FieldDataType,
  FieldValues,
  Permissions,
  TableFields,
  UserFriendlyError,
} from 'models';
import { DirtyComponent } from 'src/app/models';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StateToolbarService } from 'src/app/services/state-toolbar.service';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';
import { UiService } from 'src/app/services/ui.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { ArchivesService } from 'src/app/state/archives/archives.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { IndexerStateQuery } from 'src/app/state/indexer/indexer.query';
import { IndexerStateService } from 'src/app/state/indexer/indexer.service';

import { map } from 'rxjs';
import { AdvancedLinkQuery } from 'src/app/state/advanced-links/advanced-links.query';
import { DXCMultipleMatchSelectionDialogComponent } from '../dxc-multiple-match-selection-dialog/dxc-multiple-match-selection-dialog.component';
import { ListFieldBaseComponent } from '../field-components/list-field-base.component';
import { MultiValueFieldComponent } from '../field-components/multi-value-field/multi-value-field.component';
import { FieldComponent } from '../field/field.component';

/**
 * Describes an indexer field.
 *
 * **These values are not two-way bound to form controls.**
 *
 * They are loaded to the
 * control at initialization, then copied back into with current values at save.
 * Setting values to them directly will not update the values.
 *
 * You must use the FormControls to set values.
 */
export interface IndexerField extends Field {
  /**
   * Multi Value array.
   *
   * @see {@link IndexerField} for warning on binding.
   */
  multiValues: string[];
  /**
   * Value.
   *
   * @see {@link IndexerField} for warning on binding.
   */
  value: string;
}

/** Indexer Component. */
@UntilDestroy()
@Component({
  selector: 'app-indexer',
  templateUrl: './indexer.component.html',
  styleUrls: ['./indexer.component.scss'],
})
export class IndexerComponent
  implements OnInit, OnDestroy, OnChanges, DirtyComponent
{
  /** Archive Id. */
  @Input()
  archiveId: number;
  /** Database Id. Defaults to the active database. */
  @Input()
  databaseId: number = this.databasesQuery.activeId;
  /** Emits when a field is blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /** Query list of field components. */
  @ViewChildren(FieldComponent)
  fieldComponents: QueryList<FieldComponent>;
  /** Emits when a field is focused. */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Field values. */
  @Input()
  fieldValues: FieldValues;
  /** Document Id. */
  @Input()
  id: number;
  /** List of all the field components for the MV field. */
  @ViewChildren(MultiValueFieldComponent)
  multiValueFieldComponents: QueryList<MultiValueFieldComponent>;
  /** Document permissions. */
  @Input()
  permissions: Permissions;
  /** Document Secure Id. */
  @Input()
  secureId: string;
  /**
   * Unique id for a document used as an alternative way to reload the indexer.
   */
  @Input()
  uniqueId: string;
  /** Current viewer page number. */
  @Input()
  viewerPageNumber: number;
  /**
   * Indexer fields.
   * @TODO this might be ideally immutable
   */
  indexerFields: IndexerField[] = [];
  /** Indexer form. */
  indexerForm: UntypedFormGroup;
  /**
   * The last field that was focused in the indexer.
   *
   * This value should not be used to assume a field is currently focused.
   * It only seeks to indicate the last field that was focused.
   */
  lastFocusedField?: IndexerField;
  /**
   * Table Fields.
   * @todo Remove this? It doesn't seem to be used anywhere...
   */
  tableFieldList: TableFields[] = [];

  private advancedLinks: AdvancedLink[];
  private useCompact = false;

  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private archivesService: ArchivesService,
    private appQuery: ApplicationQuery,
    private advancedLinkQuery: AdvancedLinkQuery,
    private indexerStateService: IndexerStateService,
    private indexerStateQuery: IndexerStateQuery,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private layout: LayoutService,
    private tableFieldService: TableFieldUIService,
    private ui: UiService,
    private stateToolbarService: StateToolbarService
  ) {
    this.layout.useCompactLayout$
      .pipe(untilDestroyed(this))
      .subscribe((useCompact) => (this.useCompact = useCompact));
  }

  /**
   * If the save button should be disabled.
   *
   * @returns True if the save button should be disabled.
   */
  get disableSave(): boolean {
    return !this.indexerForm.dirty || this.indexerForm.invalid;
  }

  /**
   * If the indexer form is disabled.
   *
   * @returns True if the indexer form is disabled.
   */
  get indexerFormDisabled(): boolean {
    return this.indexerForm.disabled;
  }

  /**
   * Sets the indexer form as disabled or enabled.
   */
  set indexerFormDisabled(disabled: boolean) {
    if (disabled) {
      this.indexerForm.disable();
    } else {
      this.indexerForm.enable();
    }
  }

  /** @inheritdoc */
  get isDirty(): boolean {
    return this.indexerForm.dirty;
  }

  /**
   * Gets the table fields for the archive.
   *
   * @returns An array of table fields.
   */
  get tableFields(): TableFields {
    return this.archivesQuery.getTableFields(this.archiveId);
  }

  /**
   * Gets the active archive.
   *
   * @returns An archive.
   */
  private get archive() {
    const archive = this.archivesQuery.getEntity(this.archiveId);
    assertExists(
      archive,
      `An archive with id '${this.archiveId}' must exist in the store.`
    );
    return archive;
  }

  /**
   * Gets if the document is an import.
   *
   * @returns True if the document should be considered an import.
   */
  private get isImport(): boolean {
    return this.id === 0;
  }

  /**
   * Adds a value to a multi-value field.
   *
   * This value should be pre-validated.
   *
   * @param fieldId Field id.
   * @param value Value to be added.
   * @param clear If the field data should be cleared first.
   */
  addValueToMultiValueField(
    fieldId: number,
    values: string[],
    clear = false
  ): void {
    const field = this.indexerFields.find((f) => f.id === fieldId);
    assertExists(field, 'Field must exist in the indexer.');
    assert(field.multiValue, 'Field must be multi-value.');
    const mvFormControlArray = this.getMultiValueFormArray(fieldId);
    const existingValues = mvFormControlArray.value as string[];

    // Remove existing values from the control if requested.
    if (clear) existingValues.length = 0;

    existingValues.push(...values);

    // Start fresh with an empty form array for the control.
    mvFormControlArray.clear();
    // Add all the values to the form array.
    for (const value of existingValues) {
      // Skip empty values.
      if (!value) continue;
      const control = new UntypedFormControl(value);
      control.markAsTouched();
      mvFormControlArray.push(control);
    }

    // Ensure the final form array is not empty so it doesn't disappear from the indexer.
    if (mvFormControlArray.length === 0) {
      mvFormControlArray.push(new UntypedFormControl(''));
    }

    mvFormControlArray.markAsDirty();

    if (this.fieldHasList(field)) {
      // We need to get the mv field component and loop through its field components to ensure no list controls remain open.
      const mvFieldComponent = this.multiValueFieldComponents.find(
        (f) => f.field.id === fieldId
      );
      assertExists(mvFieldComponent, 'MultiValue Field component must exist.');
      for (const fieldComponent of mvFieldComponent.fieldComponents) {
        this.ensureListControlIsClosed(fieldComponent);
      }
    }
  }

  /**
   * Applies the given DXC match.
   *
   * @param match DXC match.
   */
  applyDataXChangeMatch(match: DXCMatch): void {
    for (const fieldValue of match.values) {
      // TODO These lines related to setting the form fields might be better served as a function on the indexer component called setField or something.

      const field = this.indexerFields.find((f) => f.id === fieldValue.id);
      assertExists(field, 'Field must exist.');

      if (field.multiValue) {
        const mvFormControlArray = this.getMultiValueFormArray(fieldValue.id);
        const values = this.appQuery.dxcAppendToMultivalue
          ? (mvFormControlArray.value as string[]) // untyped forms make this value any but we know they are strings.
          : [];

        // Check if the match contains multi values and if so use them otherwise use the field value.
        const valuesToAdd =
          fieldValue.multiValue.length > 0
            ? fieldValue.multiValue
            : [fieldValue.value];

        values.push(...valuesToAdd);

        // Start fresh with an empty form array for the control.
        mvFormControlArray.clear();
        // Add all the values to the form array.
        for (const value of values) {
          // Skip empty values.
          if (!value) continue;
          mvFormControlArray.push(new UntypedFormControl(value));
        }

        // Ensure the final form array is not empty so it doesn't disappear from the indexer.
        if (mvFormControlArray.length === 0) {
          mvFormControlArray.push(new UntypedFormControl(''));
        }

        mvFormControlArray.markAsDirty();
      } else {
        // Regular field.
        this.setFieldValue(fieldValue.id, fieldValue.value);
      }
    }

    this.notify.success('DATA_XCHANGE_MATCH_APPLIED');
  }

  /**
   * Gets an advanced link for a field.
   *
   * @param fieldId Field id.
   * @returns An array advanced links that match the current archive and provided field id.
   */
  getAdvancedLinksForField(fieldId: number): AdvancedLink[] {
    const advancedLinks = this.advancedLinks.filter(
      (a) =>
        (a.archiveId === this.archiveId || a.archiveId === 0) &&
        a.fieldId === fieldId
    );
    return advancedLinks;
  }

  /**
   * Gets the current form values from the indexer in the original field values object.
   *
   * @returns Field values as currently present in the indexer.
   */
  getFieldValuesForSave(): FieldValues {
    for (const indexField of this.indexerFields) {
      // get new values
      if (indexField.multiValue) {
        const formArray = this.indexerForm.get(
          indexField.id.toString()
        ) as UntypedFormArray;
        // Remove all empty MV field form controls.
        for (let index = formArray.length - 1; index >= 0; index--) {
          const control = formArray.at(index);
          if (!control.value && index !== 0) {
            formArray.removeAt(index);
          } else if (!control.value && index === 0) {
            control.setValue('');
          }
        }
        // clear array.
        indexField.multiValues.length = 0;
        // get values from all the mv form controls.
        for (const [, control] of Object.entries(formArray.controls)) {
          const value = this.getFormValue(control, indexField);
          indexField.multiValues.push(value);
        }
      } else {
        const control = this.indexerForm.get(
          indexField.id.toString()
        ) as UntypedFormControl;
        if (!control) {
          continue;
        }

        const value = this.getFormValue(control, indexField);
        indexField.value = value;
      }
    }

    const fieldValues: FieldValues = this.indexerFields.map((indexerField) => ({
      id: indexerField.id,
      value: indexerField.value,
      multiValue: indexerField.multiValues,
    }));

    if (this.appQuery.persistArchiveImportData) {
      this.logger.debug('Persisting archive import data.', fieldValues);
      this.indexerStateService.set(`${this.databaseId}-${this.archiveId}`, {
        fieldValues,
      });
    }

    return fieldValues;
  }

  /**
   * Get a form control.
   *
   * @param fieldId Field Id.
   *
   * @returns A form control.
   */
  getFormControl(fieldId: number): UntypedFormControl {
    return this.indexerForm.get(fieldId.toString()) as UntypedFormControl;
  }

  /**
   * Gets the backing form array for a multivalue field.
   *
   * @param fieldId Field Id.
   * @returns A form array.
   */
  getMultiValueFormArray(fieldId: number): UntypedFormArray {
    return this.indexerForm.get(fieldId.toString()) as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const archiveIdChanges = changes.archiveId;
    const idChanges = changes.id;
    const uniqueIdChanges = changes.uniqueId;
    const pageNumberChanges = changes.viewerPageNumber;
    const permissionsChanges = changes.permissions;

    if (
      permissionsChanges &&
      !permissionsChanges.isFirstChange() &&
      permissionsChanges.currentValue !== permissionsChanges.previousValue
    ) {
      this.setPermissions();
    }

    if (
      typeof pageNumberChanges !== 'undefined' &&
      pageNumberChanges.currentValue !== pageNumberChanges.previousValue
    ) {
      this.logger.debug('Page number changed', pageNumberChanges.currentValue);
    }

    if (
      (!idChanges?.isFirstChange() &&
        idChanges?.currentValue !== idChanges?.previousValue) ||
      (!archiveIdChanges?.isFirstChange() &&
        archiveIdChanges?.currentValue !== archiveIdChanges?.previousValue) ||
      (!uniqueIdChanges?.isFirstChange() &&
        uniqueIdChanges?.currentValue !== uniqueIdChanges?.previousValue)
    ) {
      // The document changed so reload.
      this.loadIndexerFields();
    }
  }

  ngOnDestroy(): void {
    /* Destroy is not called when the indexer is within the sidebar. This is because closing
    a sidebar does not destroy the components within. This currently does not matter since
    the document toolbar goes away at the same time as the indexer but it seemed worth noting here.
    */

    // Remove state toolbar controls.
    this.stateToolbarService.remove('indexer_save');

    // Unregister component from the UI.
    this.ui.unregister(this);
  }

  ngOnInit(): void {
    this.loadIndexerFields();

    this.advancedLinkQuery.advancedLinks$
      .pipe(
        untilDestroyed(this),
        map((advancedLinks) =>
          advancedLinks.filter(
            (a) =>
              (a.archiveId === this.archiveId || a.archiveId === 0) &&
              a.fieldId > 0
          )
        )
      )
      .subscribe((advancedLinks) => {
        this.advancedLinks = advancedLinks;
      });

    // Register as active UI component.
    this.ui.register(this);
  }

  /**
   * Handler for the advanced link click event.
   *
   * @param advancedLink Advanced link.
   */
  onClickAdvancedLink(advancedLinks: AdvancedLink[]): void {
    for (const advancedLink of advancedLinks) {
      let url = advancedLink.urlPattern;
      url = url.replace(
        new RegExp('{{Document ID}}', 'gi'),
        this.id.toString()
      );
      url = url.replace(
        new RegExp('{{Archive ID}}', 'gi'),
        this.archiveId.toString()
      );
      // Loop all fields to run url replacements.
      for (const field of this.indexerFields) {
        const fieldFormControl = this.getFormControl(field.id);
        if (fieldFormControl.value) {
          url = url.replaceAll(
            '{{' + field.name + '}}',
            encodeURIComponent(fieldFormControl.value)
          );
          url = url.replaceAll(
            '{{!' + field.name + '}}',
            fieldFormControl.value
          );
        }
      }
      window.open(url, '_blank');
    }
  }

  /**
   * Handler for the field blurred event.
   *
   * @param field Field that was blurred.
   */
  onFieldBlur(field: Field): void {
    this.logger.debug('Blurred field: ', field);
    this.lastFocusedField = undefined;
    this.fieldBlurred.emit(field);
  }

  /**
   * Handler for the field focused event.
   *
   * @param field Field that was focused.
   */
  onFieldFocus(field: Field): void {
    this.logger.debug('Focused field: ', field);
    this.lastFocusedField = field as IndexerField;
    this.fieldFocused.emit(field);
  }

  /**
   * Runs the provided DXC source.
   *
   * @param source DXC source.
   */
  runDataXChange(source: DXCSource): void {
    this.logger.debug('Fields', this.indexerForm);
    const fieldItems: FieldValues = [];
    for (const field of this.indexerFields) {
      if (field.multiValue) {
        // TODO do we need to implement?
        this.logger.warn('MV fields not yet supported.');
        continue;
      }

      if (field.systemField && !this.appQuery.dxcSearchSystemFields) {
        this.logger.debug(
          'System field skipped in DXC because dxcSearchSystemFields is off.'
        );
        continue;
      }

      const fieldForm = this.indexerForm.get(`${field.id}`);
      if (!fieldForm) {
        this.logger.error('Field was not found in the indexer form.', field);
        continue;
      }

      if (fieldForm.value) {
        fieldItems.push({
          id: field.id,
          value: fieldForm.value,
          multiValue: [],
        });
      }
    }

    this.logger.debug('Fields to send to DXC.', fieldItems);
    this.archivesService.api
      .runDataXChange(this.databaseId, this.archiveId, source.id, fieldItems)
      .subscribe({
        next: (matches) => {
          // TODO all the translations or remove the notifications.
          this.logger.debug('Matches returned: ', matches);
          switch (matches.length) {
            case 0:
              this.notify.warning('No matches found.');
              break;
            case 1:
              this.applyDataXChangeMatch(matches[0]);
              break;
            default:
              this.logger.debug(`${matches.length} matches found.`, matches);
              this.dialog
                .open(DXCMultipleMatchSelectionDialogComponent, {
                  data: matches,
                  maxWidth: this.useCompact ? '95vw' : '80vw',
                })
                .afterClosed()
                .subscribe((result) => {
                  this.logger.debug(
                    'DXC match selection dialog closed',
                    result
                  );
                  if (result) {
                    this.applyDataXChangeMatch(result);
                  }
                });
          }
        },
        error: (error: UserFriendlyError) => {
          this.notify.error(error);
        },
      });
  }

  /**
   * Sets a fields value. This should be pre-validated.
   *
   * @param fieldId Field ID.
   * @param value Value for the field.
   */
  setFieldValue(fieldId: number, value: string): void {
    const fieldControl = this.indexerForm.get(`${fieldId}`);
    const fieldComponent = this.fieldComponents.find(
      (f) => f.field.id === fieldId
    );
    assertExists(fieldComponent, 'Field component must exist.');
    assertExists(fieldControl, 'Field control must exist.');
    fieldControl.setValue(value);
    fieldControl.markAsDirty();
    if (this.fieldHasList(fieldComponent.field)) {
      this.ensureListControlIsClosed(fieldComponent);
    }
  }

  private ensureListControlIsClosed(component: FieldComponent): void {
    // We know list components implement the ListFieldBaseComponent interface so this cast is ok.
    (
      component.fieldComponent as unknown as ListFieldBaseComponent
    ).closeListPanel();
  }

  private getFormValue(control: AbstractControl, field: Field): string {
    let value = control.value;
    if (field.type === FieldDataType.date) {
      value = this.getISODate(value);
    }

    // Ensure that undefined is turned to an empty string the API can accept.
    return value ?? '';
  }

  private getISODate(value: string): string {
    return moment(value).utc().toISOString();
  }

  private fieldHasList(field: Field): boolean {
    return (
      field.list.listId > 0 ||
      field.list.primary > 0 ||
      field.list.secondary > 0
    );
  }

  private loadIndexerFields(): void {
    const controls: {
      [key: string]: AbstractControl<any, any>;
    } = {};

    this.indexerFields.length = 0;

    const fields = this.archivesQuery
      .getFields(this.archiveId)
      .filter(
        (field) =>
          this.isNotInTableField(field) &&
          field.systemField !== 'documentVersion' &&
          field.systemField !== 'documentVersionParent'
      );

    for (const field of fields) {
      // get the field value
      let fieldValue = this.fieldValues.find((f) => f.id === field.id);
      if (!fieldValue) {
        this.logger.debug('Field was not found in field values list.', field);
        this.logger.debug('Adding field with empty values.');
        fieldValue = {
          ...field,
          value: '',
          multiValue: [],
        };
      }

      const indexerField: IndexerField = {
        ...field,
        value: fieldValue.value,
        multiValues: fieldValue.multiValue,
      };

      this.indexerFields.push(indexerField as IndexerField);

      if (field.multiValue) {
        if (!fieldValue.multiValue) {
          this.logger.warn(
            'MV field value is undefined and not an empty array.'
          );
        }
        const mvControls =
          fieldValue.multiValue.length === 0
            ? [new UntypedFormControl()]
            : fieldValue.multiValue.map(
                (value) => new UntypedFormControl(value)
              );
        controls[field.id.toString()] = this.formBuilder.array(mvControls);
      } else {
        const control = new UntypedFormControl(
          fieldValue.value,
          Validators.maxLength(field.length)
        );
        controls[field.id.toString()] = control;
      }
    }

    this.indexerForm = this.formBuilder.group(controls);
    this.indexerForm.markAllAsTouched();
    this.setPermissions();
    // Check if there is an active table field and if it is this document
    const activeTableField = this.tableFieldService.activeTableField;
    if (activeTableField && activeTableField.documentId !== this.id) {
      this.logger.debug(
        'Active table field is not the document open in the indexer. Switching the table field to the active document...'
      );
      this.tableFieldService.switchActiveDocument(this.id, this.secureId);
    }

    if (this.isImport) {
      this.logger.debug('The document id is 0 so assuming import.');
      this.indexerForm.markAsDirty();
      this.populateFieldsFromQueryString();
      this.populateFieldsFromStore();
      setTimeout(() => {
        this.fieldComponents.first.fieldComponent.focus();
      });
    }
  }

  /**
   * Checks if a given field is not included in any table field.
   *
   * @param {Field} field - The field to check for inclusion in table fields.
   * @return {unknown} Whether the field is not included in any table field.
   */
  private isNotInTableField(field: Field): unknown {
    return !this.tableFields.some((tableField) =>
      tableField.fieldIds.includes(field.id)
    );
  }

  /**
   * Populates field data values from the 'fielddata' query parameter into the indexer.
   */
  private populateFieldsFromQueryString() {
    if (this.route.snapshot.queryParamMap.has('fielddata')) {
      const fieldDataQueryString =
        this.route.snapshot.queryParamMap.get('fielddata');
      assertExists(
        fieldDataQueryString,
        'Field data query parameters must exist.'
      );

      const fieldData = JSON.parse(atob(fieldDataQueryString)) as {
        [key: number]: string;
      };

      this.logger.debug(
        'Field data was provided in the query string.',
        fieldData
      );

      for (const fieldId of Object.keys(fieldData)) {
        this.logger.debug(
          `Attempting to populate value for field ${fieldId} into the indexer.`
        );
        this.indexerForm
          .get(`${fieldId}`)
          ?.setValue(fieldData[Number(fieldId)]);
      }
    }
  }

  private populateFieldsFromStore(): void {
    if (!this.appQuery.persistArchiveImportData) {
      return;
    }
    const previousIndexerData = this.indexerStateQuery.getIndexerValues(
      `${this.databaseId}-${this.archiveId}`
    );

    if (!previousIndexerData) {
      this.logger.debug('No previous indexer data was found.');
      return;
    }

    this.logger.debug('Populating previous import data.', previousIndexerData);

    for (const fieldValue of previousIndexerData.fieldValues) {
      const field = this.indexerFields.find((f) => f.id === fieldValue.id);
      assertExists(field, `Field ${fieldValue.id} must exist.`);
      if (field.multiValue) {
        const formControlArray = this.getMultiValueFormArray(fieldValue.id);
        for (const value of fieldValue.multiValue) {
          formControlArray.push(new UntypedFormControl(value));
        }
        // Remove the empty value created during indexer initialization.
        formControlArray.removeAt(0);
      } else {
        this.indexerForm.get(`${fieldValue.id}`)?.setValue(fieldValue.value);
      }
    }
  }

  private setPermissions(): void {
    const hasPermissions =
      this.permissions.modifyData ||
      (this.id === 0 && this.permissions.addNewDocuments);
    if (!hasPermissions) {
      // Disable the indexer form if the user cannot edit data.
      this.indexerFormDisabled = true;
    }
  }
}
