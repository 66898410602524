import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription, fromEvent } from 'rxjs';

/** Menu Base class. */
@Component({
  template: '',
})
export abstract class MenuBaseComponent {
  /** Mat Menu Trigger Reference. */
  @ViewChild(MatMenuTrigger, { static: true })
  matMenuTrigger: MatMenuTrigger;
  /** Menu Position styling. */
  menuRootStyle: {
    /** Left position in px. */
    'left.px': number;
    /** Top position in px. */
    'top.px': number;
  };

  private listenerSubscription: Subscription;
  private menuOpen = false;

  /** Handler for the menu close event. */
  onMenuClosed(): void {
    this.listenerSubscription.unsubscribe();
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   */
  open(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    // Record the mouse position in our object.
    this.menuRootStyle = {
      'left.px': mouseEvent.clientX,
      'top.px': mouseEvent.clientY,
    };

    // Open the menu
    this.menuOpen = true;
    this.matMenuTrigger.openMenu();
    this.listenerSubscription = fromEvent(window, 'contextmenu').subscribe(
      (event) => {
        if (this.menuOpen) {
          event.preventDefault();
          this.matMenuTrigger.closeMenu();
          this.menuOpen = false;
          event.target?.dispatchEvent(new MouseEvent('click', event));
        }
      }
    );
  }
}
