import { Injectable } from '@angular/core';

import { UrlState, UrlStore } from './url.store';

/** URL Service. */
@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private urlStore: UrlStore) {}

  /**
   * Updates the URL store.
   *
   * @param state Url State.
   */
  update(state: Partial<UrlState>): void {
    this.urlStore.update(state);
  }
}
