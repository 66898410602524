import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CSVImportJob, UserFriendlyError } from 'models';

import {
  CSVImportDialogComponent,
  CSVImportDialogResult,
} from '../components/csvimport-dialog/csvimport-dialog.component';
import { CSVImportJobService } from '../state/csv-import-jobs/csvimport-job.service';

import { NotificationService } from './notification.service';

/** CSV import dialog service. */
@Injectable({
  providedIn: 'root',
})
export class CSVImportDialogService {
  constructor(
    private logger: NGXLogger,
    private notify: NotificationService,
    private dialog: MatDialog,
    private csvImportJobService: CSVImportJobService
  ) {}

  /**
   * Start a CSV import.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   *
   * @throws An error saying 'Cancelled' if the import dialog is closed without a result.
   * @returns An observable job ID.
   */
  createAndStartCSVImportJob(
    databaseId: number,
    archiveId: number
  ): Observable<string> {
    const dialog = this.dialog.open(CSVImportDialogComponent, {
      disableClose: true,
      data: {
        databaseId,
        archiveId,
      },
    });
    return dialog.afterClosed().pipe(
      tap((result: CSVImportDialogResult) => {
        if (!result) throw new Error('Cancelled');
        this.csvImportJobService
          .getJobListener(result.jobId)
          .subscribe((job) => this.onGetJob(job));
      }),
      map((result: CSVImportDialogResult) => result.jobId)
    );
  }

  private onGetJob(job: CSVImportJob): void {
    this.logger.debug('CSV import job checked.', job);
    if (job.status.cancelled) {
      this.notify.warning('IMPORT_JOB_WAS_CANCELLED');
    } else if (job.status.done) {
      if (job.status.erroredRows > 0) {
        this.notify.warning('IMPORT_JOB_COMPLETED_WITH_ERRORS');
      } else {
        this.notify.success('IMPORT_JOB_COMPLETED_SUCCESSFULLY');
        this.csvImportJobService.deleteJob(job.id).subscribe({
          next: () =>
            this.logger.debug(
              'Completed CSV import job was deleted successfully.',
              job
            ),
          error: (error: UserFriendlyError) =>
            this.logger.error(
              'An error occurred while attempting to delete completed job.',
              job,
              error
            ),
        });
      }
    }
  }
}
