import { NgModule } from '@angular/core';
import { TranslocoModule, provideTransloco } from '@jsverse/transloco';
import { environment } from '../environments/environment';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        // The real list of available languages is loaded in app.component.ts by the listenForSelectedLanguage function.
        availableLangs: ['english'],
        defaultLang: 'english',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
