import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assert, assertExists } from 'common';
import { JSONPath } from 'jsonpath-plus';
import {
  LiveField,
  LiveFieldInjectableAPI,
  LiveFieldInjectableTableField,
  SearchResult,
  TableField,
} from 'models';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { v4 } from 'uuid';
import { IndexerField } from '../components/indexer/indexer.component';
import { TableFieldGridComponent } from '../components/table-field-grid/table-field-grid.component';
import { AppConfigQuery } from '../modules/app-config';
import { ApplicationQuery } from '../state/application/application.query';
import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification.service';
import { TableFieldUIService } from './table-field-ui.service';
import { UiService } from './ui.service';
@Injectable({
  providedIn: 'root',
})
export class LiveFieldService {
  /**
   * Initializes a new instance of the LiveField class.
   */
  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private notify: NotificationService,
    private auth: AuthenticationService,
    private config: AppConfigQuery,
    private app: ApplicationQuery,
    private ui: UiService,
    private tableFieldUiService: TableFieldUIService
  ) {}

  /**
   * Executes the Live Field.
   *
   * The following steps are performed:
   * - Validate the Live Field.
   * - If a URL is provided it will be, checked for replacement effects and
   *   queried (along with any body or headers)using the provided method.
   *   - If a JSON path is provided it will be used to filter the response data
   *   - If no script is provided the result above is returned.
   *   - If a script is provided it will be provided with the above value.
   * - If a script is provided it will be evaluated with the "$$inject" context
   *   included.
   *
   * @return The result of the execution.
   */
  execute(liveField: LiveField): Observable<string> {
    assert(
      liveField.status === 'NOT_STARTED',
      'LiveField has already been executed.'
    );
    liveField.setStatus('IN_PROGRESS');
    // Apply any replacement effects to the configured LiveField strings (URL,Body,Headers).
    liveField.url = this.applyReplacement(liveField.url);
    liveField.body = this.applyReplacement(liveField.body);
    for (const header in liveField.headers) {
      liveField.headers[header] = this.applyReplacement(
        liveField.headers[header]
      );
    }
    // Handle the case where only a query is used.
    if (liveField.url && !liveField.script) {
      return this.queryLiveField(liveField).pipe(
        /*
         * If query is successful, set the status. When script in
         * use, it will be handled by executeScript.
         */
        tap(() => {
          if (liveField.status === 'IN_PROGRESS')
            liveField.setStatus('COMPLETED');
        })
      );
    }
    // Handle the case where both a query and script are used.
    if (liveField.script && liveField.url) {
      return this.queryLiveField(liveField).pipe(
        switchMap((result) => {
          return this.executeScript(liveField, result);
        })
      );
    }
    // Handle where only a script is used.
    if (liveField.script && !liveField.url) {
      return this.executeScript(liveField);
    }
    // We should never reach this point.
    throw new Error('LiveField has no script or URL provided.');
  }

  /**
   * Applies any replacement effects requested to a string value.
   *
   * Values inside of curly braces will be checked for matches to the
   * replacement ruleset.
   *
   * The following are supported:
   *  - Optional prefixes:
   *    - `!` - No encoding performed on value.
   *    - `#` - Base64 encoding performed on value.
   *    - (default) - No prefix results in URL fragment encoding to sanitize.
   *  - Followed by `field_name` or `field_value_`
   *  - Multivalue fields will be joined with commas.
   *  - All other values will be returned as-is.
   *  - The above can be followed by either the **field name** or the **field Id** number.
   *
   * @link https://square9softworks.atlassian.net/wiki/spaces/DEV/pages/1614578072/Live+Fields
   *
   * @param str String to check and apply replacement effects to.
   * @return String with replacement effects applied.
   */
  private applyReplacement(str: string): string {
    // Need the indexer to be loaded.
    const registeredIndexer = this.ui.state().indexer;
    assertExists(
      registeredIndexer,
      'Indexer is required for replacement effects.'
    );
    // Dictionary of replacements requested.
    const replacements: Record<string, string> = {};
    // Parse the supported replacement effects.
    const re = /\{(!|#)?(field_name_|field_value_)(.+?)\}/g;
    let match;
    // Loop for each match.
    while ((match = re.exec(str)) !== null) {
      if (match.index === re.lastIndex) {
        re.lastIndex++; // Avoid infinite loop.
      }
      const requestedReplacement = match[0];
      // Check if we already mapped this replacement, skip.
      if (replacements[requestedReplacement]) continue;

      // Determine replacement value.
      const encoding =
        match[1] === '!' ? 'NONE' : match[1] === '#' ? 'BASE64' : 'URL';
      const wantsNameNotValue = match[2] === 'field_name_';
      const fieldNameOrId = match[3];
      const wantsFieldById = fieldNameOrId.match(/\d+/); // Checkf if it's a number.
      // Determine replacement value.
      let indexField: IndexerField | undefined =
        registeredIndexer.indexerFields.find((f) =>
          // Select field by name or Id.
          wantsFieldById
            ? f.id === Number(fieldNameOrId)
            : f.name.toLowerCase() === fieldNameOrId.toLowerCase()
        );
      assertExists(indexField, `Indexer field ${fieldNameOrId} not found.`);
      // TODO: Do we need to use updated values here? If so we need to call indexer.getFieldValuesForSave()
      const value = wantsNameNotValue
        ? indexField.name
        : // If it's a multivalue field, join the values, otherwise return the string value.
          indexField.multiValue
          ? indexField.multiValues.join(',')
          : indexField.value;
      // Add the replacement to the list of replacements.
      replacements[requestedReplacement] = applyEncoding(encoding, value);
    }

    // Apply all replacements.
    for (const [requestedReplacement, value] of Object.entries(replacements)) {
      str = str.replace(requestedReplacement, value);
    }

    // Output the replaced string.
    return str;

    /** Handles encoding the provided string based on encoding type prefex. */
    function applyEncoding(encoding: 'NONE' | 'BASE64' | 'URL', value: string) {
      switch (encoding) {
        case 'NONE':
          return value;
        case 'BASE64':
          return btoa(value);
        case 'URL':
          return encodeURIComponent(value);
      }
    }
  }

  /**
   * Creates the LiveFieldInjectableAPI for the given LiveField.
   *
   * This API provides access to various components and utilities that can be used
   * within a LiveField script. It includes access to the document, fields, and
   * utility functions such as logging and notification.
   *
   * @param [result] - The result of the LiveField's API query, if any.
   * @param [hasTableFields] - Whether the LiveField contains table fields.
   * @return The created API as an object for injection.
   */
  private createInjectableApi(
    result?: string,
    hasTableFields = false
  ): LiveFieldInjectableAPI {
    // Determine open archive document.
    const registeredComponents = this.ui.state();
    const viewer = registeredComponents.documentViewer;
    assertExists(viewer);

    let doc: SearchResult | undefined;
    try {
      doc = viewer.documentAsSearchResult;
    } catch (err) {
      this.logger.debug(
        "LiveField can't find document, values will not be available for indexing/importing."
      );
    }

    // Create the API.
    return {
      result,
      fields: this.createFieldAccessDictionary(),
      tableFields: hasTableFields
        ? this.createTableFieldAccessDictionary()
        : {},
      properties: {
        authToken: this.auth.user.token,
        config: this.config.appConfig,
        document: {
          id: doc ? doc.id : 0,
          hash: doc ? doc.secureId : '',
          databaseId: viewer.databaseId,
          archiveId: viewer.archiveId,
          fileId: 'NO_CACHE',
        },
      },
      utility: {
        newGuid: function (): string {
          return v4(); // UUIDv4 (aka. GUID)
        },
      },
      notify: {
        info: (message: string): void => {
          this.notify.info(message);
        },
        warn: (message: string): void => {
          this.notify.warning(message);
        },
        error: (message: string): void => {
          this.notify.error(message);
        },
        success: (message: string): void => {
          this.notify.success(message);
        },
      },
      log: {
        log: (message: string): void => this.logger.log(message),
        info: (message: string): void => this.logger.info(message),
        warn: (message: string): void => this.logger.warn(message),
        error: (message: string): void => this.logger.error(message),
        critical: (message: string): void => this.logger.error(message),
        debug: (message: string): void => this.logger.debug(message),
      },
      setPendingChanges: (): void => this.setPendingChanges(),
      save: (): void => this.requestSave(),
    };
  }

  /**
   * Creates a dictionary of table field access objects for the currently open table field.
   *
   * The dictionary contains a single entry for the open table field, with the table field name as the key.
   * The table field access object provides methods for adding rows, clearing the table, and accessing the raw data.
   *
   * @return A dictionary of table field access objects.
   */
  private createTableFieldAccessDictionary(): {
    [key: string]: LiveFieldInjectableTableField;
  } {
    // Use the open table field.
    const registeredComponents = this.ui.state();
    try {
      assertExists(
        registeredComponents.tableField,
        'Table field does not exist.'
      );
    } catch (e) {
      // If one is not open, and we haven't forced one open.. return an empty object.
      return {};
    }
    // The correct table field should be open before this function is called.
    const tableFieldName = registeredComponents.tableField.tableField?.name;
    assertExists(
      tableFieldName,
      `LiveField can not be loaded, table field name does not exist: "${tableFieldName}"`
    );

    const agGridColumns =
      registeredComponents.tableField.grid.api.getAllDisplayedColumns();

    const tableFields = {
      // We are just acting like the open one is the only one.
      [tableFieldName]: {
        columns: Object.fromEntries(
          registeredComponents.tableField.grid.api
            .getAllDisplayedColumns()
            .map((col) => [
              col.getColDef().headerName ?? col.getColId(),
              {
                /** Gets the sum of the values in the table field column. */
                get $$sum() {
                  let sum = 0;
                  assertExists(registeredComponents.tableField);
                  for (const row of getRowDataMap(
                    registeredComponents.tableField
                  )) {
                    const value = row[col.getColId()];
                    // TODO: this might need better parsing.
                    sum += value ? Number.parseFloat(value.toString()) : 0;
                  }
                  return sum;
                },
              },
            ])
        ),
        /** Add a new row to the table field. */
        $$add: (arr: string[]) => {
          assertExists(registeredComponents.tableField);
          // Create an object mapping column name to value.
          const arrayAsRowObject = Object.fromEntries(
            agGridColumns.map((col, index) => [col.getColId(), arr[index]])
          );
          // Use a transaction to insert the row.
          registeredComponents.tableField.grid.api.applyTransaction({
            add: [arrayAsRowObject],
          });
          registeredComponents.tableField.forceSetGridDirty(true);
          this.logger.debug('Row added to table field.', arr);
        },
        /** Clear the table field. */
        $$clear: () => {
          assertExists(registeredComponents.tableField);
          registeredComponents.tableField.grid.api.setGridOption('rowData', []);
          registeredComponents.tableField.forceSetGridDirty(true);
          this.logger.debug('Table field cleared.');
        },
        /** Get the raw data from the table field. */
        $$rawData: () => {
          assertExists(registeredComponents.tableField);
          return getRowData(registeredComponents.tableField);
        },
        /** Get the number of rows in the table field. */
        get $$rowCount() {
          assertExists(registeredComponents.tableField);
          return getRowData(registeredComponents.tableField).length;
        },
      },
    };

    /** Get a 2D array of the current data. */
    function getRowData(tableFieldComponent: TableFieldGridComponent) {
      const rowData: string[][] = [];
      tableFieldComponent.grid.api.forEachNode((row) => {
        assertExists<Record<string, string>>(row.data);
        rowData.push(Object.values(row.data));
      });
      return rowData;
    }

    /** Get a array of mapped current data. */
    function getRowDataMap(tableFieldComponent: TableFieldGridComponent) {
      const rowData: Record<string, string>[] = [];
      tableFieldComponent.grid.api.forEachNode((row) => {
        assertExists<Record<string, string>>(row.data);
        rowData.push(row.data);
      });
      return rowData;
    }

    return tableFields;
  }

  /**
   * Creates a dictionary of field accessors, allowing for the retrieval and
   * modification of field values.
   *
   * @return A dictionary of field accessors, where each key is the name of a field and each value is the corresponding field value.
   */
  private createFieldAccessDictionary(): Record<string, string> {
    const registeredComponents = this.ui.state();
    assertExists(registeredComponents.indexer);
    const fields: Record<string, string> = {};
    for (const field of registeredComponents.indexer.indexerFields) {
      Object.defineProperty(fields, field.name, {
        /**
         * Gets the value of the field.
         *
         * @return The value of the field.
         */
        get: (): string => {
          // TODO: Do we need to use updated values here? If so we need to call indexer.getFieldValuesForSave()
          return field.multiValue ? field.multiValues.join(',') : field.value;
        },
        /**
         * Sets the value of a field, handling both multi-value and non-multi-value fields.
         *
         * @param value - The new value to set for the field.
         */
        set: (value: string | string[]) => {
          assertExists(
            registeredComponents.indexer,
            'LiveField requires the indexer be registered.'
          );
          if (field.multiValue) {
            registeredComponents.indexer.addValueToMultiValueField(
              field.id,
              Array.isArray(value) ? value : value.split(',')
            );
          } else {
            registeredComponents.indexer.setFieldValue(
              field.id,
              Array.isArray(value) ? value.join(',') : value
            );
          }
        },
      });
    }
    return fields;
  }

  /**
   * Initiates a save request for the current document.
   */
  private requestSave(): void {
    const registeredComponents = this.ui.state();
    assertExists(registeredComponents.documentViewer);
    this.logger.debug('LiveField requesting save of document.');
    if (!registeredComponents.documentViewer.saveInProgress)
      registeredComponents.documentViewer.onClickSave();
    else this.logger.debug('LiveField save aborted, save already in progress.');
  }

  /**
   * Sets the pending changes for the indexer form in the registered components.
   */
  private setPendingChanges(): void {
    const registeredComponents = this.ui.state();
    assertExists(registeredComponents.indexer);
    this.logger.debug('LiveField requesting dirty document state.');
    // Set the indexer form as dirty.
    registeredComponents.indexer.indexerForm.markAsDirty();
  }

  /**
   * Executes the script of a LiveField.
   *
   * @param liveField - The LiveField to execute.
   * @param [result] - The result of a previous operation.
   * @return The result of the script execution.
   */
  private executeScript(
    liveField: LiveField,
    result?: string
  ): Observable<string> {
    try {
      assert(
        liveField.isValid(),
        'LiveField is invalid and will not be executed.'
      );

      // If a tablefield is used in the script, check if we have that one and open it.
      // Create a regular expression that finds strings like "tableFields['TABLENAME']" including valid linebreaks and whitespaces.
      const referencedTableFieldNames =
        this.getReferencedTableFieldNames(liveField);

      if (referencedTableFieldNames.length > 0) {
        if (referencedTableFieldNames.length > 1) {
          // Currently there is not an ideal way to handle multiple table fields, but we don't really support that anyway.
          throw new Error(
            'LiveFields currently only support referencing a single table field.'
          );
        }
        // for (const tableFieldName of referencedTableFieldNames) {
        // Since we can only support a single table field, we just take the first one.
        const tableFieldName = referencedTableFieldNames[0];
        // Confirm that the table field exists in the archive.
        return this.openTableFieldAndExecuteLiveField(
          tableFieldName,
          liveField,
          result
        );
        // } // END for (const tableFieldName of referencedTableFieldNames)
      } else {
        // No tablefields in LiveField.
        return this.injectAndExecute(liveField, result);
      }
    } catch (e) {
      liveField.setStatus('COMPLETED_WITH_ERRORS');
      this.logger.error(e);
      return EMPTY;
    }
  }

  /**
   * Opens the specified table field and executes the given LiveField.
   *
   * @param tableFieldName - The name of the table field to open.
   * @param liveField - The LiveField to execute.
   * @param result - The result of the LiveField execution.
   * @return An observable that emits the result of the inject and execute operation.
   */
  private openTableFieldAndExecuteLiveField(
    tableFieldName: string,
    liveField: LiveField,
    result: string | undefined
  ) {
    const registeredComponents = this.ui.state();
    assertExists(registeredComponents.indexer);
    const matchedTableField: TableField | undefined =
      registeredComponents.indexer.tableFields.find(
        (f) => tableFieldName.toLowerCase() === f.name.toLowerCase()
      );
    assertExists(
      matchedTableField,
      'Table field ' + tableFieldName + ' not found in archive.'
    );
    // Open the table field.
    const viewer = registeredComponents.documentViewer;
    assertExists(viewer);
    let doc: SearchResult | undefined;
    try {
      doc = viewer.documentAsSearchResult;
    } catch (err) {
      this.logger.debug(
        "LiveField can't find document, table field values will not be available for indexing/importing."
      );
    }
    this.logger.debug(`LiveField opening table field '${tableFieldName}'.`);
    const tableDocId = doc ? doc.id : 0;
    const tableDocSecureId = doc ? doc.secureId : '';
    this.tableFieldUiService.update(
      matchedTableField,
      tableDocId,
      tableDocSecureId
    );
    // We need to wait for the tableFieldComponent to be ready.
    return this.tableFieldUiService.onTableFieldLoaded.pipe(
      take(1), // We only want the next table field to open, not subsequent ones.
      switchMap((activeTableField) => {
        // Confirm the load event matched the table field.
        assert(
          activeTableField.tableField === matchedTableField &&
            tableDocId === activeTableField.documentId &&
            tableDocSecureId === activeTableField.documentSecureId
        );
        // Run the inject and execute once the table is ready.
        return this.injectAndExecute(liveField, result, true);
      })
    );
  }

  /**
   * Executes the given LiveField by injecting the provided result into the
   * script and setting the result and status.
   *
   * @param liveField - The LiveField to execute.
   * @param result - The result to inject into the LiveField script.
   * @param hasTableFields - Whether the LiveField contains table fields.
   * @return An observable that emits the result of the LiveField execution.
   */
  private injectAndExecute(
    liveField: LiveField,
    result: string | undefined,
    hasTableFields: boolean = false
  ): Observable<string> {
    // Create the injectable API.
    const injectableApi = this.createInjectableApi(result, hasTableFields);
    // Create a function context of the script, with the "$$inject" context.
    const scriptFunction = new Function('$$inject', liveField.script);
    try {
      // Execute the script with the injectable API provided.
      const scriptResult = scriptFunction(injectableApi) as string | undefined;
      // If the script had a return value, set the result.
      if (scriptResult || scriptResult === '') {
        liveField.setResult(scriptResult);
      }
    } catch (e) {
      // Log the error, and mark the failure.
      liveField.setStatus('COMPLETED_WITH_ERRORS');
      this.logger.error(e);
      return EMPTY;
    }
    // Mark the field as completed and return the result.
    liveField.setStatus('COMPLETED');
    return of(liveField.result);
  }

  /**
   * Retrieves the names of table fields referenced in the LiveField's script.
   *
   * @param liveField - The LiveField instance to extract table field references from.
   * @return An array of table field names referenced in the LiveField's script.
   */
  private getReferencedTableFieldNames(liveField: LiveField): string[] {
    const liveFieldRegEx =
      /tableFields[\s\n]*\[[\s\n]*["'](.+?)["'][\s\n]*\]/gm;
    const tableFieldsReferenced = [];
    let tableFieldReferenceMatch;
    while (
      (tableFieldReferenceMatch = liveFieldRegEx.exec(liveField.script)) !==
      null
    ) {
      if (tableFieldReferenceMatch.index === liveFieldRegEx.lastIndex)
        liveFieldRegEx.lastIndex++; // Avoid infinite loop.
      tableFieldsReferenced.push(tableFieldReferenceMatch[1]);
      this.logger.debug(
        `LiveField referenced table field '${tableFieldReferenceMatch[1]}'`
      );
    }
    // Return with any duplicates removed.
    return [...new Set(tableFieldsReferenced)];
  }

  /**
   * Query the provided URL in the LiveField.
   *
   * @param liveField - The LiveField to query.
   */
  private queryLiveField(liveField: LiveField): Observable<string> {
    // Create the request.
    let request: HttpRequest<unknown>;
    switch (liveField.method) {
      case 'GET':
        request = new HttpRequest(liveField.method, liveField.url, {
          reportProgress: false,
          responseType: 'text',
          headers: new HttpHeaders(liveField.headers),
        });
        break;
      case 'POST':
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
        request = new HttpRequest(
          liveField.method,
          liveField.url,
          liveField.body,
          {
            reportProgress: false,
            responseType: 'text',
            headers: new HttpHeaders(liveField.headers),
          }
        );
        break;
      default:
        throw new Error('Unsupported method: ' + liveField.method);
    }

    // Execute the request and return the response.
    return this.http.request<string>(request).pipe(
      catchError((httpError) => {
        liveField.setStatus('COMPLETED_WITH_ERRORS');
        this.logger.error(httpError);
        return EMPTY;
      }),
      // Only interested in the response, not progress.
      filter((event) => event.type === HttpEventType.Response),
      map((event) => {
        assert(event.type === HttpEventType.Response);
        var result = event.body ?? '';
        if (liveField.jsonPath) {
          // Filter result using jsonPath.
          try {
            const parsedObject = JSONPath({
              path: liveField.jsonPath,
              json: JSON.parse(result),
            }) as unknown;
            result = JSON.stringify(parsedObject);
          } catch {
            // Log, and return empty string.
            this.logger.error(
              `LiveField failed to parse JSONPath response with expression (${liveField.jsonPath}): ${result}`
            );
            return '';
          }
        }
        liveField.setResult(result);
        return result;
      })
    );
  }
}
