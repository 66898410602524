import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationProvider, License } from 'models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { S9ApiLicense, createLicenseFromApi } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API Authentication http access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiAuthenticationService implements AuthenticationProvider {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.apiUrl = apiUrl));
  }

  /** @inheritdoc */
  checkRestriction(token: string): Observable<boolean> {
    return this.http
      .get<{ Restricted: boolean }>(
        `${this.apiUrl}/licenses?token=${token}&checkRestricted`
      )
      .pipe(
        map((response: { Restricted: boolean }) => {
          return response.Restricted;
        })
      );
  }

  /** @inheritdoc */
  login(name: string, password: string): Observable<License> {
    return this.http
      .post<S9ApiLicense>(
        `${this.apiUrl}/licenses`,
        {},
        {
          headers: new HttpHeaders().set(
            'Authorization',
            `Basic ${window.btoa(`${name}:${password}`)}`
          ),
        }
      )
      .pipe(map((apiLicense) => createLicenseFromApi(apiLicense)));
  }

  /** @inheritdoc */
  loginWithJwt(jwtToken: string, provider: string): Observable<License> {
    return this.http
      .post<S9ApiLicense>(
        `${this.apiUrl}/licenses`,
        {},
        {
          headers: new HttpHeaders({ jwt: jwtToken, provider }),
        }
      )
      .pipe(map((apiLicense) => createLicenseFromApi(apiLicense)));
  }

  /** @inheritdoc */
  loginWithNtlm(): Observable<License> {
    return this.http
      .post<S9ApiLicense>(
        `${this.apiUrl}/licenses`,
        {},
        { headers: new HttpHeaders({ useNtlm: 'true' }), withCredentials: true }
      )
      .pipe(map((apiLicense) => createLicenseFromApi(apiLicense)));
  }

  /** @inheritdoc */
  loginWithToken(licenseToken: string): Observable<License> {
    return this.http
      .get<S9ApiLicense>(`${this.apiUrl}/licenses`, {
        params: {
          token: licenseToken,
        },
      })
      .pipe(map((apiLicense) => createLicenseFromApi(apiLicense)));
  }

  /** @inheritdoc */
  logout(token: string): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/licenses/${token}`, {
        params: new HttpParams().set('logout', 'true'),
      })
      .pipe(catchError(() => of(void 0)));
  }

  /** @inheritdoc */
  validate(token: string): Observable<boolean> {
    return this.http
      .get<S9ApiLicense>(`${this.apiUrl}/licenses`, {
        params: {
          noForce: true,
          token,
        },
      })
      .pipe(
        map(() => true),
        catchError(() => {
          return of(false);
        })
      );
  }
}
