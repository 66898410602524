<ng-container *ngIf="favoriteSearch">
  <div
    matRipple
    class="highlight-hover sidebar-group-item no-buttons"
    [ngClass]="{ active: isActiveSearch }"
    matTooltip="{{ search.name }}"
    matTooltipShowDelay="1000"
    matTooltipPosition="right"
    (click)="onClickSearch()"
  >
    <span class="ellipsis">{{ favoriteSearch.label }}</span>
  </div>
</ng-container>
