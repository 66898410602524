<h1 mat-dialog-title>
  <span transloco="IMPORT_DATA_AND_DOCUMENTS"></span>
</h1>
<div mat-dialog-content>
  <mat-stepper
    linear
    #stepper
    (selectionChange)="onStepSelectionChange($event)"
  >
    <mat-step [stepControl]="csvFileUploadFormGroup">
      <form [formGroup]="csvFileUploadFormGroup" class="top-margin">
        <ng-template matStepLabel>Choose a CSV file</ng-template>
        <mat-form-field>
          <mat-label>CSV File</mat-label>
          <ngx-mat-file-input
            accept=".csv"
            formControlName="csvFile"
          ></ngx-mat-file-input>
        </mat-form-field>
        <mat-checkbox
          style="margin-left: 20px"
          formControlName="containsColumnHeaders"
          >{{ 'CSV_CONTAINS_COLUMN_HEADERS' | transloco }}</mat-checkbox
        >
        <!-- TODO maybe progress should go to the left of the upload button at the bottom or within the button itself -->
        <!-- <mat-progress-bar
          *ngIf="csvUploadProgress > 0 && csvUploadProgress < 100"
          mode="determinate"
          [value]="csvUploadProgress"
        ></mat-progress-bar> -->
        <div>
          <mat-form-field class="csv-options-field" appearance="outline">
            <input
              matInput
              type="text"
              maxlength="1"
              formControlName="columnDelimiter"
            />
            <mat-hint
              >{{ 'COLUMN' | transloco }}
              {{ 'DELIMITER' | transloco }}</mat-hint
            >
          </mat-form-field>
          <mat-form-field
            class="csv-options-field"
            style="margin-left: 20px"
            appearance="outline"
          >
            <input
              matInput
              type="text"
              maxlength="1"
              formControlName="stringDelimiter"
            />
            <mat-hint>{{ 'STRING_DELIMITER' | transloco }}</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'CHOOSE_FILES' | transloco }}</ng-template>
      <div class="upload-box-content top-margin">
        <div
          class="drop-container"
          ngFileDrop
          [options]="(uploaderOptions$ | async)!"
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="jobUploadInput"
        >
          <p>
            Drag files here or
            <label class="upload-button">
              <input
                #fileUploader
                type="file"
                ngFileSelect
                [options]="(uploaderOptions$ | async)!"
                (uploadOutput)="onUploadOutput($event)"
                [uploadInput]="jobUploadInput"
                (click)="fileUploader.value = ''"
                multiple
              />
              browse
            </label>
            to upload.
          </p>
        </div>
      </div>
      <ng-container *ngIf="jobFiles.length > 0">
        <cdk-virtual-scroll-viewport
          itemSize="88"
          minBufferPx="264"
          maxBufferPx="528"
          class="item-status-container"
        >
          <mat-list>
            <mat-list-item *cdkVirtualFor="let file of jobFiles">
              <span matListItemTitle>{{ file.name }}</span>
              <div matListItemLine>
                <mat-progress-bar
                  mode="determinate"
                  [value]="file.progress.data?.percentage"
                ></mat-progress-bar>
              </div>
              <span
                matListItemLine
                *ngIf="
                  file.progress.data?.percentage !== 100;
                  else uploadCompleteLine
                "
                >{{ file.progress.data?.speedHuman }}
                {{ file.progress.data?.etaHuman }}</span
              >
              <ng-template #uploadCompleteLine
                ><span matListItemLine>{{
                  'UPLOAD_COMPLETE' | transloco
                }}</span></ng-template
              >
            </mat-list-item>
          </mat-list>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel
        ><span transloco="MAP_CSV_FIELDS"></span
      ></ng-template>
      <div
        [ngClass]="{ 'full-panel-width': stepper.selectedIndex === 2 }"
        class="top-margin"
      >
        <ag-grid-angular
          #grid
          class="ag-theme-material"
          [gridOptions]="gridOptions"
        ></ag-grid-angular>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <span transloco="Cancel"></span>
  </button>
  <button mat-button *ngIf="stepper" (click)="onReset()">
    <span transloco="RESET"></span>
  </button>
  <button
    *ngIf="showGoToPreviousStep"
    mat-button
    (click)="onClickToggleIncludeAll(!atLeastOneFieldIncluded)"
  >
    <span
      transloco="{{
        atLeastOneFieldIncluded ? 'EXCLUDE_ALL_COLUMNS' : 'INCLUDE_ALL_COLUMNS'
      }}"
    ></span>
  </button>
  <span class="spacer"></span>
  <button
    mat-button
    *ngIf="stepper.selectedIndex === 0"
    (click)="onStartUpload()"
    style="margin-left: 10px"
    [disabled]="
      csvFileUploadFormGroup.controls.csvFile.invalid ||
      csvFileUploadFormGroup.controls.uploaded.value
    "
  >
    <span transloco="UPLOAD"></span>
  </button>
  <button
    mat-button
    *ngIf="stepper && stepper.selectedIndex > 0"
    (click)="stepper.previous()"
  >
    <span transloco="PREVIOUS"></span>
  </button>
  <button
    mat-button
    *ngIf="showGoToNextStep"
    [disabled]="currentStepInvalid"
    (click)="stepper.next()"
  >
    <span transloco="NEXT"></span>
  </button>
  <button
    mat-button
    *ngIf="showGoToPreviousStep"
    [disabled]="disableImport"
    (click)="onImportDocuments()"
  >
    <span transloco="IMPORT_DOCUMENTS"></span>
  </button>
</div>
