import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { KfiEditComponent } from '../components/kfi-edit/kfi-edit.component';

/** Service for injecting indeterminate kfi edit dialog. */
@Injectable({
  providedIn: 'root',
})
export class KfiEditDialogService {
  /** Reference to the dialog service. */
  dialogRef: MatDialogRef<KfiEditComponent, any>;
  constructor(private dialogService: MatDialog) {}
  /** Close kfi edit dialog. */
  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  /**
   * Confirm kfi edit dialog.
   *
   * @param fieldValue Field value to be returned.
   */
  confirmDialog(fieldValue: string) {
    if (this.dialogRef) {
      this.dialogRef.close(fieldValue);
    }
  }
  /**
   * Open the kfi edit dialog.
   *
   * @param fieldName Field name to be displayed.
   * @param fieldValue Message to display in kfi edit dialog.
   * @returns Observable.
   */
  openKfiEditDialog(fieldName: string, fieldValue: string) {
    const data: KfiEditDialogData = {
      fieldName,
      fieldValue,
    };
    this.dialogRef = this.dialogService.open(KfiEditComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
    return this.dialogRef.afterClosed();
  }
}
/**
 * Interface to define kfi edit dialog data.
 */
export interface KfiEditDialogData {
  /** Field name to pass to header of dialog. */
  fieldName: string;
  /** Field value to pass to the input of the dialog. */
  fieldValue: string;
}
