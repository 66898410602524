import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FieldProvider, Fields } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S9ApiAdminFieldDefinition, createField } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API field service.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiFieldService implements FieldProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = apiUrl));
  }

  /** @inheritdoc */
  getAll(databaseId: number): Observable<Fields> {
    return this.http
      .get<S9ApiAdminFieldDefinition[]>(
        `${this.basePath}/admin/databases/${databaseId}/fields`
      )
      .pipe(map((s9Fields) => s9Fields.map(createField)));
  }
}
