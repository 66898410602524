import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  SearchResult,
  SearchResults,
  TaskSearch,
  TaskSearchesProvider,
} from 'models';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  S9ApiSearchResults,
  S9ApiTaskSearch,
  createSearchResults,
  createTaskSearch,
} from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Global Action Task Searches http access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiTaskSearchService implements TaskSearchesProvider {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.baseUrl = apiUrl));
  }

  /** @inheritdoc */
  getAll(databaseId: number): Observable<TaskSearch[]> {
    // Square9API/api/workflowdesigner?dbid=106&token=4bbd996a-f72e-440c-85c4-c75710f5b295&nodeType=queue
    return this.http
      .get<S9ApiTaskSearch[]>(`${this.baseUrl}/tasksearches`, {
        params: new HttpParams().set('databaseId', databaseId.toString()),
      })
      .pipe(map((apiTaskSearches) => apiTaskSearches.map(createTaskSearch)));
  }

  /** @inheritdoc */
  getById(
    databaseId: number,
    workflowId: string,
    queueKey: string
  ): Observable<TaskSearch> {
    return this.http
      .get<S9ApiTaskSearch[]>(`${this.baseUrl}/tasksearches`, {
        params: new HttpParams()
          .set('databaseId', databaseId)
          .set('workflowid', workflowId)
          .set('queueId', queueKey),
      })
      .pipe(map((apiTaskSearches) => createTaskSearch(apiTaskSearches[0])));
  }

  /** @inheritdoc */
  getCount(
    databaseId: number,
    archiveId: number,
    workflowId: string,
    queueKey: string
  ): Observable<number> {
    return this.http
      .get<S9ApiTaskSearch[]>(`${this.baseUrl}/dbs/${databaseId}/searches`, {
        params: new HttpParams()
          .set('archiveid', archiveId)
          .set('workflowid', workflowId)
          .set('queuekey', queueKey)
          .set('count', true),
      })
      .pipe(map((apiTaskSearch) => apiTaskSearch[0].ProcessCount));
  }

  /** @inheritdoc */
  getQueuedDocument(
    databaseId: number,
    archiveId: number,
    documentId: number
  ): Observable<SearchResult> {
    return this.http
      .get<S9ApiSearchResults>(`${this.baseUrl}/dbs/${databaseId}/searches`, {
        params: new HttpParams()
          .set('archiveId', archiveId)
          .set('docId', documentId)
          .set('action', 'action')
          .set('asSearchResult', true),
      })
      .pipe(
        map((apiSearchResults) => {
          const results = createSearchResults(apiSearchResults);
          // Ensure there is exactly one match.
          if (results.length !== 1) {
            throwError(
              () =>
                new Error(
                  'Get queued document did not return exactly one search result.'
                )
            );
          }

          return results[0];
        })
      );
  }

  /** @inheritdoc */
  runSearch(
    databaseId: number,
    archiveId: number,
    workflowId: string,
    queueKey: string,
    page: number,
    recordsPerPage: number,
    sort: string
  ): Observable<SearchResults> {
    return this.http
      .get<S9ApiSearchResults>(`${this.baseUrl}/dbs/${databaseId}/searches`, {
        params: new HttpParams()
          .set('workflowid', workflowId)
          .set('queuekey', queueKey)
          .set('archiveid', archiveId.toString())
          .set('page', page.toString())
          .set('recordsperpage', recordsPerPage.toString())
          .set('sort', sort),
      })
      .pipe(map((results) => createSearchResults(results)));
  }
}
