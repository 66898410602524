import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Dictionary } from 'models';

/** Redirect service which handles returning to the product. */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private router: Router) {}

  /**
   * Redirects into GlobalSearch.
   *
   * @param returnUrl Return URL to parse.
   */
  redirect(returnUrl: string): void {
    if (!returnUrl) {
      this.router.navigate(['/']);
      return;
    }
    const [path, queryParameters] = returnUrl.split('?');
    returnUrl = path;

    // If there are no query string parameters, navigate now.
    if (!queryParameters) {
      this.router.navigate([returnUrl]);
      return;
    }

    // Decode and remap to apply the return URL query string parameters.
    const returnUrlQueryParameters: Dictionary<string> = {};
    for (const kv of queryParameters.split('&')) {
      let [k, v] = kv.split('=');
      if (k.toLowerCase() === 'prompts') {
        // prompts need to be recreated to prevent rerouting from resulting in an invalid prompt parameter
        const searchPromptString = JSON.parse(atob(decodeURIComponent(v)));
        v = btoa(JSON.stringify(searchPromptString));
      } else {
        // Decode all others, as the naviagation queryParams will re-encode.
        v = decodeURIComponent(v);
      }
      returnUrlQueryParameters[k] = v;
    }

    // Navigate with parameters.
    this.router.navigate([returnUrl], {
      queryParams: returnUrlQueryParameters,
    });
  }
}
