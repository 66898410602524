import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiMetadataProvider } from 'models';
import { Observable } from 'rxjs';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square9 API Metadata Service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiMetadataService implements ApiMetadataProvider {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.apiUrl = apiUrl));
  }

  /** @inheritdoc */
  getVersion(): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/admin?function=version`);
  }
}
