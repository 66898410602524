import { HttpErrorResponse } from '@angular/common/http';
import { assertExists } from 'common';
import { TranslationParameters, UserFriendlyError } from 'models';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';

/**
 * Http Error Handler.
 */
export class HttpErrorHandler {
  private logger: NGXLogger;

  constructor(logger: NGXLogger) {
    this.logger = logger;
  }

  /**
   * Handles an error.
   *
   * @param descriptionMessage Error description message.
   * @param i18n Translation key for the error.
   * @param errorResponse The original HttpErrorResponse.
   * @returns An observable of type never.
   */
  handleError(
    descriptionMessage: string,
    i18n: string,
    errorResponse: HttpErrorResponse
  ): Observable<never> {
    this.logger.error(errorResponse);
    return throwError(() => {
      new UserFriendlyError(
        errorResponse,
        `${descriptionMessage} ${errorResponse.message}`,
        i18n
      );
    });
  }
}

/**
 * Handles errors that occur in functions such as launch and quickbooks.
 *
 * @param userFriendlyError UserFriendlyError.
 * @returns A UserFriendlyError with improved translation messages.
 */
export const handleError = (userFriendlyError: UserFriendlyError) => {
  let errorMessage = (userFriendlyError.error?.error?.Message as string) ?? '';
  if (errorMessage === 'An error has occurred.') {
    // If we get here then GSE may have nested the real error message in the ExceptionMessage property.
    errorMessage =
      (userFriendlyError.error?.error?.ExceptionMessage as string) ??
      errorMessage;
  }
  if (userFriendlyError.i18n === 'HTTP_NORESPONSE_ERR') {
    userFriendlyError.i18n = 'ERROR_UNABLE_TO_CONNECT_TO_GSE';
  }

  // Loop the map of possible errors to try to find a translation key to use.
  for (const [errorMessageKey, translationKey] of GSE_LAUNCH_TRANLATIONS_MAP) {
    if (errorMessage.toLowerCase().includes(errorMessageKey.toLowerCase())) {
      // This assert should be unnecessary since we check if the key is in the map above
      // but it makes the type safety happy.
      assertExists(
        translationKey,
        'Translation key must exist in GSE_LAUNCH_TRANLATIONS_MAP'
      );
      userFriendlyError.i18n = translationKey.i18n;
      userFriendlyError.i18nParameters = translationKey.i18nParameters;
      break;
    }
  }

  return throwError(() => userFriendlyError);
};

/** Translation key. */
interface TranslationKey {
  /** Translation key. */
  i18n: string;
  /** Any parameters to be inserted into the translation key. */
  i18nParameters?: TranslationParameters;
}

const GSE_LAUNCH_TRANLATIONS_MAP = new Map<string, TranslationKey>([
  [
    'An error occurred initializing Outlook',
    { i18n: 'ERROR_GSE_EMAIL_OUTLOOK_INIT_FAILED' },
  ],
  [
    'which is greater than the alloted',
    { i18n: 'ERROR_GSE_LAUNCH_EMAIL_ATTACHMENT_SIZE_EXCEEDED' },
  ],
  [
    'Email launch entry has not been set up',
    { i18n: 'ERROR_GSE_LAUNCH_EMAIL_ENTRY_NOT_CONFIGURED' },
  ],
]);
