import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { assert } from 'common';

import { Command } from '../models/command';

/**
 * Command service.
 *
 * Handles the registration of commands for the command palette.
 */
@Injectable({
  providedIn: 'root',
})
export class CommandService {
  /**
   * Currently registered commands.
   */
  readonly commands: Command[] = [];

  constructor(private logger: NGXLogger) {}

  /**
   * Register a command.
   *
   * @param command Command to register.
   * @returns Function to unregister the commmand.
   */
  register(command: Command): () => void;

  /**
   * Register a new command.
   *
   * @param name Name.
   * @param description Short description.
   * @param action Function to call.
   * @param group Tag to group the command with.
   * @returns Function to unregister the commmand.
   */
  register(
    name: string,
    description: string,
    action: () => void,
    group?: string,
    filterPrefix?: string
  ): () => void;

  /**
   * @inheritdoc
   */
  register(
    commandOrName: string | Command,
    description?: string,
    action?: () => void,
    group?: string,
    filterPrefix?: string
  ) {
    let newCommand: Command;

    if (commandOrName instanceof Command) {
      newCommand = commandOrName;
    } else {
      if (!description) {
        throw new Error(
          'A description is required for registering a new command by name.'
        );
      }
      if (!action) {
        throw new Error(
          'An action is required for registering a new command by name.'
        );
      }
      newCommand = new Command(
        commandOrName,
        description,
        action,
        group,
        filterPrefix
      );
    }

    this.commands.push(newCommand);
    this.logger.debug(`Registered command: "${newCommand.name}"`);

    return () => {
      const index = this.commands.indexOf(newCommand);
      if (index >= 0) {
        this.commands.splice(index, 1);
      }
    };
  }

  /**
   * Remove a matching command from the registered list.
   *
   * Provide a `Command` object, or a name to delete.
   *
   * If a group name is specified, along with the name, it will be used to filter.
   *
   * A name of `"*"` will match all entries, group filter will still apply if given.
   *
   * @param commandOrName Command or name.
   * @param group Group name.
   */
  unregister(commandOrName: string | Command, group?: string): void {
    if (typeof commandOrName == 'string') {
      // Remove command(s) matching the provided name.
      for (let index = this.commands.length - 1; index >= 0; index--) {
        if (group) {
          if (commandOrName === '*') {
            if (this.commands[index].group === group) {
              this.logger.debug('Unregistered command:', this.commands[index]);
              this.commands.splice(index, 1);
            }
          } else if (
            this.commands[index].name === commandOrName &&
            this.commands[index].group === group
          ) {
            this.logger.debug('Unregistered command:', this.commands[index]);
            this.commands.splice(index, 1);
          }
        } else if (this.commands[index].name === commandOrName) {
          this.logger.debug('Unregistered command:', this.commands[index]);
          this.commands.splice(index, 1);
        }
      }
    } else {
      // Remove the exact command.
      assert(commandOrName instanceof Command);
      const matchIndex = this.commands.indexOf(commandOrName);
      if (matchIndex >= 0) {
        this.logger.debug('Unregistered command:', commandOrName);
        this.commands.splice(matchIndex, 1);
      }
    }
  }
}
