import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';

import { NotificationService } from 'src/app/services/notification.service';

import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';

/** DateTime Cell Editor. */
@Component({
  selector: 'app-date-time-cell-editor',
  templateUrl: './date-time-cell-editor.component.html',
  styleUrls: ['./date-time-cell-editor.component.scss'],
})
export class DateTimeCellEditorComponent extends FieldCellEditorBaseComponent {
  constructor(translate: TranslocoService, notifications: NotificationService) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  getValue() {
    const date = moment(this.formControl.value);
    return date.toISOString();
  }

  /** @inheritdoc */
  isCancelAfterEnd?(): boolean {
    let value = this.getValue();
    let valueIsInvalid = this.formControl.invalid;
    if (this.formControl.hasError('maxDateExceeded')) {
      this.showError();
      return true;
    }
    if (typeof this.formControl.value === 'string' && this.formControl.value) {
      // If the value is a string then kfi put it in the control and the date component
      // hasn't had a chance to parse it yet so we need to do it here too.
      const valueAsMoment = moment(this.formControl.value);
      value = valueAsMoment.toISOString();
      valueIsInvalid = !valueAsMoment.isValid();
    }
    if (valueIsInvalid) {
      this.showError();
      return true;
    } else if (this.cellParams.field.required && !value) {
      this.showError();
      return true;
    }

    return valueIsInvalid;
  }
}
