import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { DXCSource } from 'models';
import { ArchivesService } from 'src/app/state/archives/archives.service';

/** DataXChange Sources Menu. */
@Component({
  selector: 'app-dxc-menu',
  templateUrl: './dxc-menu.component.html',
  styleUrls: ['./dxc-menu.component.scss'],
})
export class DxcMenuComponent implements OnInit, OnChanges {
  /** Archive Id. */
  @Input()
  archiveId: number;
  /** Database Id. */
  @Input()
  databaseId: number;
  /** Event emitted when a DXC source is selected. */
  @Output()
  dxcSourceSelected = new EventEmitter<DXCSource>();
  /** Array of DXC Sources. */
  sources: DXCSource[];
  constructor(
    private logger: NGXLogger,
    private archiveService: ArchivesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const archiveIdChanges = changes.archiveId;
    const databaseIdChanges = changes.databaseId;

    if (
      archiveIdChanges &&
      !archiveIdChanges.isFirstChange() &&
      archiveIdChanges.previousValue !== archiveIdChanges.currentValue
    ) {
      this.loadSources();
    } else if (
      databaseIdChanges &&
      !databaseIdChanges.isFirstChange() &&
      databaseIdChanges.previousValue !== databaseIdChanges.currentValue
    ) {
      this.loadSources();
    }
  }

  ngOnInit(): void {
    this.loadSources();
  }

  /**
   * Handler for DXC Source on click event.
   *
   * @param source Source.
   */
  onClickDxcSource(source: DXCSource): void {
    this.logger.debug('Source clicked.', source);
    this.dxcSourceSelected.emit(source);
  }

  private loadSources(): void {
    this.archiveService.api
      .getDataXChangeSources(this.databaseId, this.archiveId)
      .subscribe((sources) => {
        this.logger.debug('DXC Sources loaded.', sources);
        this.sources = sources;
      });
  }
}
