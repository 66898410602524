/**
 * Key names for storage key/value pairs.
 */
export enum StorageKey {
  /** Dark Mode storage key name. */
  darkMode = 'darkMode',
  /** Base Square 9 API URL storage key name. */
  apiUrl = 'apiUrl',
  /** Base GSE API Config storage key name. */
  gseConfig = 'gseConfig',
  /** Authenticated User storage key name. */
  authenticatedUser = 'authenticatedUser',
  /** Databases base storage key. */
  databases = 'databases',
  /** Inboxes base storage key. */
  inboxes = 'inboxes',
  /** Archives base storage key. */
  archives = 'archives',
}

/**
 * Color palette constants.
 */
export enum Color {
  /** The orange we use for our logo. */
  s9Orange = '#f3661c',
  /** Desaturated dark blue. */
  desaturatedDarkBlue = '#607d8b',
  /** Deep Jungle Green. */
  jungleGreen = '#08605f',
  /** Dark accent. */
  darkAccent = desaturatedDarkBlue,
}

/**
 * Value representing a state entity to be set for "active" when no entity is to be active.
 *
 * Akita uses `null` to `.setActive()` to clear the current active entity from state; however, this can be
 * programatically vague to do without a little extra verbosity to show it will happen.
 */
export const NO_ACTIVE_ID = null;

/**
 * Value representing the root archive ID.
 */
export const ROOT_ARCHIVE_ID = 0;

/**
 * Username for guest account.
 */
export const GUEST_USERNAME = 'guest';

/** Pattern to use for matching desired spaces in database names. */
export const DATABASE_NAME_SPACING_PATTERN =
  '(?<!^)(?=[A-Z][a-z])|(?<=[a-z])(?=[A-Z])|_';

/** File types that the internal viewer client supports without API conversion. */
export const INTERNAL_VIEWER_CLIENT_SUPPORTED_FILE_TYPES = [
  '.pdf',
  '.tif',
  '.tiff',
  '.jpg',
  '.jpeg',
  '.png',
];

/** Version number used when the version is the current version of the document. */
export const CURRENT_DOCUMENT_REVISION = 0;
