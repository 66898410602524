<h2 mat-dialog-title transloco="DESTINATION"></h2>
<div mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Archives" *ngIf="!dialogData?.hideArchives">
      <mat-tree
        [dataSource]="archiveDataSource"
        [treeControl]="archiveTreeControl"
        class="archive-tree"
      >
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let archiveNode" matTreeNodeToggle>
          <button
            class="ellipsis"
            mat-button
            [disabled]="!archiveNode.permissions.addNewDocuments"
            (click)="clickDestination(archiveNode)"
          >
            {{ archiveNode.name }}
          </button>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node
          *matTreeNodeDef="let archiveNode; when: archiveHasChildren"
        >
          <div class="mat-tree-node">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + archiveNode.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  archiveTreeControl.isExpanded(archiveNode)
                    ? 'expand_more'
                    : 'chevron_right'
                }}
              </mat-icon>
            </button>
            <button
              class="ellipsis"
              mat-button
              [disabled]="!archiveNode.permissions.addNewDocuments"
              (click)="clickDestination(archiveNode)"
            >
              {{ archiveNode.name }}
            </button>
          </div>
          <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
          <div
            [class.archive-tree-invisible]="
              !archiveTreeControl.isExpanded(archiveNode)
            "
            role="group"
          >
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </mat-tab>
    <mat-tab label="Inboxes" *ngIf="!dialogData?.hideInboxes">
      <mat-selection-list
        [multiple]="false"
        hideSingleSelectionIndicator
        (selectionChange)="onInboxSelectionChange($event)"
      >
        <mat-list-option
          *ngFor="let inbox of inboxes$ | async"
          [value]="inbox"
          [disabled]="!inbox.permissions.addNewDocuments"
          >{{ inbox.name }}</mat-list-option
        >
      </mat-selection-list>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close transloco="CANCEL"></button>
</div>
