import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Crypto from 'crypto-es';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  catchError,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { assertExists } from 'common';
import {
  AuthenticationProvider,
  AuthorizationProvider,
  ChangePasswordRequest,
  License,
  LicenseTypes,
  User,
  UserFriendlyError,
  UserProfileProvider,
} from 'models';

import { CommonStorageService } from '../common-storage.service';
import { GUEST_USERNAME } from '../common/constants';
import {
  AUTHENTICATION_PROVIDER,
  AUTHORIZATION_PROVIDER,
  USER_PROFILE_PROVIDER,
} from '../common/tokens';
import { LoginProvider } from '../modules/login';
import { ApplicationQuery } from '../state/application/application.query';
import { ApplicationService } from '../state/application/application.service';

/**
 * Provides authentication methods and state.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements LoginProvider {
  private get onLoginPage(): boolean {
    return this.router.url.startsWith('/login');
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(AUTHENTICATION_PROVIDER)
    private authenticationProvider: AuthenticationProvider,
    @Inject(AUTHORIZATION_PROVIDER)
    private authorizationProvider: AuthorizationProvider,
    @Inject(USER_PROFILE_PROVIDER)
    private userProfileProvider: UserProfileProvider,
    private logger: NGXLogger,
    private commonStorage: CommonStorageService,
    private applicationQuery: ApplicationQuery,
    private applicationService: ApplicationService,
    private cookies: CookieService
  ) {}

  /**
   * Is the guest user currently logged in.
   *
   * @returns True if the guest user is logged in.
   */
  get isGuest(): boolean {
    return this.isLoggedIn && this.user.name === GUEST_USERNAME;
  }

  /**
   * Is a user currently logged in.
   *
   * @returns True if a user is currently logged in.
   */
  get isLoggedIn(): boolean {
    return typeof this.applicationQuery.getValue().user !== 'undefined';
  }

  /**
   * Is the current user restricted.
   *
   * @returns True if the user is logged in and is restricted.
   */
  get isRestrictedUser(): boolean {
    return this.isLoggedIn && this.user.isRestricted;
  }

  /**
   * Currently logged in user.
   *
   * @returns The current user.
   */
  get user(): User {
    // These errors are breaking things and a work around or refactor will be needed for this to work correctly. It breaks the product.
    if (!this.isLoggedIn) {
      throw new Error(
        'No logged in user. Check for login state with "isLoggedIn()".'
      );
    }
    return this.applicationQuery.getValue().user as User;
  }

  /**
   * Login using basic authentication.
   *
   * @param name Username.
   * @param password Password.
   * @returns An observable of the new logged in user.
   */
  basicLogin(name: string, password: string) {
    this.logger.debug('Attempting basic authentication login.');
    return this.authenticationProvider.login(name, password).pipe(
      map((license) => this.createUserFromCredentials(name, password, license)),
      mergeMap((user) => {
        user.isRestricted = false;
        return this.authorizationProvider.getClaims(user.token).pipe(
          map((claims) => {
            const userWithClaims = new User({ ...user, ...claims });
            this.logger.debug(
              'Succesful login request, storing credentials for interceptor.'
            );
            this.applicationService.setUser(userWithClaims);
            return userWithClaims;
          })
        );
      })
    );
  }

  /**
   * Change the current user's password.
   *
   * @param currentPassword Current password.
   * @param newPassword New password.
   * @returns An observable that completes once the password is changed.
   */
  changePassword(currentPassword: string, newPassword: string) {
    this.logger.debug('Attempting to change password.');
    const request: ChangePasswordRequest = {
      currentPassword,
      newPassword,
    };
    return this.userProfileProvider.changePassword(request).pipe(
      // intercept friendly error and try to insert proper translation keys.
      catchError((errorResponse: UserFriendlyError) => {
        if (
          errorResponse.error?.error?.Message.includes(
            'Current password is incorrect'
          )
        ) {
          errorResponse.i18n = 'ERROR_CURRENT_PASSWORD_INCORRECT';
        }
        return throwError(() => errorResponse);
      }),
      switchMap(() => {
        this.logger.debug('Password changed successfully.');
        return this.logout();
      })
    );
  }

  /**
   * Clear any stored authentication data.
   */
  clear() {
    this.commonStorage.resetAllUserStores();
    this.applicationService.clearUser();
    this.clearAuthCookies();
  }

  /** @inheritdoc */
  licenseTokenLogin(licenseToken: string): Observable<User> {
    return this.authenticationProvider.loginWithToken(licenseToken).pipe(
      map((license) =>
        this.createUserFromLicenseToken(license.username, license)
      ),
      switchMap((user) =>
        forkJoin([
          of(user),
          this.authorizationProvider.getClaims(user.token),
          this.authenticationProvider.checkRestriction(user.token),
        ])
      ),
      map(([user, claims, isRestricted]) => {
        user.isRestricted = isRestricted;
        const userWithClaims = new User({ ...user, ...claims });
        this.logger.debug(
          'Succesful login request, storing credentials for interceptor.'
        );
        this.applicationService.setUser(userWithClaims);
        return userWithClaims;
      })
    );
  }

  /**
   * Logout the currently authenticated user.
   *
   * @param includeReturnUrl Optional indicating if the current path should be provided as a return url during login.
   * @param error Optional error to include on login page. Must contain the 'i18n' property.
   * @returns An observable boolean indicating if logout was successful.
   */
  logout(
    includeReturnUrl: boolean = false,
    error?: UserFriendlyError
  ): Observable<boolean> {
    if (error) {
      assertExists(error.i18n, 'Error must have a translation key.');
    }
    // todo not sure if the code below should be in a separate function
    // don't do anything if already on the login page.
    if (this.onLoginPage && !error) return of(false);

    const extras: NavigationExtras = includeReturnUrl
      ? {
          queryParams: {
            returnUrl:
              this.route.snapshot.queryParamMap.get('returnUrl') ??
              this.router.url,
            error: error ? btoa(error.i18n) : '',
          },
        }
      : {};

    return from(this.router.navigate(['/login'], extras)).pipe(
      switchMap((navigationSuccessful) => {
        if (!navigationSuccessful) return of(false);
        const logout$ = !this.user
          ? EMPTY
          : this.authenticationProvider.logout(this.user.token);
        return logout$.pipe(
          map(() => {
            this.clear();
            this.applicationService.setPdfPreviewUrl('');
            return true;
          })
        );
      })
    );
  }

  /**
   * Logs the user out without trying to redirect.
   *
   * The consumer of this function needs to manually redirect the user to the login page.
   * @returns An observable that emits true if logout is successful.
   */
  logoutWithoutRedirect(): Observable<boolean> {
    return this.authenticationProvider.logout(this.user.token).pipe(
      map(() => {
        this.clear();
        this.applicationService.setPdfPreviewUrl('');
        return true;
      })
    );
  }

  /**
   * Login using Window NTLM authentication.
   *
   * @returns An observable user.
   */
  ntlmLogin(): Observable<User> {
    return this.authenticationProvider.loginWithNtlm().pipe(
      map((license) => this.createUserFromNtlm(license.username, license)),
      mergeMap((user) => {
        user.isRestricted = false;
        return this.authorizationProvider.getClaims(user.token).pipe(
          map((claims) => new User({ ...user, ...claims })),
          tap((userWithClaims) => {
            this.logger.debug(
              'Successful login request, storing credentials for interceptor.'
            );
            this.applicationService.setUser(userWithClaims);
          })
        );
      })
    );
  }

  /**
   * Login using provider authentication.
   *
   * @param jwtToken JWT Token.
   * @param provider Authentication provider.
   * @returns An observable of the new logged in user.
   */
  tokenLogin(jwtToken: string, provider: string): Observable<User> {
    this.logger.debug('Attempting token authentication login');
    return this.authenticationProvider.loginWithJwt(jwtToken, provider).pipe(
      map((license) =>
        this.createUserFromJwtToken(
          license.username,
          provider,
          jwtToken,
          license
        )
      ),
      mergeMap((user) => {
        user.isRestricted = false;
        return this.authorizationProvider
          .getClaims(user.token, user.provider)
          .pipe(
            map((claims) => new User({ ...user, ...claims })),
            tap((userWithClaims) => {
              this.logger.debug(
                'Successful login request, storing credentials for interceptor.'
              );
              this.applicationService.setUser(userWithClaims);
            })
          );
      })
    );
  }

  /**
   * Validate current login.
   *
   * @returns An observable that is True if current login is valid.
   */
  validate(): Observable<boolean> {
    if (!this.user) {
      this.logger.debug('No login to validate.');
      return of(false);
    }
    this.logger.debug('Validating current login.');
    const token = this.user.token;
    return this.authenticationProvider.validate(token);
  }

  /** @inhertidoc */
  validateLicenseToken(licenseToken: string): Observable<boolean> {
    return this.authenticationProvider.validate(licenseToken);
  }

  private clearAuthCookies() {
    this.cookies.delete('sstoken');
    this.cookies.delete('provider');
    this.cookies.delete('authenticatedUser');
  }

  /**
   * Create a User object from username and password.
   *
   * @param name Username.
   * @param password Password.
   * @param license License.
   * @description Password will be stored as a SHA256 hash.
   * @returns A user object.
   */
  private createUserFromCredentials(
    name: string,
    password: string,
    license: License
  ) {
    return new User({
      name,
      password: Crypto.SHA512(password).toString(Crypto.enc.Hex),
      authData: window.btoa(`${name}:${password}`),
      token: license.token,
      isReadOnly: license.type === LicenseTypes.readOnly,
      provider: 'ui',
    });
  }

  private createUserFromNtlm(name: string, license: License) {
    return new User({
      name,
      token: license.token,
      isReadOnly: license.type === LicenseTypes.readOnly,
      provider: 'ui',
    });
  }

  private createUserFromJwtToken(
    name: string,
    provider: string,
    jwtToken: string,
    license: License
  ): User {
    return new User({
      name,
      provider,
      token: license.token,
      isReadOnly: license.type === LicenseTypes.readOnly,
      jwtToken,
    });
  }

  private createUserFromLicenseToken(name: string, license: License): User {
    return new User({
      name,
      token: license.token,
      isReadOnly: license.type === LicenseTypes.readOnly,
      provider: 'ui',
    });
  }
}
