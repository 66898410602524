import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  switchMap,
  throwError,
} from 'rxjs';

import {
  ArchiveDocumentTransferResult,
  InboxDocumentTransferResult,
  SearchResults,
  UserFriendlyError,
} from 'models';

import {
  DestinationSelectDialogComponent,
  DestinationSelectionResult,
  DestinationSelectionType,
} from '../components/destination-select-dialog/destination-select-dialog.component';
import { ArchivesService } from '../state/archives/archives.service';

import { NotificationService } from './notification.service';

/** Document transfer service. */
@Injectable({
  providedIn: 'root',
})
export class DocumentTransferService {
  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    private notifications: NotificationService,
    private archivesService: ArchivesService
  ) {}

  /**
   * Start a document transfer from an archive.
   *
   * Throws an error if the user cancels the destination selection.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param searchResults Search results to transfer.
   * @param move Whether to move the document to the destination. Otherwise copy it.
   * @returns Observable array of either n archive or an inbox document transfer results.
   */
  startDocumentTransferFromArchive(
    databaseId: number,
    archiveId: number,
    searchResults: SearchResults,
    move: boolean
  ): Observable<
    ArchiveDocumentTransferResult[] | InboxDocumentTransferResult[]
  > {
    this.logger.debug('Starting document transfer.');
    const dialogReference = this.dialog.open(DestinationSelectDialogComponent, {
      minWidth: 400,
    });
    return dialogReference
      .afterClosed()
      .pipe(
        switchMap((result: DestinationSelectionResult) =>
          this.handleDestinationDialogClose(
            databaseId,
            archiveId,
            searchResults,
            move,
            result
          )
        )
      );
  }

  private handleDestinationDialogClose(
    databaseId: number,
    archiveId: number,
    searchResults: SearchResults,
    move: boolean,
    result: DestinationSelectionResult
  ): Observable<
    ArchiveDocumentTransferResult[] | InboxDocumentTransferResult[]
  > {
    if (!result) {
      this.logger.debug('Destination dialog was closed without a selection.');
      return throwError(
        () =>
          new UserFriendlyError(
            undefined,
            'Operation cancelled.',
            'OPERATION_CANCELLED_MSG'
          )
      );
    }

    this.logger.debug('Destination selected', result);
    switch (result.type) {
      case DestinationSelectionType.archive:
        return this.transferArchiveDocumentsToArchive(
          databaseId,
          archiveId,
          result,
          searchResults,
          move
        );
      case DestinationSelectionType.inbox:
        return this.transferArchiveDocumentsToInbox(
          databaseId,
          archiveId,
          result,
          searchResults,
          move
        );
      default:
        return throwError(
          () =>
            new UserFriendlyError(
              undefined,
              'Document(s) were not transfered because the destination type is not yet supported.',
              'NOT_TRANSFERED_DESTINATION_TYPE_MSG'
            )
        );
    }
  }

  /**
   * Handles transfer errors.
   *
   * @param error Error.
   * @param documentId Document Id.
   * @returns An empty observable.
   */
  private handleTransferError(
    error: UserFriendlyError,
    documentId: number
  ): Observable<never> {
    error.description = `Document with ID ${documentId} failed to transfer.`;
    this.notifications.error(error);
    return EMPTY;
  }

  /**
   * Transfer a document to an archive.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param destinationResult Destination result.
   * @param searchResults SearchResults to transfer.
   * @param move Determines if the document should be moved. Otherwise it will be copied.
   * @returns An observable array of transfer results.
   */
  private transferArchiveDocumentsToArchive(
    databaseId: number,
    archiveId: number,
    destinationResult: DestinationSelectionResult,
    searchResults: SearchResults,
    move: boolean
  ): Observable<ArchiveDocumentTransferResult[]> {
    const transfers$: Observable<ArchiveDocumentTransferResult>[] = [];
    for (const searchResult of searchResults) {
      const transfer$ = move
        ? this.archivesService.api.moveDocument(
            databaseId,
            archiveId,
            searchResult.id,
            searchResult.secureId,
            destinationResult.id
          )
        : this.archivesService.api.copyDocument(
            databaseId,
            archiveId,
            searchResult.id,
            searchResult.secureId,
            destinationResult.id
          );
      transfer$.pipe(
        catchError((error: UserFriendlyError) =>
          this.handleTransferError(error, searchResult.id)
        )
      );
      transfers$.push(transfer$);
    }

    return combineLatest(transfers$);
  }

  /**
   * Transfer an archive document to an inbox.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param destinationResult Destination result.
   * @param searchResults SearchResults to transfer.
   * @param move Determines if the document should be moved. Otherwise it will be copied.
   * @returns An observable array of transfer results.
   */
  private transferArchiveDocumentsToInbox(
    databaseId: number,
    archiveId: number,
    destinationResult: DestinationSelectionResult,
    searchResults: SearchResults,
    move: boolean
  ): Observable<InboxDocumentTransferResult[]> {
    const transfers$: Observable<InboxDocumentTransferResult>[] = [];
    for (const searchResult of searchResults) {
      this.logger.debug(
        `Transfering document to the ${destinationResult.name} inbox.`,
        searchResult
      );
      const transfer$ = move
        ? this.archivesService.api.moveDocumentToInbox(
            databaseId,
            archiveId,
            searchResult.id,
            searchResult.secureId,
            destinationResult.id
          )
        : this.archivesService.api.copyDocumentToInbox(
            databaseId,
            archiveId,
            searchResult.id,
            searchResult.secureId,
            destinationResult.id
          );

      transfer$.pipe(
        catchError((error: UserFriendlyError) =>
          this.handleTransferError(error, searchResult.id)
        )
      );

      transfers$.push(transfer$);
    }

    return combineLatest(transfers$);
  }
}
