import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/** Describes an instance provider. */
export interface InstanceProvider {
  /** Base URL for an instance. */
  instanceUrl$: Observable<string>;
  /**
   * Updates the base URL.
   *
   * @param instanceUrl New base instance URL.
   */
  update(instanceUrl: string): void;
}

/** Instance provider. */
export const INSTANCE_PROVIDER = new InjectionToken<InstanceProvider>(
  'INSTANCE_PROVIDER'
);
