import { Dictionary } from './dictionary';
import { FieldValues } from './field';
import { Permissions } from './permissions';
import { SearchOperator } from './properties.enum';

/**
 * Search object.
 */
export interface Search {
  /** Advanced grouping string. */
  advancedGrouping: string;
  /** Archive IDs included in search. */
  archives: number[];
  /** Description of search. */
  description: string;
  /**
   * Material icon name.
   *
   * @todo It should be documented that they can find values from: https://fonts.google.com/icons?icon.set=Material+Icons.
   */
  icon: string;
  /** Search ID. */
  id: number;
  /** Is default search. */
  isDefault: boolean;
  /** Is favorited search (Queue search). */
  isFavorite: boolean;
  /** Name. */
  name: string;
  /** Search parameters. */
  parameters: SearchParameter[];
  /** Parent archive ID. */
  parentArchive: number;
  /** Secure ID hash. */
  secureIdHash: string;
  /** Search settings. */
  settings: SearchSettings;
}

/**
 * Search parameters.
 */
export interface SearchParameter extends SearchPrompt {
  /** Field ID. */
  fieldId: number;
  /** Operator. */
  operator: SearchOperator;
}

/**
 * Search prompt.
 */
export interface SearchPrompt {
  /** Search parameter/prompt ID. */
  id: number;
  /** Displayed prompt. */
  prompt: string;
  /** Value. */
  value: string;
}

/**
 * Converts the given prompt array to a string that can be sent to the API.
 *
 * @param searchPromptArray Search prompts to include.
 * @returns A JSON string of the prompts. Empty string is no interable value provided.
 */
export const createApiSearchPromptString = (
  searchPromptArray: SearchPrompt[]
): string => {
  const promptDic: Dictionary<string> = {};
  try {
    for (const prompt of searchPromptArray) {
      promptDic[prompt.id.toString()] = prompt.value;
    }
  } catch {
    return '';
  }

  return JSON.stringify(promptDic);
};

/**
 * Search settings.
 */
export interface SearchSettings {
  /** Content Search properties. */
  contentSearch: {
    /** Content search enabled. */
    enabled: boolean;
    /** Fuzzy search. */
    fuzzy: boolean;
    /** Stemming. */
    stemming: boolean;
  };
  /** Always show the prompt for a dashboard search. */
  dashboardSearchShouldAlwaysPrompt: boolean;
  /** Date fields use time. */
  dateFieldsUseTime: boolean;
  /** Display view tabs. */
  displayViewTabs: boolean;
  /** Is dashboard search. */
  isDashboardSearch: boolean;
  /** Direct Access enabled. */
  isDirectAccessEnabled: boolean;
  /** Image XChange enabled. */
  ixEnabled: boolean;
  /** Multi-value. */
  multiValue: boolean;
}

/** Search options. */
export interface SearchOptions {
  /** Only return a count, no document results. */
  countOnly: boolean;
  /** Optionally specify a document for which to search. */
  documentId?: number;
  /** Optionally include extended data for the documents. This does not change what is returned when using `countOnly`. */
  includeExtendedData?: boolean;
  /** Page. */
  page: number;
  /** Records per page. */
  recordsPerPage: number;
  /**
   * Search criteria.
   *
   * @todo Needs better type, and documentation on format?
   */
  searchCriteria: string;
  /**
   * Sort.
   *
   *@todo The actual value for this is currently unknown.
   */
  sort: string;
  /** Tab ID. */
  tabId: number;
  /** Target archive ID. */
  targetArchiveId: number;
}

/**
 * A list of `Search`.
 */
export declare type Searches = Search[];

/**
 * Search result.
 */
export interface SearchResult {
  /** ContentSearch result data. */
  contentSearch: ContentSearchResult;
  /** Field values. */
  fields: FieldValues;
  /** File type. */
  fileType: string;
  /** Search result ID. */
  id: number;
  /** Whether the document is currently being converted to a PDF. */
  isConvertingToPdf: boolean;
  /** Document permissions. */
  permissions: Permissions;
  /** Secure ID hash. */
  secureId: string;
  /** Result tab ID. */
  tabId: number;
  /** Workflow Id. 0 if not in a workflow or if not provided by the API. */
  workflowProcessId: number;
}

/** ContentSearch result. */
export interface ContentSearchResult {
  /** Hit count on the document. */
  hits: number;
  /** Search terms found. */
  terms: string[];
}

/**
 * A list of `SearchResult`.
 */
export declare type SearchResults = SearchResult[];

/** Search response. */
export interface SearchResultResponse {
  /** The total number of documents matching the search. */
  count: number;
  /** The search results on the requested page. */
  searchResults: SearchResults;
}
