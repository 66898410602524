/**
 * Scan Status.
 */
export interface ScanStatus {
  // timeOfRequest: string; // TODO see if we will even need this
  /** Total file length of the scan. */
  length: number;
  /** Path to the temp file created by the scan. */
  path: string;
  /** Current processing status for the scan. */
  progress: string;
  /** ID for the scan. */
  scanId: string;
  /** ID for the scan session. */
  sessionId?: string;
}

/**
 * GlobalSearch Extensions API scan status object with PascalCase properties.
 */
export class GSEApiScanStatus {
  // TimeOfRequest: string; // TODO see if we will even need this
  // biome-ignore lint/style/useNamingConvention: Should move to GSE API module
  Length: number;
  // biome-ignore lint/style/useNamingConvention: Should move to GSE API module
  Path: string;
  // biome-ignore lint/style/useNamingConvention: Should move to GSE API module
  Progress: string;
  // biome-ignore lint/style/useNamingConvention: Should move to GSE API module
  ScanID: string;
  // biome-ignore lint/style/useNamingConvention: Should move to GSE API module
  Sessionid: string;

  constructor(obj = {} as Partial<GSEApiScanStatus>) {
    this.ScanID = obj.ScanID as string;
    this.Sessionid = obj.Sessionid as string;
    this.Progress = obj.Progress as string;
    this.Length = obj.Length as number;
    this.Path = obj.Path as string;
  }

  /**
   * Convert to ScanStatus object.
   *
   * @returns A `ScanStatus` object.
   */
  asScanStatus(): ScanStatus {
    return {
      scanId: this.ScanID,
      sessionId: this.Sessionid,
      progress: this.Progress,
      length: this.Length,
      path: this.Path,
    };
  }
}
