/**
 * Fires the provided function whenever a new value is set.
 *
 * @param methodName Method/function name to execute on change.
 * @param scope Optional scope. Uses 'this' if not provided.
 * @returns A function that will be called at runtime with information about the decorated declaration.
 */
export const OnPropertyChange = <T = any>(methodName: string, scope?: any) => {
  return (target: T, key: keyof T) => {
    const originalDescriptor = Object.getOwnPropertyDescriptor(target, key);
    let value: any;

    // Wrap hook methods
    Object.defineProperty(target, key, {
      /**
       * Sets a new value.
       *
       * @param newValue New value.
       */
      set(newValue) {
        const instance = this;
        const previousValue = value;

        if (previousValue === newValue) {
          return;
        }

        value = newValue;
        if (originalDescriptor) {
          originalDescriptor.set?.call(instance, value);
        }

        if (methodName && value !== previousValue) {
          instance[methodName].call(scope || instance, value, previousValue);
        }
      },
      /**
       * Gets the current value.
       *
       * @returns The value.
       */
      get() {
        const instance = this;
        if (originalDescriptor) {
          return originalDescriptor.get?.call(instance);
        }
        return value;
      },
    });
  };
};
