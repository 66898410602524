import { Component, Inject } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CSVImportJob,
  CSVImportJobResult,
  CSVImportJobStatus,
  CSVImportProvider,
  UserFriendlyError,
} from 'models';
import { CSV_IMPORT_PROVIDER } from 'src/app/common/tokens';
import { NotificationService } from 'src/app/services/notification.service';
import { CSVImportJobQuery } from 'src/app/state/csv-import-jobs/csvimport-job.query';
import { CSVImportJobService } from 'src/app/state/csv-import-jobs/csvimport-job.service';

/** CSV Import Job Manager Dialog. */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-csvimport-job-manager-dialog',
  templateUrl: './csvimport-job-manager-dialog.component.html',
  styleUrls: ['./csvimport-job-manager-dialog.component.scss'],
})
export class CSVImportJobManagerDialogComponent {
  /** Observable array of job IDs. */
  jobIds$ = this.csvImportJobQuery.jobs$.pipe(
    map((jobs) =>
      jobs.length === 0 ? undefined : jobs.map((index) => index.id)
    )
  );

  /**
   * Dictionary of jobId and job results.
   */
  jobResults: { [jobId: string]: CSVImportJobResult } = {};

  private jobListeners: { [jobId: string]: Subscription } = {};

  constructor(
    private logger: NGXLogger,
    private notify: NotificationService,
    private translate: TranslocoService,
    private csvImportJobQuery: CSVImportJobQuery,
    private csvImportJobService: CSVImportJobService,
    @Inject(CSV_IMPORT_PROVIDER) private csvImportProvider: CSVImportProvider
  ) {}

  /**
   * Determines if a job's delete button should be disabled.
   *
   * @param jobStatus Job Status.
   * @returns Whether the delete button should be disabled.
   */
  getDisableDelete(jobStatus: CSVImportJobStatus): boolean {
    return jobStatus.started && !jobStatus.cancelled && !jobStatus.done;
  }

  /**
   * Determines if a job's results button should be disabled.
   *
   * @param jobStatus Job status.
   * @returns Whether the results button should be disabled.
   */
  getDisableResults(jobStatus: CSVImportJobStatus): boolean {
    return !jobStatus.cancelled && !jobStatus.done;
  }

  /**
   * Get translated job progress text.
   *
   * @param jobStatus Job status.
   * @returns A translated string indicating job progress.
   */
  getJobProgressText(jobStatus: CSVImportJobStatus): string {
    if (jobStatus.done) {
      return this.translate.translate('PROCESSED_TOTAL_ROWS', {
        totalRows: jobStatus.totalRows,
      });
    }

    return this.translate.translate('IMPORTING_ROW_CURRENT_OF_TOTALROWS', {
      currentRow: jobStatus.currentRow,
      totalRows: jobStatus.totalRows,
    });
  }

  /**
   * Get translated job status string.
   *
   * @param jobStatus Job Status.
   * @returns A translating string representing job status.
   */
  getJobStatusMessage(jobStatus: CSVImportJobStatus): string {
    let i18n = '';
    if (!jobStatus.started) {
      i18n = 'NOT_STARTED';
    }
    if (jobStatus.done) {
      i18n = jobStatus.erroredRows > 0 ? 'COMPLETED_WITH_ERRORS' : 'COMPLETED';
    }
    if (jobStatus.cancelled) {
      i18n = 'CANCELLED';
    }
    if (jobStatus.started && !jobStatus.done && !jobStatus.cancelled) {
      i18n = 'IN_PROGRESS';
    }

    return this.translate.translate(i18n);
  }

  /**
   * Handler for the cancel button click event.
   *
   * @param jobId Job ID.
   */
  onClickCancel(jobId: string): void {
    this.csvImportJobService.cancelJob(jobId).subscribe({
      next: () => this.notify.success('IMPORT_JOB_WAS_SUCCESSFULLY_CANCELLED'),
      error: (error: UserFriendlyError) =>
        this.notify.error({
          ...error,
          i18n: 'ERROR_OCCURRED_ATTEMPING_CANCEL_IMPORT_JOB',
        }),
    });
  }

  /**
   * Handler for the delete button click event.
   *
   * @param jobId Job ID.
   */
  onClickDelete(jobId: string): void {
    this.csvImportJobService.deleteJob(jobId).subscribe({
      next: () => this.notify.success('IMPORT_JOB_WAS_SUCCESSFULLY_DELETED'),
      error: (error: UserFriendlyError) =>
        this.notify.error({
          ...error,
          i18n: 'ERROR_OCCURRED__ATTEMPING_DELETE_IMPORT_JOB',
        }),
    });
  }

  /**
   * Handler for the download results button click event.
   *
   * @param jobId Job ID.
   */
  onClickDownloadResults(jobId: string): void {
    this.csvImportProvider.downloadJobResult(jobId).subscribe();
  }

  /**
   * Handler for the result button click event.
   *
   * @param jobId Job ID.
   */
  onClickResult(jobId: string): void {
    this.csvImportProvider
      .getJobResult(jobId)
      .subscribe((jobResult) => (this.jobResults[jobId] = jobResult));
  }

  /**
   * Handler for the job panel close event.
   *
   * @param jobId Job ID.
   */
  onCloseJobPanel(jobId: string): void {
    this.logger.debug('Job panel closed. Detaching job listener.', jobId);
    this.jobListeners[jobId]?.unsubscribe();
    delete this.jobListeners[jobId];
  }

  /**
   * Handler for the job panel open event.
   *
   * @param jobId Job ID.
   */
  onOpenJobPanel(jobId: string): void {
    this.logger.debug(
      'Job panel opened. Attaching job listener for job.',
      jobId
    );
    this.jobListeners[jobId] = this.csvImportJobService
      .getJobListener(jobId)
      .pipe(untilDestroyed(this))
      .subscribe((job) => this.logger.debug('Job status refreshed.', job));
  }

  /**
   * Get an observable of the job.
   *
   * @param jobId Job ID.
   * @returns An observable job.
   */
  selectJob$(jobId: string): Observable<CSVImportJob> {
    return this.csvImportJobQuery
      .selectEntity(jobId)
      .pipe(untilDestroyed(this), filterNilValue());
  }
}
