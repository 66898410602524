import { assert, AssertionError } from 'common';
import { Color as PDFColor, rgb } from 'pdf-lib';

/** Color, RGBa. */
export class Color {
  /**
   * Create an RGB `Color` object.
   *
   * Values are between 0-255.
   *
   * @param red Red.
   * @param green Green.
   * @param blue Blue.
   * @param alpha Alpha.
   * @throws {AssertionError} If color values are invalid.
   * @returns Color.
   */
  constructor(
    public red: number,
    public green: number,
    public blue: number,
    public alpha: number = 255
  ) {
    const errorText = ' value must be between 0-255.';
    const validColor = (color: number): boolean => color >= 0 && color <= 255;
    assert(validColor(red), 'Red' + errorText);
    assert(validColor(green), 'Green' + errorText);
    assert(validColor(blue), 'Blue' + errorText);
    assert(validColor(alpha), 'Alpha' + errorText);
  }

  /**
   * Convert a system.drawing.color to our Color object.
   *
   * @param signedInteger Signed 32bit integer representing a color with alpha.
   * @returns Color.
   */
  static convertColor(signedInteger: number): Color {
    const uint = signedInteger >>> 0; // Bit shift, convert to unsigned.
    const hex: string = uint.toString(16).padStart(8, '0');
    return new Color(
      Number.parseInt(hex.slice(2, 4), 16),
      Number.parseInt(hex.slice(4, 6), 16),
      Number.parseInt(hex.slice(6, 8), 16),
      Number.parseInt(hex.slice(0, 2), 16)
    );
  }

  /**
   * Get the color as a hex code.
   *
   * @returns A value like "#000000".
   */
  get hexCode(): string {
    return `#${this.red.toString(16).padStart(2, '0')}${this.green
      .toString(16)
      .padStart(2, '0')}${this.blue.toString(16).padStart(2, '0')}`;
  }

  /**
   * Get opacity value based on alpha property.
   *
   * @returns Number between 0 and 1.
   */
  get opacity(): number {
    return Number((this.alpha / 255).toFixed(2));
  }

  /**
   * Get the color as a PDF Lib compatible color.
   *
   * @returns PDF Color.
   */
  get pdfColor(): PDFColor {
    return rgb(
      Number((this.red / 255).toFixed(2)),
      Number((this.green / 255).toFixed(2)),
      Number((this.blue / 255).toFixed(2))
    );
  }

  /**
   * Get the color as a css RGBa string.
   *
   * @returns A value like "rgba(0, 255, 0, 0.5)".
   */
  get rgba(): string {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.opacity})`;
  }
}
