<span
  class="message"
  transloco="DOCUMENT_PARTIAL_LOAD_MSG"
  [translocoParams]="translateParams"
></span>
<button mat-button color="accent" (click)="loadComplete()">
  <span transloco="LOAD_COMPLETE"></span>
</button>
<button mat-button color="warn" (click)="dismiss()">
  <span transloco="DISMISS"></span>
</button>
