import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, filter, from, map, of } from 'rxjs';

import { TranslationParameters, UserFriendlyError } from 'models';

import { ApplicationQuery } from '../state/application/application.query';

/**
 * Notification Service.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private logger: NGXLogger,
    private translate: TranslocoService,
    private toastr: ToastrService,
    private application: ApplicationQuery
  ) {}

  /**
   * Display an error notification.
   *
   * I18n key from a UserFriendlyError will be translated and displayed.
   *
   * @param errorOrMessage Either an error object or a string to display.
   * @throws {Error} If notification error is missing the required error message.
   * @returns Dismiss function, call to remove the toast.
   */
  error(errorOrMessage: UserFriendlyError | Error | string): () => void {
    let message = '';

    if (typeof errorOrMessage === 'string') {
      message = errorOrMessage;
      this.logger.error(message);
    } else if (errorOrMessage instanceof Object) {
      if (errorOrMessage.hasOwnProperty('i18n')) {
        const friendlyError = errorOrMessage as UserFriendlyError;
        message = this.translate.translate(
          friendlyError.i18n,
          friendlyError.i18nParameters
        );
        this.logger.error(friendlyError.description);
      } else {
        message = (errorOrMessage as Error).message;
        this.logger.error(message);
      }
    }

    if (!message) {
      throw new Error('Notification error requires a valid error message.');
    }

    const toast = this.toastr.error(message, 'Error');
    const dismissToast = () => {
      this.toastr.remove(toast.toastId);
      this.logger.debug('Dismissed toast:', message);
    };

    this.systemNotification(message, 'Error').subscribe({
      next: (systemNotified) => {
        if (systemNotified) dismissToast();
      },
    });
    return dismissToast;
  }

  /**
   * Display an informational notifcation.
   *
   * @param i18n Translation key.
   * @param i18nParameters Any translation parameters to be injected into the translation.
   * @returns Dismiss function, call to remove the toast.
   */
  info(i18n: string, i18nParameters?: TranslationParameters): () => void {
    const message = this.translate.translate(i18n, i18nParameters);
    const toast = this.toastr.info(message, 'Info');
    const dismissedSubject = new BehaviorSubject(false);

    // Since info messages may be used while something is in progress, then
    // closed programtically, we will pass their dismissal up to the system
    // notification if one is used.
    const dismissToast = (bySystem = false) => {
      // Clear the toast.
      this.toastr.remove(toast.toastId);
      this.logger.debug('Dismissed toast:', message);
      // If the message was dismissed by the original invoker, broadcast that to
      // allow us to clear the system notification.
      if (!bySystem) {
        dismissedSubject.next(true);
      }
    };

    this.logger.info(message);
    this.systemNotification(message).subscribe({
      next: (notification) => {
        // If a system notification was created:
        if (notification !== false) {
          dismissToast();
          // Listen for a dismissal in our code.
          dismissedSubject
            .asObservable()
            .pipe(filter((dismissed) => dismissed))
            .subscribe({
              next: () => {
                // Close the system notification.
                notification();
                this.logger.debug('System notification dismissed.', message);
              },
            });
        }
      },
    });
    return dismissToast;
  }

  /**
   * Display a success notification.
   *
   * @param i18n Translation key.
   * @param i18nParameters Any translation parameters to be injected into the translation.
   * @returns Dismiss function, call to remove the toast.
   */
  success(i18n: string, i18nParameters?: TranslationParameters): () => void {
    const message = this.translate.translate(i18n, i18nParameters);
    const toast = this.toastr.success(message, 'Success');
    const dismissToast = () => {
      this.toastr.remove(toast.toastId);
      this.logger.debug('Dismissed toast:', message);
    };

    this.logger.info(message);
    this.systemNotification(message).subscribe({
      next: (systemNotified) => {
        if (systemNotified) dismissToast();
      },
    });
    return dismissToast;
  }

  /**
   * Display a warning notifcation.
   *
   * @param i18n Translation key.
   * @param i18nParameters Any translation parameters to be injected into the translation.
   * @returns Dismiss function, call to remove the toast.
   */
  warning(i18n: string, i18nParameters?: TranslationParameters): () => void {
    const message = this.translate.translate(i18n, i18nParameters);
    const toast = this.toastr.warning(message, 'Warning');
    const dismissToast = () => {
      this.toastr.remove(toast.toastId);
      this.logger.debug('Dismissed toast:', message);
    };

    this.logger.warn(message);
    this.systemNotification(message, 'Warning').subscribe({
      next: (systemNotified) => {
        if (systemNotified) dismissToast();
      },
    });
    return dismissToast;
  }

  /**
   * Create system notification.
   *
   * @param message Message to display.
   * @param title Title for the message.
   *
   * @returns Observable, close function if the notification was shown, and false if blocked.
   */
  private systemNotification(
    message: string,
    title?: string
  ): Observable<false | (() => void)> {
    // Only use system notifications if the user has enabled them.
    if (!this.application.notifications) return of(false);

    // Create a notificiation.
    const show = () => {
      // If a title was provided use that, and the message as the body.
      // Otherwise, use the message as the title.
      const options = {
        body: title ? message : undefined,
        silent: true,
        icon: "data:image/svg+xml,%3Csvg height='128' width='128' viewBox='-4 230 60 90' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath id='accent' fill='darkgray' d='m 2.9336607,261.31571 22.7500003,13.30375 22.7825,-13.26875 -22.7825,-13.1675 -22.7500003,13.1325'%3E%3C/path%3E%3Cpath id='primary' fill='darkgray' d='m 56.003661,291.56946 -30.25625,17.46875 -30.2750003,-17.4175 -0.0375,0.0213 0,-13.815 7.4775,4.20875 0,5.2275 22.8275003,13.125 22.78375,-13.15375 0,-0.004 0.0775,0.045 0,-17.2825 -22.925,13.2763 -30.2512503,-17.44375 0,-8.90125 0.03,0.0175 4.54250001,-2.625 25.67875029,-14.78375 30.325,17.44625 0,0.0712 -0.02625,0.015 -0.01375,0.007 0.04,0.0238 0,34.455 0.0025,10e-4 0,0.0163 z m -30.29,-61.4725 -38.26875,22.09375 0,44.19 38.26875,22.09375 38.27,-22.09375 0,-44.19 -38.27,-22.09375'%3E%3C/path%3E%3C/g%3E%3C/svg%3E",
      };
      const notification = new Notification(title ? title : message, options);
      // Return the close function.
      return () => {
        notification.close();
      };
    };

    const blocked = () => {
      this.logger.error(
        'System notifications are enabled, but have been blocked by the user.'
      );
    };

    // Check if they are supported.
    if (!('Notification' in window)) {
      this.logger.error('This browser does not support desktop notification');
    }

    // Check for notification permission.
    switch (Notification.permission) {
      case 'granted':
        // If we have permission already to send them.
        return of(show());
      case 'denied':
        // We are blocked.
        blocked();
        return of(false);
      case 'default':
        // Otherwise, request permission from the user.
        return from(Notification.requestPermission()).pipe(
          map((permission) => {
            if (permission === 'granted') {
              return show();
            } else {
              blocked();
              return false;
            }
          })
        );
    }
  }
}
