import { AnyDrawingOptions } from '.';

/**
 * Abstract class that provides methods for converting annotations.
 */
export abstract class ConvertableAnnotation {
  /** @param scale Annotation scale factor. */
  public scaleFactor = 0.24;

  /**
   * Adjust point locations between 300DPI and 72DPI.
   *
   * Converts between Atalasoft positions and PDF Lib.
   * (Atalasoft is 300DPI, PDFLib is 72DPI, so 72/300 to convert).
   *
   * @param options Options to adjust.
   */
  convertPixelsToPoints(options: AnyDrawingOptions) {
    if (typeof options.borderWidth === 'number')
      options.borderWidth *= this.scaleFactor;
    if (typeof options.x === 'number') options.x *= this.scaleFactor;
    if (typeof options.y === 'number') options.y *= this.scaleFactor;
    if (typeof options.scale === 'number') options.scale *= this.scaleFactor;
    if (typeof options.xScale === 'number') options.xScale *= this.scaleFactor;
    if (typeof options.yScale === 'number') options.yScale *= this.scaleFactor;
    if (typeof options.height === 'number') options.height *= this.scaleFactor;
    if (typeof options.width === 'number') options.width *= this.scaleFactor;
    if (typeof options.size === 'number') options.size *= this.scaleFactor;
    if (typeof options.maxWidth === 'number')
      options.maxWidth *= this.scaleFactor;
    if (typeof options.thickness === 'number')
      options.thickness *= this.scaleFactor;
    if (options.start && typeof options.start.x === 'number')
      options.start.x *= this.scaleFactor;
    if (options.start && typeof options.start.y === 'number')
      options.start.y *= this.scaleFactor;
    if (options.end && typeof options.end.x === 'number')
      options.end.x *= this.scaleFactor;
    if (options.end && typeof options.end.y === 'number')
      options.end.y *= this.scaleFactor;
  }

  /**
   * Adjust the PDF drawing locations from top-left relative to cartesian plane.
   *
   * Annotations like those stored from Atalasoft annotations are based on a
   * top-left origin, whereas PDF lib, and others use a cartesian plane based on
   * the bottom-left corner of the media box.
   *
   * @param pageHeight Page to draw on, for relative size.
   * @param options Options to adjust.
   */
  convertToCartesian(pageHeight: number, options: AnyDrawingOptions) {
    const sizeOffset = options.height || options.size || 0;
    const recalculate = (verticalCoordinate: number): number =>
      pageHeight - (verticalCoordinate + sizeOffset);
    if (typeof options.y === 'number') options.y = recalculate(options.y);
    if (options.start && typeof options.start.y === 'number')
      options.start.y = recalculate(options.start.y);
    if (options.end && typeof options.end.y === 'number')
      options.end.y = recalculate(options.end.y);
  }
}
