<mat-form-field *ngIf="!showFieldAsTextarea; else textarea">
  <mat-label>{{ field.name }}</mat-label>
  <input
    #input
    matInput
    type="text"
    [formControl]="control"
    [appMultiValueHotkeys]="mvFieldMenu"
    (focus)="onFocus()"
    (blur)="onBlur($event)"
  />
  <!-- Live fields are only chacter type fields so we only need to account for
   them in this component (here and for "textarea" below). -->
  <mat-icon
    *ngIf="field.liveField"
    matSuffix
    class="live-field-indicator"
    [ngClass]="{
      'live-field-indicator-loading': field.liveField.status === 'IN_PROGRESS',
      'live-field-indicator-error':
        field.liveField.status === 'COMPLETED_WITH_ERRORS',
    }"
    [matTooltip]="
      (field.liveField.status === 'IN_PROGRESS'
        ? 'LIVEFIELD_LOADING'
        : field.liveField.status === 'COMPLETED_WITH_ERRORS'
          ? 'LIVEFIELD_ERROR'
          : 'LIVEFIELD'
      ) | transloco
    "
    >rss_feed</mat-icon
  >
  <button
    *ngIf="field.multiValue"
    [disabled]="control.disabled"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>

<ng-template #textarea>
  <mat-form-field>
    <mat-label>{{ field.name }}</mat-label>
    <textarea
      #input
      matInput
      type="text"
      [formControl]="control"
      [appMultiValueHotkeys]="mvFieldMenu"
      (focus)="onFocus()"
      (blur)="onBlur($event)"
    ></textarea>
    <mat-icon
      *ngIf="field.liveField"
      matSuffix
      class="live-field-indicator"
      [ngClass]="{
        'live-field-indicator-loading':
          field.liveField.status === 'IN_PROGRESS',
        'live-field-indicator-error':
          field.liveField.status === 'COMPLETED_WITH_ERRORS',
      }"
      [matTooltip]="
        (field.liveField.status === 'IN_PROGRESS'
          ? 'LIVEFIELD_LOADING'
          : field.liveField.status === 'COMPLETED_WITH_ERRORS'
            ? 'LIVEFIELD_ERROR'
            : 'LIVEFIELD'
        ) | transloco
      "
      >rss_feed</mat-icon
    >
    <button
      *ngIf="field.multiValue"
      [disabled]="control.disabled"
      tabIndex="-1"
      mat-icon-button
      type="button"
      matSuffix
      [matMenuTriggerFor]="mvFieldMenu.matMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-error
      *ngIf="control.invalid"
      class="ellipsis"
      [matTooltip]="errorMessage"
      >{{ errorMessage }}</mat-error
    >
  </mat-form-field>
</ng-template>
