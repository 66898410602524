import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  GSEProvider,
  LaunchDocumentProvider,
  QuickbooksProvider,
} from 'models';

import {
  GSE_PROVIDER,
  LAUNCH_DOCUMENT_PROVIDER,
  QUICKBOOKS_PROVIDER,
} from '../common/tokens';

import { PlatformService } from './platform.service';

/**
 * GSE Service.
 */
@Injectable({
  providedIn: 'root',
})
export class GseService {
  /** GSE Api. */
  api = this.gseProvider;
  /** Observable containing the GSE connection status. */
  isConnected$: Observable<boolean>;
  /** Observable that returns if GSE Launch is enabled. */
  isLaunchAvailable$: Observable<boolean>;
  /** Observable that determines if GSE Quickbooks is available. */
  isQuickbooksAvailable$: Observable<boolean>;
  /** Launch API. */
  launchApi = this.launchProvider;
  /** Quickbooks API. */
  quickbooksApi = this.quickbooksProvider;

  constructor(
    @Inject(GSE_PROVIDER) private gseProvider: GSEProvider,
    @Inject(LAUNCH_DOCUMENT_PROVIDER)
    private launchProvider: LaunchDocumentProvider,
    @Inject(QUICKBOOKS_PROVIDER)
    private quickbooksProvider: QuickbooksProvider,
    private platform: PlatformService
  ) {
    if (this.platform.isWindows) {
      this.isConnected$ = this.gseProvider.isConnected$;
      this.isLaunchAvailable$ = this.gseProvider.isLaunchAvailable$;
      this.isQuickbooksAvailable$ = this.gseProvider.isQuickbooksAvailable$;
    } else {
      // GSE is Windows only so don't bother trying to connect.
      this.isConnected$ = of(false);
      this.isLaunchAvailable$ = of(false);
      this.isQuickbooksAvailable$ = of(false);
    }
  }

  /**
   * Tests the connection to GSE.
   *
   * @returns An observable containing GSE metadata.
   */
  testConnection(): Observable<boolean> {
    return this.gseProvider.testConnection();
  }

  /**
   * Tests is GSE Launch is installed.
   *
   * @returns True when GSE launch is available.
   */
  testLaunch(): Observable<boolean> {
    return this.gseProvider.testLaunch();
  }
}
