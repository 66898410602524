import { assert } from 'common';
import { CURRENT_DOCUMENT_REVISION } from '../common/constants';
/**
 * A requested document for an archive revision.
 *
 * The string representation of an `ArchiveRequestedDocument` is a string in the form of
 * `<docId>|<secureId>|versionNumber`.
 *
 */
export class ArchiveRevisionRequestedDocument {
  /** Document Id. */
  docId: number;
  /** Secure Id. */
  secureId: string;
  /** Version number */
  versionNumber: number;

  constructor(
    docIdOrString: number | string,
    secureId?: string,
    versionNumber?: number
  ) {
    if (typeof docIdOrString === 'string') {
      const [docId, secureId, versionNumber] = docIdOrString.split('|');
      this.docId = Number(docId);
      this.secureId = secureId;
      this.versionNumber = Number(versionNumber);
    } else {
      assert(typeof secureId === 'string' && secureId.length > 0),
        "SecureId can't be empty when docId is a number";
      assert(
        typeof versionNumber === 'number' &&
          (versionNumber > 0 || versionNumber === CURRENT_DOCUMENT_REVISION),
        'Version number must be a positive number when docId is a number.'
      ),
        (this.docId = docIdOrString);
      this.secureId = secureId;
      this.versionNumber = versionNumber;
    }
  }

  /**
   * Returns a string representation of the ArchiveRequestedDocument object.
   *
   * @return {string} The string representation of the object in the format "<docId>|<secureId>".
   */
  toString(): string {
    return `${this.docId}|${this.secureId}|${this.versionNumber}`;
  }

  /**
   * Checks if a string has exactly two parts separated by a pipe.
   *
   * @param {string} str - The string to be checked.
   * @return {boolean} Returns true if the string has exactly three parts separated by a period, otherwise false.
   */
  static looksLike(str: string): boolean {
    // Looks like a `docId|secureId`, e.g. `123|0000-0000-0000-0000|1`.
    return str.split('|').length === 3;
  }
}
