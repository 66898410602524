import { BehaviorSubject, Observable } from 'rxjs';

import { ViewTab } from 'models';

/** Item in the list of view tabs. */
export class ViewTabItem {
  /** Result count for view tab. */
  count$: Observable<number>;
  /** Definition of the view tab. */
  description: ViewTab;
  /** True when the tab is requesting data from the API. */
  isLoading = false;

  /** Behavior subject for the observable tab count. */
  private countSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Create a ViewTabItem.
   *
   * Setting the optional `isLoading` boolean property to true, will initialize
   * it with that value, and it will be set to false by the setter on `count`.
   *
   * @param viewTab ViewTab object to associate as the description.
   * @param isLoading Initial "loading" state.
   */
  constructor(viewTab: ViewTab, isLoading: boolean = false) {
    this.description = viewTab;
    this.isLoading = isLoading;
    this.count$ = this.countSource.asObservable();
  }

  /**
   * Current count.
   *
   * @returns Last updated count value.
   */
  get count(): number {
    return this.countSource.getValue();
  }

  /**
   * Current count.
   */
  set count(v) {
    this.countSource.next(v);
    this.isLoading = false;
  }
}
