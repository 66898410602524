import { Component } from '@angular/core';

import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';

@Component({
  selector: 'app-right-sidebar-toggle-button',
  templateUrl: './right-sidebar-toggle-button.component.html',
  styleUrls: ['./right-sidebar-toggle-button.component.scss'],
})
export class RightSidebarToggleButtonComponent {
  /** Observable indicating if the right sidebar is open. */
  rightSidebarOpen$ = this.appQuery.rightSidebarOpen$;

  constructor(
    private appQuery: ApplicationQuery,
    private appService: ApplicationService
  ) {}

  /**
   * Click event handler for the click toggle right sidebar button.
   */
  onClickToggleRightSidebar(): void {
    this.appService.toggleRightSidebarOpen();
  }
}
