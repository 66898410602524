import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, finalize } from 'rxjs';

import { assertExists } from 'common';
import { TaskSearch, TaskSearchArchive } from 'models';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

/** Task search action toolbar. */
@UntilDestroy()
@Component({
  selector: 'app-task-search-archive-selector',
  templateUrl: './task-search-archive-selector.component.html',
  styleUrls: ['./task-search-archive-selector.component.scss'],
})
export class TaskSearchArchiveSelectorComponent implements OnInit {
  /** Current task search archive. */
  archive: TaskSearchArchive;
  /** Tracks if the result set is single, multiple, or no archives. */
  archiveSets: 'single' | 'multi' | 'none';
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layout.isHandset$;
  /** Whether counts are being loaded. */
  isLoading = true;
  /** Task search. */
  taskSearch: TaskSearch;

  private archiveId: number;
  private databaseId: number;
  private hideArchivesWithNoResults: boolean;
  private sortResultsArchivesByResultsCount: boolean;

  constructor(
    private logger: NGXLogger,
    private taskSearchesQuery: TaskSearchesQuery,
    private taskSearchesService: TaskSearchesService,
    private appQuery: ApplicationQuery,
    private layout: LayoutService,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.appQuery.hideArchivesWithNoResults$.pipe(untilDestroyed(this)),
      this.appQuery.sortResultsArchivesByResultsCount$.pipe(
        untilDestroyed(this)
      ),
      this.taskSearchesQuery.taskSearchRouteParams$.pipe(untilDestroyed(this)),
    ]).subscribe(([hide, sort, [databaseId, archiveId, _taskId]]) => {
      this.isLoading = true;
      this.logger.debug(
        'Task search route parameters changed or app settings changed. Reloading task search counts.'
      );
      this.hideArchivesWithNoResults = hide;
      this.sortResultsArchivesByResultsCount = sort;
      assertExists(
        databaseId,
        'Database Id must be provided in the task search route'
      );
      this.databaseId = databaseId;
      assertExists(
        archiveId,
        'Archive Id must be provided in the task search route.'
      );
      this.archiveId = archiveId;
      this.taskSearch = { ...this.taskSearchesQuery.active };
      this.loadTaskArchives();
      this.isLoading = false;
    });
  }

  /**
   * Event handler for selecting a new archive.
   *
   * @param archiveId Archive ID.
   */
  onClickSelectArchive(archiveId: number): void {
    this.router.navigate([
      'db',
      this.databaseId,
      'archive',
      archiveId,
      'task',
      this.taskSearch.id,
    ]);
  }

  /**
   * Reloads the search counts.
   */
  reloadSearchCounts(): void {
    this.logger.debug('Reloading search counts.');
    this.isLoading = true;
    this.taskSearchesService
      .getById(this.databaseId, this.taskSearch.workflowId, this.taskSearch.key)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((updatedTaskSearch) => {
        this.taskSearch = updatedTaskSearch;
        this.loadTaskArchives();
      });
  }

  private filterArchiveCounts(archiveId: number): TaskSearchArchive[] {
    let filteredArchives = this.taskSearch.archives.filter(
      (a) => a.processCount > 0
    );
    if (filteredArchives.length === 0) {
      // If there are none with results just return the current archive.
      const activeArchive = this.taskSearch.archives.find(
        (x) => x.id === archiveId
      );
      assertExists(activeArchive);
      filteredArchives = [activeArchive];
      return filteredArchives;
    }

    // Navigate to the first archive with results if the current archive does not have results.
    if (!filteredArchives.some((x) => x.id === archiveId)) {
      const firstArchiveWithResults = filteredArchives[0];
      this.onClickSelectArchive(firstArchiveWithResults.id);
      return [];
    }

    return filteredArchives;
  }

  private loadTaskArchives(): void {
    let taskArchives: TaskSearchArchive[] = [...this.taskSearch.archives];
    if (taskArchives.length > 1 && this.hideArchivesWithNoResults) {
      taskArchives = this.filterArchiveCounts(this.archiveId ?? 0);
    }

    // sort
    if (this.sortResultsArchivesByResultsCount) {
      taskArchives.sort((a, b) => b.processCount - a.processCount);
    }

    // Set member state for multiple archives before we remove "current archive".
    switch (taskArchives.length) {
      case 0:
        this.archiveSets = 'none';
        break;
      case 1:
        this.archiveSets = 'single';
        break;
      default:
        this.archiveSets = 'multi';
        break;
    }

    if (this.archiveSets === 'multi') {
      // Remove the active archive from the available selections if there are multiple archives.
      const index = taskArchives.findIndex((x) => x.id === this.archiveId);
      taskArchives.splice(index, 1);
    }

    // Set
    const currentArchive = this.taskSearch.archives.find(
      (archive) => archive.id === Number(this.archiveId)
    );
    assertExists(
      currentArchive,
      `Archive ${this.archiveId} must exist in task search.`
    );
    this.taskSearch.archives = taskArchives;
    this.archive = currentArchive;
  }
}
