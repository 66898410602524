import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { from, map, switchMap } from 'rxjs';
import * as Tesseract from 'tesseract.js';

import { ApplicationQuery } from '../state/application/application.query';

/**
 * Keyfree indexing service.
 */
@Injectable({
  providedIn: 'root',
})
export class KeyfreeIndexingService {
  /** Language. */
  language: string;
  constructor(
    private logger: NGXLogger,
    private applicationQuery: ApplicationQuery
  ) {}

  /**
   * Get words from tesseract.
   *
   * @param canvas - Html canvas element.
   * @returns - Observable.
   */
  getWords = (canvas: HTMLCanvasElement) => {
    return this.ocrPage(canvas.toDataURL()).pipe(
      map((result: Tesseract.RecognizeResult) => {
        return result.data;
      })
    );
  };
  /**
   * Ocr the page.
   *
   * @param ocrImage - Image to be processed.
   * @returns - Observable.
   */
  ocrPage = (ocrImage: Tesseract.ImageLike) => {
    return from(Tesseract.createWorker('eng')).pipe(
      switchMap((worker) => {
        worker.setParameters({});
        return from(worker.recognize(ocrImage));
      })
    );
  };
}
