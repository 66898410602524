import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ListsState, ListsStore } from './lists.store';

/** Lists Query. */
@Injectable({ providedIn: 'root' })
export class ListsQuery extends QueryEntity<ListsState> {
  constructor(protected store: ListsStore) {
    super(store);
  }
}
