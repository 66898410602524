<button
  id="avatar-button"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="User Menu"
>
  <img
    *ngIf="userHasEmail; else genericUser"
    class="avatar"
    [src]="avatarUrl"
  />
  <ng-template #genericUser>
    <mat-icon class="large-icon">{{
      isGuest || isRestrictedUser ? 'public' : 'account_circle'
    }}</mat-icon>
  </ng-template>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="showAbout()">
    <mat-icon>info</mat-icon>
    <span transloco="ABOUT"></span>
  </button>
  <!-- Extensions -->
  <ng-container *ngIf="showExtensionsItems">
    <button
      mat-menu-item
      (click)="showExtensions()"
      *ngIf="isGseConnected$ | async as isGseConnected; else installGse"
    >
      <mat-icon>extension</mat-icon>
      <span transloco="EXTENSIONS"></span>
    </button>
    <ng-template #installGse>
      <button
        mat-menu-item
        (click)="installExtensions()"
        [disabled]="isGseInstalling"
      >
        <mat-icon>download</mat-icon>
        <span transloco="INSTALL_GSE"></span>
      </button>
    </ng-template>
  </ng-container>

  <!-- User Settings -->
  <button mat-menu-item (click)="showSettings()">
    <mat-icon>settings</mat-icon>
    <span transloco="SETTINGS"></span>
  </button>
  <!-- Logout -->
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span transloco="LOGOUT"></span>
  </button>
  <!-- Logged in User -->
  <mat-divider></mat-divider>
  <div class="username">
    <div>
      <img
        class="avatar giant-avatar"
        [class.dark-border]="!darkMode"
        [src]="avatarUrl"
      />
      <mat-icon
        class="profile-icon"
        [class.dark-border]="!darkMode"
        matRipple
        (click)="showProfile()"
      >
        account_circle
      </mat-icon>
    </div>
    <div class="username-label">{{ displayUsername$ | async }}</div>
  </div>
  <!-- Toggle Darkmode -->
  <div class="toggle-group">
    <div class="toggle-item">
      <mat-icon [class.light-icon]="darkMode">dark_mode</mat-icon>
      <mat-slide-toggle
        aria-label="Toggle Dark Mode"
        [disabled]="disableDarkModeToggle$ | async"
        [matTooltipDisabled]="(disableDarkModeToggle$ | async) === false"
        [matTooltip]="'DISABLED_DARK_MODE_TOGGLE_TOOLTIP' | transloco"
        [checked]="darkMode"
        (click)="$event.stopPropagation()"
        (change)="onDarkModeChange($event)"
      >
      </mat-slide-toggle>
    </div>
    <div class="toggle-item">
      <mat-icon [class.light-icon]="darkMode">notifications</mat-icon>
      <mat-slide-toggle
        aria-label="Toggle Notifications"
        [checked]="notifications"
        (click)="$event.stopPropagation()"
        (change)="onNotificationsChange($event)"
      >
      </mat-slide-toggle>
    </div>
  </div>
</mat-menu>
