import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdvancedLinks } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  S9ApiAdvancedLinkDefinition,
  createAdvancedLink,
} from '../models/advanced-link';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';
/**
 * Square 9 API Advanced Links Service.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiAdvancedLinkService {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.basePath = apiUrl;
    });
  }

  /** @inheritdoc */
  getAll(databaseId: number): Observable<AdvancedLinks> {
    return this.http
      .get<S9ApiAdvancedLinkDefinition[]>(
        `${this.basePath}/advancedlinks/databases/${databaseId}`
      )
      .pipe(map((s9AdvancedLinks) => s9AdvancedLinks.map(createAdvancedLink)));
  }
}
