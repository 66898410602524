import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { HotkeysService } from '@ngneat/hotkeys';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Archives } from 'models';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { CommandService } from 'src/app/modules/command-palette';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { environment } from 'src/environments/environment';

/** Navigation Component. */
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  /** Application name. */
  appName: string = environment.appName;
  /** Observable list of archives. */
  archives$: Observable<Archives> = this.archivesQuery.selectAll();
  /** Observable of whether a compact layout should be used. */
  compactLayout$ = this.layout.useCompactLayout$;
  /** Observable Dark Mode enabled. */
  darkMode$ = this.app.darkMode$;
  /** Observable active database. */
  database$ = this.databasesQuery.selectActive();
  /** Observable of if branding should be hidden. */
  isBrandingHidden$ = this.appConfigQuery.hideBranding$;
  /** Observable state of if we are on a mobile handset sized output. */
  isHandset$ = this.layout.isHandset$;
  /** Observable of whether in handset or hidden.*/
  isHandsetOrHidden$: Observable<boolean>;
  /** Observable of whether the left sidebar is expanded. */
  isLeftSidebarHidden$ = this.app.leftSidebarNavHidden$;
  /** Observable state of if we are on the login page. */
  isLoginPage$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url.startsWith('/login'))
  );
  /** Observable of whether the sidebar should be opened. */
  isOpened$ = combineLatest([
    this.compactLayout$,
    this.isLoginPage$,
    this.isLeftSidebarHidden$,
  ]).pipe(
    map(
      ([compactLayout, isLoginPage, isLeftSidebarHidden]) =>
        !this.auth.isGuest &&
        !compactLayout &&
        !isLoginPage &&
        isLeftSidebarHidden &&
        !this.auth.isRestrictedUser
    )
  );
  /** Whether to show the logo button in compact mode for non guest users.*/
  shouldShowCompactLogoBtn$: Observable<boolean>;

  constructor(
    private layout: LayoutService,
    private app: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private applicationService: ApplicationService,
    private command: CommandService,
    private auth: AuthenticationService,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery,
    private router: Router,
    private logger: NGXLogger,
    private translate: TranslocoService,
    private hotkeys: HotkeysService
  ) {}

  /**
   * Is the user an admin of any database?
   *
   * @returns True if a user is logged in and admin of any database.
   */
  get isAdminAny() {
    return this.isLoggedIn && this.auth.user.isAdminAny;
  }

  /**
   * Is the user a guest?
   *
   * @returns True if the user is a guest.
   * */
  get isGuest() {
    return this.auth.isGuest;
  }

  /**
   *  Is a user currently logged in?
   *
   * @returns True if user logged in.
   */
  get isLoggedIn() {
    return this.auth.isLoggedIn;
  }

  /**
   * Is the current user restricted?
   *
   * @returns True is user is restricted.
   */
  get isRestrictedUser() {
    return this.auth.isRestrictedUser;
  }

  ngOnInit() {
    this.registerHotkeys();
    this.isHandsetOrHidden$ = combineLatest([
      this.compactLayout$,
      this.isLeftSidebarHidden$,
    ]).pipe(
      map(
        ([useCompactLayout, isLeftSidebarHidden]) =>
          useCompactLayout || !isLeftSidebarHidden
      )
    );

    this.shouldShowCompactLogoBtn$ = combineLatest([
      this.isHandsetOrHidden$,
      this.isLoginPage$,
    ]).pipe(
      map(
        ([isHandsetOrHidden, isLoginPage]) => isHandsetOrHidden && !isLoginPage
      )
    );
    this.registerHelpCommand();
  }

  /**
   * Click event handler for Guest mode Square 9 Logo icon.
   */
  onClickGuestLogo() {
    window.open('https://wwww.square-9.com', '_blank');
  }

  /** Handler for the feedback click event. */
  onSubmitFeedback(): void {
    this.logger.debug('Submit feedback clicked.');
    const emailAddress =
      'contact-project+square9softworks-products-globalsearch-web-23785835-issue-@incoming.gitlab.com';
    const emailSubject = this.translate.translate('FEEDBACK_EMAIL_SUBJECT');
    const emailMessage = this.translate.translate('FEEDBACK_EMAIL_BODY');
    const url = `mailto:${emailAddress}?subject=${emailSubject}&body=${emailMessage}`;
    window.open(url, '_blank');
  }

  /**
   * Show Help/Documentation.
   */
  showHelp() {
    window.open(this.appConfigQuery.s9HelpUrl, '_blank');
  }

  private registerHelpCommand() {
    this.command.register('Help', 'Show help.', () => {
      this.showHelp();
    });
  }

  private registerHotkeys() {
    this.hotkeys
      .addShortcut({
        keys: 'control.h',
        group: 'Global',
        description: 'Show help',
      })
      .subscribe(() => {
        this.logger.debug('Hotkey for user settings pressed.');
        this.showHelp();
      });
  }
}
