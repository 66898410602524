/**
 * User Friendly Error.
 *
 * Describes a human readable error message, including a translation key.
 */
export class UserFriendlyError {
  /** Human readable (english) description of the failure, for logging etc. */
  description: string;
  /** Original error object or message. */
  error: any;
  /** Internationalization translation key. */
  i18n: string;
  /** Any parameters to be inserted into the translation key. */
  i18nParameters?: TranslationParameters;

  /**
   * Create a new instance of `UserFriendlyError`.
   *
   * @param error Original error object or message.
   * @param description Human readable (english) description of the failure, for logging etc.
   * @param i18n Internationalization translation key.
   * @param i18nParameters Any parameters to be inserted into the translation.
   */
  constructor(
    error?: any,
    description?: string,
    i18n?: string,
    i18nParameters?: TranslationParameters
  ) {
    this.error = error;
    this.description = description as string;
    this.i18n = i18n as string;
    this.i18nParameters = i18nParameters;
  }
}

/** Translation parameters. */
export declare type TranslationParameters = { [paramname: string]: string };
