import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { PdfViewerService } from '../../services/pdf-viewer.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  constructor(
    private logger: NGXLogger,
    private pdfViewerService: PdfViewerService
  ) {}

  /**
   * Determine if the button for "Next Result" should be disabled.
   *
   * @returns True when should be disabled.
   */
  get isDisabledNextResult() {
    return this.pdfViewerService.searchText === '';
  }

  /**
   * Get the current page count.
   *
   * @returns Number of pages. */
  get pageCount() {
    return this.pdfViewerService.pageCount;
  }

  /**
   * Get the current page number.
   *
   * @returns Zoom scale. */
  get pageNumber() {
    return this.pdfViewerService.pageNumber;
  }

  /** Set the current page number. */
  set pageNumber(value: number) {
    this.pdfViewerService.pageNumber = value;
  }

  /**
   * Get the current zoom scale.
   *
   * @returns Zoom scale. */
  get zoomScale() {
    return this.pdfViewerService.zoomScale;
  }

  /**
   * Event handler for click of next result.
   */
  onClickNextResult(): void {
    this.pdfViewerService.search(this.pdfViewerService.searchText);
    this.logger.debug('Jump to next result.');
  }

  /**
   * Event handler for click of find in file.
   */
  onClickSearch(): void {
    this.pdfViewerService.promptSearch();
  }

  /**
   * Event handler for click of zoom decrease.
   *
   * Minimum value of 0.1.
   */
  onClickZoomDecrease(): void {
    this.pdfViewerService.zoomDecrease();
  }

  /**
   * Event handler for click of zoom increase.
   *
   * No maximum value.
   */
  onClickZoomIncrease(): void {
    this.pdfViewerService.zoomIncrease();
  }

  /**
   * Event handler for click of zoom-to-fit.
   *
   * Resets the view to default scale.
   */
  onClickZoomToFit(): void {
    this.pdfViewerService.zoomScale = 1;
  }
}
