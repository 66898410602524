<h1 mat-dialog-title><span transloco="DOWNLOAD_AS_CSV"></span></h1>
<div mat-dialog-content>
  <form
    id="exportConfigForm"
    [formGroup]="exportConfigForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field>
      <mat-label transloco="FIELD_DELIMITER"></mat-label>
      <input
        matInput
        type="text"
        placeholder=","
        maxlength="1"
        formControlName="fieldDelimiter"
        class="form-control"
      />
      <mat-error *ngIf="controls.fieldDelimiter.hasError">{{
        getErrorMessage(controls.fieldDelimiter)
      }}</mat-error>
    </mat-form-field>
    <ul class="form-checkbox-list">
      <li>
        <mat-checkbox formControlName="withHeaders"
          ><span transloco="INCLUDE_HEADERS"></span
        ></mat-checkbox>
      </li>
      <!-- <li>
        <mat-checkbox>
          <span>Include table data</span>
        </mat-checkbox>
      </li> -->
    </ul>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="clickCancel()" transloco="CANCEL"></button>
  <button
    mat-button
    form="exportConfigForm"
    [disabled]="!exportConfigForm.valid"
    transloco="OK"
  ></button>
</div>
