import { Injectable } from '@angular/core';
import { QueryEntity, filterNilValue } from '@datorama/akita';
import { ColumnState } from 'ag-grid-community';
import { Observable } from 'rxjs';

import { GridColumnsState, GridStateStore } from './grid-states.store';

/**
 * Grid settings Query.
 */
@Injectable({ providedIn: 'root' })
export class GridStatesQuery extends QueryEntity<GridColumnsState> {
  constructor(protected store: GridStateStore) {
    super(store);
  }

  /**
   * Get the grid column states for an archive.
   *
   * @param databaseId Database Id.
   * @param archiveId ArchiveId.
   * @returns An observable array of grid column states.
   */
  getArchiveColumnStates(databaseId: number, archiveId: number): ColumnState[] {
    return this.getColumnStates(`${databaseId}_${archiveId}`);
  }

  /**
   * Get an observable grid column states for an archive.
   *
   * @param databaseId Database Id.
   * @param archiveId ArchiveId.
   * @returns An observable array of grid settings.
   */
  getArchiveColumnStates$(
    databaseId: number,
    archiveId: number
  ): Observable<ColumnState[]> {
    return this.getColumnStates$(`${databaseId}_${archiveId}`);
  }

  /**
   * Get column states for a grid.
   *
   * @param id Unique ID for the grid settings.
   * @returns An array of grid column states.
   */
  getColumnStates(id: string): ColumnState[] {
    return this.getEntity(id)?.columnStates ?? [];
  }

  /**
   * Get column states for a grid.
   *
   * @param id Unique ID for the grid settings.
   * @returns An observable array of grid column states.
   */
  getColumnStates$(id: string): Observable<ColumnState[]> {
    return this.selectEntity(id, 'columnStates').pipe(filterNilValue());
  }
}
