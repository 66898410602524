import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { Field } from 'models';

import { MultiValueFieldMenuComponent } from './multi-value-field-menu/multi-value-field-menu.component';

/** Base field component. */
@Component({
  template: '',
})
export abstract class FieldBaseComponent {
  /**
   * Form control for the field.
   *
   * This should be an @Input in consumers of this class.
   */
  abstract control: UntypedFormControl;
  /**
   * Field.
   *
   * This should be an @Input in consumers of this class.
   */
  abstract field: Field;

  /**
   * Emits when this field is blurred.
   *
   * This should be an @Output in the consumers of this class.
   */
  abstract fieldBlurred: EventEmitter<Field>;

  /**
   * Emits when this field is focused.
   *
   * This should be an @Output in the consumers of this class.
   */
  abstract fieldFocused: EventEmitter<Field>;

  /**
   * Multi-Value field menu component.
   *
   * This should be an @Input in consumers of this class.
   */
  abstract mvFieldMenu: MultiValueFieldMenuComponent;

  constructor(protected translate: TranslocoService) {}

  /**
   * Get form control error message.
   *
   * @returns A string containing the error message.
   */
  get errorMessage(): string {
    if (this.control.hasError('required')) {
      return this.translate.translate('REQUIRED_FIELD');
    }
    if (this.control.hasError('pattern')) {
      return this.regExMessageFromField;
    }
    if (this.control.hasError('matDatepickerParse')) {
      return this.translate.translate('INVALID_FIELD_DATE');
    }
    if (this.control.hasError('valueNotInList')) {
      return this.translate.translate('NOT_IN_LIST');
    }
    if (this.control.hasError('maxlength')) {
      const maxLengthError = this.control.getError('maxlength');
      return this.translate.translate('MAX_FIELD_LENGTH_ERROR', {
        maxLength: maxLengthError.requiredLength,
      });
    }

    return '';
  }

  /**
   * Get the validation message from the `Field`'s validation regular expression.
   *
   * @returns String containing the validation message, or an empty string if not available.
   */
  get regExMessageFromField(): string {
    return /(?<=\(\?#)(.*?)(?= #\))/.exec(this.field.regex)?.[0] ?? '';
  }

  /**
   * Adds all form validators to the form control.
   */
  addValidators(): void {
    this.control.addValidators(Validators.maxLength(this.field.length));
    if (this.field.required) {
      this.control.addValidators(Validators.required);
    }
    if (this.field.useRegExValidation) {
      this.control.addValidators(
        Validators.pattern(this.getRegExPatternFromField())
      );
    }
  }

  /**
   * Get Reg Ex pattern from the field object.
   *
   * @returns String containing the regular expression.
   */
  getRegExPatternFromField(): string {
    let pattern = this.field.regex;
    if (this.field.useRegExValidation) {
      pattern = pattern
        .replace(/(?<=\(\?#)(.*?)(?= #\))/, '')
        .replace('(?# #)', '');
    }
    return pattern;
  }

  /** Focus the underlying control. */
  abstract focus(): void;

  /**
   * Handles the blur event for the field input.
   *
   * @param event Focus event.
   */
  abstract onBlur(event: FocusEvent): void;

  /** Handles the focus event for the field input. */
  abstract onFocus(): void;
}
