import { LiveField } from 'models';

export interface S9ApiLiveField {
  Method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  Url: string;
  Headers: {
    [key: string]: string;
  };
  JsonPath: string;
  Body: string;
  Script: string;
}

/**
 * Create a `LiveField` from the external API type.
 *
 * @param s9ApiLiveField Square 9 API Live Field.
 * @returns A new`LiveField` object.
 */
export const createLiveField = (s9ApiLiveField: S9ApiLiveField): LiveField =>
  new LiveField({
    body: s9ApiLiveField.Body,
    headers: s9ApiLiveField.Headers,
    jsonPath: s9ApiLiveField.JsonPath,
    method: s9ApiLiveField.Method,
    url: s9ApiLiveField.Url,
    script: s9ApiLiveField.Script,
  });
