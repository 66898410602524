/** Field Properties. */
export enum FieldProperties {
  /** No special field properties. */
  none = 0,
  /**
   * Quick Search.
   *
   * @deprecated 🤣.
   */
  quickSearch = 1,
  /** Required field. */
  required = 1 << 1,
  /** Date entered system field. */
  dateEntered = 1 << 2,
  /** Name of user who indexed a document, system field. */
  indexedBy = 1 << 3,
  /** Page count, system field. */
  pageCount = 1 << 4,
  /** Multiple values. */
  multiValue = 1 << 5,
  /** Dropdown type list field. */
  dropdownList = 1 << 6,
  /** Dynamic list associated field. */
  dynamicList = 1 << 7,
  /** Name of user last modified document, system field. */
  lastModifiedBy = 1 << 8,
  /** Table field. */
  tableField = 1 << 9,
  /** File type extension, system field. */
  fileType = 1 << 10,
  /** Read only, system field. User may not change values. */
  readOnly = 1 << 11,
  /** Document version, system field. */
  documentVersion = 1 << 12,
  /** Parent document ID, system field. */
  documentParent = 1 << 13,

  /** Any of the System Fields. */
  anySystemField = dateEntered |
    indexedBy |
    pageCount |
    lastModifiedBy |
    fileType |
    readOnly,

  /** Any Revision Control reserved field. */
  revisionControlField = documentVersion | documentParent,
}

/** Type of data stored in a field. */
export enum FieldDataType {
  /** String value. */
  character = 1,
  /** Interger number value. */
  integer,
  /** Date/time value. */
  date,
  /** Floating point number value. */
  decimal,
}

/** Square 9 API security types. */
export enum SecurityTypes {
  /** Group. */
  group = 0,
  /** Uuser. */
  user = 1,
  /** Search. */
  search = 1 << 1,
  /** Queue search, favorite. */
  queueSearch = 1 << 2,
  /** Default search. */
  defaultSearch = 1 << 3,
  /** Direct access search. */
  directSearch = 1 << 4,
}

/** Archive properties. */
export enum ArchiveProperties {
  /** Check-in check-out revision controlled. */
  checkInCheckOut = 1,
  /** Automatic PDF conversion enabled. */
  pdfConvert = 1 << 1,
  /** Versioning type revision controlled. */
  revisions = 1 << 2,
  /** Read only. */
  readonly = 1 << 3,
  /**
   * The archive storing the alternative file revisions for revisioned archives.
   */
  versionsArchive = 1 << 4,
}

/**
 * Archive security properties.
 */
export enum ArchiveSecurityProperties {
  /** No access. */
  none = 0,
  /** View documents. */
  view = 1,
  /** Add documents. */
  add = 1 << 1,
  /** Modify documents. */
  modifyDocuments = 1 << 2,
  /** Delete documents. */
  delete = 1 << 3,
  /** Print documents. */
  print = 1 << 4,
  /** Email documents. */
  email = 1 << 5,
  /** Export data. */
  exportData = 1 << 6,
  /** Export documents. */
  exportDocuments = 1 << 7,
  /** View document in external application Adobe Acrobat. */
  viewInAcrobat = 1 << 8,
  /** View document audit history. */
  viewDocumentHistory = 1 << 9,
  /** Modify data. */
  modifyData = 1 << 10,
  /** Modify annotations. */
  modifyAnnotations = 1 << 11,
  /** Launch document. */
  launchDocument = 1 << 12,
  /** Launch a new version of document. */
  launchNewVersion = 1 << 13,
  /** View documents revisions. */
  viewDocumentRevisions = 1 << 14,
  /** Set a document version as the published revision. */
  publishDocumentRevisions = 1 << 15,
  /** Modify documents pages. */
  modifyPages = 1 << 16,
  /** Move document from container. */
  moveDocuments = 1 << 17,
  /**
   * Delete batches.
   *
   * @deprecated Legacy batch manager no longer exists.
   */
  deleteBatches = 1 << 18,
  /** Full API access, allows bypass of certain API routes such a removing the need to provide secure ID search hashes for some calls. */
  fullApiAccess = 1 << 19,

  /** SSAdmin level. */
  all = ~(~0 << 19),
}

/**
 * @alias ArchiveSecurityProperties
 * @description Inbox security properties are the same as the Archive properties.
 */
export { ArchiveSecurityProperties as InboxSecurityProperties };

/**
 * Database security properties.
 */
export enum DatabaseSecurityProperties {
  /** No access. */
  none = 0,
  /** Entity is an administrator of this database. */
  admin = 1,
  /**
   * User may delete batches.
   *
   * @deprecated Legacy batch management no longer exists.
   */ deleteBatches = 1 << 1,
}

/**
 * Check-in/check-out document states.
 */
export enum CheckInCheckOutStates {
  /** Document is checked in. */
  checkedIn = 1,
  /** Document is checked out. */
  checkedOut = 1 << 1,
  /** Document is the published revision. */
  published = 1 << 2,
  /** Document is the latest revision. */
  currentRevision = 1 << 3,
  /** Document is a new revision. */
  newRevision = 1 << 4,
}

/**
 * Search properties.
 */
export enum SearchProperties {
  /** No special properties. */
  none = 0,
  /** Content search is enabled. */
  contentSearch = 1,
  /** Phonics alternatives for content search. */
  phonics = 1 << 1,
  /** Stemming alternatives for content search. */
  stemming = 1 << 2,
  /** ImageXChange enabled. */
  ixEnabled = 1 << 3,
  /** Mutltiple values for search prompts accepted. */
  multiValue = 1 << 4,
  /** Display view tabs in results. */
  displayViewTabs = 1 << 5,
  /** Date field prompts include time. */
  dateFieldsUseTime = 1 << 6,
  /** Dashboard search. */
  dashboardSearch = 1 << 7,
  /** Always prompt for a dashboard search. */
  dashboardAlwaysPrompt = 1 << 8,
}

/**
 * Search operators.
 */
export enum SearchOperator {
  /** Keywords. */
  keywords = -1,
  /** Equals. */
  equals = 1,
  /** Contains. */
  contains,
  /** Greater than equal to. */
  greaterThanEqual,
  /** Less than equal to. */
  lessThanEqual,
  /** Does not equal. */
  doesNotEqual,
  /** Is empty. */
  isEmpty,
  /** Is not empty. */
  isNotEmpty,
}

/**
 * List type.
 */
export enum ListType {
  /** Drop down list. */
  dropdown = 1,
  /** Type ahead completion list. */
  typeahead,
}

/**
 * Database security permissions for setting security.
 */
export enum DatabaseSecurityPostPermissions {
  /** Access only. */
  access = 0,
  /** Access and admin. */
  admin,
  /** Access and delegation. */
  delgation,
  /** Access, admin, and delegation. */
  delegationAdmin,
}

/**
 * Registration type.
 */
export enum RegistrationType {
  /** Unregistered. */
  unregistered = 0,
  /** Timed lease registration. */
  leased,
  /** Full perpetually registered. */
  registered,
}

/**
 * License types.
 */
export enum LicenseTypes {
  /** Full license. */
  full = 0,
  // webOnly = 1 << 1, -- This license type was deprecated and not used, only included as comment for clarity.
  /** Read only license. */
  readOnly = 1 << 2,
}

/**
 * Search substitution strings.
 */
export enum SearchSubstitution {
  /** Replaced with today's date. */
  today = '@TODAY',
  /** Replaced with the context's username. */
  username = '@USER',
  /** Replaced with the context's domain and username. */
  fullUsername = '@FULLUSER',
}
