import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { PartialLoadData } from '../../models';

@Component({
  selector: 'app-partial-load-snackbar',
  templateUrl: './partial-load-snackbar.component.html',
  styleUrls: ['./partial-load-snackbar.component.scss'],
})
export class PartialLoadSnackbarComponent {
  /** Parameters for translation. */
  translateParams: PartialLoadData;

  constructor(
    public snackBarReference: MatSnackBarRef<PartialLoadSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: PartialLoadData
  ) {
    this.translateParams = data;
  }

  /** Dismiss the snackbar. */
  dismiss(): void {
    this.snackBarReference.dismiss();
  }

  /** Request the load be completed. */
  loadComplete(): void {
    this.snackBarReference.dismissWithAction();
  }
}
