<ng-container *ngFor="let button of stateToolbarButtons$ | async">
  <button
    *ngIf="shouldRender(button)"
    mat-icon-button
    [matTooltip]="button.tooltip | transloco"
    [matTooltipShowDelay]="button.tooltipShowDelay"
    [matTooltipPosition]="button.tooltipPosition"
    [disabled]="disableButton(button)"
    (click)="button.onClick()"
  >
    <mat-icon>{{ button.icon }}</mat-icon>
  </button>
</ng-container>
