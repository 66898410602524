import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';

import { SearchResult } from 'models';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-locked-document-menu',
  templateUrl: './locked-document-menu.component.html',
  styleUrls: ['./locked-document-menu.component.scss'],
})
export class LockedDocumentMenuComponent {
  /** Document search result. */
  @Input('search-result')
  searchResult: SearchResult;

  /**
   * Is the document locked in PDF conversion.
   *
   * @returns True if the document is locked in PDF conversion.
   */
  get isPdfConversionLocked(): boolean {
    return this.searchResult.isConvertingToPdf;
  }

  /**
   * Is the document locked in a workflow.
   *
   * @returns True if the document is locked in a workflow.
   */
  get isWorkflowLocked(): boolean {
    return this.searchResult.workflowProcessId > 0;
  }

  /**
   * Is the document locked.
   *
   * @returns True if the document is locked.
   */
  get isDocumentLocked(): boolean {
    return this.isWorkflowLocked || this.isPdfConversionLocked;
  }

  constructor(
    private clipboard: Clipboard,
    private notify: NotificationService
  ) {}

  /**
   * Handler for the copy process id to clipboard click event.
   */
  onCopyProcessIdToClipboard(): void {
    this.clipboard.copy(this.searchResult.workflowProcessId.toString());
    this.notify.success('PROCESS_ID_COPIED_TO_CLIPBOARD');
  }
}
