import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';

import { Field } from 'models';
import { GridHelperService } from 'src/app/services/grid-helper.service';

import { FieldBaseComponent } from '../field-component.base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

@Component({
  selector: 'app-decimal-field',
  templateUrl: './decimal-field.component.html',
  styleUrls: ['./decimal-field.component.scss'],
})
export class DecimalFieldComponent
  extends FieldBaseComponent
  implements OnInit
{
  /** Field form control. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /**
   * Emits when this field is blurred. The event contains the field that was blurred.
   */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;
  /** Whether the field is focused. */
  focused = false;

  constructor(
    translate: TranslocoService,
    private logger: NGXLogger,
    private gridHelper: GridHelperService
  ) {
    super(translate);
  }

  /**
   * Gets the masked value.
   *
   * @returns A string representing the masked value.
   */
  get maskedValue(): string {
    return this.field.format
      ? this.gridHelper.getDecimalCellValue(
          this.control.value,
          this.field.format
        )
      : this.control.value;
  }

  /** @inheritdoc */
  focus(): void {
    // If not already focused we need to complete the focus loop with the masked control.
    if (!this.focused) {
      this.onMaskControlFocus();
      return;
    }
    this.inputElement.nativeElement.focus();
    this.inputElement.nativeElement.select();
  }

  ngOnInit(): void {
    this.addValidators();
  }

  /** @inheritdoc */
  onBlur(): void {
    this.focused = false;
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }

  /** Handler for the mask control focused event. */
  onMaskControlFocus(): void {
    this.focused = true;
    // Push the call to focus the control until the next render cycle when it exists.
    setTimeout(() => this.focus());
  }
}
