import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TableFieldProvider, TableFields } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S9ApiTableField, createTableFieldsFromApi } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 API table field service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiTableFieldService implements TableFieldProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = apiUrl));
  }

  /** @inheritdoc */
  getAll(databaseId: number): Observable<TableFields> {
    return this.http
      .get<S9ApiTableField[]>(
        `${this.basePath}/admin/databases/${databaseId}/tablefields`
      )
      .pipe(map((apiTableFields) => createTableFieldsFromApi(apiTableFields)));
  }
}
