import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ExportJob,
  ExportProvider,
  ExportSettings,
  ExportStatus,
} from 'models';
import { Observable, map } from 'rxjs';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 API Export Service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiExportService implements ExportProvider {
  private basePath: string;

  constructor(
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig,
    private http: HttpClient
  ) {
    this.config.apiUrl$.subscribe(
      (apiUrl) => (this.basePath = `${apiUrl}/export`)
    );
  }

  /** @inheritdoc */
  cancelJob(jobId: string): Observable<void> {
    return this.http.put<void>(`${this.basePath}/${jobId}`, {});
  }

  /** @inheritdoc */
  createJob(settings: ExportSettings): Observable<string> {
    return this.http.post<string>(`${this.basePath}`, settings);
  }

  /** @inheritdoc */
  deleteJob(jobId: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${jobId}`);
  }

  /** @inheritdoc */
  downloadExportZip(jobId: string): Observable<void> {
    return this.http
      .get<any>(`${this.basePath}/${jobId}/zip`, {
        params: new HttpParams().set('jobId', jobId),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((response) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: dataType,
            })
          );
          document.body.append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        })
      );
  }

  /** @inheritdoc */
  getAllUserJobs(): Observable<ExportJob[]> {
    return this.http.get<ExportJob[]>(`${this.basePath}`);
  }

  /** @inheritdoc */
  getJob(jobId: string): Observable<ExportJob> {
    return this.http.get<ExportJob>(`${this.basePath}/${jobId}/job`);
  }

  /** @inheritdoc */
  getJobStatus(jobId: string): Observable<ExportStatus> {
    return this.http.get<ExportStatus>(`${this.basePath}/${jobId}/status`);
  }
}
