import {
  ArchiveSession,
  ArchiveSessionDocument,
  ImportArchiveSessionDocument,
  InboxSession,
  InboxSessionDocument,
  Session,
  SessionDocument,
} from 'models';

import {
  S9ApiSessionDocument,
  S9ApiSessionInboxDocument,
} from './session-document';

/**
 * GlobalSearch API Session object with PascalCase properties.
 */
export interface S9ApiSession {
  Database: number;
  Documents: S9ApiSessionDocument[];
  InboxId: number;
  SearchCriteria: { [key: number]: string };
  SearchId: number;
}

/**
 * GlobalSearch API inbox session object.
 */
export interface S9ApiInboxSession {
  Database: number;
  Documents: S9ApiSessionInboxDocument[];
  InboxId: number;
}

/**
 * Creates an API session object from the provided inbox session.
 *
 * @param session A session object.
 * @returns An S9ApiInboxSession object.
 */
export const createS9ApiInboxSession = (
  session: InboxSession
): S9ApiInboxSession => ({
  Database: session.database,
  Documents: session.documents.map((document) => ({
    InboxFileName: document.filename,
    InboxId: document.id,
  })),
  InboxId: session.documents[0].id,
});

/**
 * Creates an API session object from the provided session object.
 *
 * @param session A Session object.
 * @returns A S9ApiSession object.
 */
export const createS9ApiSession = (session: Session): S9ApiSession => ({
  Documents: (session.documents as SessionDocument[]).map(
    (d: SessionDocument) => ({
      Id: (d as ArchiveSessionDocument).id ?? 0,
      Archive: (d as ArchiveSessionDocument).archiveId ?? 0,
      Hash:
        (d as ArchiveSessionDocument).hash ??
        (d as ImportArchiveSessionDocument).filename ??
        '',
      ContentSearchTerms:
        (d as ArchiveSessionDocument).contentSearchTerms ?? [],
      InboxId: d.hasOwnProperty('archiveId')
        ? 0
        : (d as InboxSessionDocument).id,
      InboxFileName: d.hasOwnProperty('archiveId')
        ? ''
        : (d as InboxSessionDocument).filename,
      OriginalFileName: (d as InboxSessionDocument).originalFilename ?? '',
      FieldData: (d as ImportArchiveSessionDocument).fieldData ?? {},
    })
  ),
  Database: session.database,
  InboxId: 0,
  SearchId: (session as ArchiveSession).searchId ?? 0,
  SearchCriteria: (session as ArchiveSession).searchCriteria ?? {},
});
