import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { AuthenticationProviderConfiguration, UserFriendlyError } from 'models';
import { GUEST_USERNAME } from 'src/app/common/constants';
import { AppConfigQuery, AppConfigService } from 'src/app/modules/app-config';

import { LocationStrategy } from '@angular/common';
import { assertExists } from 'common';
import { Observable, combineLatest, throwError } from 'rxjs';
import { DUO_ICON, GOOGLE_ICON, MICROSOFT_ICON } from '../../models/icons';
import {
  INSTANCE_PROVIDER,
  InstanceProvider,
} from '../../models/instance-provider';
import { LOGIN_PROVIDER, LoginProvider } from '../../models/login-provider';
import { redirectUri } from '../../models/redirect-uri';
import { MsalHelperService } from '../../services/msal-helper.service';
import {
  OpenIdProviders,
  ProviderService,
} from '../../services/provider.service';
import { RedirectService } from '../../services/redirect.service';

const homePath = '/';

/** Login Component. */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /** Authentication Providers. */
  authenticationProviders$ = this.appConfigQuery.authenticationProviders$;
  /** Error message to display. */
  error = '';
  genericProviders$ = this.authenticationProviders$.pipe(
    map((providers) => {
      const genericProvidersMap = new Map<
        string,
        AuthenticationProviderConfiguration
      >();
      const providerEnumValues = Object.values(OpenIdProviders);
      const genericProviders = Object.keys(providers).filter(
        (key) =>
          key.startsWith('generic-') ||
          (!providerEnumValues.includes(key as OpenIdProviders) &&
            !key.startsWith('azure'))
      );

      for (const provider of genericProviders) {
        const providerConfig = providers[provider];
        if (!providerConfig.genericProviderButtonConfiguration) {
          this.logger.error(
            'The generic provider is missing a generic button configuration property and will not be displayed.',
            provider
          );
          continue;
        }
        genericProvidersMap.set(provider, providers[provider]);
      }

      return genericProvidersMap;
    }),
    tap((genericProviders) => {
      for (const [providerKey, provider] of genericProviders) {
        assertExists(
          provider.genericProviderButtonConfiguration,
          'Generic providers must have a valid genericProviderButtonConfiguration object.'
        );
        if (!provider.genericProviderButtonConfiguration.svgIcon) {
          this.logger.debug(
            'Provider does not have a defined svgIcon so the default will be used.'
          );
          continue;
        }
        // There is a value in the svg icon property if we get here so add it to the registry
        this.iconRegistry.addSvgIconLiteral(
          providerKey,
          this.sanitizer.bypassSecurityTrustHtml(
            provider.genericProviderButtonConfiguration.svgIcon
          )
        );
      }
    })
  );
  /** Whether to show the ad panel. */
  hideLoginSidePanel$ = this.appConfigQuery.hideLoginSidePanel$;
  /** API URL. */
  instanceUrl: string;
  /** Observable state of mobile. */
  isSmallViewport$ = this.breakpointObserver
    .observe(['(max-width: 959px)'])
    .pipe(map((results) => results.matches));
  /** Is the instance URL valid. */
  isValidInstance: boolean;
  /** Azure loading state. */
  loadingAzure = false;
  /** Basic auth loading state. */
  loadingBasic = false;
  /** Duo auth loading state. */
  loadingDuo = false;
  /** Loading Generic Auth State. */
  loadingGenericProvider = false;
  /** Loading Google Auth State. */
  loadingGoogle = false;
  /** Guest login loading state. */
  loadingGuest = false;
  /** Basic auth loading state. */
  loadingOkta = false;
  /** Basic auth loading state. */
  loadingOnelogin = false;
  /** Login form. */
  loginForm: UntypedFormGroup;
  /** Login Side panel URL. */
  loginSidePanelUrl$ = this.appConfigQuery.loginSidePanelUrl$.pipe(
    map((url) => this.sanitizer.bypassSecurityTrustResourceUrl(url))
  );
  /** NTLM Authentication is enabled. */
  ntlmAuthentication$ = this.appConfigQuery.ntlmAuthentication$;
  /** Whether to show internal user login inputs. */
  showInternalUserLogin: boolean;
  /** Terms of service URL. */
  tosUrl$ = this.appConfigQuery.tosUrl$.pipe(
    map((url) => this.sanitizer.bypassSecurityTrustResourceUrl(url))
  );

  private availableProviders: string[] = [];
  private msalService = this.msalHelperService.msalService;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslocoService,
    private redirectService: RedirectService,
    private providerService: ProviderService,
    private appConfigQuery: AppConfigQuery,
    private appConfigService: AppConfigService,
    private locationStrategy: LocationStrategy,
    private msalHelperService: MsalHelperService,
    @Inject(LOGIN_PROVIDER)
    private loginProvider: LoginProvider,
    @Inject(INSTANCE_PROVIDER)
    private instanceProvider: InstanceProvider
  ) {
    this.iconRegistry.addSvgIconLiteral(
      'microsoft',
      this.sanitizer.bypassSecurityTrustHtml(MICROSOFT_ICON)
    );
    this.iconRegistry.addSvgIconLiteral(
      'google',
      this.sanitizer.bypassSecurityTrustHtml(GOOGLE_ICON)
    );
    this.iconRegistry.addSvgIconLiteral(
      'duo',
      this.sanitizer.bypassSecurityTrustHtml(DUO_ICON)
    );
    this.iconRegistry.addSvgIcon(
      'openid',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/openid.svg'
      )
    );
  }

  /**
   * Get login form controls.
   *
   * @returns Login form controls.
   */
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Gets if guest login is enabled.
   *
   * @returns A boolean.
   */
  get guestLoginEnabled(): boolean {
    return this.appConfigQuery.appConfig.enableGuestLogin;
  }

  /**
   * Checks if anything is loading.
   *
   * @returns A boolean.
   */
  get loading(): boolean {
    return (
      this.loadingAzure ||
      this.loadingBasic ||
      this.loadingOkta ||
      this.loadingGoogle ||
      this.loadingGuest ||
      this.loadingOnelogin ||
      this.loadingDuo ||
      this.loadingGenericProvider
    );
  }

  /**
   * Get password input error.
   *
   * @returns A string containing the error to display or an empty string if there is no error.
   */
  get passwordInputError(): string {
    if (this.f.username.hasError('required')) {
      return this.translateService.translate('PASSWORD_IS_REQUIRED');
    }

    return '';
  }

  /**
   * Check if any configured providers exist.
   *
   * @returns True if providers are configured.
   */
  get providersExist(): boolean {
    return this.availableProviders.length > 0;
  }

  /**
   * Get username input error.
   *
   * @returns A string containing the error to display or an empty string if there is no error.
   */
  get userInputError(): string {
    if (this.f.username.hasError('required')) {
      return this.translateService.translate('USERNAME_IS_REQUIRED');
    }

    return '';
  }

  /**
   * Check if a provider is configured.
   *
   * @param providerName Name of the provider configuration key.
   * @returns True is the provider is configured.
   */
  hasProvider(providerName: string): boolean {
    return this.availableProviders.includes(providerName);
  }

  ngOnInit(): void {
    this.appConfigQuery.hideLoginInternalUsers$.subscribe(
      (hideInternalUserLogin) =>
        (this.showInternalUserLogin = !hideInternalUserLogin)
    );

    combineLatest([
      this.instanceProvider.instanceUrl$,
      this.listenForInstanceUrl(),
      this.listenForLicenseToken(),
    ]).subscribe(([instanceUrl, routeInstanceUrl, routeLicenseToken]) => {
      const url = routeInstanceUrl || instanceUrl;
      this.instanceUrl = url;
      if (url) {
        this.setInstanceUrl();
      }

      this.appConfigService.get(url).subscribe(() => {
        if (routeLicenseToken) {
          this.logger.debug(
            'License token was found in the route. Attempting auto login.'
          );
          this.loadingBasic = true;
          this.loginProvider
            .validateLicenseToken(routeLicenseToken)
            .pipe(
              map((isValid) => {
                if (!isValid) {
                  return throwError(() => 'License token is not valid.');
                }
              }),
              switchMap(() =>
                this.loginProvider.licenseTokenLogin(routeLicenseToken)
              ),
              finalize(() => (this.loadingBasic = false))
            )
            .subscribe((authenticatedUser) => {
              this.logger.debug(
                'Query parameter login completed. Redirecting into application.'
              );
              // We have a valid license so redirect into the application.
              this.onLoginSuccess();
            });
        }
      });
    });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.setRedirectUri();
    this.listenForErrorFromUrl();

    // Subscribe to the list of configured provider keys.
    this.authenticationProviders$
      .pipe(untilDestroyed(this))
      .subscribe((providers) => {
        this.availableProviders = Object.keys(providers);
        this.handleAutoLoginRequests();
      });
  }

  /**
   * Event handler for the clear error message button.
   */
  onClearError(): void {
    this.error = '';
  }

  /** Handler for the double click title event. */
  onDoubleClickTitle(): void {
    this.logger.debug('Login title double clicked.');
    this.showInternalUserLogin = true;
  }

  /** Event handler for starting the Duo login process. */
  onDuoLogin(): void {
    this.setInstanceUrl();
    this.loadingDuo = true;
    this.setProvider('duo');
    this.providerService.login(OpenIdProviders.Duo).subscribe({
      error: (error) => {
        this.logger.error('Unable to log in with Duo', error);
        this.error =
          'An error occurred while attempting to log in with provider.';
      },
    });
  }

  onGenericProviderLogin(providerKey: string): void {
    this.setInstanceUrl();
    this.loadingGenericProvider = true;
    this.setProvider(providerKey);
    this.providerService.loginGeneric(providerKey).subscribe({
      error: (error) => {
        this.logger.error('Unable to log in with Duo', error);
        this.error =
          'An error occurred while attempting to log in with provider.';
      },
    });
  }

  /**
   * Event handler for starting the Google login process.
   */
  onGoogleLogin(): void {
    this.setInstanceUrl();
    this.loadingGoogle = true;
    this.setProvider('google');
    this.providerService.login(OpenIdProviders.Google).subscribe({
      error: (error) => {
        this.logger.error('Unable to log in with Google', error);
        this.error =
          'An error occurred while attempting to log in with provider.';
      },
    });
  }

  /**
   * Event handler for starting the guest login process.
   */
  onGuestLogin(): void {
    this.logger.debug('Starting guest login...');
    this.setInstanceUrl();
    this.loadingGuest = true;
    this.loginProvider.basicLogin(GUEST_USERNAME, '').subscribe({
      next: () => this.onLoginSuccess(),
      error: (error: UserFriendlyError) => {
        this.logger.warn('Basic login attempted failed.', error);
        this.error = error.i18n;
      },
      complete: () => (this.loadingGuest = false),
    });
  }

  /**
   * Event handler for starting the Microsoft login process.
   */
  onMicrosoftLogin(): void {
    this.setInstanceUrl();
    this.loadingAzure = true;
    this.setProvider('azure');
    const redirectRequest = {
      scopes: ['User.Read'],
      redirectStartPage: redirectUri,
    };
    this.msalService.handleRedirectObservable().subscribe({
      next: (_tokenResponse) => {
        this.msalService.acquireTokenRedirect(redirectRequest).subscribe({
          next: () => this.logger.debug('Azure acquire token successful'),
          error: () =>
            this.msalService.loginRedirect(redirectRequest).subscribe(),
        });
      },
      error: () =>
        this.logger.error('An error occured while handling Azure redirect.'),
    });
  }

  /**
   * Event handler for starting the Okta login process.
   */
  onOktaLogin() {
    this.setInstanceUrl();
    this.loadingOkta = true;
    this.setProvider('okta');
    this.providerService.login(OpenIdProviders.Okta).subscribe({
      error: (error) => {
        this.logger.error('Unable to log in with Okta', error);
        this.error =
          'An error occurred while attemping to log in with provider.';
      },
    });
  }

  /**
   * Event handler for starting the OneLogin login process.
   */
  onOneLogin(): void {
    this.setInstanceUrl();
    this.loadingOnelogin = true;
    this.setProvider('onelogin');
    this.providerService.login(OpenIdProviders.OneLogin).subscribe();
  }

  /**
   * Event handler for onSubmit.
   *
   * @listens this.onSubmit
   */
  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loadingBasic = true;
    this.setInstanceUrl();
    this.loginProvider
      .basicLogin(this.f.username.value, this.f.password.value)
      .pipe(finalize(() => (this.loadingBasic = false)))
      .subscribe({
        next: () => this.onLoginSuccess(),
        error: (error: UserFriendlyError) => {
          this.logger.warn('Basic login attempted failed.', error);
          this.error = error.i18n;
        },
      });
  }

  /** Event handler for starting the NTLM login process. */
  onWindowsLogin(): void {
    this.loginProvider.ntlmLogin().subscribe({
      next: () => this.onLoginSuccess(),
      error: (error: UserFriendlyError) => {
        this.logger.error('NTLM login attempt failed.', error);
        this.error = error.i18n;
      },
    });
  }

  /**
   * Check for an auto-login request in the query string, redirecting the flow
   * to the specified provider.
   */
  private handleAutoLoginRequests() {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        map((params) => (params['autologin'] as string)?.toLowerCase()),
        filter((autologin) => !!autologin)
      )
      .subscribe((autologin) => {
        this.logger.debug('Auto-login requested for provided: ', autologin);
        const unsupported = () => {
          this.logger.error('Unsupported auto-login provider requested.');
        };
        if (!this.hasProvider(autologin)) {
          unsupported();
          return;
        }
        switch (autologin) {
          case 'okta':
            this.onOktaLogin();
            break;
          case 'azure':
            this.onMicrosoftLogin();
            break;
          case 'google':
            this.onGoogleLogin();
            break;
          case 'onelogin':
            this.onOneLogin();
            break;
          default:
            unsupported();
        }
      });
  }

  private listenForErrorFromUrl(): void {
    const subscription = this.route.queryParamMap
      .pipe(untilDestroyed(this))
      .subscribe((queryParameters) => {
        const errorParameter = queryParameters.get('error');
        if (errorParameter) {
          this.error = atob(errorParameter);
          subscription.unsubscribe();
        }
      });
  }

  /**
   * Get the instance URL from the query string.
   *
   * @returns An observable instance URL from the query string or undefined if not found.
   */
  private listenForInstanceUrl(): Observable<string | undefined> {
    return this.route.queryParams.pipe(
      untilDestroyed(this),
      map((params) => params['instance'] as string)
    );
  }

  /**
   * Get the license token from the query string.
   *
   * @returns An observable license token from the query string or undefined if not found.
   */
  private listenForLicenseToken(): Observable<string | undefined> {
    return this.route.queryParams.pipe(
      untilDestroyed(this),
      map((params) => params['licenseToken'] as string)
    );
  }

  private onLoginSuccess(): void {
    this.logger.debug('Login complete, redirecting.');
    this.redirectService.redirect(this.route.snapshot.queryParams.returnUrl);
  }

  private setInstanceUrl(): void {
    if (!this.instanceUrl) {
      throw new Error('Instance URL must be set.');
    }

    this.instanceProvider.update(this.instanceUrl);
  }

  /**
   * Sets the provider in session storage.
   *
   * @param provider Provider.
   */
  private setProvider(provider: string): void {
    window.sessionStorage.setItem('login.provider', provider);
  }

  /**
   * Sets the redirect uri property in session storage to use the returnUrl query parameter path or the home path if no return URL is specified.
   */
  private setRedirectUri(): void {
    const url = this.route.snapshot.queryParams.returnUrl;
    window.sessionStorage.setItem('redirect.uri', url || homePath);
    return;
  }
}
