import { assert, assertExists } from 'common';

export type LiveFieldExecutionStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'COMPLETED_WITH_ERRORS';

export type LiveFieldSupportedMethods =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'DELETE';

/**
 * Live field.
 *
 * Many of the values are optional for usage.
 *
 * Documentation:
 * @link https://square9softworks.atlassian.net/wiki/spaces/DEV/pages/1614578072/Live+Fields
 */
export class LiveField {
  /** Optional URL to send the request to. */
  url: string;
  /** HTTP method to use. */
  method: LiveFieldSupportedMethods;
  /** JSON path to extract result from response. */
  jsonPath: string;
  /** Optional script to execute. */
  script: string;
  /** Optional headers to include. */
  headers: { [key: string]: string };
  /** Optional body to send with the request. */
  body: string;

  /** The result of the execution. */
  private _result?: string;

  /** Track the status of the execution. */
  private _status: LiveFieldExecutionStatus = 'NOT_STARTED';

  /**
   * Initializes a new instance of the LiveField class.
   *
   * @param {Partial<LiveField>} init - Optional initialization object.
   */
  constructor(init?: Partial<LiveField>) {
    // Use a structured clone to insure the object is mutable.
    Object.assign(this, structuredClone(init));
  }

  /**
   * Gets the current status of the LiveField execution.
   *
   * @return {LiveFieldExecutionStatus} The current status of the execution.
   */
  get status(): LiveFieldExecutionStatus {
    return this._status;
  }

  setStatus(status: LiveFieldExecutionStatus): void {
    this._status = status;
  }

  /**
   * Gets the result of the LiveField execution.
   *
   * @return {string} The result of the execution.
   */
  get result(): string {
    assert(this.isComplete, 'LiveField execution is not complete.');
    assertExists(this._result, 'LiveField result does not exist.');
    assertExists(this._result, 'LiveField result is undefined.');
    return this._result;
  }

  /**
   * Sets the result of the LiveField execution.
   *
   * This function is intended to be called by the LiveField service during/after
   * execution of the LiveField, and should not be set directly outside of that context.
   *
   * @param {string} result - The result to set.
   */
  setResult(result: string): void {
    assert(
      this._status === 'IN_PROGRESS',
      'LiveField result can only be set while in progress.'
    );
    this._result = result;
  }

  /**
   * Whether the live field execution is complete.
   *
   * @return {boolean} True if complete or complete with errors.
   */
  get isComplete(): boolean {
    return (
      this.status === 'COMPLETED' || this.status === 'COMPLETED_WITH_ERRORS'
    );
  }

  /**
   * Checks if the Live Field is valid for use.
   *
   * @return {boolean} True if the state is valid, false otherwise.
   */
  isValid(): boolean {
    try {
      // If a URL is provided...
      if (this.url) {
        // It must be valid.
        new URL(this.url);
        // A Method must be provided.
        assertExists(this.method);
        // The method must be a valid method.
        assert(
          ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].indexOf(this.method) >= 0
        );
        /*
         * Note: If a JSON path is provided it should be valid, but the library
         * does not provide a validator. Evaluation will have no result.
         */
      }
      // If a script is provided...
      if (this.script) {
        // Confirm no forbidden techniques and javascript are used.

        // No nested evaluations.
        assert(this.script.indexOf('eval(') < 0);

        /*
         * Block low hanging fruit of DOM manipulation.
         * Anything that tries to get a DOM element should just be prevented.
         * We only want elements and data accessed via the injected API.
         */

        // No selection of DOM elements using getElementById.
        assert(this.script.indexOf('getElementById') < 0);
        // No selection of DOM elements using querySelector.
        assert(this.script.indexOf('querySelector') < 0);
        // No selection of DOM elements using querySelectorAll.
        assert(this.script.indexOf('querySelectorAll') < 0);
        // No selection of DOM elements using getElementsByName.
        assert(this.script.indexOf('getElementsByName') < 0);
        // No selection of DOM elements using getSelection.
        assert(this.script.indexOf('getSelection') < 0);
        // No selection of DOM elements using getElementsByClassName.
        assert(this.script.indexOf('getElementsByClassName') < 0);
        // No selection of DOM elements using getElementsByTagName.
        assert(this.script.indexOf('getElementsByTagName') < 0);
        // No selection of DOM elements using getElementsByTagNameNS.
        assert(this.script.indexOf('getElementsByTagNameNS') < 0);
      }

      // If we got this far, the state is valid.
      return true;
    } catch {
      return false;
    }
  }
}
