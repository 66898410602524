import { Injectable } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { BehaviorSubject, Observable } from 'rxjs';

export interface StateToolbarButton {
  /**
   * Determines if the button should be disabled.
   *
   * This can be a function which runs to check if the button should be disabled.
   */
  disabled: boolean | (() => boolean);
  /** Material icon to be displayed. */
  icon: string;
  /** Unique identifer for the button. */
  id: string;
  /** Function fired when the click event fires. */
  onClick: () => void;
  /**
   * Determines if the button should be rendered.
   *
   * This can either be a boolean or a function that returns a boolean.
   */
  shouldRender: boolean | (() => boolean);
  /** Tooltip to be displayed. */
  tooltip: string;
  /** Tooltip position. */
  tooltipPosition: TooltipPosition;
  /** Tooltip show delay. */
  tooltipShowDelay: number;
}

/** Manages the state toolbar. */
@Injectable({
  providedIn: 'root',
})
export class StateToolbarService {
  /** Observable of state toolbar buttons that should current be displayed. */
  stateToolbarButtons$: Observable<StateToolbarButton[]>;

  /**
   * State toolbar buttons that should current be displayed.
   *
   * @returns An array of state toolbar buttons.
   */
  get stateToolbarButtons() {
    return this.stateToolbarButtonsSubject.getValue();
  }

  private stateToolbarButtonsSubject = new BehaviorSubject<
    StateToolbarButton[]
  >([]);
  constructor() {
    this.stateToolbarButtons$ = this.stateToolbarButtonsSubject.asObservable();
  }

  /**
   * Add state buttons to the state toolbar.
   *
   * @param buttons State buttons.
   */
  add(...buttons: StateToolbarButton[]) {
    const currentButtons = this.stateToolbarButtonsSubject.getValue();
    this.stateToolbarButtonsSubject.next([...currentButtons, ...buttons]);
  }

  /** Clears the state toolbar buttons. */
  clear() {
    this.stateToolbarButtonsSubject.next([]);
  }

  /**
   * Remove state buttons from the state toolbar.
   *
   * @param ids Ids to remove.
   */
  remove(...ids: string[]) {
    const toolbarButtons = this.stateToolbarButtonsSubject.getValue();
    for (const id of ids) {
      const index = toolbarButtons.findIndex((button) => button.id === id);
      if (index > -1) {
        toolbarButtons.splice(index, 1);
      }
    }

    this.stateToolbarButtonsSubject.next(toolbarButtons);
  }
}
