<div class="container">
  <div class="column-list-container">
    <div class="column-list">
      <mat-list>
        <mat-list-item *ngFor="let column of gridColumns">
          <button
            mat-icon-button
            [matTooltip]="'VISIBILITY' | transloco"
            (click)="onClickToggleVisibility(column)"
          >
            <mat-icon>{{
              column.isVisible() ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'PINNING' | transloco"
            (click)="onClickPinToggle(column)"
          >
            <ng-container *ngIf="getPinIconName(column) as icon">
              <mat-icon *ngIf="icon === 'pin_off'" svgIcon="pin_off"></mat-icon>
              <mat-icon *ngIf="icon !== 'pin_off'">{{ icon }}</mat-icon>
            </ng-container>
          </button>
          <div
            *ngIf="getColumnName(column) as name"
            class="ellipsis"
            [matTooltip]="name"
          >
            {{ name }}
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="column-controls-container">
    <mat-action-list>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'SHOW_ALL_COLUMNS' | transloco"
        matTooltipPosition="left"
        (click)="onClickShowAllColumns()"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'HIDE_ALL_COLUMNS' | transloco"
        matTooltipPosition="left"
        (click)="onClickHideAllColumns()"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'UNPIN_ALL_COLUMNS' | transloco"
        matTooltipPosition="left"
        (click)="onClickUnPinAllColumns()"
      >
        <mat-icon>push_pin</mat-icon>
      </button>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'FIT_TO_COLUMN_WIDTH' | transloco"
        matTooltipPosition="left"
        (click)="onClickFitToColumnWidth()"
      >
        <mat-icon>align_horizontal_center</mat-icon>
      </button>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'REMOVE_ALL_SORTS' | transloco"
        matTooltipPosition="left"
        (click)="onClickRemoveAllSorts()"
      >
        <mat-icon>sort</mat-icon>
      </button>
      <button
        mat-list-item
        class="column-control"
        [matTooltip]="'RESET_LAYOUT' | transloco"
        matTooltipPosition="left"
        (click)="onClickResetLayout()"
      >
        <mat-icon>view_column</mat-icon>
      </button>
    </mat-action-list>
  </div>
</div>
