import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  persistState,
} from '@datorama/akita';

import { FieldValues } from 'models';

/** Describes indexer state. */
export interface IndexerData {
  /** Field values. */
  fieldValues: FieldValues;
}

/** Indexer state. */
export type IndexerState = EntityState<IndexerData, string>;

const usersPersistStorage = persistState({
  include: ['indexer'],
  key: 'indexerStore',
  storage: sessionStorage,
});

/** Indexer state store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'indexer', resettable: true })
export class IndexerStateStore extends EntityStore<IndexerState> {
  constructor() {
    super([]);
    // this.reset();
  }
}
