import { assert, assertExists } from 'common';
import { PDFImage, PDFPage, PDFPageDrawImageOptions, degrees } from 'pdf-lib';

import { Color } from './color';

import { ConvertableAnnotation, IDocumentAnnotationBase } from '.';

/**
 * SVG path annotation.
 */
export class PathAnnotation
  extends ConvertableAnnotation
  implements IDocumentAnnotationBase
{
  /** @inheritdoc */
  hasFont = false;

  constructor(
    public page: number,
    public x: number,
    public y: number,
    /** Line color. */
    public color: Color,
    /** Line thickness. */
    public thickness: number = 1,
    /** Rotation, in degrees. */
    public rotation: number = 0,
    /** Visbility. */
    public visible: boolean = true,
    /**
     * A flat list of points to use to create the path.
     *
     * This is how the data we get from Atalasoft is formatted.
     */
    private pathPoints: number[] = [],
    /**
     * Embedded image for annotation.
     */
    private embeddedImage?: PDFImage,
    private height: number = 0,
    private width: number = 0
  ) {
    super();
  }

  /** @inheritdoc */
  drawOnPage(
    page: PDFPage,
    scalePixelsToPoints = false,
    convertToCartesian = false
  ): void {
    const options = this.toPdfImageOptions();
    if (scalePixelsToPoints) this.convertPixelsToPoints(options);
    this.adjustForRotation(page, options);
    if (convertToCartesian) {
      const height = page.getSize().height;
      assert(typeof height === 'number');
      this.convertToCartesian(height, options);
    }
    assertExists(
      this.embeddedImage,
      'No image was embedded for this annotaion.'
    );
    page.drawImage(this.embeddedImage, options);
  }

  /**
   * Set the image embedded for this annotation.
   *
   * @param image Embedded image.
   */
  embedImage(image: PDFImage) {
    this.embeddedImage = image;
  }

  /**
   * Convert the image to a PNG, and get the data URL.
   *
   * @todo Currently this implemented to expect conversion from Atalasoft only. Future drawing should support the appropriate path conversion.
   *
   * @returns Data URL of PNG.
   */
  toPng() {
    assertExists(this.pathPoints);
    return this.pointsToPng(this.pathPoints);
  }

  private adjustForRotation(page: PDFPage, options: PDFPageDrawImageOptions) {
    assertExists(options.x);
    assertExists(options.y);
    assertExists(options.width);
    assertExists(options.height);

    const pageRotation = page.getRotation();
    const xOrig = options.x;
    options.rotate = degrees(
      ((options.rotate?.angle ?? 0) + pageRotation.angle) % 360
    );
    switch (pageRotation.angle) {
      case 90:
        // Swap x and y values:
        // The x value requires adding the image height to correct corner.
        options.x = options.y + options.height;
        // The y value requires subtracting page height, and the object height
        // to offset corner.
        options.y = page.getHeight() - xOrig - options.height;
        break;
      case 180:
        // Subtract x value from page width.
        options.x = page.getWidth() - options.x;
        // Subtract y value from page height, then the height x2 to offset corner.
        options.y = page.getHeight() - options.y - options.height * 2;
        break;
      case 270:
        // Swap x and y values:
        // Subtract y value from page width and height to offset corner.
        options.x = page.getWidth() - options.y - options.height;
        // Subtract the object height from the original x coordinate to offset.
        options.y = xOrig - options.height;
        break;
      case 0:
      default:
      // No rotation.
    }
  }

  /**
   * Convert a flat list of points into a PNG drawing of the path.
   *
   * @param points A flat list of path coordinates.
   * @returns PNG data URL.
   */
  private pointsToPng(points: number[]): string {
    assert(
      points.length > 0 && points.length % 2 === 0,
      'Point list must exist and have an even number of values.'
    );
    assert(this.height > 0, 'Must have a valid height.');
    assert(this.width > 0, 'Must have a valid width.');
    // Creates a canvas.
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = this.width;
    canvas.height = this.height;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    // Create a path from the points list.
    context.beginPath();
    // Move to the origin point.
    context.moveTo(points[0], points[1]);
    // For each point pair in the list list...
    for (
      let index = 2, length = points.length;
      index < length + 1;
      index += 2
    ) {
      // Get next coordinates.
      const x = points[index];
      const y = points[index + 1];
      // Continue the path to the coordinate pair.
      context.lineTo(x, y);
    }
    // Configure the stroke settings.
    context.strokeStyle = this.color.rgba;
    context.lineWidth = this.thickness;
    // Stroke the path.
    context.stroke();
    // Render the canvas to a PNG.
    const data = canvas.toDataURL();
    // Return the data URL.
    return data;
  }

  /**
   * Convert Atalasoft like lists of paths coordinates to an SVG path.
   *
   * This is currently unused, as PDF-Lib can not stroke paths.
   *
   * @param points A list of numbers that will split to coordinate pairs.
   * @returns A string for the SVG path value.
   */
  private pointsToSvgPath(points: number[]): string {
    const movePen = 'M';
    const drawLineToAbsolute = 'L';
    const pointSeparator = ' ';
    let svgPath = `${movePen}${points[0]},${points[1]}${drawLineToAbsolute}`;
    for (
      let index = 2, length = points.length;
      index < length + 1;
      index += 2
    ) {
      svgPath += `${pointSeparator}${points[index]},${points[index + 1]}`;
    }
    return svgPath;
  }

  /**
   * Convert the annotation to PDF element drawing options as image.
   *
   * @returns PDF drawing options.
   * @throws Error for unknown style properties.
   */
  private toPdfImageOptions(): PDFPageDrawImageOptions {
    const options: PDFPageDrawImageOptions = {
      x: this.x,
      y: this.y,
      height: this.height,
      width: this.width,
      rotate: degrees(this.rotation * -1),
    };
    return options;
  }
}
