<h2 mat-dialog-title>{{ dialogData.title | transloco }}</h2>
<mat-dialog-content>
  <p>
    {{ dialogData.contents | transloco }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ dialogData.cancelActionText | transloco }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ dialogData.confirmActionText | transloco }}
  </button>
</mat-dialog-actions>
