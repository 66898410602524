import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { CSVImportJob } from 'models';

/** CSV import job state. */
export type CSVImportJobState = EntityState<CSVImportJob>;

/** CSV Import Job Store. */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'CSVImportJob', resettable: true })
export class CSVImportJobStore extends EntityStore<CSVImportJobState> {
  constructor() {
    super();
  }
}
