import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { AdvancedLinksState, AdvancedLinksStore } from './advanced-links.store';

/**
 * Advanced Link store query.
 */
@Injectable({ providedIn: 'root' })
export class AdvancedLinkQuery extends QueryEntity<AdvancedLinksState> {
  /** Observable of all available fields. */
  advancedLinks$ = this.selectAll();

  constructor(protected store: AdvancedLinksStore) {
    super(store);
  }
}
