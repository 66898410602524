<h2 mat-dialog-title transloco="SHARE_DOCUMENT"></h2>

<mat-vertical-stepper #stepper (selectionChange)="stepEvent($event)" linear>
  <mat-step [stepControl]="expirationFromGroup">
    <form [formGroup]="expirationFromGroup">
      <ng-template matStepLabel>{{
        'CHOOSE_EXPIRATION_DATE' | transloco
      }}</ng-template>
      <mat-form-field class="full-width">
        <mat-label transloco="EXPIRATION"></mat-label>
        <input
          matInput
          formControlName="expiration"
          [ngxMatDatetimePicker]="picker"
          class="form-control"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [enableMeridian]="true"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="f.expiration.hasError">
          {{ expirationFormControlError | transloco }}
        </mat-error>
      </mat-form-field>
      <div>
        <button
          [disabled]="expirationFromGroup.invalid"
          mat-button
          matStepperNext
          type="button"
          transloco="NEXT"
        ></button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="urlFormGroup">
    <form [formGroup]="urlFormGroup">
      <ng-template matStepLabel disabled="true">{{
        'COPY_URL_TO_SHARE' | transloco
      }}</ng-template>
      <mat-form-field class="full-width">
        <mat-label transloco="URL"></mat-label>
        <input
          matInput
          type="text"
          formControlName="url"
          class="form-control"
          [readonly]="true"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="copyUrl(urlFormGroup.controls.url.value)"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious transloco="BACK"></button>
        <button mat-button (click)="closeDialog()" transloco="CLOSE"></button>
      </div>
    </form>
  </mat-step>
</mat-vertical-stepper>
