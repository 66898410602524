import { Pipe, PipeTransform } from '@angular/core';

/** Pipe for displaying relative dates. */
@Pipe({
  name: 'DateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  /** @inheritdoc */
  transform(value: any): any {
    if (value) {
      const seconds = Math.floor((Date.now() - Number(new Date(value))) / 1000);
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals: { [index: string]: number } = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const index in intervals) {
        if (Object.prototype.hasOwnProperty.call(intervals, index)) {
          counter = Math.floor(seconds / intervals[index]);
          if (counter > 0) {
            if (counter === 1) {
              return counter + ' ' + index + ' ago'; // singular (1 day ago)
            } else {
              return counter + ' ' + index + 's ago'; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }
}
