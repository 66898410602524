import { UploadOutput } from '@angular-ex/uploader';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Inbox, UserFriendlyError } from 'models';
import { Api } from 'src/app/models';
import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { InboxesService } from 'src/app/state/inboxes/inboxes.service';

import { NavigationInboxMenuComponent } from '../navigation-inbox-menu/navigation-inbox-menu.component';

/**
 * Sidebar inbox component.
 */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-navigation-inbox',
  templateUrl: './navigation-inbox.component.html',
  styleUrls: ['./navigation-inbox.component.scss'],
})
export class NavigationInboxComponent implements OnDestroy {
  /** Database ID. */
  @Input() dbId: number;
  /** Reference to the file input element. */
  @ViewChild('inboxFileUploader') fileUploader: ElementRef;
  /** Inbox object. */
  @Input() inbox: Inbox;
  /** Reference to the menu component. */
  @ViewChild(NavigationInboxMenuComponent)
  menu: NavigationInboxMenuComponent;
  /** Determines if a file is dragged over the component. */
  dragOver: boolean;
  /** Observable containing the GSE connection status. */
  isGseConnected$ = this.gseService.isConnected$;
  /** Uploader attached to this navigation component. */
  uploader = this.uploadService.createUploader(Api.square9);
  /** Uploader options bound to the @angular-ex/uploader. */
  uploaderOptions$ = this.uploadService.uploaderOptions$;

  constructor(
    private logger: NGXLogger,
    private uploadService: UploadService,
    private gseService: GseService,
    private inboxService: InboxesService,
    private grid: GridSettingsService,
    private notify: NotificationService
  ) {}

  ngOnDestroy(): void {
    this.uploadService.destroyUploader(this.uploader);
  }

  /**
   * Event handler for opening the menu.
   *
   * @param event Mouse event.
   */
  onOpenMenu(event: MouseEvent): void {
    event.preventDefault();
    this.menu.openMenu(event, this.inbox);
  }

  /**
   * The event function that runs whenever a change occurs in the @angular-ex/uploader component.
   *
   * @param output An instance of UploadOutput provided by @angular-ex/uploader.
   */
  onUploadOutput = (output: UploadOutput) => {
    const result = this.uploadService.onUploadOutput(output, this.uploader);
    this.dragOver = result.dragOver;
    if (result.isDone) {
      const uploadedFiles = this.uploader.uploadedFiles;
      this.uploader.flushCompletedFiles();
      this.logger.debug(
        'File uploads are finished and will be imported into the inbox.',
        uploadedFiles
      );
      const imports: Observable<string>[] = [];
      for (const file of uploadedFiles) {
        this.logger.debug('Importing file to inbox...', file);
        const import$ = this.inboxService
          .importFile(this.inbox.id, file.response.files[0].name, file.name)
          .pipe<string>(
            catchError((error: UserFriendlyError) => {
              error.i18n = 'ERROR_IMPORT';
              this.notify.error(error);
              return EMPTY;
            })
          );
        imports.push(import$);
      }

      const imports$ = forkJoin(imports);
      imports$.subscribe(() => {
        this.logger.debug('Inbox imports completed.');
        this.grid.refreshInboxGrid(this.inbox.id);
      });
    }
  };
}
