import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Field } from 'models';

/**
 * Field state entity.
 */
export type FieldsState = EntityState<Field, number>;

/**
 * Field store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fields', resettable: true })
export class FieldsStore extends EntityStore<FieldsState> {
  constructor() {
    super();
  }
}
