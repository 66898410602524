import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'marked';

/**
 * A pipe that converts markdown to HTML.
 */
@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  /** @inheritdoc */
  transform(value: string): string {
    return parse(value);
  }
}
