<div class="mv-field-container">
  <div class="bar"></div>
  <div class="fields">
    <ng-container *ngFor="let control of formControls">
      <app-field
        [form-control]="control"
        [field]="field"
        [mv-field-menu]="mvFieldMenu"
        (keyup.enter)="onAddMultiValue({ append: true }, control, false)"
        (fieldFocused)="onFieldFocused()"
        (fieldBlurred)="onFieldBlurred()"
      ></app-field>
      <app-multi-value-field-menu
        #mvFieldMenu
        (addMultiValue)="onAddMultiValue($event, control, true)"
        (removeMultiValue)="onRemoveMultiValue(control)"
      ></app-multi-value-field-menu>
    </ng-container>
  </div>
</div>
