import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
} from '@angular-material-components/datetime-picker';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { debounceTime } from 'rxjs';

import { Field } from 'models';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { DateFormatsService } from 'src/app/state/date-formats/date-formats.service';

import { FieldBaseComponent } from '../field-component.base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

/** Date Field Component. */
@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent extends FieldBaseComponent implements OnInit {
  /** Form control for the field. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;
  /**
   * Emits when this field is blurred. The event contains the field that was blurred.
   */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;

  /** Whether the field is focused. */
  focused = false;
  /**
   * Form control for the field which contains the masked value string for the date.
   */
  protected maskedControl = new FormControl('');
  private momentFormat: string;
  private dateRangeValidator = this.createDateRangeValidator();
  /** Minimum date supported by SQL. */
  private minDate = moment('1753-01-01');
  /** Maximum date supported by SQL. */
  private maxDate = moment('9999-12-31');

  constructor(
    translate: TranslocoService,
    private gridHelper: GridHelperService,
    private dateFormatService: DateFormatsService,
    @Inject(DateAdapter) private dateAdapter: MomentDateAdapter,
    @Inject(NGX_MAT_DATE_FORMATS) private dateFormats: NgxMatDateFormats,
    private logger: NGXLogger
  ) {
    super(translate);
  }

  /** @inheritdoc */
  get errorMessage(): string {
    if (this.maskedControl.hasError('dateOutOfRange')) {
      return this.translate.translate('DATE_OUT_OF_RANGE_MSG');
    }

    // fallback to base getter.
    return super.errorMessage;
  }

  /**
   * Get masked form control error message.
   *
   * @returns A string containing the error message.
   */
  get maskedErrorMessage(): string {
    if (this.maskedControl.hasError('required')) {
      return this.translate.translate('REQUIRED_FIELD');
    }
    if (this.maskedControl.hasError('pattern')) {
      return this.regExMessageFromField;
    }
    if (this.maskedControl.hasError('matDatepickerParse')) {
      return this.translate.translate('INVALID_FIELD_DATE');
    }
    if (this.maskedControl.hasError('valueNotInList')) {
      return this.translate.translate('NOT_IN_LIST');
    }
    if (this.maskedControl.hasError('maxlength')) {
      const maxLengthError = this.maskedControl.getError('maxlength');
      return this.translate.translate('MAX_FIELD_LENGTH_ERROR', {
        maxLength: maxLengthError.requiredLength,
      });
    }
    if (this.maskedControl.hasError('dateOutOfRange')) {
      return this.translate.translate('DATE_OUT_OF_RANGE_MSG');
    }

    return '';
  }

  /** @inheritdoc */
  focus(): void {
    if (!this.focused) {
      // If not already focused we need to complete the focus loop with the masked control.
      this.onMaskControlFocus();
      return;
    }
    this.inputElement.nativeElement.focus();
    this.inputElement.nativeElement.select();
  }

  ngOnInit(): void {
    this.addValidators();
    this.maskedControl.addValidators(this.createMaskedDateParseValidator());
    this.maskedControl.addValidators(this.dateRangeValidator);
    if (this.field.format) {
      this.dateFormatService
        .get(this.field.format)
        .subscribe((momentFormat) => {
          this.momentFormat = momentFormat;
          this.setMaskedValue();
        });
    } else {
      this.setMaskedValue();
    }

    this.listenForControlStatusChanges();
    this.listenForControlValueChanges();
  }

  /** @inheritdoc */
  onBlur(event: FocusEvent): void {
    // Ignore if the next target is a button to assume you are trying to click the date picker.
    // Related target is the date picker button element if that is what the user clicked.
    if (
      event.relatedTarget &&
      (event.relatedTarget as Element).nodeName === 'BUTTON'
    ) {
      this.logger.debug(
        'Blur handler will be skipped. Blur event target was the date picker button.'
      );
      return;
    }
    this.focused = false;
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }

  /** Handler for the mask control focused event. */
  onMaskControlFocus(): void {
    this.focused = true;
    // Push the call to focus the control until the next render cycle when it exists.
    setTimeout(() => this.focus());
  }

  /** Handler for the date picker closed event. */
  onPickerClosed(): void {
    this.logger.debug('Date picker closed.');
    // Ensure the input is refocused after the date picker closes.
    this.focus();
  }

  /**
   * Creates a validator function which determines if the masked control should be considered valid.
   *
   * @returns A validator function.
   */
  private createMaskedDateParseValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      // We don't want to validate if the control is empty.
      if (!this.control.value) return null;

      // Use the same method of getting a moment as the date picker.
      const valueAsMoment = this.dateAdapter.deserialize(this.control.value);
      // If the value is not null and is a valid moment everything is fine.
      if (valueAsMoment && this.dateAdapter.isValid(valueAsMoment)) {
        return null;
      }

      // If we get here the value is not a valid date.
      return {
        matDatepickerParse: { text: this.control.value },
      };
    };
  }

  /**
   * Creates a validator function that checks if a given date is within SQL supported range.
   *
   * @returns {ValidatorFn} A validator function that checks if a given date is within SQL supported range.
   */
  private createDateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const valueAsMoment = moment(control.value);
      if (!valueAsMoment || !valueAsMoment.isValid()) return null;

      const dateOutOfRange =
        valueAsMoment.isBefore(this.minDate) ||
        valueAsMoment.isAfter(this.maxDate);
      return dateOutOfRange ? { dateOutOfRange: true } : null;
    };
  }

  /**
   * Subscribes to control status changes and ensures the masked control state stays in sync.
   */
  private listenForControlStatusChanges(): void {
    // Set the initial disabled state.
    if (this.control.disabled) {
      this.maskedControl.disable();
    }
    // Listen for status changes and update the disabled state on the masked control.
    this.control.statusChanges.subscribe(() => {
      if (this.control.disabled) {
        this.maskedControl.disable();
      } else {
        this.maskedControl.enable();
      }
    });
  }

  /**
   * Subscribes to control value change and ensures date value is formatted and that the mask stays up to date.
   */
  private listenForControlValueChanges(): void {
    this.control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (typeof value === 'string') {
        this.logger.debug(
          'Date field value changed to a string. Forcing it to a moment.',
          value
        );
        // Getting here means something outside this control such as KFI changed the value by passing a string.
        // We need the value to be set as a moment for this control to work so we convert the string to a moment
        // and update the control value.
        const valueAsMoment = moment(value);
        if (valueAsMoment.isValid()) {
          this.control.setValue(valueAsMoment);
        }
      }

      // Always update the masked control value.
      this.setMaskedValue();
    });
  }

  /**
   * Sets the masked control value based on the control value.
   */
  private setMaskedValue(): void {
    // Control value should either be a moment or a string.
    const valueAsMoment = this.dateAdapter.deserialize(this.control.value);
    if (!valueAsMoment || !valueAsMoment.isValid()) {
      // If we can't cast as a moment just put whatever was in the control in the masked control.
      this.maskedControl.setValue(this.control.value);
      this.maskedControl.updateValueAndValidity();
      this.maskedControl.markAsTouched();
      return;
    }

    const valueAsString =
      !this.field.format || !this.momentFormat
        ? this.dateAdapter.format(
            valueAsMoment,
            this.dateFormats.display.dateInput
          )
        : this.gridHelper.getDateTimeCellValue(
            this.control.value,
            this.momentFormat
          );

    this.maskedControl.setValue(valueAsString);
    this.maskedControl.updateValueAndValidity();
    this.maskedControl.markAsTouched();
  }
}
