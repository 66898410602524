import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { TaskSearch } from 'models';

export interface TaskSearchesState
  extends EntityState<TaskSearch, string>,
    ActiveState<string> {}

/**
 * Task Searches state store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tasksearches', resettable: true })
export class TaskSearchesStore extends EntityStore<TaskSearchesState> {
  constructor() {
    super();
  }
}
