import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Hotkey, HotkeysService } from '@ngneat/hotkeys';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { MultiValueFieldMenuComponent } from './multi-value-field-menu/multi-value-field-menu.component';

/** Multivalue hotkey directive. */
@Directive({
  selector: '[appMultiValueHotkeys]',
})
export class MultiValueHotkeysDirective implements OnDestroy {
  /**
   * Gets the Multivalue field menu reference.
   *
   * @returns The MultiValueFieldMenuComponent reference or undefined if there isn't one.
   */
  @Input('appMultiValueHotkeys')
  get _mvFieldMenu(): MultiValueFieldMenuComponent | undefined {
    return this.mvFieldMenu;
  }
  /**
   * Sets the MultiValueFieldMenuComponent. All listeners and hotkeys are disabled if set to undefined.
   */
  set _mvFieldMenu(newMenu: MultiValueFieldMenuComponent | undefined) {
    // Ensure we have cleaned up any previous listeners.
    this.removeListeners();
    if (this.hotkeysSetHere) {
      // Remove hotkeys only if they were previously set here to avoid accidentally
      // removing hotkeys created by other instances of this directive.
      this.removeMultivalueHotkeys();
    }
    this.mvFieldMenu = newMenu;
    if (newMenu !== undefined) {
      this.unlistenOnFocus = this.renderer.listen(
        this.elementReference.nativeElement,
        'focus',
        () => this.onFocus()
      );
      this.unlistenOnBlur = this.renderer.listen(
        this.elementReference.nativeElement,
        'blur',
        () => this.onBlur()
      );
    }
  }

  private readonly appendHotkey: Hotkey = {
    keys: 'alt.shift.j',
    group: 'MV Field',
    description: 'Append a new multivalue entry',
    allowIn: ['INPUT'],
  };
  private appendHotkeySubscription: Subscription;
  private readonly deleteHotkey: Hotkey = {
    keys: 'alt.shift.y',
    group: 'MV Field',
    description: 'Delete a multivalue entry',
    allowIn: ['INPUT'],
  };
  private deleteHotkeySubscription: Subscription;
  private hotkeysSetHere = false;
  private mvFieldMenu: MultiValueFieldMenuComponent | undefined;
  private readonly prependHotkey: Hotkey = {
    keys: 'alt.shift.k',
    group: 'MV Field',
    description: 'Prepend a new multivalue entry',
    allowIn: ['INPUT'],
  };
  private prependHotkeySubscription: Subscription;
  private unlistenOnBlur: (() => void) | undefined;
  private unlistenOnFocus: (() => void) | undefined;

  constructor(
    private logger: NGXLogger,
    private hotkeys: HotkeysService,
    private elementReference: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.removeMultivalueHotkeys();
    this.removeListeners();
  }

  /**
   * Adds the hotkeys for the multi value field.
   *
   * @throws If the field is a multivalue field but does not have an MVFieldMenuComponent reference.
   */
  private addMultiValueHotkeys(): void {
    if (!this.mvFieldMenu) {
      throw new Error(
        'MV Fields must have an mvFieldMenu to listen for add and remove hotkeys.'
      );
    }

    this.hotkeysSetHere = true;

    this.appendHotkeySubscription = this.hotkeys
      .addShortcut(this.appendHotkey)
      .subscribe((event) => {
        if (!event) return;
        this.onAddMultiValue(event, true);
      });
    this.deleteHotkeySubscription = this.hotkeys
      .addShortcut(this.deleteHotkey)
      .subscribe((event) => {
        this.onDeleteMultiValue(event);
      });
    this.prependHotkeySubscription = this.hotkeys
      .addShortcut(this.prependHotkey)
      .subscribe((event) => {
        if (!event) return;
        this.onAddMultiValue(event, false);
      });
  }

  private onAddMultiValue(event: KeyboardEvent, append: boolean): void {
    if (!event) return;
    this.mvFieldMenu?.onAddMultiValueClicked(append);
  }

  private onBlur(): void {
    this.removeMultivalueHotkeys();
  }

  private onDeleteMultiValue(event: KeyboardEvent): void {
    if (!event) return;
    this.mvFieldMenu?.onRemoveMultiValueClick();
  }

  private onFocus(): void {
    this.addMultiValueHotkeys();
  }

  /**
   * Remove listeners for adding and removing the hotkeys.
   */
  private removeListeners(): void {
    if (typeof this.unlistenOnBlur === 'function') {
      this.unlistenOnBlur();
    }
    if (typeof this.unlistenOnFocus === 'function') {
      this.unlistenOnFocus();
    }
  }

  /**
   * Removes the hotkeys for the multi value field.
   *
   * @throws If the field is a multivalue field but does not have an MVFieldMenuComponent reference.
   */
  private removeMultivalueHotkeys(): void {
    this.hotkeysSetHere = false;
    this.appendHotkeySubscription?.unsubscribe();
    this.deleteHotkeySubscription?.unsubscribe();
    this.prependHotkeySubscription?.unsubscribe();
    this.hotkeys.removeShortcuts(this.appendHotkey.keys);
    this.hotkeys.removeShortcuts(this.deleteHotkey.keys);
    this.hotkeys.removeShortcuts(this.prependHotkey.keys);
  }
}
