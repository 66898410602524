<div *ngIf="showProgress">
  <mat-card>
    <mat-card-title>
      {{ 'LOADING' | transloco }}
    </mat-card-title>
    <mat-card-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
</div>
