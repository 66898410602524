import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ProgressDialogComponent } from '../components/progress-dialog/progress-dialog.component';

/** Service for injecting indeterminate progress dialog. */
@Injectable({
  providedIn: 'root',
})
export class ProgressDialogService {
  constructor(private dialogService: MatDialog) {}
  /** Close progress dialog. */
  closeProgressDialog() {
    this.dialogService.closeAll();
  }
  /**
   * Open the progress dialog.
   *
   * @param message Message to display in progress dialog.
   */
  openProgressDialog(message: string) {
    const data: ProgressDialogData = {
      message,
    };
    this.dialogService.open(ProgressDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
  }
}
/**
 * Interface to define progress dialog data.
 */
export interface ProgressDialogData {
  /** Message to pass to the body of dialog. */
  message: string;
}
