import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';

/** Platform service. */
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  /**
   * Gets the device info for the current session.
   *
   * @returns A device info object.
   */
  get deviceInfo(): DeviceInfo {
    return this.deviceService.getDeviceInfo();
  }
  /**
   * Whether the current session is running windows.
   *
   * @returns A boolean.
   */
  get isWindows(): boolean {
    return this.deviceService.os.toLowerCase() === 'windows';
  }

  constructor(
    private logger: NGXLogger,
    private deviceService: DeviceDetectorService
  ) {
    this.logger.debug('Device info', this.deviceInfo);
  }
}
