import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { ICellEditorAngularComp } from 'ag-grid-angular';

import { UserFriendlyError } from 'models';
import { NotificationService } from 'src/app/services/notification.service';

import { FieldBaseComponent } from '../field-components/field-component.base.component';

import { FieldCellEditorParameters } from './field-cell-editor-parameters';

/** Field cell editor base component. */
@Component({ template: '' })
export class FieldCellEditorBaseComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  /** Field component reference. */
  @ViewChild('fieldComponent')
  fieldComponent: FieldBaseComponent;
  /** Cell Parameters. */
  cellParams: FieldCellEditorParameters;
  /** Form control. */
  formControl: UntypedFormControl;

  /**
   * Whether the cell editor is a popup.
   *
   * @returns True if the cell editor is a popup.
   */
  get isCellEditorPopup(): boolean {
    return !!this.cellParams.colDef.cellEditorPopup;
  }

  constructor(
    protected translate: TranslocoService,
    protected notifications: NotificationService
  ) {}

  // All commented functions in this file are things that are overloadable functions provided by AG-Grid.
  // They are here for easy access to their availability in the future.

  // /** @inheritdoc */
  // afterGuiAttached?(): void {
  //   throw new Error('Method not implemented.');
  // }

  /** @inheritdoc */
  agInit(params: FieldCellEditorParameters): void {
    this.cellParams = params;
    const validators: ValidatorFn[] = [];
    if (params.field.required) {
      validators.push(Validators.required);
    }

    this.formControl = new UntypedFormControl(params.value, validators);
  }

  /** @inheritdoc */
  focusIn?(): void {
    this.focus();
  }

  // /** @inheritdoc */
  // focusOut?(): void {
  //   throw new Error('Method not implemented.');
  // }

  // /** @inheritdoc */
  // getPopupPosition?(): string | undefined {
  //   throw new Error('Method not implemented.');
  // }

  /** @inheritdoc */
  getValue() {
    return this.formControl.value;
  }

  /** @inheritdoc */
  isCancelAfterEnd?(): boolean {
    if (this.getValue() !== this.cellParams.value && this.formControl.invalid) {
      this.showError();
    } else if (this.cellParams.field.required && !this.getValue()) {
      this.showError();
    }
    return this.formControl.invalid;
  }

  // /** @inheritdoc */
  // isCancelBeforeStart?(): boolean {
  //   throw new Error('Method not implemented.');
  // }

  // /** @inheritdoc */
  // isPopup?(): boolean {
  //   throw new Error('Method not implemented.');
  // }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      if (this.cellParams.cellStartedEdit) {
        this.focus();
      }
    });
  }

  /** Shows an error when a field cell has failed validation. This also flashes the cell. */
  showError(): void {
    const error: UserFriendlyError = {
      error: undefined,
      description: `The value ${this.formControl.value} is not valid for the ${this.cellParams.field.name} field. The field will not be updated.`,
      i18n: 'INVALID_FIELD_VALUE',
      i18nParameters: {
        fieldValue: this.formControl.value ?? '',
        fieldName: this.cellParams.field.name,
      },
    };
    this.notifications.error(error);
    this.cellParams.api.flashCells({
      rowNodes: [this.cellParams.node],
      columns: [this.cellParams.column],
    });
  }

  private focus(): void {
    this.fieldComponent.focus();
  }
}
