import { Component, OnInit, effect, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { assertExists } from 'common';
import {
  DocumentId,
  DocumentSecureId,
  UserAction,
  UserFriendlyError,
} from 'models';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

/** User action executed event. */
export interface UserActionExecutedEvent {
  /**
   * Function that should be run if the action is cancelled.
   *
   * This does **not** cancel the network request running the action if that has already started.
   */
  cancelAction: () => void;
  /** Name of the action taken. */
  name: string;
  /** Function that will run the action. */
  runAction: () => Observable<void>;
}

/** User Actions Panel. */
@UntilDestroy()
@Component({
  selector: 'app-user-actions-panel',
  templateUrl: './user-actions-panel.component.html',
  styleUrls: ['./user-actions-panel.component.scss'],
})
export class UserActionsPanelComponent implements OnInit {
  /** Document id. */
  documentId = input.required<number>();
  /** Document secure id. */
  documentSecureId = input.required<string>();
  /** Emitted when an action is executed. */
  actionExecuted = output<UserActionExecutedEvent>();
  /** Whether to disable the buttons. */
  disableButtons = false;
  /** User actions for the document. */
  userActions: UserAction[];

  private archiveId = toSignal(
    this.archivesQuery.activeId$.pipe(
      map((id) => {
        assertExists(id, 'Archive ID must exist.');
        return id;
      })
    ),
    {
      initialValue: this.archivesQuery.activeId,
    }
  );
  private databaseId = toSignal(
    this.databasesQuery.activeDbId$.pipe(
      map((id) => {
        assertExists(id, 'Database ID must exist.');
        return id;
      })
    ),
    { initialValue: this.databasesQuery.activeId }
  );

  constructor(
    private logger: NGXLogger,
    private notify: NotificationService,
    private taskSearchesService: TaskSearchesService,
    private appQuery: ApplicationQuery,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery
  ) {
    effect(() => {
      this.taskSearchesService.userActionsApi
        .getActions(
          this.databaseId(),
          this.archiveId(),
          this.documentId(),
          this.documentSecureId()
        )
        .subscribe((userActions) => {
          this.logger.debug('User actions loaded', userActions);
          this.userActions = userActions;
        });
    });
  }

  /**
   * Does the document have user actions.
   *
   * @returns True if there are user actions.
   */
  get hasActions(): boolean {
    return this.userActions?.length > 0;
  }

  /**
   * Should the action panel be shown.
   *
   * @returns True if there are user actions and the license is not read only.
   */
  get shouldShowActions(): boolean {
    return !this.appQuery.isReadOnlyLicense && this.hasActions;
  }

  ngOnInit(): void {}

  /**
   * Handler for the run action click event.
   *
   * @param action Action.
   */
  onClickUserAction(action: UserAction): void {
    this.logger.debug('Action button clicked', action);
    this.disableButtons = true;
    const documents: Record<DocumentId, DocumentSecureId> = {};
    documents[this.documentId()] = this.documentSecureId();
    const databaseId = this.databaseId();
    const archiveId = this.archiveId();

    const runAction = () => {
      return this.taskSearchesService.userActionsApi
        .runAction(databaseId, archiveId, action.key, documents)
        .pipe(
          finalize(() => (this.disableButtons = false)), // always re-enable buttons when call completes
          tap(() => {
            this.notify.success('USER_ACTION_SUBMITTED_MESSAGE', {
              userActionName: action.name,
            });
          }),
          catchError((error: UserFriendlyError) => {
            this.notify.error(error);
            // rethrow the error in case something consuming this wants to use it.
            return throwError(() => error);
          })
        );
    };
    this.actionExecuted.emit({
      name: action.name,
      runAction,
      cancelAction: () => {
        this.disableButtons = false;
      },
    });
  }
}
