import { Component } from '@angular/core';
import { map } from 'rxjs';

import { AppConfigQuery } from 'src/app/modules/app-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent {
  /** Should the GA design link be available. */
  isGlobalActionDesignConfigured = !!this.appConfigQuery.globalCaptureUrl;
  /** Should the GA design link be enabled. */
  isGlobalActionDesignEnabled$ = this.databaseQuery.activeDbId$.pipe(
    map((databaseId) => !!databaseId)
  );

  constructor(
    private appConfigQuery: AppConfigQuery,
    private databaseQuery: DatabasesQuery,
    private auth: AuthenticationService
  ) {}

  /**
   * Open full administration web application in a new window.
   */
  openAdmin() {
    window.open(this.appConfigQuery.adminUrl, '_blank');
  }

  /**
   * Open GlobalAction design in a new window.
   */
  openGlobalActionDesign() {
    window.open(
      `${this.appConfigQuery.globalCaptureUrl}#/design?mode=gaw&db=${this.databaseQuery.activeId}&token=${this.auth.user.token}`,
      '_blank'
    );
  }

  /**
   * Display license manager web application in a new window.
   */
  openLicenseManager() {
    window.open(this.appConfigQuery.licenseManagerUrl, '_blank');
  }

  /**
   * Open user management web application in a new window.
   */
  openUserManagement() {
    window.open(this.appConfigQuery.userManagementUrl, '_blank');
  }
}
