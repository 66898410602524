import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import {
  Search,
  SearchOptions,
  SearchProvider,
  SearchResult,
  SearchResultResponse,
  Searches,
  filterCheckInCheckOutPermissions,
  filterReadOnlyPermissions,
} from 'models';
import { NO_ACTIVE_ID } from 'src/app/common/constants';
import { CommandService } from 'src/app/modules/command-palette';
import { SearchUIService } from 'src/app/services/search-ui.service';

import { SEARCH_PROVIDER } from '../../common/tokens';
import { toNumberOrUndefined } from '../../common/utility';
import { ApplicationQuery } from '../application/application.query';
import {
  FavoriteSearch,
  FavoriteSearchesService,
} from '../application/favorite-searches.service';
import { ArchivesQuery } from '../archives/archives.query';
import { DatabasesQuery } from '../databases/databases.query';

import { SearchesStore } from './searches.store';

/**
 * Searches Service.
 */
@Injectable({ providedIn: 'root' })
export class SearchesService {
  /** Search API. */
  api = this.searchProvider;
  constructor(
    private searchesStore: SearchesStore,
    @Inject(SEARCH_PROVIDER) private searchProvider: SearchProvider,
    private logger: NGXLogger,
    private favoriteSearchService: FavoriteSearchesService,
    private routerQuery: RouterQuery,
    private searchUiService: SearchUIService,
    private appQuery: ApplicationQuery,
    private commandService: CommandService,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery
  ) {
    // Watch route to determine active.
    this.routerQuery
      .selectParams('searchId')
      .pipe(map(toNumberOrUndefined))
      .subscribe((searchId) => {
        this.searchesStore.setActive(searchId ?? NO_ACTIVE_ID);
        this.logger.debug(
          typeof searchId === 'undefined'
            ? 'No search active.'
            : `Search ${searchId} set as active.`
        );
      });
  }

  /**
   * Clears favorite searches from the command palette given a database ID.
   * This is intended for use only on database load.
   *
   * @param databaseId Database Id for the searches to clear.
   */
  clearFavoriteSearchesFromCommandPalette(databaseId: number): void {
    const favoriteSearches = this.appQuery.getFavoritedSearches(
      databaseId.toString()
    );
    for (const [, value] of Object.entries(favoriteSearches)) {
      this.favoriteSearchService.removeFromCommandPalette(value.label);
    }
  }

  /**
   * Refresh all searches for a database.
   *
   * @param databaseId Database ID.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }
    this.searchProvider
      .getAll(databaseId)
      .pipe(setLoading(this.searchesStore))
      .subscribe((searches) => {
        this.logger.debug('Refreshing searches.');
        this.loadFavoriteSearches(databaseId, searches);
        this.addAllSearchesToCommandPalette(databaseId, searches);
        this.addDashboardSearchesToCommandPalette(databaseId, searches);
        this.searchesStore.set(searches);
      });
  }

  /**
   * Gets document data for the provided document.
   *
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param documentId Document Id.
   * @param documentSecureId Document Secure Id.
   * @returns An observable search result containing the document.
   */
  getDocumentData(
    databaseId: number,
    archiveId: number,
    documentId: number,
    documentSecureId: string
  ): Observable<SearchResult> {
    return this.api
      .getDocumentData(databaseId, archiveId, documentId, documentSecureId)
      .pipe(
        map((result) => {
          const targetArchive = this.archivesQuery.getArchive(archiveId);
          if (targetArchive.isCheckInCheckOut) {
            result.permissions = filterCheckInCheckOutPermissions(
              result.permissions
            );
          }

          if (this.appQuery.isReadOnlyLicense) {
            result.permissions = filterReadOnlyPermissions(result.permissions);
          }

          return result;
        })
      );
  }

  /**
   * Run a search.
   *
   * @param search Search to run.
   * @param options Search options.
   * @returns Observable list of search results.
   */
  run(
    search: Search,
    options: SearchOptions
  ): Observable<SearchResultResponse> {
    // Ensure archives are done loading before attempting to run the search.
    return this.archivesQuery.isLoading$.pipe(
      first((isLoading) => isLoading === false),
      switchMap(() =>
        this.searchProvider
          .run(this.databasesQuery.activeId, search, options)
          .pipe(
            setLoading(this.searchesStore),
            map((resultResponse) => {
              const targetArchive = this.archivesQuery.getArchive(
                options.targetArchiveId
              );
              if (
                targetArchive.isCheckInCheckOut ||
                this.appQuery.isReadOnlyLicense
              ) {
                resultResponse.searchResults = resultResponse.searchResults.map(
                  (result) => {
                    if (targetArchive.isCheckInCheckOut) {
                      result.permissions = filterCheckInCheckOutPermissions(
                        result.permissions
                      );
                    }

                    if (this.appQuery.isReadOnlyLicense) {
                      result.permissions = filterReadOnlyPermissions(
                        result.permissions
                      );
                    }
                    return result;
                  }
                );
              }

              return resultResponse;
            }),
            tap((resultResponse) => {
              this.logger.debug(
                `Search completed for "${search.name}", with ${resultResponse.searchResults.length} results.`
              );
            })
          )
      )
    );
  }

  /**
   * Update a search in the store.
   *
   * @param id Search ID.
   * @param search Search.
   */
  update(id: number, search: Partial<Search>): void {
    this.searchesStore.update(id, search);
  }

  private addAllSearchesToCommandPalette(
    databaseId: number,
    searches: Search[]
  ): void {
    // Clear previously stored searches from command Palette.
    this.commandService.unregister('*', 'Search');
    for (const search of searches) {
      this.searchUiService.addToCommandPalette(
        search,
        databaseId,
        search.parentArchive,
        true,
        true,
        '',
        'Search',
        '@'
      );
    }
  }

  private addDashboardSearchesToCommandPalette(
    databaseId: number,
    searches: Search[]
  ): void {
    // Clear previously stored dashboard searches from the command palette.
    this.commandService.unregister('*', 'Dashboard Search');
    for (const dashboardSearch of searches.filter(
      (search) => search.settings.isDashboardSearch
    )) {
      this.searchUiService.addToCommandPalette(
        dashboardSearch,
        databaseId,
        dashboardSearch.parentArchive,
        false,
        true,
        '',
        'Dashboard Search'
      );
    }
  }

  private loadFavoriteSearches(databaseId: number, searches: Searches): void {
    const favoritedSearches = this.appQuery.getFavoritedSearches(
      databaseId.toString()
    );
    for (const search of searches) {
      const favoriteSearch = favoritedSearches[search.id];
      if (favoriteSearch) {
        search.isFavorite = true;
        this.favoriteSearchService.addToCommandPalette(
          search,
          databaseId,
          favoriteSearch.targetArchiveId,
          favoriteSearch.label
        );
      } else if (search.isFavorite) {
        const queueFavoriteSearch: FavoriteSearch = {
          label: search.name,
          searchPrompts: search.parameters,
          showPrompt: false,
          targetArchiveId: search.archives[0],
        };
        this.favoriteSearchService.addFavoriteSearch(
          search,
          queueFavoriteSearch,
          databaseId,
          search.parentArchive
        );
      }
    }
  }
}
