import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EmailArchiveRequest,
  EmailInboxRequest,
  LaunchDocumentProvider,
  UserFriendlyError,
} from 'models';
import { Observable, catchError, map } from 'rxjs';

import { GseApiConfig } from './gse-api-config.model';
import { GSE_API_CONFIG } from './gse-api-config.token';
import { handleError } from './handle-error';
/**
 * GlobalSearch Extensions Launch service.
 */
@Injectable({
  providedIn: 'root',
})
export class GseLaunchService implements LaunchDocumentProvider {
  /** @inheritdoc */
  private config = {} as GseApiConfig;

  private get extensionsUrl(): string {
    return `${this.config.apiUrl}/api`;
  }

  constructor(
    private http: HttpClient,
    @Inject(GSE_API_CONFIG) private config$: Observable<GseApiConfig>
  ) {
    this.config$.subscribe((config) => Object.assign(this.config, config));
  }

  /** @inheritdoc */
  public emailArchiveDocument(
    databaseId: number,
    searchId: number,
    fieldId: number,
    includeAnnotations: boolean,
    documentInfo: EmailArchiveRequest,
    filename?: string
  ): Observable<void> {
    let queryParameters = new HttpParams()
      .set('database', `${databaseId}`)
      .set('searchId', `${searchId}`)
      .set('fieldId', `${fieldId}`)
      .set('withAnnotations', `${includeAnnotations}`);

    if (filename) {
      queryParameters = queryParameters.set('filename', filename);
    }

    return this.http
      .post<void>(`${this.extensionsUrl}/launch/email`, documentInfo, {
        params: queryParameters,
      })
      .pipe(catchError((error: UserFriendlyError) => handleError(error)));
  }

  /** @inheritdoc */
  public emailInboxDocument(request: EmailInboxRequest): Observable<void> {
    return this.http
      .post<void>(
        `${this.extensionsUrl}/launch/inboxemail`,
        { documents: request.documents },
        {
          params: new HttpParams().set(
            'withAnnotations',
            `${request.includeAnnotations}`
          ),
        }
      )
      .pipe(catchError((error: UserFriendlyError) => handleError(error)));
  }

  /** @inheritdoc */
  getStatus(launchId: string): Observable<'open' | 'closed'> {
    return this.http
      .get<string>(`${this.extensionsUrl}/launch?launchid=${launchId}`)
      .pipe(
        map((status) => {
          return status.toLowerCase() === 'open' ? 'open' : 'closed';
        })
      );
  }

  /** @inheritdoc */
  public launchDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    searchId: number,
    retryAttempts: number = 0
  ): Observable<string> {
    const params = new HttpParams()
      .set('database', `${databaseId}`)
      .set('archive', `${archiveId}`)
      .set('docId', `${documentId}`)
      .set('searchId', `${searchId}`)
      .set('retryattempts', `${retryAttempts}`);
    return this.http
      .post<string>(
        `${this.extensionsUrl}/launch/document`,
        // This post has no body. Only parameters.
        {},
        {
          params,
        }
      )
      .pipe(catchError((error: UserFriendlyError) => handleError(error)));
  }
}
