import { CommonModule, DatePipe } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { MSAL_INSTANCE, MsalModule } from '@azure/msal-angular';
import { TranslocoModule } from '@jsverse/transloco';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LoggerModule } from 'ngx-logger';

import { ModelsModule } from 'models';

import { AppConfigModule } from '../app-config/app-config.module';

import { ApiUrlFieldComponent } from './components/api-url-field/api-url-field.component';
import { DividerWithTextComponent } from './components/divider-with-text/divider-with-text.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LoginComponent } from './components/login/login.component';
import { LogoSvgComponent } from './components/logo-svg/logo-svg.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { msalInstanceFactory } from './helpers/azure-config.factory';

const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: 'redirect',
        component: RedirectComponent,
      },
    ],
  },
];

/** Login Module. */
@NgModule({
  declarations: [
    LoginComponent,
    RedirectComponent,
    ApiUrlFieldComponent,
    DividerWithTextComponent,
    LogoSvgComponent,
    LoginButtonComponent,
  ],
  exports: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatGridListModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatRippleModule,
    MsalModule,
    LoggerModule.forChild(),
    TranslocoModule,
    ModelsModule,
    RouterModule.forChild(routes),
    OAuthModule.forRoot(),
    AppConfigModule,
  ],
  providers: [DatePipe, provideHttpClient(withInterceptorsFromDi())],
})
// biome-ignore lint/complexity/noStaticOnlyClass: Angular Module pattern
export class LoginModule {
  /**
   * Creates a login module.
   *
   * @returns A login module with configured providers.
   */
  static forRoot(): ModuleWithProviders<LoginModule> {
    const providers: Provider[] = [];
    providers.push({
      provide: MSAL_INSTANCE, // stub this since it will need to be reinited on config retrieval
      useFactory: () => msalInstanceFactory(undefined),
    });

    return {
      ngModule: LoginModule,
      providers,
    };
  }
}
