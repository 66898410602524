import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthorizationProvider, Claims } from 'models';
import { Observable } from 'rxjs';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API Authorization http access.
 */
@Injectable({
  providedIn: 'root',
})
export class Square9apiAuthorizationService implements AuthorizationProvider {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.apiUrl = apiUrl));
  }

  /** @inheritdoc */
  getClaims(token: string, provider?: string): Observable<Claims> {
    let headers = new HttpHeaders().set('auth-token', token);
    if (provider) {
      headers = headers.set('authenticated-provider', provider);
    }
    return this.http.get<Claims>(`${this.apiUrl}/admin`, {
      headers,
      params: new HttpParams().set('function', 'claims'),
    });
  }
}
