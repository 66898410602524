import {
  CSVImportJob,
  CSVImportJobFiles,
  CSVImportJobResult,
  CSVImportJobStatus,
  CSVImportMapping,
  CSVImportMappings,
} from 'models';

/**
 * Create a CSVImportMapping object from its API equivalent.
 *
 * @param apiCSVImportMapping API CSV import mapping object.
 * @returns A CSVImportMapping object.
 */
export const createCSVImportMappingFromApi = (
  apiCSVImportMapping: S9ApiCSVImportMapping
): CSVImportMapping => ({
  displayName: apiCSVImportMapping.DisplayName,
  includeField: apiCSVImportMapping.IncludeField,
  isFilepath: apiCSVImportMapping.IsFilePath,
  fieldId: apiCSVImportMapping.FieldId,
  columnIndex: apiCSVImportMapping.ColumnIndex,
});

/**
 * Create a CSVImportMappings object from its API equivalent.
 *
 * @param apiCSVImportMappings API CSV import mapping objects.
 * @returns A CSVImportMappings object.
 */
export const createCSVImportMappingsFromApi = (
  apiCSVImportMappings: S9ApiCSVImportMappings
): CSVImportMappings => apiCSVImportMappings.map(createCSVImportMappingFromApi);

/**
 * Create a CSVImportJob object from its API equivalent.
 *
 * @param apiJob API job object.
 * @returns A CSV import job.
 */
export const createCSVImportJobFromApi = (
  apiJob: S9APICSVImportJob
): CSVImportJob => ({
  files: apiJob.Files,
  id: apiJob.Id,
  archiveId: apiJob.ArchiveId,
  databaseId: apiJob.DatabaseId,
  status: createCSVImportStatusFromApi(apiJob.Status),
  columnDelimiter: apiJob.ColumnDelimiter,
  stringDelimiter: apiJob.StringDelimiter,
  containsColumnHeaders: apiJob.ContainsColumnHeaders,
  columnMappings: apiJob.ColumnMappings
    ? createCSVImportMappingsFromApi(apiJob.ColumnMappings)
    : undefined,
  startTime: new Date(apiJob.StartTime),
});

/**
 * Create an CSVImportJobStatus object from its API equivalent.
 *
 * @param apiStatus API status object.
 * @returns A CSVImportJobStatus object.
 */
export const createCSVImportStatusFromApi = (
  apiStatus: S9ApiCSVImportJobStatus
): CSVImportJobStatus => ({
  totalRows: apiStatus.TotalRows,
  importedRows: apiStatus.ImportedRows,
  erroredRows: apiStatus.ErroredRows,
  currentRow: apiStatus.CurrentRow,
  progress: apiStatus.Progress,
  started: apiStatus.Started,
  done: apiStatus.Done,
  cancelled: apiStatus.Cancelled,
});

/**
 * Create a CSVimportJobResult object from its API equivalent.
 *
 * @param apiResult API result object.
 * @returns A CSVImportJobResult object.
 */
export const createCSVImportResultFromApi = (
  apiResult: S9APICSVimportJobResult
): CSVImportJobResult => ({
  errorMessages: apiResult.ErrorMessages,
  erroredRows: apiResult.ErroredRows,
  importedRows: apiResult.ImportedRows,
  totalRows: apiResult.TotalRows,
});

export interface S9ApiCSVImportMapping {
  DisplayName: string;
  IncludeField: boolean;
  IsFilePath: boolean;
  FieldId: number;
  ColumnIndex: number;
}

export interface S9APICSVJobStatus {
  TotalRows: number;
  ImportedRows: number;
  ErroredRows: number;
  CurrentRow: number;
  Progress: number;
  Started: boolean;
  Done: boolean;
}

/**
 * An Array of S9ApiCSVImportMapping objects.
 *
 * @see S9ApiCSVImportMapping
 */
export declare type S9ApiCSVImportMappings = S9ApiCSVImportMapping[];

export interface S9APICSVImportJob {
  Files: CSVImportJobFiles;
  Id: string;
  ArchiveId: number;
  DatabaseId: number;
  Status: S9ApiCSVImportJobStatus;
  JobPath: string;
  CsvFilepath: string;
  ColumnDelimiter: string;
  StringDelimiter: string;
  ContainsColumnHeaders: boolean;
  ColumnMappings: S9ApiCSVImportMappings;
  IdentityHolder: IdentityHolder;
  StartTime: Date;
}

export interface IdentityHolder {
  UserWithDomain: string;
  Roles: string[];
}

export interface S9ApiCSVImportJobStatus {
  TotalRows: number;
  ImportedRows: number;
  ErroredRows: number;
  CurrentRow: number;
  Progress: number;
  Started: boolean;
  Done: boolean;
  Cancelled: boolean;
}

export interface S9APICSVimportJobResult {
  TotalRows: number;
  ImportedRows: number;
  ErroredRows: number;
  ErrorMessages: string[];
}
