import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import {
  DocumentUpdateProvider,
  Field,
  FieldValue,
  Permissions,
  UserFriendlyError,
} from 'models';
import { DOCUMENT_UPDATE_PROVIDER } from 'src/app/common/tokens';
import { DocumentUpdateSession } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { FieldsQuery } from 'src/app/state/fields/fields.query';

import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { tap } from 'rxjs';
import { MultiValueFieldComponent } from '../../field-components/multi-value-field/multi-value-field.component';

/** MultiValue Dialog Data. */
export interface MultiValueDialogData {
  /** Document Id. */
  documentId: number;
  /** Document permissions. */
  documentPermissions: Permissions;
  /** Document Secure Id. */
  documentSecureId: string;
  /** Field Value. */
  fieldValue: FieldValue;
}

/** MultiValue Dialog Result. */
export interface MultiValueDialogResult {
  /** Field Value. */
  fieldValue: FieldValue;
}

/** MultiValue Editor Dialog. */
@Component({
  selector: 'app-multi-value-editor-dialog',
  templateUrl: './multi-value-editor-dialog.component.html',
  styleUrls: ['./multi-value-editor-dialog.component.scss'],
})
export class MultiValueEditorDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MultiValueFieldComponent)
  mvFieldComponent: MultiValueFieldComponent;
  /** Field. */
  field: Field;
  /** Form control array. */
  formControlArray: UntypedFormArray;

  /**
   * Get the title to display on the dialog.
   *
   * @returns Title.
   */
  get dialogTitle() {
    return this.field.name;
  }

  /**
   * Should save be disabled.
   *
   * @returns True if save should be disabled.
   */
  get disableSave(): boolean {
    return (
      !this.showEditControls ||
      this.formControlArray.invalid ||
      !this.formControlArray.dirty
    );
  }

  /**
   * Gets whether the edit controls should be displayed.
   *
   * @returns True if the edit controls should be displayed.
   */
  get showEditControls(): boolean {
    const activeArchive = this.archivesQuery.getActive();
    if (!activeArchive) return false;
    return (
      // User is a non-guest.
      !this.auth.isGuest &&
      // User has permission to modify the document.
      this.dialogData.documentPermissions.modifyData &&
      // No field in the archive is a LiveField.
      !this.archivesQuery
        .getFields(activeArchive.id)
        .some((field) => !!field.liveField)
    );
  }

  constructor(
    private logger: NGXLogger,
    private notify: NotificationService,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private fieldsQuery: FieldsQuery,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider,
    private auth: AuthenticationService,
    private dialogReference: MatDialogReference<MultiValueEditorDialogComponent>,
    private formBuilder: FormBuilder,
    private changeDetectionReference: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private dialogData: MultiValueDialogData
  ) {}

  ngAfterViewInit(): void {
    // Always focus the last component which should be the blank entry created for convienence.
    this.mvFieldComponent.focusComponentAt(this.formControlArray.length - 1);
  }

  ngOnInit(): void {
    const multiValues = this.dialogData.fieldValue.multiValue;
    this.field = this.fieldsQuery.getField(this.dialogData.fieldValue.id);
    const controls = multiValues.map((value) => new UntypedFormControl(value));
    this.formControlArray = this.formBuilder.array(controls);
    this.formControlArray.markAllAsTouched();
    if (!this.showEditControls) {
      // disable the controls if the user cannot edit.
      this.formControlArray.disable();
    }
  }

  /** Handler for the add click event. */
  onAddClicked(): void {
    this.logger.debug('Add clicked.');
    this.formControlArray.push(new UntypedFormControl(''));
    this.changeDetectionReference.detectChanges();
  }

  /**
   * Event handler for the clear event.
   */
  onClear(): void {
    this.logger.debug('Clear clicked.');
    // this.grid.api?.updateGridOptions({ rowData: [] });
    this.formControlArray = this.formBuilder.array([
      new UntypedFormControl(''),
    ]);
    this.changeDetectionReference.detectChanges();
  }

  /**
   * Handler for the field blurred event.
   *
   * @param field Field that was blurred.
   */
  onFieldBlur(field: Field): void {
    this.logger.debug('Blurred field: ', field);
  }

  /**
   * Handler for the field focused event.
   *
   * @param field Field that was focused.
   */
  onFieldFocus(field: Field): void {
    this.logger.debug('Focused field: ', field);
  }

  /** Event handler for the dialog save click. */
  onSave(): void {
    this.logger.debug('Save MV field was clicked.');
    const mvFieldData: string[] = this.formControlArray.value;
    // mvFieldData = mvFieldData.filter((value) => !!value);
    const newFieldValue: FieldValue = {
      ...this.dialogData.fieldValue,
      multiValue: mvFieldData.filter((value) => !!value),
    };
    const updateSession = new DocumentUpdateSession(false, false, true, false);
    this.documentUpdateProvider
      .updateFieldData(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        this.dialogData.documentId,
        this.dialogData.documentSecureId,
        [newFieldValue],
        updateSession
      )
      .subscribe({
        next: () => {
          this.logger.debug('Save completed. Closing the dialog.');
          this.notify.success('SAVE_SUCCESS');
          const result: MultiValueDialogResult = {
            fieldValue: newFieldValue,
          };
          this.dialogReference.close(result);
        },
        error: (error: UserFriendlyError) => this.notify.error(error),
      });
  }
}
