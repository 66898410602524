import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { TranslocoModule } from '@jsverse/transloco';

import { CommandPaletteComponent } from './components/command-palette.component';
import { HighlightCommandPipe } from './pipes/highlight-command.pipe';
import { MatchesCommandPipe } from './pipes/matches-command.pipe';

@NgModule({
  declarations: [
    CommandPaletteComponent,
    MatchesCommandPipe,
    HighlightCommandPipe,
  ],
  imports: [
    CommonModule,
    MatListModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule,
  ],
  exports: [CommandPaletteComponent],
})
export class CommandPaletteModule {}
