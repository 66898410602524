import { InboxFile } from './inbox';
import { SearchResult } from './search';

/**
 * Base GlobalSearch Archive Session Document.
 */
export interface BaseArchiveSessionDocument {
  /** Archive ID. */
  archiveId: number;
}

/**
 * GlobalSearch Archive Session Document.
 */
export interface ArchiveSessionDocument extends BaseArchiveSessionDocument {
  /** ContentSearch terms within the document. */
  contentSearchTerms?: string[];
  /** Document Secure ID. */
  hash: string;
  /** Document ID. */
  id: number;
}

/**
 * GlobalSearch Archive Import Session Document.
 */
export interface ImportArchiveSessionDocument
  extends BaseArchiveSessionDocument {
  /** Field data to be sent to the Viewer. */
  fieldData?: { [key: number]: string };
  /** Filename in the cache. */
  filename: string;
}

/**
 * GlobalSearch Inbox Session Document.
 */
export interface InboxSessionDocument {
  /** Filename in the cache. */
  filename: string;
  /** Inbox ID. */
  id: number;
  /** Original filename for the uploaded file. */
  originalFilename: string;
}

/**
 * Any viewer session document type.
 */
export declare type SessionDocument =
  | ArchiveSessionDocument
  | ImportArchiveSessionDocument
  | InboxSessionDocument;

/**
 * Create an `ArchiveSessionDocument` from a SearchResult.
 *
 * @param searchResult SearchResult.
 * @param archiveId Archive Id.
 * @returns Archive Session Document.
 */
export const createArchiveSessionDocumentFromSearchResult = (
  searchResult: SearchResult,
  archiveId: number
): ArchiveSessionDocument => ({
  contentSearchTerms: searchResult.contentSearch?.terms ?? [],
  archiveId,
  id: searchResult.id,
  hash: searchResult.secureId,
});

/**
 * Create an `InboxSessionDocument` from an InboxFile.
 *
 * @param inboxFile InboxFile.
 * @param inboxId Inbox Id.
 * @returns Inbox Session Document.
 */
export const createInboxSessionDocumentFromInboxFile = (
  inboxFile: InboxFile,
  inboxId: number
): InboxSessionDocument => ({
  filename: `${inboxFile.filename}${inboxFile.fileType}`,
  id: inboxId,
  originalFilename: '',
});
