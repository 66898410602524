import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { ArchivesStore } from './state/archives/archives.store';
import { DatabasesStore } from './state/databases/databases.store';
import { InboxesStore } from './state/inboxes/inboxes.store';
import { IndexerStateStore } from './state/indexer/indexer.store';

/**
 * Common storage service.
 *
 * Provies function for working with all of a users stored data at once.
 */
@Injectable({
  providedIn: 'root',
})
export class CommonStorageService {
  constructor(
    private databasesStore: DatabasesStore,
    private archivesStore: ArchivesStore,
    private inboxesStore: InboxesStore,
    private indexerStore: IndexerStateStore,
    private logger: NGXLogger
  ) {}

  /**
   * Reset all state storage tied to a user.
   *
   * @description Resets the entity stores tied to a user back to their initial states, (i.e. At logout).
   */
  resetAllUserStores(): void {
    this.logger.debug('Resetting all user specific state storage.');
    this.databasesStore.reset();
    this.archivesStore.reset();
    this.inboxesStore.reset();
    this.indexerStore.reset();
    sessionStorage.clear();
  }
}
