import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { MatMenuTrigger } from '@angular/material/menu';
import { assert, assertTypeByKey } from 'common';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

import { replaceBetweenIndexes } from 'src/app/common/utility';

/**
 * A mention can be a string or any other type of object with string key and value.
 *
 * Value accessor will assert type as string or check for a value of string in
 * and object at the property defined by the mentionDisplayKey input.
 */
export type Mention = unknown;

/** Custom form field input for mentions. */
@Component({
  selector: 'app-mention-form-field',
  templateUrl: './mention-form-field.component.html',
  styleUrls: ['./mention-form-field.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MentionFormFieldComponent },
  ],
})
export class MentionFormFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  /** Next Id. */
  static nextId = 0;
  /** Allows the use of required attribute. */
  // biome-ignore lint/style/useNamingConvention: Angular can't name things right.
  static ngAcceptInputType_required: BooleanInput;
  /** Provides an ID. */
  @HostBinding()
  id = `mention-input-${MentionFormFieldComponent.nextId++}`;
  /** Form field label. */
  @Input()
  label: string;

  /** Value used to display in the mention list if mentions is an object. */
  @Input('mentionDisplayKey')
  mentionDisplayKey: string;
  /** Form control. */
  @Input()
  mentionFormControl = new FormControl('');
  /** Input element. */
  @ViewChild('mentionInput') mentionInput: HTMLInputElement;
  /** Menu trigger. */
  @ViewChild(MatMenuTrigger) mentionMenutrigger: MatMenuTrigger;
  /**
   * Character to add to the end of mention replacement string.
   *
   * Defaults to ''.
   */
  @Input()
  mentionReplacementEndChar = '';
  /**
   * Character to add to the beginning of mention replacement string.
   *
   * Defaults to ''.
   */
  @Input()
  mentionReplacementStartChar = '';
  /** Character that triggers mention menu. */
  @Input()
  mentionTriggerChar: string;
  /** Array of objects to list as mention options. */
  @Input()
  mentions: Mention[];
  /** @inheritdoc */
  @Input('aria-describedby') userAriaDescribedBy: string;

  /** @inheritdoc */
  autofilled?: boolean;
  /** @inheritdoc */
  controlType? = 'mention-input';
  /** @inheritdoc */
  focused = false;
  /** @inheritdoc */
  stateChanges = new Subject<void>();

  /** @inheritdoc */
  @Input()
  get disabled(): boolean {
    return this.isDisabled;
  }
  /** @inheritdoc */
  set disabled(value: boolean) {
    this.isDisabled = coerceBooleanProperty(value);
    if (this.isDisabled) {
      this.mentionFormControl.disable();
    } else {
      this.mentionFormControl.enable();
    }
    this.stateChanges.next();
  }

  /**
   * @inheritDoc
   *
   * @returns A boolean.
   */
  get empty(): boolean {
    return !this.mentionFormControl;
  }

  /** @inheritdoc */
  get errorState(): boolean {
    return this.mentionFormControl.invalid && this.mentionFormControl.dirty;
  }

  /** @inheritdoc */
  @Input()
  get placeholder(): string {
    return this.privatePlaceholder;
  }
  /** @inheritdoc */
  set placeholder(value: string) {
    this.privatePlaceholder = value;
    this.stateChanges.next();
  }

  /** @inheritdoc */
  @Input()
  get required(): boolean {
    return this.isRequired;
  }
  /** @inheritdoc */
  set required(value: boolean) {
    this.isRequired = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  /** @inheritdoc */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  /** @inheritdoc */
  @Input()
  get value(): string | null {
    if (this.mentionFormControl.valid) {
      return this.mentionFormControl.value;
    }

    // this getter is specified in the MatFormFieldControl
    // class this inherits from and must return null
    return null;
  }
  /** @inheritdoc */
  set value(value: string | null) {
    this.mentionFormControl.setValue(value ?? '');
    this.stateChanges.next();
  }

  /**
   * Callback function that is called when the control's value changes in the UI.
   *
   * This is required as part of the ControlValueAccessor implementation though it is not used here.
   */
  private changeCallback: (value: string) => void = () => {};
  private isDisabled = false;
  private isRequired = false;
  private lastInputStartIndex = 0;
  private lastInputEndIndex = 0;
  private privatePlaceholder: string;
  /**
   * Callback function that is called by the forms API on initialization to update the form model on blur.
   *
   * This is required as part of the ControlValueAccessor implementation though it is not used here.
   */
  private touchedCallback: () => void = () => {};

  constructor(
    private logger: NGXLogger,
    private focusMonitor: FocusMonitor,
    private elementReference: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.focusMonitor.monitor(elementReference, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.touchedCallback();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != undefined) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Retrieves the mention value from the given mention object.
   *
   * @param {Mention} value - The mention object.
   * @return {string} The mention value.
   * @throws {Error} If the mention display key is not provided or if the mention object does not have a string value at the display key.
   */
  getMentionValue(value: Mention): string {
    // Ignore string values.
    if (typeof value === 'string') return value;
    // Require a key.
    assert(
      !!this.mentionDisplayKey,
      'Mention display key must be provided to access a mentions array of objects.'
    );
    // Require a string at the key value.
    assertTypeByKey<Record<string, string>>(
      value,
      this.mentionDisplayKey,
      'string',
      'Mention object must have a string value at the display key.'
    );
    return value[this.mentionDisplayKey];
  }

  /**
   * Sets the mention value in the mention form field.
   *
   * @param {string} value - The new mention value to be set.
   * @return {void}
   */
  setMentionValue(value: string): void {
    const newValue = `${this.mentionReplacementStartChar}${value}${this.mentionReplacementEndChar}`;
    this.logger.debug(
      `New value '${newValue}' will be added: `,
      this.mentionFormControl.value
    );
    const nextControlValue = replaceBetweenIndexes(
      this.mentionFormControl.value ?? '',
      this.lastInputStartIndex,
      this.lastInputEndIndex,
      newValue
    );
    this.mentionFormControl.setValue(nextControlValue);

    const newInputStartIndex = this.lastInputEndIndex + newValue.length;
    const elementRefInput = this.mentionInput as unknown as ElementRef;
    elementRefInput.nativeElement.focus();
    setTimeout(() => {
      elementRefInput.nativeElement.selectionStart = newInputStartIndex;
      elementRefInput.nativeElement.selectionEnd = newInputStartIndex;
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementReference);
  }

  /** @inheritdoc */
  onContainerClick(_: MouseEvent): void {
    if (this.mentionFormControl.valid) {
      this.focusMonitor.focusVia(this.mentionInput, 'program');
    }
  }

  /**
   * Handles input keypress event.
   *
   * @param event Event.
   */
  onKeyPress(event: KeyboardEvent) {
    if (event.key === `${this.mentionTriggerChar}`) {
      const inputElement = this.mentionInput as unknown as ElementRef;
      this.lastInputStartIndex = inputElement.nativeElement.selectionStart;
      this.lastInputEndIndex = inputElement.nativeElement.selectionEnd;
      this.mentionMenutrigger.openMenu();
    }
  }

  /**
   * Handles key up event.
   *
   * @param event Event.
   */
  onKeyUp(event: KeyboardEvent) {
    this.logger.debug('Keyup event raised', event);
  }

  /** @inheritdoc */
  registerOnChange(fn: (value: string) => void): void {
    this.changeCallback = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: () => void): void {
    this.touchedCallback = fn;
  }

  /** @inheritdoc */
  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elementReference.nativeElement.querySelector(
      '.mention-input-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @inheritdoc */
  writeValue(value: string): void {
    this.value = value;
  }
}
