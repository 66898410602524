import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ColumnState } from 'ag-grid-community';

/**
 * Grid column state.
 */
export interface GridState {
  /** Column states. */
  columnStates: ColumnState[];
}

/** Grid Column states. */
export type GridColumnsState = EntityState<GridState, string>;

/**
 * Grid store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'grid' })
export class GridStateStore extends EntityStore<GridColumnsState> {
  constructor() {
    super([]);
  }
}
