import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Archive,
  ArchiveDocumentTransferResult,
  ArchiveMergeDocument,
  ArchiveProvider,
  Archives,
  DXCMatch,
  DXCSource,
  FieldValues,
  IDocumentUpdateSession,
  ImportedArchiveDocument,
  InboxDocumentTransferResult,
  MergeObject,
  MergeResult,
  MergeTarget,
  UserFriendlyError,
  createPermissions,
} from 'models';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  S9ApiArchive,
  S9ApiDataXChangeMatch,
  S9ApiDataXChangeSource,
  S9ApiViewTab,
  createArchive,
  createDXCMatch,
  createDXCSourceFromApi,
  createS9ApiFieldValues,
} from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/**
 * Square 9 API http access.
 *
 * @inheritdoc
 */
@Injectable({ providedIn: 'root' })
export class Square9ApiArchiveService implements ArchiveProvider {
  private apiUrl: string;
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.apiUrl = apiUrl;
      this.basePath = `${apiUrl}/dbs`;
    });
  }

  /** @inheritdoc */
  copyDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationArchiveId: number
  ): Observable<ArchiveDocumentTransferResult> {
    return this.transferDocument(
      databaseId,
      archiveId,
      documentId,
      secureId,
      destinationArchiveId,
      false
    );
  }

  /** @inheritdoc */
  copyDocumentToInbox(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationInboxId: number
  ): Observable<InboxDocumentTransferResult> {
    return this.transferDocumentToInbox(
      databaseId,
      archiveId,
      documentId,
      secureId,
      destinationInboxId,
      false
    );
  }

  /** @inheritdoc */
  deleteDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string
  ): Observable<void> {
    return this.http.get<void>(
      `${this.basePath}/${databaseId}/archives/${archiveId}/documents/${documentId}/delete`,
      {
        params: new HttpParams().set('secureId', secureId),
      }
    );
  }

  /** @inheritdoc */
  get(): Observable<Archive> {
    throw new Error('Method not implemented.');
  }

  /** @inheritdoc */
  getAll(databaseId: number): Observable<Archives> {
    const archives = this.http.get<S9ApiArchive[]>(
      `${this.basePath}/${databaseId}/archives`,
      {
        params: new HttpParams().set('all', 'true'),
      }
    );
    return archives.pipe(
      mergeMap((archives) => {
        return forkJoin(
          archives.map((archive: S9ApiArchive) => {
            // If the user does not have view permissions, skip merging the view tabs.
            if (!createPermissions(archive.Permissions).viewDocuments)
              return of(createArchive(archive));
            return this.getViewTabs(databaseId, archive.Id).pipe(
              map((viewTabs) => {
                return createArchive(archive, viewTabs);
              })
            );
          })
        );
      }),
      map(this.convertRemoteObjectToLocal)
    );
  }

  /** @inheritdoc */
  getDataXChangeSources(
    databaseId: number,
    id: number
  ): Observable<DXCSource[]> {
    //api/dbs/1/archives/19/dxc?token=6d409373-38b5-446f-bb86-1aae1ebc4f47
    return this.http
      .get<S9ApiDataXChangeSource[]>(
        `${this.basePath}/${databaseId}/archives/${id}/dxc`
      )
      .pipe(map((apiSources) => apiSources.map(createDXCSourceFromApi)));
  }

  /** @inheritdoc */
  import(
    databaseId: number,
    archiveId: number,
    filenames: string[],
    session: IDocumentUpdateSession
  ): Observable<ImportedArchiveDocument[]> {
    return this.http
      .post(
        `${this.basePath}/${databaseId}/archives/${archiveId}`,
        {
          files: filenames.map((filename) => ({ name: filename })),
          fields: [], // We don't use this but it is required by the API method.
        },
        {
          observe: 'response',
          headers: new HttpHeaders()
            .set('session-id', session.id)
            .set('session-actions', session.actions),
        }
      )
      .pipe(
        catchError((userFriendlyError: UserFriendlyError) => {
          if (
            userFriendlyError.error.error ===
            'Unregistered document limit reached'
          ) {
            userFriendlyError.i18n = 'UNREGISTERED_DOCUMENT_LIMIT_REACHED';
          }
          return throwError(() => userFriendlyError);
        }),
        map((response) => {
          // API formats header as docHashHeaderString += $"{docId},{sha256($"{ArchiveId}_{docId}{Token}")}";
          const documentString = response.headers.get('Doc-Hashes');
          if (!documentString) {
            throw new Error('Document hashes header was not returned.');
          }

          const documents: ImportedArchiveDocument[] = [];
          const documentsAndSecureIds = documentString.split(',');
          for (
            let index = 0, length = documentsAndSecureIds.length;
            index < length;
            index += 2
          ) {
            const documentId = Number(documentsAndSecureIds[index]);
            const secureId = documentsAndSecureIds[index + 1];
            if (Number.isNaN(documentId)) {
              throw new TypeError('Document id was not a number.');
            }
            documents.push({ id: documentId, secureId });
          }

          return documents;
        })
      );
  }

  /** @inheritdoc */
  mergeDocuments(
    databaseId: number,
    target: MergeTarget,
    baseDocument: ArchiveMergeDocument,
    additionalDocuments: ArchiveMergeDocument[]
  ): Observable<MergeResult> {
    const mergeObject: MergeObject = {
      baseDocument,
      documents: additionalDocuments,
      target,
    };

    return this.http
      .post<number>(`${this.basePath}/${databaseId}/merge`, mergeObject)
      .pipe(
        catchError((userFriendlyError: UserFriendlyError) => {
          // FYI In this catch it looks like userFriendly.error.error is a C# exception object
          // with a 'Message' property.
          if (!userFriendlyError.error?.error) {
            // We don't need to run the code below since it all requires error.error
            return throwError(() => userFriendlyError);
          }

          const innerErrorMessage =
            (userFriendlyError.error.error.Message as string) ?? '';
          if (
            innerErrorMessage.includes('Unregistered document limit reached')
          ) {
            userFriendlyError.i18n = 'UNREGISTERED_DOCUMENT_LIMIT_REACHED';
          } else if (
            innerErrorMessage.includes(
              'The first selected document is not a valid merge type'
            )
          ) {
            userFriendlyError.i18n = 'ERR_MERGE_FIRST_SELECTED_INVALID_TYPE';
          }
          return throwError(() => userFriendlyError);
        }),
        map((newDocumentId) => ({ documentId: newDocumentId }))
      );
  }

  /** @inheritdoc */
  moveDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationArchiveId: number
  ): Observable<ArchiveDocumentTransferResult> {
    return this.transferDocument(
      databaseId,
      archiveId,
      documentId,
      secureId,
      destinationArchiveId,
      true
    );
  }

  /** @inheritdoc */
  moveDocumentToInbox(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationInboxId: number
  ): Observable<InboxDocumentTransferResult> {
    return this.transferDocumentToInbox(
      databaseId,
      archiveId,
      documentId,
      secureId,
      destinationInboxId,
      true
    );
  }

  /** @inheritdoc */
  runDataXChange(
    databaseId: number,
    archiveId: number,
    sourceId: number,
    fieldValues: FieldValues
  ): Observable<DXCMatch[]> {
    ///dbs/1/archives/19/dxc/5?token=6d409373-38b5-446f-bb86-1aae1ebc4f47
    const apiFieldValues = createS9ApiFieldValues(fieldValues);
    return this.http
      .post<S9ApiDataXChangeMatch[]>(
        `${this.basePath}/${databaseId}/archives/${archiveId}/dxc/${sourceId}`,
        apiFieldValues
      )
      .pipe(
        map((apiMatches) =>
          apiMatches.map((apiMatch) => createDXCMatch(apiMatch))
        )
      );
  }

  /**
   * Convert from the server structure to the application structure.
   *
   * @param archives Square9Api formatted archives list.
   * @returns A list of `Archives`.
   */
  private convertRemoteObjectToLocal = (archives: Archives) => {
    // Restructure as a tree with children.
    return archives.map((a: Archive) => {
      a.children = archives.filter((child) => child.parentId === a.id);
      return a;
    });
  };

  /**
   * Get View tabs from the Square9Api.
   *
   * @param databaseId Database identifier.
   * @param archiveId Archive identifier.
   * @returns Observable View Tabs for the archive.
   */
  private getViewTabs(databaseId: number, archiveId: number) {
    const viewTabs = this.http.get<S9ApiViewTab[]>(
      `${this.basePath}/${databaseId}/archives/${archiveId}`,
      {
        params: new HttpParams().set('type', 'tabs'),
      }
    );
    return viewTabs;
  }

  /**
   * Transfers a document to another archive.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param documentId Document ID.
   * @param secureId Document Secure ID.
   * @param destinationArchiveId Destination Archive ID.
   * @param move Determines if the document should be moved or copied.
   * @returns A transfer result object.
   */
  private transferDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationArchiveId: number,
    move: boolean
  ): Observable<ArchiveDocumentTransferResult> {
    const action = move ? 'move' : 'copy';
    return this.http
      .get(
        `${this.basePath}/${databaseId}/archives/${archiveId}/documents/${documentId}/${action}`,
        {
          observe: 'response',
          params: new HttpParams()
            .set('destinationarchive', destinationArchiveId.toString())
            .set('secureid', secureId),
        }
      )
      .pipe(
        catchError((error: UserFriendlyError) => {
          if (error.error.error === 'Unregistered document limit reached') {
            error.i18n = 'UNREGISTERED_DOCUMENT_LIMIT_REACHED';
            return throwError(() => error);
          }

          if (error.error.status !== 405) {
            return throwError(() => error);
          }

          // Here we handle any 405 errors that require mapping errors to translation keys.
          // There are other possible error messages and they can be found in the Square9Api FileController.TransferDocument function.
          switch (error.error.error) {
            case 'DestinationPermissions':
              error.i18n = 'ERROR_DESTINATION_PERMISSIONS';
          }

          return throwError(() => error);
        }),
        map((response) => {
          // DocHashes header is in format 'DOCID,DOCHASH'
          const documentHashHeader = response.headers.get('Doc-Hashes') ?? ''; // TODO: Should this throw if it was empty?
          const documentIdHashPair = documentHashHeader.split(',');
          const newDocumentId = documentIdHashPair[0] as unknown as number;
          const documentHash = documentIdHashPair[0];
          return {
            id: newDocumentId,
            secureId: documentHash,
          };
        })
      );
  }

  /**
   * Transfers an archive document to an inbox.
   *
   * @param databaseId Database ID.
   * @param archiveId Archive ID.
   * @param documentId Document ID.
   * @param secureId Document Secure ID.
   * @param destinationInboxId Destination Inbox ID.
   * @param move Determines if the document should be moved or copied.
   * @returns A transfer result object.
   */
  private transferDocumentToInbox(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    destinationInboxId: number,
    move: boolean
  ): Observable<InboxDocumentTransferResult> {
    return this.http
      .get<string>(`${this.apiUrl}/inboxes/${destinationInboxId}`, {
        params: new HttpParams()
          .set('databaseid', databaseId.toString())
          .set('archiveid', archiveId.toString())
          .set('docid', documentId.toString())
          .set('secureid', secureId)
          .set('targetinboxid', destinationInboxId.toString())
          .set('deleteoriginal', move.toString())
          .set('token', ''),
      })
      .pipe(map((newFilepath) => ({ filepath: newFilepath })));
  }
}
