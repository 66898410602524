<div class="sidebar-group mat-elevation-z1">
  <button
    aria-label="load root archives"
    mat-icon-button
    (click)="loadRootArchives()"
  >
    <mat-icon>inventory_2</mat-icon>
  </button>
  <span transloco="ARCHIVES"></span>
  <span class="spacer"></span>
  <button
    mat-icon-button
    *ngIf="inChildArchive"
    (click)="loadParentArchives()"
    [matTooltip]="breadcrumbString"
    matTooltipShowDelay="1000"
    matTooltipPosition="right"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
  <ng-container *ngIf="rootSearches$ | async as searches">
    <button
      *ngIf="searches.length > 0 && !inChildArchive"
      mat-icon-button
      aria-label="Root archive searches"
      [matMenuTriggerFor]="rootSearchesMenu"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>search</mat-icon>
    </button>
    <app-navigation-expansion-button
      [isExpanded]="(isExpanded$ | async) === true"
      (isExpandedChange)="onExpandChanged()"
    ></app-navigation-expansion-button>
    <mat-menu #rootSearchesMenu="matMenu">
      <button
        *ngFor="let search of searches"
        (click)="clickSearch(search)"
        mat-menu-item
      >
        <mat-icon>search</mat-icon>
        <span>{{ search.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
</div>
<mat-progress-bar
  *ngIf="isArchivesLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<div id="archive-items" *ngIf="isExpanded$ | async">
  <ng-container *ngFor="let archive of displayedArchives$ | async">
    <app-navigation-archive
      *ngIf="(showVersionsArchive$ | async) || archive.name !== 'Versions'"
      [dbId]="database.id"
      [archive]="archive"
      (loadChildArchives)="onLoadChildArchives($event)"
    >
    </app-navigation-archive>
  </ng-container>
</div>
