import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { AppConfigQuery } from '../../app-config';
import { msalInstanceFactory } from '../helpers/azure-config.factory';

/**
 * MSAL Helper Service.
 *
 * Handles configuration of MsalService.
 */
@Injectable({
  providedIn: 'root',
})
export class MsalHelperService {
  private configuredMsalService: MsalService = new MsalService(
    msalInstanceFactory(undefined),
    this.location
  );

  /**
   * Currently configured MsalService.
   *
   * @returns A configured instance of MsalService.
   */
  get msalService(): MsalService {
    return this.configuredMsalService;
  }

  constructor(
    private location: Location,
    private appConfigQuery: AppConfigQuery
  ) {
    this.appConfigQuery.authenticationProviders$.subscribe((providers) => {
      this.configuredMsalService.instance = msalInstanceFactory(
        providers.azure
      );
    });
  }
}
