import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { UrlState, UrlStore } from './url.store';

/** URL Query. */
@Injectable({ providedIn: 'root' })
export class UrlQuery extends Query<UrlState> {
  /** Observable array of URLS. */
  urls$ = this.select('urls');
  constructor(protected store: UrlStore) {
    super(store);
  }

  /**
   * Gets URLs.
   *
   * @returns An array of strings.
   */
  get urls(): string[] {
    return this.getValue().urls;
  }
}
