import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-row-selection',
  templateUrl: './badge-cell-renderer.component.html',
  styleUrls: ['./badge-cell-renderer.component.scss'],
})
export class BadgeCellRendererComponent implements ICellRendererAngularComp {
  /** Cell value. */
  value: string;

  constructor() {}

  /** @inheritdoc */
  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  /** @inheritdoc */
  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}
