<h2 mat-dialog-title>{{ 'INSTALL_GSE' | transloco }}</h2>
<mat-dialog-content>
  <p transloco="GSE_NOT_FOUND_ON_COMPUTER"></p>
  <p transloco="GSE_SUGGEST_CONFIGURE"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'CANCEL' | transloco }}
  </button>
  <span class="spacer"></span>
  <button mat-button (click)="onClickConfiguration()">
    {{ 'CONFIGURE_GSE' | transloco }}
  </button>
  <button mat-button [disabled]="isGseInstalling" (click)="installExtensions()">
    {{ 'INSTALL' | transloco }}
  </button>
</mat-dialog-actions>
