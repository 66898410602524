import { Inject, Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import {
  Archive,
  ArchiveProvider,
  filterCheckInCheckOutPermissions,
  filterReadOnlyPermissions,
} from 'models';
import { NO_ACTIVE_ID } from 'src/app/common/constants';
import { ARCHIVE_PROVIDER } from 'src/app/common/tokens';
import { toNumberOrUndefined } from 'src/app/common/utility';
import { AppConfigQuery } from 'src/app/modules/app-config';

import { ApplicationQuery } from '../application/application.query';

import { ArchivesQuery } from './archives.query';
import { ArchivesStore } from './archives.store';

/**
 * Archives service.
 */
@Injectable({ providedIn: 'root' })
export class ArchivesService {
  /** Archive API access. */
  api = this.archiveProvider;
  /** Observable of all archives. */
  archives$ = this.archivesQuery.selectAll();
  /** Observable of archives loading state. */
  isLoading$ = this.archivesQuery.selectLoading();

  constructor(
    private archivesStore: ArchivesStore,
    private archivesQuery: ArchivesQuery,
    private routerQuery: RouterQuery,
    private appQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private logger: NGXLogger,
    @Inject(ARCHIVE_PROVIDER) private archiveProvider: ArchiveProvider
  ) {
    // Watch route to decide active.
    this.routerQuery
      .selectParams('archiveId')
      .pipe(
        map(toNumberOrUndefined),
        distinctUntilChanged(),
        filter((id) => id !== 0) // Ignore attempts to load the non-existant root archive ID.
      )
      .subscribe((archiveId) => {
        this.archivesStore.setActive(archiveId ?? NO_ACTIVE_ID);
        this.logger.debug(
          typeof archiveId === 'undefined'
            ? 'No archive active.'
            : `Archive ${archiveId} set as active.`
        );
      });
  }

  /**
   * Add an archive to the store.
   *
   * @param archive Archive to add.
   */
  add(archive: Archive) {
    this.archivesStore.add(archive);
  }

  /**
   * Refresh archives for a database.
   *
   * @param databaseId Database ID.
   */
  get(databaseId: number): void {
    if (!databaseId) {
      // Ignore requests to refresh `null`.
      return;
    }
    this.archiveProvider
      .getAll(databaseId)
      .pipe(
        setLoading(this.archivesStore),
        map((archives) => {
          archives = archives.map((archive) => {
            if (archive.isCheckInCheckOut) {
              // Filter check-in/check-out permissions on root archives.
              archive.permissions = filterCheckInCheckOutPermissions(
                archive.permissions
              );

              /** Filter check-in/check-out permissions on the archive's children recursively. */
              this.filterReadOnlyPermissionsOnChildArchives(archive);
            }
            if (this.appQuery.isReadOnlyLicense) {
              // If the user is licensed as read-only, apply a permissions filter to their archive permissions to only allow read actions.

              // Filter root level archive permissions.
              archive.permissions = filterReadOnlyPermissions(
                archive.permissions
              );
              // Filter the permissions on the archive's children recursively.
              this.filterReadOnlyPermissionsOnChildArchives(archive);
            }

            if (this.appConfigQuery.sortArchivesTasksAndInboxesAlphabetically) {
              // Sort each archive's children recursively.
              this.sortArchiveChildren(archive);
            }

            return archive;
          });

          if (this.appConfigQuery.sortArchivesTasksAndInboxesAlphabetically) {
            // Sort root level archives.
            archives = archives.sort((a, b) => {
              const aName = a.name.toLowerCase();
              const bName = b.name.toLowerCase();
              return aName < bName ? -1 : aName > bName ? 1 : 0;
            });
          }
          return archives;
        })
      )
      .subscribe((archives) => {
        this.logger.debug(
          `Refreshing archive list for database ${databaseId}.`
        );
        this.archivesStore.set(archives);
      });
  }

  /**
   * Remove an archive from the store.
   *
   * @param id Archive ID.
   */
  remove(id: number) {
    this.archivesStore.remove(id);
  }

  /**
   * Update an archive in the store.
   *
   * @param id Archive ID.
   * @param archive Archive object properties to update.
   */
  update(id: number, archive: Partial<Archive>) {
    this.archivesStore.update(id, archive);
  }

  /**
   * Removes permissions not allowed in check in check out archives.
   *
   * @param archive Parent archive.
   */
  private filterCheckInCheckOutPermissionsOnChildArchives(
    archive: Archive
  ): void {
    if (!archive.children) {
      return;
    }

    archive.children = archive.children.map((childArchive) => {
      childArchive.permissions = filterCheckInCheckOutPermissions(
        childArchive.permissions
      );
      return childArchive;
    });

    for (const child of archive.children) {
      this.filterCheckInCheckOutPermissionsOnChildArchives(child);
    }
  }

  /**
   * Filters read only permissions on the provided archive's children recursively.
   *
   * @param archive Parent archive.
   */
  private filterReadOnlyPermissionsOnChildArchives(archive: Archive): void {
    if (!archive.children) {
      return;
    }

    archive.children = archive.children.map((childArchive) => {
      childArchive.permissions = filterReadOnlyPermissions(
        childArchive.permissions
      );
      return childArchive;
    });

    for (const child of archive.children) {
      this.filterReadOnlyPermissionsOnChildArchives(child);
    }
  }

  /**
   * Sorts the children on the provided archive recursively.
   *
   * @param archive Parent Archive.
   */
  private sortArchiveChildren(archive: Archive): void {
    // If there are no children, there's nothing to sort
    if (!archive.children) {
      return;
    }

    // Sort the children based on their ID
    archive.children = archive.children.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    });

    // Recursively sort the children's children
    for (const child of archive.children) {
      this.sortArchiveChildren(child);
    }
  }
}
