import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';

/** Describes the GSE settings form. */
interface SettingsForm {
  /** Port. */
  port: FormControl<number>;
}

/** GSE Configuration Dialog. */
@Component({
  selector: 'app-gseconfiguration-dialog',
  templateUrl: './gseconfiguration-dialog.component.html',
  styleUrls: ['./gseconfiguration-dialog.component.scss'],
})
export class GSEConfigurationDialogComponent implements OnInit {
  /** Settings form. */
  settings: FormGroup<SettingsForm>;
  /** Whether a test connection was successful. */
  testedConnectionSuccessfully: boolean;

  private newUrl: string;

  /**
   * Whether save should be disabled.
   *
   * @returns A boolean.
   */
  get disableSave(): boolean {
    return !this.settings.dirty || !this.testedConnectionSuccessfully;
  }

  /**
   * Whether the test message should be displayed.
   *
   * @returns A boolean.
   */
  get showTestMessage(): boolean {
    return this.settings.dirty && !this.testedConnectionSuccessfully;
  }

  constructor(
    private logger: NGXLogger,
    private gse: GseService,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private notify: NotificationService,
    private dialog: MatDialogReference<GSEConfigurationDialogComponent>
  ) {}

  ngOnInit(): void {
    const url = new URL(this.appQuery.gseConfig.apiUrl);
    const port = Number(url.port);
    if (Number.isNaN(port)) {
      // TODO decide if this check is necessary. There is no way to enter text into this input.
      this.logger.error('Port is not a number.', port);
    }
    this.settings = new FormGroup<SettingsForm>({
      port: new FormControl(port, { nonNullable: true }),
    });

    // Reset test connection whenever the port value changes.
    this.settings.controls.port.valueChanges.subscribe(
      () => (this.testedConnectionSuccessfully = false)
    );
  }

  /** Handler for the save click event. */
  onSave(): void {
    if (!this.newUrl) {
      this.logger.warn('GSE config save called without a new url.');
      return;
    }
    this.logger.debug('Saving new GSE URL.', this.newUrl);
    this.appService.setGSEUrl(this.newUrl);
    this.gse.testConnection().subscribe((isConnected) => {
      if (isConnected) {
        this.dialog.close();
      } else {
        // Display an error if the user somehow manages to successfully test the connection and then it fails on save.
        this.onTestConnectFailure();
      }
    });
  }

  /** Handler for the test connection click event. */
  onTestConnection(): void {
    let url = 'https://127.0.0.1:' + this.settings.controls.port.value;
    this.logger.debug(`Testing connection to GSE using: ${url}`);
    // Try with SSL first.
    this.gse.api.testNewUrl(url).subscribe({
      next: () => this.onTestConnectSuccess(url),
      // If it fails try http.
      error: () => {
        url = 'http://localhost:' + this.settings.controls.port.value;
        this.gse.api.testNewUrl(url).subscribe({
          next: () => this.onTestConnectSuccess(url),
          error: () => this.onTestConnectFailure(),
        });
      },
    });
  }

  private onTestConnectFailure() {
    this.testedConnectionSuccessfully = false;
    this.notify.error({
      error: undefined,
      description: 'GSE test connection failed.',
      i18n: 'TEST_CONNECTION_FAILED',
    });
  }

  private onTestConnectSuccess(url: string) {
    this.testedConnectionSuccessfully = true;
    this.newUrl = url;
    this.notify.success('TEST_CONNECTION_SUCCESSFUL');
  }
}
