import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  of,
  shareReplay,
} from 'rxjs';

import { ApplicationQuery } from '../state/application/application.query';
import { DatabasesQuery } from '../state/databases/databases.query';

/**
 * Stores information about current layout.
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  /**
   * Observable containing if currently viewed on handset.
   */
  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  /**
   * Observable of whether a compact layout should be used.
   */
  useCompactLayout$: Observable<boolean>;

  /**
   * Get the last stored value of the right sidebar width.
   *
   * @returns Width in pixels.
   */
  get rightSidebarWidth() {
    return this.rightSidebarWidthSubject.getValue();
  }

  /**
   * Sets a new right sidebar width.
   */
  set rightSidebarWidth(value: number) {
    this.rightSidebarWidthSubject.next(value);
  }

  private rightSidebarWidth$: Observable<number>;
  private rightSidebarWidthSubject = new BehaviorSubject<number>(0);
  private useCompactLayoutSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: NGXLogger,
    private breakpointObserver: BreakpointObserver,
    private databaseQuery: DatabasesQuery,
    private appQuery: ApplicationQuery
  ) {
    this.rightSidebarWidth$ = this.rightSidebarWidthSubject.asObservable();
    const windowSize$ = merge(
      of(window.innerWidth), // Initial width.
      fromEvent(window, 'resize').pipe(
        debounceTime(100),
        map((event: any) => (event.target?.innerWidth as number) ?? 0)
      )
    );

    this.useCompactLayout$ = this.useCompactLayoutSubject
      .asObservable()
      .pipe(distinctUntilChanged());
    this.useCompactLayout$.subscribe((result) => {
      this.logger.debug('Use compact layout changed', result);
    });

    // Combine all changes to the size of the window or the right sidebar.
    combineLatest([
      windowSize$, // Window size is only needed to trigger not for its data.
      this.rightSidebarWidth$,
      this.appQuery.rightSidebarOpen$,
    ]).subscribe(([, rightSidebarWidth, rightSidebarOpen]) => {
      // This is the current narrowest the window can be with the left sidebar
      // and a long archive name shown, and still not block a button.
      const cutoffSize = 767;
      const maxWidth = rightSidebarOpen
        ? cutoffSize + rightSidebarWidth
        : cutoffSize;
      // Create a breakpoint observation for crossing the cutoff space.
      const breakpointMatched = this.breakpointObserver.isMatched(
        `(max-width: ${maxWidth}px)`
      );
      this.useCompactLayoutSubject.next(breakpointMatched);
    });

    // Listen to see if we are at the database route.
    this.databaseQuery.isDatabaseRoute$.subscribe((isDatabaseRoute) => {
      if (isDatabaseRoute) {
        // The right sidebar cannot display while in the database route so set its width to 0.
        this.rightSidebarWidthSubject.next(0);
      }
    });
  }
}
